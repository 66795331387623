import Api from "../Api";

export const getStates = async () => {
  try {
    const response = await Api.get("/get-states");
    return response;
  } catch (error) {
    throw error;
  }
};

export const getDistByStateId = async (id) => {
  try {
    const response = await Api.get(`/get-dist/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
