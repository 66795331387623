import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  getDistByStateId,
  getStates,
} from "../../Services/AdminApis/StateandDistrictApi";
import "../../Styles/SchoolCreate.css";
import { BranchValidation } from "../../Utility/CommonUtility/Validation";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import MultiStepForm from "../../Components/CommonComponents/Multistepform";
import { useNavigate, useParams } from "react-router-dom";
import {
  getBranchById,
  updateBranch,
} from "../../Services/CommonApis/branchApi";
import { getAllAcademics } from "../../Services/AdminApis/AcademicApi";
import BeatLoader from "react-spinners/BeatLoader";

const BranchEdit = () => {
  const { id, branchId } = useParams();
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [isDistrictLoading, setIsDistrictLoading] = useState(false);
  const progressBarRef = useRef(null);
  const [academic, setAcademic] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    academic_id: "",
    branch_name: "",
    branch_code: "",
    branch_email: "",
    branch_phone: "",
    branch_city: "",
    branch_dist: "",
    branch_state: "",
    branch_pin: "",
    branch_address: "",
    print_file: null,
    report_card: null,
    logo_file: null,
    text_logo: null,
    period_attendance: "",
    subject_select: "",
    personality_traits: "",
    id_card_template: "",
    receipt_template: "",
    report_card_template: "",
    office_number: "",
    call_center_no: "",
    website: "",
  });
  const steps = 3;

  const prevStep = () => {
    setCurrent((prevCurrent) =>
      prevCurrent > 1 ? prevCurrent - 1 : prevCurrent
    );
  };

  const generateBranchCode = (schoolName) => {
    if (!schoolName) return "";
    const words = schoolName.trim().split(" ");
    if (words.length === 1) {
      return words[0].substring(0, 3).toUpperCase();
    }
    return words
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  useEffect(() => {
    if (initialValues.branch_state) {
      fetchDistrict(initialValues.branch_state);
    }
  }, [initialValues.branch_state]);

  const fetchStates = async () => {
    try {
      setIsStateLoading(true);
      const response = await getStates();
      setStates(response.data.states);
    } catch (error) {
      console.error("Error fetching schools:", error);
    } finally {
      setIsStateLoading(false);
    }
  };

  const fetchDistrict = async (stateId) => {
    try {
      setIsDistrictLoading(true);
      const response = await getDistByStateId(stateId);
      setDistricts(response.data.data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    } finally {
      setIsDistrictLoading(false);
    }
  };

  const goToStep = (step) => {
    setCurrent(step);
  };

  const fetchAcademicYear = async () => {
    try {
      const response = await getAllAcademics();
      const academicYearsArray = response.data.data;
      setAcademic(academicYearsArray);
    } catch (error) {
      console.error("Error fetching academic years:", error);
      setAcademic([]);
    }
  };

  useEffect(() => {
    const fetchBranchDetails = async () => {
      try {
        const response = await getBranchById(branchId);
        if (response.status === 200) {
          const branchData = response.data.branch;
          setInitialValues({
            academic_id: branchData.academic_id,
            branch_name: branchData.branch_name,
            branch_code: branchData.branch_code,
            branch_email: branchData.email,
            branch_phone: branchData.phone,
            office_number: branchData.office_number,
            call_center_no: branchData.call_center_no,
            website: branchData.website,
            branch_city: branchData.city,
            branch_dist: branchData.dist,
            branch_state: branchData.state,
            branch_pin: branchData.pin,
            branch_address: branchData.address,
            print_file: branchData?.branch_meta?.print_file || null,
            report_card: branchData.branch_meta?.report_card || null,
            logo_file: branchData.branch_meta?.logo_file || null,
            text_logo: branchData.branch_meta?.text_logo || null,
            period_attendance: branchData.branch_meta.period_attendance,
            personality_traits: branchData.branch_meta.personality_traits,
            subject_select: branchData.branch_meta.subject_select,
            report_card_template:
              branchData.branch_meta?.report_card_template?.id || null,
            id_card_template:
              branchData.branch_meta?.id_card_template?.id || null,
            receipt_template:
              branchData.branch_meta?.receipt_template?.id || null,

            id_card_template_file_path:
              branchData.branch_meta?.id_card_template?.file_path || null,
            receipt_template_file_path:
              branchData.branch_meta?.receipt_template?.file_path || null,
            report_card_template_file_path:
              branchData.branch_meta?.report_card_template?.file_path || null,
            principalSignature:
              branchData.branch_meta?.principal_signature || null,
            principal_signature:
              branchData.branch_meta?.principal_signature || null,
            student_photo: branchData.branch_meta?.student_photo || false,
          });
          setLoading(false);
        } else {
          showErrorAlert("Error!", "Failed to fetch branch details.");
        }
      } catch (error) {
        console.error("API error:", error);
        showErrorAlert(
          "Error!",
          "An error occurred while fetching branch details."
        );
      }
    };
    fetchAcademicYear();
    fetchBranchDetails();
    fetchStates();
  }, [branchId]);

  const setProgressBar = useCallback(
    (curStep) => {
      const percent = ((100 / steps) * curStep).toFixed();
      if (progressBarRef.current) {
        progressBarRef.current.style.width = `${percent}%`;
      }
    },
    [steps]
  );

  useEffect(() => {
    setProgressBar(current);
  }, [current, setProgressBar]);

  const getBorderColor = (fieldName, touched, errors) => {
    return touched[fieldName] && errors[fieldName] ? "red" : "skyblue";
  };

  const handleSubmit = async (values) => {
    console.log(values);
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to update the branch",
      "info",
      "Yes, Update",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      showLoadingAlert();
      try {
        const response = await updateBranch(branchId, values);
        if (response.status === 201) {
          showSuccessAlert(
            "Updated!",
            "The branch has been updated successfully."
          );
          navigate(`/admin/school/${id}/branch-list`);
        } else {
          const errorMessage =
            response.data.message || "Branch update failed. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (error) {
        console.error("API error:", error);
        const errorMessage =
          error.response.data.message ||
          "An error occurred during branch update.";
        showErrorAlert("Error!", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Branch update has been cancelled.");
    }
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Branch Edit</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Branch Edit</li>
              </ol>
            </div>
          </div>
          <div className="card">
            <div className="head-card">
              <div className="row ml-5 text-center">
                <div className="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={BranchValidation[`tab${current}`]}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      touched,
                      errors,
                      setFieldValue,
                      setTouched,
                    }) => (
                      <Form id="msform">
                        <ul id="progressbar">
                          <li
                            className={current >= 1 ? "active" : ""}
                            id="account"
                            onClick={() => goToStep(1)}
                            style={{ width: "350px" }}
                          >
                            <strong>Branch Information</strong>
                          </li>
                          <li
                            className={current >= 2 ? "active" : ""}
                            id="payment"
                            onClick={() => goToStep(2)}
                            style={{ width: "350px" }}
                          >
                            <strong>Branch Address</strong>
                          </li>
                          <li
                            className={current >= 3 ? "active" : ""}
                            id="images"
                            onClick={() => goToStep(3)}
                            style={{ width: "350px" }}
                          >
                            <strong>Logo and Templates</strong>
                          </li>
                        </ul>
                        <div className="progress">
                          <div
                            ref={progressBarRef}
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <br />
                        {loading ? (
                          <div className="text-center mt-5">
                            <BeatLoader
                              size={15}
                              color={"#123abc"}
                              loading={loading}
                            />
                          </div>
                        ) : (
                          <div>
                            {current === 1 && (
                              <div
                                className="form-step"
                                style={{
                                  border: "2px solid lightSkyBlue",
                                  borderRadius: "8px",
                                }}
                              >
                                <fieldset>
                                  <div className="form-card">
                                    <div className="row">
                                      <div className="col-7">
                                        <h2 className="fs-title mt-3 ml-3">
                                          Branch Information:
                                        </h2>
                                      </div>
                                      <div className="col-5">
                                        <h2 className="steps mr-3 mt-3">
                                          Step 1 - {steps - 2}
                                        </h2>
                                      </div>
                                    </div>
                                    <div className="row ml-3 mr-3 mt-3">
                                      <div className="form-group col-6">
                                        <label htmlFor="branchName">
                                          Branch Name{" "}
                                          <span className="mandatory">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          id="branchName"
                                          name="branch_name"
                                          placeholder="Enter branch name"
                                          style={{ borderColor: "skyblue" }}
                                          onChange={(e) => {
                                            const { value } = e.target;
                                            setFieldValue("branch_name", value);
                                            const branchCode =
                                              generateBranchCode(value);
                                            setFieldValue(
                                              "branch_code",
                                              branchCode
                                            );
                                          }}
                                        />

                                        <ErrorMessage
                                          name="branch_name"
                                          component="div"
                                          className="text-error"
                                        />
                                      </div>
                                      <div className="form-group col-6">
                                        <label htmlFor="branchCode">
                                          Branch Code{" "}
                                          <span className="mandatory">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          id="branchCode"
                                          name="branch_code"
                                          placeholder="Enter branch code"
                                          style={{ borderColor: "skyblue" }}
                                        />
                                        <ErrorMessage
                                          name="branch_code"
                                          component="div"
                                          className="text-error"
                                        />
                                      </div>
                                    </div>
                                    <div className="row ml-3 mr-3 mt-3">
                                      <div className="form-group col-6">
                                        <label htmlFor="email">
                                          Email{" "}
                                          <span className="mandatory">*</span>
                                        </label>
                                        <Field
                                          type="email"
                                          className="form-control"
                                          id="email"
                                          name="branch_email"
                                          placeholder="Enter email"
                                          style={{ borderColor: "skyblue" }}
                                        />
                                        <ErrorMessage
                                          name="branch_email"
                                          component="div"
                                          className="text-error"
                                        />
                                      </div>
                                      <div className="form-group col-6">
                                        <label htmlFor="mobileNo">
                                          Mobile No{" "}
                                          <span className="mandatory">*</span>
                                        </label>
                                        <Field
                                          type="number"
                                          className="form-control"
                                          id="mobileNo"
                                          name="branch_phone"
                                          placeholder="Enter mobile number"
                                          onInput={(e) => {
                                            e.target.value = e.target.value
                                              .replace(/\D/g, "")
                                              .slice(0, 10);
                                          }}
                                          style={{
                                            borderColor: getBorderColor(
                                              "branch_phone",
                                              touched,
                                              errors
                                            ),
                                            WebkitAppearance: "none",
                                            MozAppearance: "textfield",
                                          }}
                                          onKeyPress={(e) => {
                                            if (
                                              !/[0-9]/.test(e.key) &&
                                              e.key !== "Backspace"
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                        <ErrorMessage
                                          name="branch_phone"
                                          component="div"
                                          className="text-error"
                                        />
                                      </div>

                                      <div className="form-group col-6">
                                        <label htmlFor="call_center_no">
                                          Call Centre Number
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          id="callCentreNo"
                                          name="call_center_no"
                                          value={values.call_center_no}
                                          onBlur={(e) =>
                                            setFieldValue(
                                              "call_center_no",
                                              e.target.value
                                            )
                                          }
                                          style={{
                                            borderColor: getBorderColor(
                                              "call_center_no",
                                              touched,
                                              errors
                                            ),
                                            letterSpacing: "1px",
                                          }}
                                        />
                                        <ErrorMessage
                                          name="call_center_no"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>

                                      <div className="form-group col-6">
                                        <label htmlFor="office_number">
                                          Office Number
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          id="officeNumber"
                                          name="office_number"
                                          value={values.office_number}
                                          onBlur={(e) =>
                                            setFieldValue(
                                              "office_number",
                                              e.target.value
                                            )
                                          }
                                          style={{
                                            borderColor: getBorderColor(
                                              "office_number",
                                              touched,
                                              errors
                                            ),
                                            letterSpacing: "1px",
                                          }}
                                        />
                                        <ErrorMessage
                                          name="office_number"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>

                                      <div className="form-group col-6">
                                        <label htmlFor="website">Website</label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          id="website"
                                          name="website"
                                          placeholder="https://www.example.com"
                                          style={{
                                            borderColor: getBorderColor(
                                              "website",
                                              touched,
                                              errors
                                            ),
                                            letterSpacing: "1px",
                                          }}
                                        />
                                        <ErrorMessage
                                          name="website"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                      <div className="form-group col-6">
                                        <label htmlFor="academic_id">
                                          Academic Year{" "}
                                          <span className="mandatory">*</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="academic_id"
                                          className="form-control"
                                        >
                                          <option value="">Select Year</option>
                                          {academic.map((year) => {
                                            const startDate = new Date(
                                              year.start_date
                                            );
                                            const endDate = new Date(
                                              year.end_date
                                            );

                                            const startMonthYear =
                                              startDate.toLocaleString(
                                                "default",
                                                {
                                                  month: "short",
                                                  year: "numeric",
                                                }
                                              );
                                            const endMonthYear =
                                              endDate.toLocaleString(
                                                "default",
                                                {
                                                  month: "short",
                                                  year: "numeric",
                                                }
                                              );

                                            const formattedDateRange = `${startMonthYear} - ${endMonthYear}`;

                                            return (
                                              <option
                                                key={year.id}
                                                value={year.id}
                                              >
                                                {formattedDateRange}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                        <ErrorMessage
                                          name="academic_id"
                                          component="div"
                                          className="text-error"
                                        />
                                      </div>
                                      <div className="form-group col-6 d-flex mt-4">
                                        <Field
                                          type="checkbox"
                                          id="period_attendance"
                                          name="period_attendance"
                                          className="form-control mt-2"
                                          checked={
                                            values.period_attendance === "1"
                                          } // Ensure this comparison matches the form value
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "period_attendance",
                                              e.target.checked ? "1" : ""
                                            );
                                          }}
                                        />
                                        <label
                                          htmlFor="period_attendance"
                                          className="mt-1 ml-2"
                                        >
                                          Enable Period wise attendance
                                        </label>
                                      </div>

                                      <div className="form-group col-6 d-flex  flex-row">
                                        <label className="mt-1">
                                          Select Subject for
                                          <span className="mandatory">* </span>
                                        </label>
                                        <div className="d-flex align-items-center ml-3 mb-1">
                                          <div className="form-check mr-3">
                                            <Field
                                              type="radio"
                                              className="form-check-input mt-2"
                                              id="class"
                                              name="subject_select"
                                              value="1"
                                              checked={
                                                values.subject_select === "1"
                                              }
                                              onChange={() =>
                                                setFieldValue(
                                                  "subject_select",
                                                  "1"
                                                )
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="class"
                                            >
                                              Class
                                            </label>
                                          </div>
                                          <div className="form-check mr-3">
                                            <Field
                                              type="radio"
                                              className="form-check-input mt-2"
                                              id="section"
                                              name="subject_select"
                                              value="0"
                                              checked={
                                                values.subject_select === "0"
                                              }
                                              onChange={() =>
                                                setFieldValue(
                                                  "subject_select",
                                                  "0"
                                                )
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="section"
                                            >
                                              Section
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group col-6 d-flex  flex-row">
                                        <label className="mt-1">
                                          Personality Traits
                                          <span className="mandatory">* </span>
                                        </label>
                                        <div className="d-flex align-items-center ml-3 mb-1">
                                          <div className="form-check mr-3">
                                            <Field
                                              type="radio"
                                              className="form-check-input mt-2"
                                              id="yes"
                                              name="personality_traits"
                                              value="1"
                                              checked={
                                                values.personality_traits ===
                                                "1"
                                              }
                                              onChange={() =>
                                                setFieldValue(
                                                  "personality_traits",
                                                  "1"
                                                )
                                              }
                                            />
                                            <label
                                              className="form-check-label mt-1 "
                                              htmlFor="yes"
                                            >
                                              Yes
                                            </label>
                                          </div>
                                          <div className="form-check mr-3 ">
                                            <Field
                                              type="radio"
                                              className="form-check-input mt-2"
                                              id="no"
                                              name="personality_traits"
                                              value="0"
                                              checked={
                                                values.personality_traits ===
                                                "0"
                                              }
                                              onChange={() =>
                                                setFieldValue(
                                                  "personality_traits",
                                                  "0"
                                                )
                                              }
                                            />
                                            <label
                                              className="form-check-label mt-1"
                                              htmlFor="no"
                                            >
                                              No
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                            )}
                            {current === 2 && (
                              <div
                                className="form-step"
                                style={{
                                  border: "2px solid lightSkyBlue",
                                  borderRadius: "8px",
                                }}
                              >
                                <fieldset>
                                  <div className="form-card">
                                    <div className="row">
                                      <div className="col-7">
                                        <h2 className="fs-title mt-3 ml-3">
                                          Branch Address:
                                        </h2>
                                      </div>
                                      <div className="col-5">
                                        <h2 className="steps mr-3 mt-3">
                                          Step 2 - {steps - 1}
                                        </h2>
                                      </div>
                                    </div>
                                    <div className="row ml-3 mr-3 mt-3">
                                      <div className="form-group col-6">
                                        <label htmlFor="branchCity">
                                          Branch City{" "}
                                          <span className="mandatory">*</span>
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          id="branchCity"
                                          name="branch_city"
                                          placeholder="Enter branch city"
                                          style={{ borderColor: "skyblue" }}
                                        />
                                        <ErrorMessage
                                          name="branch_city"
                                          component="div"
                                          className="text-error"
                                        />
                                      </div>
                                      <div className="form-group col-6">
                                        <label htmlFor="branchState">
                                          Branch State{" "}
                                          <span className="mandatory">*</span>
                                        </label>
                                        <Field
                                          as="select"
                                          className="form-control"
                                          id="branchState"
                                          name="branch_state"
                                          placeholder="Select state"
                                          onChange={(e) => {
                                            const stateId = e.target.value;
                                            setFieldValue(
                                              "branch_state",
                                              stateId
                                            );
                                            fetchDistrict(stateId);
                                          }}
                                        >
                                          <option
                                            value=""
                                            label="Select State"
                                          />
                                          {isStateLoading ? (
                                            <option>Loading states...</option>
                                          ) : (
                                            states.map((state) => (
                                              <option
                                                key={state.id}
                                                value={state.id}
                                                label={state.name}
                                              />
                                            ))
                                          )}
                                        </Field>
                                        <ErrorMessage
                                          name="branch_state"
                                          component="div"
                                          className="text-error"
                                        />
                                      </div>
                                      <div className="form-group col-6">
                                        <label htmlFor="branchDist">
                                          Branch District{" "}
                                          <span className="mandatory">*</span>
                                        </label>
                                        <Field
                                          as="select"
                                          className="form-control"
                                          id="branchDist"
                                          name="branch_dist"
                                          placeholder="Enter district"
                                        >
                                          <option
                                            value=""
                                            label="Select Districts"
                                          />
                                          {isDistrictLoading ? (
                                            <option>
                                              Loading districts...
                                            </option>
                                          ) : (
                                            districts.map((district) => (
                                              <option
                                                key={district.id}
                                                value={district.id}
                                                label={district.name}
                                              />
                                            ))
                                          )}
                                        </Field>
                                        <ErrorMessage
                                          name="branch_dist"
                                          component="div"
                                          className="text-error"
                                        />
                                      </div>
                                      <div className="form-group col-6">
                                        <label htmlFor="branchPin">
                                          Branch PIN{" "}
                                          <span className="mandatory">*</span>
                                        </label>
                                        <Field
                                          type="number"
                                          className="form-control"
                                          id="branchPin"
                                          name="branch_pin"
                                          placeholder="Enter branch PIN"
                                          style={{ borderColor: "skyblue" }}
                                          onInput={(e) => {
                                            e.target.value = e.target.value
                                              .replace(/\D/g, "")
                                              .slice(0, 6);
                                          }}
                                          onKeyPress={(e) => {
                                            if (
                                              !/[0-9]/.test(e.key) &&
                                              e.key !== "Backspace"
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                        />
                                        <ErrorMessage
                                          name="branch_pin"
                                          component="div"
                                          className="text-error"
                                        />
                                      </div>
                                    </div>
                                    <div className="row ml-3 mr-3 mt-3">
                                      <div className="form-group col-12">
                                        <label htmlFor="branchAddress">
                                          Branch Address{" "}
                                          <span className="mandatory">*</span>
                                        </label>
                                        <Field
                                          as="textarea"
                                          className="form-control"
                                          id="branchAddress"
                                          name="branch_address"
                                          placeholder="Enter branch address"
                                          style={{ borderColor: "skyblue" }}
                                        />
                                        <ErrorMessage
                                          name="branch_address"
                                          component="div"
                                          className="text-error"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                            )}
                            {current === 3 && (
                              <div
                                className="form-step"
                                style={{
                                  border: "2px solid lightSkyBlue",
                                  borderRadius: "8px",
                                }}
                              >
                                <fieldset>
                                  <div className="form-card">
                                    <div className="row">
                                      <div className="col-7">
                                        <h2 className="fs-title mt-3 ml-3">
                                          Logo and Templates:
                                        </h2>
                                      </div>
                                      <div className="col-5">
                                        <h2 className="steps mr-3 mt-3">
                                          Step 2 - {steps}
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                </fieldset>
                                <MultiStepForm />
                              </div>
                            )}
                          </div>
                        )}

                        <div className="row justify-content-between">
                          <div className="col-4">
                            <button
                              type="button"
                              name="previous"
                              className="action-button-previous"
                              value="Previous"
                              onClick={prevStep}
                            >
                              Previous
                            </button>
                          </div>
                          <div className="col-4 text-right">
                            {current < steps && (
                              <button
                                type="button"
                                className="next action-button"
                                onClick={() => {
                                  const currentTabValidationSchema =
                                    BranchValidation[`tab${current}`];
                                  currentTabValidationSchema
                                    .validate(values, { abortEarly: false })
                                    .then(() => {
                                      setCurrent(current + 1);
                                      setTouched({});
                                    })
                                    .catch((validationErrors) => {
                                      const errors =
                                        validationErrors.inner.reduce(
                                          (acc, err) => {
                                            acc[err.path] = err.message;
                                            return acc;
                                          },
                                          {}
                                        );
                                      setTouched(errors);
                                    });
                                }}
                              >
                                Next
                              </button>
                            )}
                            {current === steps && (
                              <button
                                type="submit"
                                name="submit"
                                className="action-button"
                                value="Submit"
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BranchEdit;
