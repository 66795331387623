import { React, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getAllAcademics } from "../../Services/AdminApis/AcademicApi";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { createAcademicSetup } from "../../Services/CommonApis/FeesApi";
import { getAllFees } from "../../Services/CommonApis/FeesApi";
import Select from "react-select";
import { getFeesSubComponentsByFCID } from "../../Services/CommonApis/FeesApi";
import { getSession } from "../../Services/Storage";
import { COOKIE } from "../../Schemas/cookieNames";
import { useNavigate } from "react-router-dom";

function AcademicFeeCreate() {
  const { branchId } = useParams();
  const { schoolId } = useParams();
  const navigate = useNavigate();
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [classId, setClassId] = useState(null);
  const [isClassLoading, setClassLoading] = useState(true);
  const [isSectionLoading, setSectionLoading] = useState(null);
  const [feeTypeLoding, setFeetypeLoading] = useState(true);
  const [fees, setFees] = useState([]);
  const [selectedFeeTypes, setSelectedFeeTypes] = useState([]);
  const [feesSubComponents, setFeesSubComponents] = useState([]);
  const academicId = getSession(COOKIE.ACADEMIC_YEAR);

  useEffect(() => {
    fetchClassByBranchId(branchId);
    fetchFeeComponents();
  }, []);

  const fetchFeeComponents = async (branchId) => {
    try {
      const response = await getAllFees(branchId);
      setFees(response.data.data);
      setFeetypeLoading(false);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const fetchClassByBranchId = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
      setClassLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubComponetsByFCID = async (id) => {
    try {
      let response = await getFeesSubComponentsByFCID(id);
  
      if (response && response.length > 0) { 
        setFeesSubComponents((prev) => ({
          ...prev,
          [id]: response, 
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const handleSubmit = async (values) => {
    console.log(values);

    const result = await showSweetAlert(
      "Are you sure?",
      "You want to create the academic setup",
      "info",
      "Yes, Create",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      showLoadingAlert();
      try {
        const formattedData = values.fee_type.map((feeTypeId) => {
          return {
            comp_id: feeTypeId,
            data:
              values.fee_type_details[feeTypeId]?.map((detail) => ({
                id: detail.id,
                fee_sub_component: detail.select,
                start_date: detail.start_date,
                end_date: detail.end_date,
                reminder_day: detail.reminder_day,
                amount_old: detail.amount_old,
                amount_new: detail.amount_new,
                show_on_receipt: detail.show_on_receipt,
                online_payment: detail.online_payment,
              })) || [],
          };
        });

        console.log(formattedData, "formattedData");

        // Prepare the final payload
        const payload = {
          class_id: values.class_id,
          section_id: values.section_id,
          academic_id: academicId,
          school_id: schoolId,
          branch_id: branchId,
          fees: formattedData,
        };

        console.log(payload, "payload");

        const response = await createAcademicSetup(payload);
        if (response.status === 201) {
          showSuccessAlert(response.data.data);
          // navigate(`/school/${schoolId}/branch/${branchId}/academic-fee-setup`);
        } else {
          const errorMessage =
            response.data?.message ||
            "Academic setup creation failed. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";
        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert(
        "Cancelled",
        "Academic setup creation has been cancelled."
      );
    }
  };

  const initialValues = {
    class_id: [],
    fee_type: [],
    fee_type_details: {},
  };
  const validationSchema = Yup.object().shape({
    class_id: Yup.array().required("Class is required"),
    fee_type: Yup.array().min(1, "At least one fee type must be selected"),
    fee_type_details: Yup.object().shape({
      ...Object.fromEntries(
        selectedFeeTypes.map((feeTypeId) => [
          [feeTypeId],
          Yup.array().of(
            Yup.object().shape({
              fee_sub_component: Yup.string().required(
                "Fee sub component is required"
              ),
              start_date: Yup.date()
                .required("Start date is required")
                .nullable(),
              end_date: Yup.date()
                .required("End date is required")
                .nullable()
                .test(
                  "is-greater",
                  "End date must be after start date",
                  function (endDate) {
                    const { start_date } = this.parent;
                    return endDate && start_date
                      ? new Date(endDate) > new Date(start_date)
                      : true;
                  }
                ),
              reminder_day: Yup.number()
                .typeError("Reminder day must be a number")
                .required("Reminder day is required"),
              amount_old: Yup.number()
                .typeError("Amount for old students must be a number")
                .required("Amount for old students is required"),
              amount_new: Yup.number()
                .typeError("Amount for new students must be a number")
                .required("Amount for new students is required"),
              show_on_receipt: Yup.boolean(),
              online_payment: Yup.boolean(),
            })
          ),
        ])
      ),
    }),
  });

  return (
    <>
      <div className="col-md-12">
        <div className="card-primary">
          <div className="card-header">
            <h3 className="card-title">
              Academic Fee Creation <small>Form</small>
            </h3>
          </div>

          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ setFieldValue, values }) => (
              <Form id="form">
                <div className="card p-4">
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label htmlFor="class_id">
                        Class Name <span className="mandatory">*</span>
                      </label>
                      <Field name="class_id">
                        {({ field, form }) => {
                          const options = classList.map((cls) => ({
                            value: cls.id,
                            label: cls.name,
                          }));

                          const selectAllOption = {
                            value: "selectAll",
                            label: "Select All",
                          };

                          const handleChange = (selectedOptions) => {
                            let selectedClassIds = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];

                            // Handle "Select All"
                            if (selectedClassIds.includes("selectAll")) {
                              selectedClassIds = options.map(
                                (option) => option.value
                              );
                            }

                            form.setFieldValue("class_id", selectedClassIds);
                            setClassId(selectedClassIds);
                          };

                          return (
                            <Select
                              id="class_id"
                              options={[selectAllOption, ...options]}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              onChange={handleChange}
                              value={options.filter((option) =>
                                (form.values.class_id || []).includes(
                                  option.value
                                )
                              )}
                              placeholder="Select Class"
                              getOptionLabel={(e) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      e.value === "selectAll"
                                        ? options.length ===
                                          form.values.class_id.length
                                        : form.values.class_id.includes(e.value)
                                    }
                                    readOnly
                                    style={{ marginRight: "10px" }}
                                  />
                                  {e.label}
                                </div>
                              )}
                            />
                          );
                        }}
                      </Field>
                      <ErrorMessage
                        name="class_id"
                        component="div"
                        className="text-error"
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="fee_type">
                        Fee Type <span className="mandatory">*</span>
                      </label>
                      <Field name="fee_type">
                        {({ field, form }) => {
                          const options = fees.map((fee) => ({
                            value: fee.id,
                            label: fee.name,
                          }));

                          const handleChange = async (selectedOptions) => {
                            let selectedFeeTypeIds = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];

                            if (selectedFeeTypeIds.includes("selectAll")) {
                              selectedFeeTypeIds = options.map(
                                (option) => option.value
                              );
                            }

                            setFieldValue("fee_type", selectedFeeTypeIds);
                            setSelectedFeeTypes(selectedFeeTypeIds);

                            const updatedFeeTypeDetails = {
                              ...form.values.fee_type_details,
                            };

                            for (const id of selectedFeeTypeIds) {
                              if (!updatedFeeTypeDetails[id]) {
                                await fetchSubComponetsByFCID(id);
                                updatedFeeTypeDetails[id] =
                                  feesSubComponents.map((subComp) => ({
                                    fee_sub_component: subComp.name,
                                    start_date: new Date()
                                      .toISOString()
                                      .split("T")[0], // Today's date
                                    end_date: new Date()
                                      .toISOString()
                                      .split("T")[0], // Today's date
                                    reminder_day: "",
                                    amount_old: 0, // Default amount for old students
                                    amount_new: 0, // Default amount for new students
                                    show_on_receipt: false,
                                    online_payment: false,
                                  }));
                              }
                            }

                            Object.keys(updatedFeeTypeDetails).forEach(
                              (key) => {
                                if (!selectedFeeTypeIds.includes(Number(key))) {
                                  delete updatedFeeTypeDetails[key];
                                }
                              }
                            );

                            form.setFieldValue(
                              "fee_type_details",
                              updatedFeeTypeDetails
                            );
                          };

                          const selectAllOption = {
                            value: "selectAll",
                            label: "Select All",
                          };

                          return (
                            <Select
                              id="fee_type"
                              options={[selectAllOption, ...options]}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              onChange={handleChange}
                              value={options.filter((option) =>
                                (form.values.fee_type || []).includes(
                                  option.value
                                )
                              )}
                              placeholder="Select fee Type"
                              getOptionLabel={(e) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      e.value === "selectAll"
                                        ? options.length ===
                                          form.values.fee_type.length
                                        : form.values.fee_type.includes(e.value)
                                    }
                                    readOnly
                                    style={{ marginRight: "10px" }}
                                  />
                                  {e.label}
                                </div>
                              )}
                            />
                          );
                        }}
                      </Field>
                      <ErrorMessage
                        name="fee_type"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    {selectedFeeTypes.map((feeTypeId) => (
                      <div key={feeTypeId} className="card mb-3 shadow-sm">
                        <div className="card-header">
                          <h5>
                            {fees.find((fee) => fee.id === feeTypeId)?.name}:
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead className="thead-light">
                                <tr>
                                  <th>
                                    <Field
                                      type="checkbox"
                                      name={`fee_type_details.${feeTypeId}.selectAll`}
                                      checked={feesSubComponents[
                                        feeTypeId
                                      ]?.every(
                                        (subComp, index) =>
                                          values.fee_type_details?.[
                                            feeTypeId
                                          ]?.[index]?.select === subComp.id
                                      )}
                                      onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        feesSubComponents[feeTypeId]?.forEach(
                                          (subComp, index) => {
                                            setFieldValue(
                                              `fee_type_details.${feeTypeId}[${index}].select`,
                                              isChecked ? subComp.id : false
                                            );
                                          }
                                        );
                                      }}
                                    />
                                  </th>
                                  <th>Fee Sub Component</th>
                                  <th>Fee Start Date</th>
                                  <th>Fee End Date</th>
                                  <th>Reminder Day</th>
                                  <th>Amount (Old Student)</th>
                                  <th>Amount (New Student)</th>
                                  <th>Show on Receipt</th>
                                  <th>Online Payment</th>
                                </tr>
                              </thead>
                              <tbody>
                                {feesSubComponents[feeTypeId]?.map(
                                  (subComp, index) => (
                                    <tr key={index}>
                                      <td className="text-center">
                                        <Field
                                          type="checkbox"
                                          name={`fee_type_details.${feeTypeId}[${index}].select`}
                                          checked={
                                            values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select === subComp.id
                                          }
                                          onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setFieldValue(
                                              `fee_type_details.${feeTypeId}[${index}].select`,
                                              isChecked ? subComp.id : false
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <span className="form-control-plaintext">
                                          {subComp.name || "N/A"}
                                        </span>
                                      </td>
                                      <td>
                                        <Field
                                          type="date"
                                          name={`fee_type_details.${feeTypeId}[${index}].start_date`}
                                          className="form-control"
                                          disabled={
                                            !values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select
                                          }
                                          value={
                                            values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.start_date ||
                                            new Date()
                                              .toISOString()
                                              .split("T")[0]
                                          } // Default to today's date
                                        />
                                        <ErrorMessage
                                          name={`fee_type_details.${feeTypeId}[${index}].start_date`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          type="date"
                                          name={`fee_type_details.${feeTypeId}[${index}].end_date`}
                                          className="form-control"
                                          disabled={
                                            !values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select
                                          }
                                          value={
                                            values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.end_date ||
                                            new Date()
                                              .toISOString()
                                              .split("T")[0]
                                          } // Default to today's date
                                        />
                                        <ErrorMessage
                                          name={`fee_type_details.${feeTypeId}[${index}].end_date`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </td>

                                      <td>
                                        <Field
                                          type="number"
                                          name={`fee_type_details.${feeTypeId}[${index}].reminder_day`}
                                          className="form-control"
                                          placeholder="Enter reminder days"
                                          disabled={
                                            !values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select
                                          }
                                        />
                                        <ErrorMessage
                                          name={`fee_type_details.${feeTypeId}[${index}].reminder_day`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          type="number"
                                          name={`fee_type_details.${feeTypeId}[${index}].amount_old`}
                                          className="form-control"
                                          placeholder="Amount for old students"
                                          disabled={
                                            !values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select
                                          }
                                          value={
                                            values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.amount_old ?? 0 // Default to 0 if undefined
                                          }
                                        />
                                        <ErrorMessage
                                          name={`fee_type_details.${feeTypeId}[${index}].amount_old`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          type="number"
                                          name={`fee_type_details.${feeTypeId}[${index}].amount_new`}
                                          className="form-control"
                                          placeholder="Amount for new students"
                                          disabled={
                                            !values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select
                                          }
                                          value={
                                            values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.amount_new ?? 0
                                          }
                                        />
                                        <ErrorMessage
                                          name={`fee_type_details.${feeTypeId}[${index}].amount_new`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </td>

                                      <td className="text-center">
                                        <Field
                                          type="checkbox"
                                          name={`fee_type_details.${feeTypeId}[${index}].show_on_receipt`}
                                          disabled={
                                            !values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select
                                          }
                                        />
                                        <ErrorMessage
                                          name={`fee_type_details.${feeTypeId}[${index}].show_on_receipt`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </td>
                                      <td className="text-center">
                                        <Field
                                          type="checkbox"
                                          name={`fee_type_details.${feeTypeId}[${index}].online_payment`}
                                          disabled={
                                            !values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select
                                          }
                                        />
                                        <ErrorMessage
                                          name={`fee_type_details.${feeTypeId}[${index}].online_payment`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="text-right">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default AcademicFeeCreate;
