import Api from "../Api";

export const createPickUpRequest = (data) => {
  return Api.post("/create-student-pickup", data);
};

export const getAllPickUpRequests = (params) => {
  return Api.get("/get-all-student-pickup", { params });
};
export const getPickRequestById = async (id) => {
  const response = await Api.get(`/get-student-pickup/${id}`);
  return response.data;
};
export const updatePickUpRequestById = async (id, data) => {
  const response = await Api.post(`/update-student-pickup/${id}`, data);
  return response;
};
export const deletePickUpRequest = (id) => {
  const response = Api.delete(`/delete-student-pickup/${id}`);
  return response;
};
