import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Select from "react-select";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  getSchools,
  deleteSchoolById,
} from "../../Services/AdminApis/schoolApi";
import { useState } from "react";
import {
  generateOverallFeeReport,
  getFeesCollectionReport,
  getOverAllFeeReports,
} from "../../Services/CommonApis/FeesApi";
import { useParams } from "react-router-dom";
import {
  getAllFees,
  getAllConcession,
  getFeesSubComponentsByFCID,
  getFeesTransactionReport,
} from "../../Services/CommonApis/FeesApi";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";

const OverallFeeReport = () => {
  const tableRef = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const { branchId } = useParams();
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [classId, setClassId] = useState(null);
  const [fees, setFees] = useState([]);
  const [concessions, setConcessions] = useState([]);
  const [feesSubComponents, setFeesSubComponents] = useState([]);
  const [selectedHeaderType, setSelectedHeaderType] = useState("1");
  const [selectedFeesType, setSelectedFeesType] = useState(null);
  const [selectedFeeSubType, setSelectedFeeSubType] = useState(null);
  const [startdate, setStartdate] = useState(null);
  const [enddate, setEnddate] = useState(null);
  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        const response = await deleteSchoolById(id);
        Swal.close();
        showSuccessAlert(
          "Deleted!",
          response.message || "Data Deleted Successfully"
        );
        setRefresh((prev) => !prev);
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";

        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The school is safe :)");
    }
  };

  useEffect(() => {
    fetchClassByBranchId(branchId);
    fetchFeeComponents();
  }, [branchId]);

  const fetchClassByBranchId = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchSectionByClassId = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSectionList(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  const fetchFeeComponents = async () => {
    try {
      const response = await getAllFees(branchId);
      setFees(response.data.data);
    } catch (error) {
      console.error("Error fetching fees:", error);
    }
  };

  const fetchSubComponentsByFCID = async (id) => {
    try {
      const response = await getFeesSubComponentsByFCID(id);
      setFeesSubComponents(response);
    } catch (error) {
      console.error("Error fetching fee subcomponents:", error);
    }
  };

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        {
          data: null,
          title: "S.No",
          render: (data, type, row, meta) => meta.row + 1,
        },
        {
          data: "class",
          title: "Class Name",
        },
        {
          data: "student_count",
          title: "Total Students",
          render: (data) => (data === null ? 0 : data),
        },
        {
          data: "amount",
          title: "Total Fee",
          render: (data) => (data === null ? 0 : data),
        },
        {
          data: "amount_paid",
          title: "Total Amount Paid",
          render: (data) => (data === null ? 0 : data),
        },
        {
          data: "discount",
          title: "Total Concession Fee",
          render: (data) => (data === null ? 0 : data),
        },
        {
          data: "balance",
          title: "Total Balance",
          render: (data) => (data === null ? 0 : data),
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "csv",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "excel",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "pdf",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "print",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
        emptyTable: "No Data Found",
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getOverAllFeeReports({
            branch_id: branchId,
            page: page,
            _limit: length,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
            comp_id: selectedFeesType,
            fsap_id: selectedFeeSubType,
            start_date: startdate,
            end_date: enddate,
            class_id: classId,
          });
          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;
          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (err) {
          console.error("Error fetching data:", error);
          showErrorAlert(err.response.data.message);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".print-icon", function (e) {
      e.preventDefault();
      const student_id = $(this).data("id");

      getFeesTransactionReport(branchId, student_id);
    });

    return () => {
      table.destroy();
    };
  }, [
    refresh,
    selectedFeesType,
    selectedFeeSubType,
    startdate,
    enddate,
    classId,
  ]);

  const initialValues = {
    class_id: "",
    section_id: "",
    fcap_id: "",
    fsap_id: "",
    concession_id: "",
    amount: "0",
    date: "",
    type: "amount",
  };
  const handleDownloadPdf = async (branchId) => {
    console.log(branchId);
    try {
      const response = await generateOverallFeeReport(branchId);

      console.log(response);
    } catch (error) {
      console.error("Download error:", error);
    }
  };
  return (
    <>
      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Overall Fee Report
          </h3>
          <button
            className="btn btn-primary float-right"
            onClick={() => handleDownloadPdf(branchId)} 
          >
            Download PDF
          </button>
        </div>

        <div className="card-body">
          <Formik initialValues={initialValues}>
            {({ setFieldValue, values }) => (
              <Form id="form" className="row">
                <div className="form-group col-md-4">
                  <label htmlFor="class_id">
                    Class Name <span className="mandatory">*</span>
                  </label>
                  <Field name="class_id">
                    {({ field, form }) => {
                      const options = classList.map((cls) => ({
                        value: cls.id,
                        label: cls.name,
                      }));

                      const selectAllOption = {
                        value: "selectAll",
                        label: "Select All",
                      };

                      const handleChange = (selectedOptions) => {
                        let selectedClassIds = selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : [];

                        // Handle "Select All"
                        if (selectedClassIds.includes("selectAll")) {
                          selectedClassIds = options.map(
                            (option) => option.value
                          );
                        }

                        form.setFieldValue("class_id", selectedClassIds);
                        setClassId(selectedClassIds);
                      };

                      return (
                        <Select
                          id="class_id"
                          options={[selectAllOption, ...options]}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          onChange={handleChange}
                          value={options.filter((option) =>
                            (form.values.class_id || []).includes(option.value)
                          )}
                          placeholder="Select Class"
                          getOptionLabel={(e) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  e.value === "selectAll"
                                    ? options.length ===
                                      form.values.class_id.length
                                    : form.values.class_id.includes(e.value)
                                }
                                readOnly
                                style={{ marginRight: "10px" }}
                              />
                              {e.label}
                            </div>
                          )}
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage
                    name="class_id"
                    component="div"
                    className="text-error"
                  />
                </div>
                {/* Fee Type Multi-Select Dropdown */}
                <div className="form-group col-md-4">
                  <label htmlFor="fcap_id">
                    Fee Component <span className="mandatory">*</span>
                  </label>
                  <Field name="fcap_id">
                    {({ field, form }) => {
                      const options = fees.map((fee) => ({
                        value: fee.id,
                        label: fee.name,
                      }));

                      const selectAllOption = {
                        value: "selectAll",
                        label: "Select All",
                      };

                      const handleChange = (selectedOptions) => {
                        let selectedFeeIds = selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : [];

                        // Handle "Select All"
                        if (selectedFeeIds.includes("selectAll")) {
                          selectedFeeIds = options.map(
                            (option) => option.value
                          );
                        }

                        form.setFieldValue("fcap_id", selectedFeeIds);
                        setSelectedFeesType(selectedFeeIds);
                        fetchSubComponentsByFCID(selectedFeeIds);
                      };

                      return (
                        <Select
                          id="fcap_id"
                          options={[selectAllOption, ...options]}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          onChange={handleChange}
                          value={options.filter((option) =>
                            (form.values.fcap_id || []).includes(option.value)
                          )}
                          placeholder="Select Fee Type"
                          getOptionLabel={(e) => (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  e.value === "selectAll"
                                    ? options.length ===
                                      form.values.fcap_id.length
                                    : form.values.fcap_id.includes(e.value)
                                }
                                readOnly
                                style={{ marginRight: "10px" }}
                              />
                              {e.label}
                            </div>
                          )}
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage
                    name="fcap_id"
                    component="div"
                    className="text-error"
                  />
                </div>

                {/* Fee Subcomponent Dropdown */}
                <div className="form-group col-md-4">
                  <label htmlFor="fcap_id">SubComponent Name</label>
                  <Field
                    as="select"
                    id="fsap_id"
                    name="fsap_id"
                    className="form-control"
                    onChange={(e) => {
                      const selectedFeeSubType = e.target.value;
                      setFieldValue("fsap_id", selectedFeeSubType);
                      setSelectedFeeSubType(selectedFeeSubType);
                    }}
                  >
                    <option value="">Select Fee Subcomponent</option>
                    {feesSubComponents.map((subComponent) => (
                      <option key={subComponent.id} value={subComponent.id}>
                        {subComponent.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fsap_id"
                    component="div"
                    className="text-error"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="start_date">
                    From date <span className="mandatory">*</span>
                  </label>
                  <Field
                    type="date"
                    name="start_date"
                    className="form-control"
                    onChange={(e) => {
                      const selecteddate = e.target.value;
                      setStartdate(selecteddate);
                    }}
                  />
                  <ErrorMessage
                    name="start_date"
                    component="div"
                    className="text-error"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="end_date">
                    To date <span className="mandatory">*</span>
                  </label>
                  <Field
                    type="date"
                    name="end_date"
                    className="form-control"
                    onChange={(e) => {
                      const enddate = e.target.value;
                      setEnddate(enddate);
                    }}
                  />
                  <ErrorMessage
                    name="end_date"
                    component="div"
                    className="text-error"
                  />
                </div>
              </Form>
            )}
          </Formik>

          {/* DataTable */}
          <table
            ref={tableRef}
            id="example1"
            className="table table-bordered table-striped"
          >
            <tbody></tbody>
          </table>

          {/* Error Alert */}
          {showError && (
            <div
              className={`error-alert error-alert-danger ${
                showError ? "fade-in" : "fade-out"
              }`}
              onAnimationEnd={() => {
                if (!showError) setError("");
              }}
            >
              {error}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default OverallFeeReport;
