import Api from "../Api";

export const createSchool = async (data) => {
  try {
    const response = await Api.post("/create-school", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;

  } catch (error) {
    throw error;
    
  }
};

export const getSchoolById = async (id) => {
  try {
    const response = await Api.get(`/get-school/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSchoolById = async (id) => {
  try {
    const response = await Api.get(`delete-school/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateSchoolById = async (id, data) => {
  try {
    const response = await Api.post(`/update-school`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        school_id: id,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCurrency = async (data) => {
  try {
    const response = await Api.get("/get-currency", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSchools = async (params) => {
  try {
    const response = await Api.get("/get-schools", { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadSchool = async (data) => {
  return Api.post("/upload-schools", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getSchoolDetailsById = async (id) => {
  try {
    const response = await Api.get(`/get-school/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSchoolSettings = async (id) => {
  try {
    const response = await Api.get(`/get-school-settings/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const ChangePassword = async (data) => {
  try {
    const response = await Api.post("/change-password", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};