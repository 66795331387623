import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { QualificationValidationschema } from "../../Utility/AdminUtility/AdminValidation";
import Spinner from "react-bootstrap/Spinner";
import { useParams, useNavigate } from "react-router-dom";
import {
  showErrorAlert,
  showSuccessAlert,
  showLoadingAlert,
  showSweetAlert
} from "../../Modals/SweetAlertModel";
import {
  createQualification,
  getQualificationById,
  updateQualificationById,
} from "../../Services/AdminApis/QualificationApi";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

const Qualification = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const { id } = useParams();
  const [apiMessage, setApiMessage] = useState("");
  const [data, setData] = useState({ name: "", status: "" });

  const getQualification = async (id) => {
    setLoadingData(true);
    try {
      const response = await getQualificationById(id);
      setData(response.data);
    } catch (err) {
      console.error("API error:", err);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (id) {
      getQualification(id);
    } else {
      setLoadingData(false);
    }
  }, [id]);
  const handleSubmit = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      `You want to ${id ? "update" : "create"} the qualification.`,
      "info",
      "Yes, Proceed",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      setLoading(true);
      showLoadingAlert();
      try {
        let response;
        if (id) {
          response = await updateQualificationById(id, values);
        } else {
          response = await createQualification(values);
        }
        if (response && (response.status === 200 || response.status === 201)) {
          const successMessage =
            response.data?.message || response.message;
          setApiMessage(successMessage);

          showSuccessAlert(
            id ? "Updated!" : "Created!",
            successMessage
          );
          navigate("/admin/qualification-list");
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        console.error("API Error:", error);

        const errorMessage =
          error.response?.data?.message ||
          "An error occurred. Please try again.";
        setApiMessage(errorMessage);

        showErrorAlert("Error", errorMessage);
      } finally {
        setLoading(false);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert(
        "Cancelled",
        `The qualification has not been ${id ? "updated" : "created"}.`
      );
    }
  };


  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Qualifications</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Qualification</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 style={{ color: "skyblue" }}>
                    {id ? "Update Qualification" : "Add Qualification"}
                  </h3>
                </div>
                <div className="card-body">
                  {loadingData ? (
                    <div className="text-center">
                      <BeatLoader color="#16dada" />
                    </div>
                  ) : (
                    <Formik
                      enableReinitialize
                      initialValues={{
                        name: data.name,
                      }}
                      validationSchema={QualificationValidationschema}
                      onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                      }}
                    >
                      {({ isSubmitting, setValues }) => (
                        <Form className="form-card ml-4">
                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-6">
                              <label htmlFor="name" className="form-label">
                                Qualification name{" "}
                                <span className="mandatory">* </span>{" "}
                              </label>
                              <Field
                                type="text"
                                name="name"
                                className="form-control"
                                id="name"
                                placeholder="Enter name"
                                style={{ borderColor: "skyblue" }}
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="row ml-4 mr-5 mt-3 ">
                            <button
                              type="submit"
                              className="btn btn-primary mr-2"
                              style={{
                                width: loading ? "150px" : "100px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {loading ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  &nbsp;Submitting...
                                </>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Qualification;
