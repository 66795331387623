import React, { useState } from "react";
import HolidayCarousel from "./HolidayNotification";
import CreateHoliday from "../AdminPages/CreateHoliday";
import CalendarComponent from "../AdminPages/CalendarComponent";
import SpecialDayList from "../../Components/CommonComponents/SpecialDayList";
import WorkingDaysSetup from "../AdminPages/WorkingDaysSetup";
import WorkingDaySetUpList from "../AdminPages/WorkingDaysSetupList";

function HolidayManagement() {
  const [activeTab, setActiveTab] = useState("calendar");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "calendar" ? "active" : ""}`}
              onClick={() => handleTabClick("calendar")}
            >
              Calendar
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "createclass" ? "active" : ""
              }`}
              onClick={() => handleTabClick("createclass")}
            >
              Holiday List
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "uploadclass" ? "active" : ""
              }`}
              onClick={() => handleTabClick("uploadclass")}
            >
              Special Day List
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "classlist" ? "active" : ""
              }`}
              onClick={() => handleTabClick("classlist")}
            >
              Create Holiday
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "nonworking" ? "active" : ""
              }`}
              onClick={() => handleTabClick("nonworking")}
            >
              Non-Working Days Setup
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "nonworkinglist" ? "active" : ""
              }`}
              onClick={() => handleTabClick("nonworkinglist")}
            >
              Non-Working Days List
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === "classlist" ? "active" : ""}`}
            id="classlist"
          >
            {activeTab === "classlist" && <CreateHoliday />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "uploadclass" ? "active" : ""
            }`}
            id="uploadclass"
          >
            {activeTab === "uploadclass" && <SpecialDayList />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "createclass" ? "active" : ""
            }`}
            id="createclass"
          >
            {activeTab === "createclass" && <HolidayCarousel />}
          </div>
          <div
            className={`tab-pane ${activeTab === "calendar" ? "active" : ""}`}
            id="calendar"
          >
            {activeTab === "calendar" && <CalendarComponent />}
          </div>
          <div
            className={`tab-pane ${activeTab === "nonworking" ? "active" : ""}`}
            id="nonworking"
          >
            {activeTab === "nonworking" && <WorkingDaysSetup />}
          </div>
          <div
            className={`tab-pane ${
              activeTab === "nonworkinglist" ? "active" : ""
            }`}
            id="nonworkinglist"
          >
            {activeTab === "nonworkinglist" && <WorkingDaySetUpList />}
          </div>
        </div>
      </div>
    </>
  );
}

export default HolidayManagement;
