import React from "react";
import LibraryBookIssueList from "./LibraryBookIssueList";
import NewBookIssue from "./NewBookIssue";

function LibraryBookIssueManagement() {
  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a className="nav-link active" href="#booksissuedlist" data-toggle="tab">
              Issued Book List
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link" href="#createbookissued" data-toggle="tab">
              Issue New Book
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div className="active tab-pane" id="booksissuedlist">
            <LibraryBookIssueList />
          </div>
          <div className="tab-pane" id="createbookissued">
            <NewBookIssue />
          </div>
        </div>
      </div>
    </>
  );
}
export default LibraryBookIssueManagement;