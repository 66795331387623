import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "../../Styles/SchoolCreate.css";
import Spinner from "react-bootstrap/Spinner";
import { useParams, useNavigate } from "react-router-dom";
import StudentInformation from "../../Components/CommonComponents/StudentAcademicInformation";
import StudentDetails from "../../Components/CommonComponents/StudentDetails";

import PersonalInfo from "../../Components/CommonComponents/StudentPersonalInformation";
import UserDetails from "../../Components/CommonComponents/StudentUserDetails";
import {
  StudentEditValidation,
  StudentValidation,
} from "../../Utility/CommonUtility/Validation";
import {
  createStudent,
  getstudentByid,
  updatestudent,
} from "../../Services/CommonApis/StudentApi";
import StudentParentDetails from "../../Components/CommonComponents/StudentParentDetails";
import {
  showLoadingAlert,
  showSuccessAlert,
  showErrorAlert,
} from "../../Modals/SweetAlertModel";

const StudentCreate = () => {
  const { branchId, id, schoolId } = useParams();
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);
  const progressBarRef = useRef(null);
  const steps = id ? 4 : 5;
  const [loading, setLoading] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [student, setStudent] = useState("");
  const [studentImage, setStudentImage] = useState(null);
  const [parentImage, setParentImage] = useState(null);

  const prevStep = () => {
    setCurrent((prevCurrent) =>
      prevCurrent > 1 ? prevCurrent - 1 : prevCurrent
    );
  };

  const goToStep = (step) => {
    setCurrent(step);
  };

  const setProgressBar = useCallback(
    (curStep) => {
      const percent = ((100 / steps) * curStep).toFixed();
      if (progressBarRef.current) {
        progressBarRef.current.style.width = `${percent}%`;
      }
    },
    [steps]
  );

  useEffect(() => {
    setProgressBar(current);
  }, [current, setProgressBar]);

  useEffect(() => {
    if (id) {
      getstudent(id);
    }
  }, [id]);
  const getstudent = async (id) => {
    try {
      const response = await getstudentByid(id);
      setStudent(response.data.student.student);
    } catch (error) {
      console.log(error);
    }
  };
  const mapStudentFields = (values) => {
    const mappedValues = {};
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        if (key.startsWith("st")) {
          const newKey = key.replace(/^st/, "");
          mappedValues[newKey] = values[key];
        } else {
          mappedValues[key] = values[key];
        }
      }
    }
    return mappedValues;
  };
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };
  const handleSubmit = async (values) => {
    const updatedValues = { ...values, branch_id: branchId };
    const originalvalues = { ...values, branch_id: branchId };
    const mappedValues = mapStudentFields(updatedValues);

    if (mappedValues.admission_date || mappedValues.date_of_birth) {
      mappedValues.admission_date = formatDate(mappedValues.admission_date);
      mappedValues.date_of_birth = formatDate(mappedValues.date_of_birth);
    }

    setLoading(true);
    try {
      let response;
      if (id) {
        response = await updatestudent(id, mappedValues);
        showSuccessAlert(
          "Updated!",
          response.data.data || "Data Updated Successfully"
        );
        navigate(`/school/${schoolId}/branch/${branchId}/student`);
      } else {
        if (originalvalues.admission_date) {
          originalvalues.admission_date = formatDate(
            originalvalues.admission_date
          );
        }
        if (originalvalues.stdate_of_birth) {
          originalvalues.stdate_of_birth = formatDate(
            originalvalues.stdate_of_birth
          );
        }
        if (originalvalues.date_of_birth) {
          originalvalues.date_of_birth = formatDate(
            originalvalues.date_of_birth
          );
        }
        response = await createStudent(originalvalues);
        showSuccessAlert(
          "Created!",
          response.data || "Data Created Successfully"
        );
        window.location.reload();
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.[0]?.message ||
        error.response?.data?.message ||
        "An error occurred while creating/updating the student.";
      showErrorAlert(errorMessage);
      console.error(errorMessage);
      setApiMessage("Error creating/updating student: " + errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    academic_year_id: "",
    roll_no: "",
    stfirst_name: "",
    stlast_name: "",
    fee_book_no: "",
    place_of_birth: "",
    physically_challenge: "no",
    transport_required: "no",
    medium_id: "",
    class_id: "",
    section_id: "",
    group_id: "",
    reg_no: "",
    emis_no: "",
    cse_no: "",
    file_no: "",
    admission_no: "",
    application_no: "",
    joining_quota: "",
    first_lang_id: "",
    second_lang_id: "",
    third_lang_id: "",
    achievements: "",
    area_of_interest: "",
    additional_skills: "",
    previous_school: "",
    last_study_course: "",
    last_exam_marks: "",
    reason_change: "",
    last_study_school: "",
    staadhaar_card_no: "",
    stpam_card_no: "",
    aadhaar_card_no: "",
    pan_card_no: "",
    reason_gap: "",
    stdist: "",
    stdate_of_birth: "",
    stgender: "",
    stblood_group: "",
    stcast: "",
    stimage: "",
    streligion: "",
    status: "",
    admission_date: "",
    neet_applicable: "no",
    email: "",
  };

  const getInitialValues = () => {
    if (student) {
      return {
        academic_year_id: student?.academic_year_id || "",
        roll_no: student?.roll_no || "",
        stfirst_name: student?.first_name || "",
        middle_name: student?.middle_name || "",
        stlast_name: student?.last_name || "",
        fee_book_no: student?.fee_book_no || "",
        place_of_birth: student?.place_of_birth || "",
        physically_challenge: student?.physically_challenge || "no",
        transport_required: "yes",
        medium_id: student?.medium_id || "",
        class_id: student?.class_id || "",
        section_id: student?.section_id || "",
        group_id: student?.group_id || "",
        reg_no: student?.reg_no || "",
        emis_no: student?.emis_no || "",
        cse_no: student?.cse_no || "",
        file_no: student?.file_no || "",
        admission_no: student?.admission_no || "",
        application_no: student?.application_no || "",
        joining_quota: student?.joining_quota || "",
        first_lang_id: student?.first_lang_id || "",
        second_lang_id: student?.second_lang_id || "",
        third_lang_id: student?.third_lang_id || "",
        achievements: "",
        area_of_interest: student?.area_of_interest || "",
        additional_skills: student?.additional_skills || "",
        previous_school: student?.previous_school || "",
        last_study_course: student?.last_study_course || "",
        last_exam_marks: student?.last_exam_marks || "",
        reason_change: student?.reason_change || "",
        last_study_school: student?.last_study_school || "",
        reason_gap: student?.reason_gap || "",
        staadhaar_card_no: student?.aadhaar_card_no || "",
        stpan_card_no: student.pan_card_no || "",
        stdate_of_birth: student?.date_of_birth || "",
        stgender: student?.gender || "",
        stblood_group: student?.blood_group || "",
        stcast: student?.cast || "",
        streligion: student?.religion || "",
        status: student?.status || "",
        neet_applicable: "yes",
        parent_id: student?.parent_id || "",
        mother_tongue: student?.mother_tongue || "",
        stcity: student?.city || "",
        stpin: student?.pin || "",
        ststate: student?.state || "",
        stdist: student?.dist || "",
        stcountry: student?.country || "",
        staddress: student?.address || "",
        sttemp_city: student?.temp_city || "",
        sttemp_pin: student?.temp_pin || "",
        sttemp_state: student?.temp_state || "",
        sttemp_dist: student?.temp_dist || "",
        sttemp_address: student?.temp_address || "",
        sttemp_country: student?.temp_country || "",
        sttmp_address: student?.tmp_address || "",
        admission_date: student?.admission_date || "",
        stimage: student?.image,
      };
    } else {
      return initialValues;
    }
  };

  return (
    <>
      <div className="card">
        <div className="head-card">
          <div className="row ml-4">
            <div className="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
              <Formik
                initialValues={getInitialValues()}
                enableReinitialize
                validationSchema={
                  id
                    ? StudentEditValidation[`tab${current}`]
                    : StudentValidation[`tab${current}`]
                }
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  isSubmitting,
                  setFieldValue,
                  errors,
                  setTouched,
                }) => (
                  <Form id="msform">
                    <ul id="progressbar">
                      <li
                        className={current >= 1 ? "active" : ""}
                        id="account"
                        onClick={() => goToStep(1)}
                        style={{ width: id ? "250px" : "200px" }}
                      >
                        <strong>Student Academic Details</strong>
                      </li>
                      <li
                        className={current >= 2 ? "active" : ""}
                        id="personal"
                        onClick={() => goToStep(2)}
                        style={{ width: id ? "250px" : "200px" }}
                      >
                        <strong>Student Details</strong>
                      </li>
                      {!id && (
                        <li
                          className={current >= 3 ? "active" : ""}
                          id="parents"
                          onClick={() => goToStep(3)}
                          style={{ width: id ? "250px" : "200px" }}
                        >
                          <strong>Student Parent Details</strong>
                        </li>
                      )}
                      <li
                        className={current >= (id ? 3 : 4) ? "active" : ""}
                        id="payment"
                        onClick={() => goToStep(id ? 3 : 4)}
                        style={{ width: "  200px" }}
                      >
                        <strong>Personal Info</strong>
                      </li>
                      <li
                        className={current >= (id ? 4 : 5) ? "active" : ""}
                        id="settings"
                        onClick={() => goToStep(id ? 4 : 5)}
                        style={{ width: id ? "250px" : "200px" }}
                      >
                        <strong>Address</strong>
                      </li>
                    </ul>
                    <div className="progress">
                      <div
                        ref={progressBarRef}
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <br />
                    {current === 1 && (
                      <StudentInformation
                        steps={steps}
                        current={1}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    )}
                    {current === 2 && (
                      <StudentDetails steps={steps} current={2} />
                    )}
                    {!id && current === 3 && (
                      <StudentParentDetails
                        steps={steps}
                        current={3}
                        parentImage={parentImage}
                        setParentImage={setParentImage}
                      />
                    )}
                    {current === (id ? 3 : 4) && (
                      <PersonalInfo
                        steps={steps}
                        current={current}
                        studentImage={studentImage} // Pass image state
                        setStudentImage={setStudentImage}
                      />
                    )}
                    {current === (id ? 4 : 5) && (
                      <UserDetails steps={steps} current={current} />
                    )}

                    <div className="button-group">
                      {((id && current > 1 && current <= 4) ||
                        (!id && current > 1 && current <= 5)) && (
                        <button
                          type="button"
                          className="previous action-button-previous"
                          onClick={prevStep}
                        >
                          Previous
                        </button>
                      )}

                      {((id && current < 4) || (!id && current < 5)) && (
                        <button
                          type="button"
                          className="next action-button"
                          onClick={() => {
                            // Determine the correct validation schema based on id
                            const currentTabValidationSchema = id
                              ? StudentEditValidation[`tab${current}`]
                              : StudentValidation[`tab${current}`];

                            currentTabValidationSchema
                              .validate(values, { abortEarly: false })
                              .then(() => {
                                setCurrent(current + 1); // Move to the next tab
                                setTouched({}); // Clear touched fields
                              })
                              .catch((validationErrors) => {
                                const errors = validationErrors.inner.reduce(
                                  (acc, err) => {
                                    acc[err.path] = err.message;
                                    return acc;
                                  },
                                  {}
                                );

                                console.log(errors);
                                setTouched(errors); // Set the touched fields with errors
                              });
                          }}
                        >
                          Next
                        </button>
                      )}

                      {(id && current === 4) || (!id && current === 5) ? (
                        <button
                          type="submit"
                          className="action-button"
                          style={{
                            width: loading ? "150px" : "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {loading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              &nbsp;Submitting...
                            </>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      ) : null}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StudentCreate;
