import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { LoginValidation } from "../../Utility/CommonUtility/Validation";
import { login } from "../../Services/CommonApis/authApi";
import { getSession, setSession } from "../../Services/Storage";
import { Modal, Button } from "react-bootstrap";
import LoginModal from "../../Modals/LoginModal";
import { COOKIE } from "../../Schemas/cookieNames";
import { USERROLEID } from "../../Schemas/userRoles";
import {
  getBranchId,
  getSchoolId,
  getUserRoleId,
} from "../../Utility/CommonUtility/extractUserDetailsFromToken";
import CookiePopUp from "./CookiePopUp";
import {
  ForgotPassword,
  validateLoginOTP,
} from "../../Services/AdminApis/ForgotPasswordApi";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { ChangePassword } from "../../Services/AdminApis/schoolApi";
import { resetPassword } from "../../Services/AdminApis/ForgotPasswordApi";
import * as Yup from "yup";

const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [branchId, setBranchId] = useState(null);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState(["", "", "", "", "", ""]);
  const [otpValidation, setOtpValidation] = useState("");
  const email = "user@example.com"; // Replace with actual email
  const [forgotEmai, setForgotEmail] = useState(null);
  const [otpToken, setOtpToken] = useState(null);
  const [passwordModal, setPasswordModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [passwordChangeOtp, setpasswordChangeOtp] = useState(null);

  useEffect(() => {
    const token = getSession(COOKIE.ADMIN_TOKEN);
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const initialValues = {
    email: "",
    password: "",
  };
  const handelpasswordsubmit = async (values) => {
    console.log(values);
    const result = await showSweetAlert(
      "Are you sure?",
      `You want to change the password.`,
      "info",
      "Yes, Proceed",
      "No, Cancel"
    );
    values = { ...values, otp_token: passwordChangeOtp };

    if (result.isConfirmed) {
      setLoading(true);
      showLoadingAlert();
      try {
        let response;
        response = await resetPassword(values);
        setApiMessage(response?.data?.message || response.message);
        if (response.status === 201 || response.status === 200) {
          setShowChangePasswordModal(false);
          setpasswordChangeOtp("");
          showSuccessAlert(
            "Created!",
            response?.data?.message || `Password Changed Successfully.`
          ).then((result) => {
            if (result.isConfirmed) {
              window.location.reload(); // Page reload only after clicking "OK"
            }
          });
        }
      } catch (err) {
        let errorMessage = "An unexpected error occurred. Please try again.";
        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      } finally {
        setLoading(false);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", `The password has not been created.`);
    }
  };
  useEffect(() => {
    if (otpToken) {
      setShowOtpPopup(true);
    }
  }, [otpToken]);
  const handleForgotPassword = async (values) => {
    console.log("valuessss", values);
    setForgotEmail(values.email);
    setForgotPasswordModal(false);
    try {
      const response = await ForgotPassword(values);
      console.log(response.status);
      setOtpToken(response.data.otp_token);
      setEnteredOtp("");
      if (response.status == 200) {
        setShowOtpPopup(true);
      }
    } catch (err) {
      console.log(err);
      showErrorAlert(err.response.data.message);
    }
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleVerifyOTP = async () => {
    try {
      const data = {
        otp: Number(enteredOtp.join("")),
        otp_token: otpToken,
      };

      console.log(data);
      const response = await validateLoginOTP(data);
      if (response.status == 200) {
        setShowOtpPopup(false);
        setShowChangePasswordModal(true);
        setpasswordChangeOtp(response.data.otp_token);
      }
      console.log(response);
    } catch (error) {
      setOtpValidation(error.response.data.message);
      setEnteredOtp(["", "", "", "", "", ""]);

      console.log(error);
    }
  };
  const passwordvalidationSchema = Yup.object().shape({
    new_password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Must include at least one uppercase letter")
      .matches(/[a-z]/, "Must include at least one lowercase letter")
      .matches(/[0-9]/, "Must include at least one number")
      .matches(/[@$!%*?&]/, "Must include at least one special character"),

    new_password_confirmation: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });
  const handleSubmit = async (values) => {
    try {
      setSubmitting(true);
      const response = await login(values.email, values.password);
      const adminToken = response.data.access_token;
      const roleId = getUserRoleId(adminToken);
      const schoolId = getSchoolId(adminToken);
      const branchId = getBranchId(adminToken);
     

      if (adminToken) {
        setSession(COOKIE.ADMIN_TOKEN, adminToken);
        setRoleId(roleId);
        setSchoolId(schoolId);
        setBranchId(branchId);
        switch (roleId) {
          case 1:
          case 2:
          case 3:
            setShowModal(true);
            break;
          case USERROLEID.TEACHER_ID:
            navigate(`/teaching-staff/school/${schoolId}/branch/${branchId}/class`);
            break;
          case 5:
            navigate("/staff/dashboard");
            break;
          case USERROLEID.PARENT_ID:
            navigate("/parent/student-profile");
            break;
          case USERROLEID.SCHOOLMANAGER_ID:
            navigate("/school-manager/dashboard");
            break;
          case 7:
            navigate("/student/dashboard");
            break;
          default:
            navigate("/admin/dashboard");
            break;
        }
      } else {
        setError("No admin token received.");
        setShowError(true);
        setTimeout(() => setShowError(false), 2000);
      }
    } catch (err) {
      if (err.response) {
        const errorMessage =
          err.response.data.error || err.response.data.message;
        setError(errorMessage);
      } else if (err.request) {
        setError("No response from the server. Please try again.");
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
      setShowError(true);
      setTimeout(() => setShowError(false), 2000);
    } finally {
      setSubmitting(false);
    }
  };

  const handleAdditionalInfoSubmit = (values) => {
    if (roleId === USERROLEID.ADMIN_ID) {
      navigate("/admin/dashboard");
    } else if (roleId === USERROLEID.SCHOOL_ID && values.branch) {
      setSession(COOKIE.SCHOOL_ID, schoolId);
      setSession(COOKIE.BRANCH_ID, values.branch);
      navigate(`/school/${schoolId}/branch/${values.branch}/dashboard`);
    } else if (roleId === USERROLEID.BRANCH_ID) {
      setSession(COOKIE.SCHOOL_ID, schoolId);
      setSession(COOKIE.BRANCH_ID, branchId);
      navigate(`/school/${schoolId}/branch/${branchId}/dashboard`);
    } else {
      const redirectPath = localStorage.getItem("redirectPath") || "/";
      localStorage.removeItem("redirectPath");
      navigate(redirectPath);
    }
    setShowModal(false);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const toogleNewPasswordVisibiity = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };
  const toogleConfirmPasswordVisibiity = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };
  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <a href="#" className="h1">
              <b>
                <i class="fa-solid fa-school"></i> Login!
              </b>
            </a>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Sign in to start your session</p>

            <Formik
              initialValues={initialValues}
              // validationSchema={LoginValidation}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      className={`input-group mb-1 ${
                        errors.email && touched.email ? "is-invalid" : ""
                      }`}
                    >
                      <Field
                        type="email"
                        name="email"
                        className={`form-control ${
                          errors.email && touched.email ? "border-danger" : ""
                        }`}
                        placeholder="Email"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-envelope"></span>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-error"
                    />

                    <div
                      className={`input-group mb-1 mt-3 ${
                        errors.password && touched.password ? "is-invalid" : ""
                      }`}
                    >
                      <Field
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        className={`form-control ${
                          errors.password && touched.password
                            ? "border-danger"
                            : ""
                        }`}
                        placeholder="Password"
                      />
                      <div className="input-group-append">
                        <div
                          className="input-group-text"
                          onClick={togglePasswordVisibility}
                        >
                          <span
                            className={`fa ${
                              passwordVisible ? "fa-eye-slash" : "fa-eye"
                            }`}
                          ></span>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-error"
                    />
                  </div>

                  <div className="row">
                    <div className="col-4">
                      {isSubmitting ? (
                        <button
                          className="btn btn-primary"
                          type="button"
                          style={{
                            width: "125px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                          ></span>
                          Submitting..
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary btn-block mt-3"
                          disabled={isSubmitting}
                          style={{ position: "relative" }}
                        >
                          Sign In
                        </button>
                      )}
                    </div>
                    <div className="col-8 d-flex justify-content-end align-items-end">
                      <button
                        type="button"
                        className="btn btn-link p-0"
                        onClick={() => {
                          if (otpToken && passwordChangeOtp) {
                            setShowChangePasswordModal(true);
                          } else if (otpToken) {
                            setShowOtpPopup(true);
                          } else {
                            setForgotPasswordModal(true);
                          }
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        Forgot Password?
                      </button>
                    </div>
                  </div>

                  {error && (
                    <div
                      className={`error-alert error-alert-danger ${
                        showError ? "fade-in" : "fade-out"
                      }`}
                      onAnimationEnd={() => {
                        if (!showError) setError("");
                      }}
                    >
                      {error}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
            <Modal
              show={forgotPasswordModal}
              onHide={() => setForgotPasswordModal(false)}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Forgot Password</Modal.Title>
              </Modal.Header>
              <Formik
                initialValues={{ email: "" }}
                onSubmit={handleForgotPassword}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Modal.Body>
                      <div className="form-group">
                        <label>Email Address</label>
                        <Field
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter your email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-error"
                        />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => setForgotPasswordModal(false)}
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Processing..." : "Submit"}
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </Modal>
            <Modal
              show={showChangePasswordModal}
              onHide={handleModalClose}
              centered
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
              </Modal.Header>
              <Formik
                initialValues={initialValues}
                validationSchema={passwordvalidationSchema}
                onSubmit={(values) => {
                  handelpasswordsubmit(values);
                }}
              >
                {({ handleSubmit, errors, touched, setFieldValue, values }) => (
                  <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                      <div className="form-group">
                        <label>Password</label>
                        <span className="mandatory">* </span>
                        <div className="input-group">
                          <Field
                            type={newPasswordVisible ? "text" : "password"}
                            name="new_password"
                            className={`form-control ${
                              errors.new_password && touched.new_password
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Password"
                          />
                          <div className="input-group-append">
                            <span
                              className="input-group-text cursor-pointer"
                              onClick={toogleNewPasswordVisibiity}
                            >
                              <i
                                className={`fa ${
                                  newPasswordVisible ? "fa-eye-slash" : "fa-eye"
                                }`}
                              ></i>
                            </span>
                          </div>
                        </div>
                        <ErrorMessage
                          name="new_password"
                          component="div"
                          className="text-danger"
                          style={{ marginTop: "0.25rem", fontSize: "0.9rem" }}
                        />
                      </div>

                      <div className="form-group">
                        <label>Confirm Password</label>
                        <span className="mandatory">* </span>
                        <div className="input-group">
                          <Field
                            type={confirmPasswordVisible ? "text" : "password"}
                            name="new_password_confirmation"
                            className={`form-control ${
                              errors.new_password_confirmation &&
                              touched.new_password_confirmation
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Confirm Password"
                          />
                          <div className="input-group-append">
                            <span
                              className="input-group-text cursor-pointer"
                              onClick={toogleConfirmPasswordVisibiity}
                            >
                              <i
                                className={`fa ${
                                  confirmPasswordVisible
                                    ? "fa-eye-slash"
                                    : "fa-eye"
                                }`}
                              ></i>
                            </span>
                          </div>
                        </div>
                        <ErrorMessage
                          name="new_password_confirmation"
                          component="div"
                          className="text-danger mt-1"
                        />
                      </div>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => setShowChangePasswordModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </Modal>
            {showOtpPopup && (
              <>
                {/* Overlay */}
                <div
                  style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    backdropFilter: "blur(5px)",
                    zIndex: 1000,
                  }}
                />

                {/* OTP Popup */}
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "white",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    zIndex: 1001,
                    borderRadius: "8px",
                    textAlign: "center",
                    width: "350px",
                  }}
                >
                  <div className="text-center">
                    <h5>
                      Please verify your identity
                      <br />
                      Enter the OTP sent to
                      <br />
                      <strong>{forgotEmai}</strong>
                    </h5>
                  </div>

                  <div className="d-flex justify-content-center mb-3">
                    {[...Array(6)].map((_, index) => (
                      <input
                        key={index}
                        type="text"
                        className="form-control mx-1 text-center"
                        style={{ width: "40px", fontSize: "20px" }}
                        maxLength="1"
                        value={enteredOtp[index] || ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[0-9]$/.test(value)) {
                            const newOtp = [...enteredOtp];
                            newOtp[index] = value;
                            setEnteredOtp(newOtp);

                            if (index < 5) {
                              document
                                .querySelector(
                                  `input[data-index="${index + 1}"]`
                                )
                                ?.focus();
                            }
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                          const pasteData = e.clipboardData
                            .getData("text")
                            .slice(0, 6);
                          const otpArray = pasteData.split("");

                          setEnteredOtp((prev) => {
                            const newOtp = [...prev];
                            otpArray.forEach((char, i) => {
                              if (i + index < 6) {
                                newOtp[i + index] = char;
                              }
                            });
                            return newOtp;
                          });

                          // Focus the last field filled
                          const lastIndex = Math.min(
                            index + otpArray.length - 1,
                            5
                          );
                          const lastInput = document.querySelector(
                            `input[data-index="${lastIndex}"]`
                          );
                          if (lastInput) lastInput.focus();
                        }}
                        data-index={index}
                        onKeyDown={(e) => {
                          if (e.key === "Backspace") {
                            const newOtp = [...enteredOtp];
                            newOtp[index] = "";
                            setEnteredOtp(newOtp);

                            if (index > 0) {
                              document
                                .querySelector(
                                  `input[data-index="${index - 1}"]`
                                )
                                ?.focus();
                            }
                          }
                        }}
                      />
                    ))}
                  </div>

                  {otpValidation && (
                    <p className="text-danger">{otpValidation}</p>
                  )}

                  <button
                    type="submit"
                    onClick={handleVerifyOTP}
                    className="btn btn-primary w-75 mb-2"
                  >
                    Verify OTP
                  </button>

                  <button
                    onClick={() => {
                      setShowOtpPopup(false);
                      setOtpValidation(""); // Clear OTP validation message
                    }}
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      border: "none",
                      padding: "10px 20px",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Close
                  </button>
                </div>
              </>
            )}
            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              centered
              backdrop="static"
            >
              <LoginModal onSubmit={handleAdditionalInfoSubmit} />
            </Modal>
          </div>
        </div>
      </div>
      {/* Cookie Pop Up */}
      <CookiePopUp />
    </div>
  );
};
export default Login;
