import Api from "../Api";
export const createSchoolManager = async (data) => {
  try {
    const response = await Api.post("/create-school-manager", data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getSchoolManagers = async (params) => {
  return await Api.get("/get-school-managers", { params });
};

export const deleteSchoolManagerById = async (id, data) => {
  try {
    const response = await Api.post(`/school-manager-activedeactive/${id}`, { status: data });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateSchoolManagerById = async (user_id, data) => {
  try {
    const response = await Api.post(`/update-school-manager/${user_id}`, data);
    return response;
  } catch (error) {
    console.error("Update School Manager Error:", error.response || error.message);
    throw error;
  }
};
export const getSchoolManagerById = async (id) => {
  try {
    const response = await Api.get(`/school-manager/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAllMenuList = async () => {
  return await Api.get("/global/menu");
};
export const getAllAssetList = async () => {
  return await Api.get("/global/assetType");
};
export const getAllReturntype = async () => {
  return await Api.get("/global/returnType");
};