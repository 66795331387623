import * as Yup from "yup";

export const LoginValidation = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .matches(
      /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
      "Email must be a valid format"
    )
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const BranchValidation = {
  tab2: Yup.object({
    branch_city: Yup.string().required("city is required"),
    branch_dist: Yup.string().required("district is required"),
    branch_state: Yup.string().required("state is required"),
    branch_pin: Yup.string()
      .length(6, "pin must be exactly 6 digits")
      .required("pin is required"),
    branch_address: Yup.string().required("address is required"),
  }),
  tab1: Yup.object({
    branch_name: Yup.string().required("branch name is required"),
    branch_code: Yup.string().required("branch code is required"),
    branch_email: Yup.string()
      .email("Invalid email format")
      .matches(
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
        "Email must be a valid format"
      )
      .required("Email is required"),
    branch_phone: Yup.string()
      .length(10, "mobile No must be exactly 10 digits")
      .required("mobile No is required"),
    academic_id: Yup.string().required("Select the academic year"),
  }),
};

export const BranchExcelValidation = (data) => {
  const errors = [];
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phonePattern = /^\d{10}$/;
  const pincodePattern = /^\d{6}$/;

  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    const rowErrors = {};

    if (!row || row.length < 8) {
      rowErrors.missingFields = "Missing required fields.";
    } else {
      const email = row[3]?.toString().trim();
      if (email && !emailPattern.test(email)) {
        rowErrors.email = "Invalid email format.";
      }

      const pincode = row[6]?.toString().trim();
      if (!pincode || !pincodePattern.test(pincode)) {
        rowErrors.pincode = "Pincode must be a 6-digit number.";
      }

      const phone = row[4]?.toString().trim();
      if (phone && !phonePattern.test(phone)) {
        rowErrors.phone = "Phone must be exactly 10 digits.";
      }

      const status = row[7]?.toString().trim();
      if (status && status !== "1" && status !== "0") {
        rowErrors.status = "Status must be 1 or 0.";
      }
    }

    if (Object.keys(rowErrors).length > 0) {
      errors.push({ rowIndex: i, ...rowErrors });
    }
  }

  return errors;
};
export const StaffValidation = {
  tab1: Yup.object({
    // Staff table validation for Tab 1
    role_id: Yup.number().required("Role is required"),
    employee_no: Yup.number().required("Employee Number is required"),
    phone_no: Yup.string()
      .required("Mobile Number is required")
      .transform((value) => value.replace(/[^0-9]/g, ""))
      .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits"),
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .matches(
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
        "Email must be a valid format"
      )
      .required("Email is required"),
  }),

  tab2: Yup.object({
    // Staff table validation for Tab 2
    joining_date: Yup.date().required("Joining Date is required"),
  }),

  tab3: Yup.object({
    // User Details table validation for Tab 3
    date_of_birth: Yup.date().required("Date of Birth is required"),
    gender: Yup.string()
      .oneOf(["male", "female"])
      .required("Gender is required"),
  }),

  tab4: Yup.object({
    // User Details table validation for Tab 4
    aadhaar_card_no: Yup.string()
      .required("Aadhaar Card No is required")
      .transform((value) => value.replace(/[^0-9]/g, "")) // Remove all non-numeric characters
      .matches(/^[0-9]{12}$/, "Aadhaar Card No must be exactly 12 digits"),
    pan_card_no: Yup.string()
      .matches(/^[A-Z]{5}-[0-9]{4}[A-Z]{1}$/, "PAN format is invalid.")
      .required("PAN is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    dist: Yup.string().required("District is required"),
    pin: Yup.string()
      .length(6, "Pin must be exactly 6 digits")
      .required("Pin is required"),
  }),
};
// for  staff edit validation
export const StaffEditValidation = {
  tab1: Yup.object({
    // Staff table validation for Tab 1
    role_id: Yup.number().required("Role is required"),

    phone_no: Yup.string()
      .required("Mobile Number is required")
      .transform((value) => value.replace(/[^0-9]/g, ""))
      .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits"),
    employee_no: Yup.number().required("Employee Number is required"),
    // school: Yup.string().required("School is required"),
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .matches(
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
        "Email must be a valid format"
      )
      .required("Email is required"),
  }),
  tab2: Yup.object({
    // Staff table validation for Tab 2
    joining_date: Yup.date().required("Joining Date is required"),
  }),
  tab3: Yup.object({
    // User Details table validation for Tab 3
    date_of_birth: Yup.date().required("Date of Birth is required"),
    gender: Yup.string()
      .oneOf(["male", "female"])
      .required("Gender is required"),
  }),
  tab4: Yup.object({
    // User Details table validation for Tab 4
    aadhaar_card_no: Yup.string()
      .required("Aadhaar Card No is required")
      .transform((value) => value.replace(/[^0-9]/g, "")) // Remove all non-numeric characters
      .matches(/^[0-9]{12}$/, "Aadhaar Card No must be exactly 12 digits"),
    pan_card_no: Yup.string()
      .matches(/^[A-Z]{5}-[0-9]{4}[A-Z]{1}$/, "PAN format is invalid.")
      .required("PAN is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    dist: Yup.string().required("District is required"),
    pin: Yup.string()
      .length(6, "Pin must be exactly 6 digits")
      .required("Pin is required"),
  }),
};
export const StudentValidation = {
  tab1: Yup.object({
    academic_year_id: Yup.string().required("Academic Name is required"),
    class_id: Yup.string().required("Class is required"),
    section_id: Yup.number().required("Section is required"),
    medium_id: Yup.number()
      .required("Medium  is required")
      .integer("Medium ID must be an integer"),
    admission_date: Yup.date().required("Admission Date is required"),
  }),

  tab2: Yup.object({
    stfirst_name: Yup.string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters long")
      .max(50, "First Name can't be longer than 50 characters"),

    stlast_name: Yup.string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters long")
      .max(50, "Last Name can't be longer than 50 characters"),
  }),
  tab3: Yup.object({
    pfirst_name: Yup.string().required("First Name is required"),
    plast_name: Yup.string().required("Last Name is required"),
    gender: Yup.string().required("Gender is required"),
    phone: Yup.string()
      .required("Mobile Number is required")
      .transform((value) => value.replace(/[^0-9]/g, ""))
      .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits"),
    email: Yup.string()
      .email("Invalid email format")
      .matches(
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
        "Email must be a valid format"
      )
      .required("Email is required"),
  }),
  tab4: Yup.object({
    stdate_of_birth: Yup.string().required("Date of birth is required"),
    stcast: Yup.string().required("Cast is Required"),
    mother_tongue: Yup.string().required("Mother tongue is Required"),
    streligion: Yup.string().required("Religion is Required"),
  }),
  tab5: Yup.object({
    staddress: Yup.string().required("Address is required"),
    stcity: Yup.string().required("City is required"),
    ststate: Yup.string().required("State is required"),
    stdist: Yup.string().required("Dist is required"),
    stpin: Yup.string()
      .required("Pin is required")
      .matches(/^\d{6}$/, "Pin must be exactly 6 digits"),
  }),
};
export const SimpleStudentValidation = Yup.object({
  // Academic Details
  academic_year_id: Yup.number().required("Academic Year ID is required"),

  class_id: Yup.number()
    .required("Class ID is required")
    .integer("Class ID must be an integer"),

  section_id: Yup.number()
    .required("Section ID is required")
    .integer("Section ID must be an integer"),

  medium_id: Yup.number()
    .required("Medium ID is required")
    .integer("Medium ID must be an integer"),
  mother_phone: Yup.string().length(10, "mobile No must be exactly 10 digits"),

  // Student Details
  stfirst_name: Yup.string()
    .required("First Name is required")
    .min(2, "First Name must be at least 2 characters long")
    .max(50, "First Name can't be longer than 50 characters"),

  // Parent Details
  pfirst_name: Yup.string().required("Parent First Name is required"),
  stdate_of_birth: Yup.string().required("Date of birth is required"),

  email: Yup.string()
    .email("Invalid email format")
    .matches(
      /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
      "Email must be a valid format"
    )
    .required("Email is required"),

  admission_date: Yup.date().required("Date of Birth is required"),

  aadhaar_card_no: Yup.string().length(
    12,
    "Aadhaar Card No must be exactly 12 digits"
  ),
  pan_card_no: Yup.string().length(10, "Pan Card No must be exactly 10 digits"),

  phone: Yup.string()
    .length(10, "mobile No must be exactly 10 digits")
    .required("mobile No is required"),

  stpin: Yup.string().length(6, "Pin must be exactly 6 digits"),
  // Additional Details
});

export const StudentEditValidation = {
  tab1: Yup.object({
    academic_year_id: Yup.number().required("Academic Year ID is required"),
    class_id: Yup.number()
      .required("Class ID is required")
      .integer("Class ID must be an integer"),

    section_id: Yup.number()
      .required("Section ID is required")
      .integer("Section ID must be an integer"),
    medium_id: Yup.number()
      .required("Medium ID is required")
      .integer("Medium ID must be an integer"),
  }),

  tab2: Yup.object({
    stfirst_name: Yup.string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters long")
      .max(50, "First Name can't be longer than 50 characters"),

    stlast_name: Yup.string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters long")
      .max(50, "Last Name can't be longer than 50 characters"),
  }),

  tab3: Yup.object({
    stdate_of_birth: Yup.string().required("Date of birth is required"),
  }),
};
// for admission Create Validation
export const admissionCreateValidation = {
  tab1: Yup.object({
    application_type: Yup.string().required("Application Type is required"),
    academic_year_id: Yup.string().required("Academic Year is required"),
    announcement_id: Yup.string().required("Announcemnt Year is required"),
    class_id: Yup.string().required("Class Name is required"),
    admission_date: Yup.date().required("Admission Date is required"),
    payment_status: Yup.string().required("Payment Status  is required"),
  }),

  tab2: Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    gender: Yup.string().required("Gender is required"),
    date_of_birth: Yup.date().required("Date Of Birth is required"),
    father_name: Yup.string().required("Father Name is required"),
    father_last_name: Yup.string().required("Father Last Name is required"),
  }),
  tab3: Yup.object({
    phone: Yup.string()
      .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits")
      .required("Mobile number is required"),
  }),
  studentimage: Yup.mixed()
    .required("Image is required")
    .test("fileSize", "File is too large", (value) => {
      return value && value.size <= 5 * 1024 * 1024;
    })
    .test("fileType", "Unsupported file format", (value) => {
      return (
        value && ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
      ); // Allowed file formats: JPEG, PNG
    }),
};
