import Api from "../Api";

export const createEnquiry = (data) => {
  return Api.post(`/create-admission-enquery`, data);
};
export const getAllEnquirys = (params) => {
  return Api.get("/get-admission-enquerys", {
    params,
  });
};
export const getEnquiryById = (id) => {
  return Api.get(`/get-admission-enquery/${id}`);
};
export const updateEnquiryById = (id, data) => {
  return Api.post(`/update-admission-enquery/${id}`, data);
};
export const deleteEnquiryById = (id) => {
  return Api.delete(`/delete-admission-enquery/${id}`);
};
export const generateAdmitCard = async (id) => {
  return Api.get("/generate-admit-pdf", {
    params: {
      form_selling_id: id,
    },
    responseType: "blob",
  }).then((response) => {
    const blob = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", `AdmitCard_${id}.pdf`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
  });
};
export const generateAdmissionFeeReciept = async (id) => {
  return Api.get("/generate-admission_recipt-pdf", {
    params: {
      form_selling_id: id,
    },
    responseType: "blob",
  }).then((response) => {
    const blob = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", `fee_Reciept_${id}.pdf`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
  });
};
