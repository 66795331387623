import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { COOKIE } from "../../Schemas/cookieNames";
import { USERROLEID } from "../../Schemas/userRoles";
import { useData } from "../../Utility/CommonUtility/DataContext";
import {
  getSession,
  removeAllSession,
  setSession,
} from "../../Services/Storage";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, Modal } from "react-bootstrap";
import { getSchools, ChangePassword } from "../../Services/AdminApis/schoolApi";
import Swal from "sweetalert2";
import * as Yup from "yup";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";

const Header = React.memo(() => {
  const navigate = useNavigate();
  const REACT_APP_IMAGEURL = process.env.REACT_APP_IMAGEURL;
  const { academic, branches, languages, userData, roleId } = useData();
  const schoolId = getSession(COOKIE.SCHOOL_ID);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [selectedSchoolId, setSelectedSchoolId] = useState(null);
  const [schoolData, setSchoolData] = useState([]);
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const location = useLocation();
  const isSchoolBranchPath =
    location.pathname.includes("/school/") &&
    location.pathname.includes("/branch/");

  const [ispopup, setIsPopup] = useState(false);
  localStorage.setItem("isPopup", ispopup);

  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  useEffect(() => {
    const storedYearData = getSession(COOKIE.ACADEMIC_YEAR);
    const storedBranchData = getSession(COOKIE.BRANCH_ID);

    if (storedYearData) {
      try {
        const parsedData = JSON.parse(storedYearData);
        setSelectedYear(parsedData.value || parsedData);
      } catch (error) {
        console.error("Error parsing stored year data:", error);
      }
    }

    if (storedBranchData) {
      setSelectedBranch(storedBranchData);
    }
  }, [branches]);

  const handleYearChange = (e) => {
    const yearId = e.target.value;
    setSelectedYear(yearId);
    setSession(COOKIE.ACADEMIC_YEAR, yearId);
    window.location.reload();
  };

  const handleLanguageChange = (e) => {
    const langId = e.target.value;
    setSelectedLanguage(langId);
  };

  const handleBranchChange = (e) => {
    const branchId = e.target.value;
    setSelectedBranch(branchId);
    const selectedBranchObj = branches.find(
      (branch) => branch.id.toString() === branchId
    );
    setSelectedBranchName(selectedBranchObj?.branch_name || "");
    setSession(COOKIE.BRANCH_ID, branchId);
    navigate(`/school/${schoolId}/branch/${branchId}/dashboard`, {
      replace: true,
    });
    if (selectedBranchObj?.branch_name) {
      Swal.fire({
        html: `Welcome! <strong>${selectedBranchObj.branch_name}</strong>`,
        icon: "success",
        confirmButtonText: "Okay",
      });
    }
  };

  const handleLogout = () => {
    removeAllSession();
    navigate("/login");
  };

  const handleSwitchClick = async () => {
    setShowModal(true);
    try {
      const response = await getSchools();
      setSchoolData(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handelpasswordsubmit = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      `You want to change the password.`,
      "info",
      "Yes, Proceed",
      "No, Cancel"
    );

    if (result.isConfirmed) {
      setLoading(true);
      showLoadingAlert();
      try {
        let response;
        response = await ChangePassword(values);
        setApiMessage(response?.data?.message || response.message);
        if (response.status === 201 || response.status === 200) {
          showSuccessAlert(
            "Created!",
            response?.data?.message || `Password Changed Successfully.`
          ).then((result) => {
            if (result.isConfirmed) {
              window.location.reload(); // Page reload only after clicking "OK"
            }
          });
        }
      } catch (err) {
        let errorMessage = "An unexpected error occurred. Please try again.";
        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      } finally {
        setLoading(false);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", `The password has not been created.`);
    }
  };
  const handleChangePassword = async () => {
    setShowChangePasswordModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleSwitchSubmit = () => {
    if (selectedSchoolId) {
      navigate(`/admin/school/${selectedSchoolId}/branch-list`);
      setShowModal(false);
    } else {
      alert("Please select an option before switching.");
    }
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const languageOptions = [
    { code: "en", name: "English" },
    ...languages.filter((lang) => lang.name.toLowerCase() !== "english"),
  ];

  const initialValues = {
    password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    confirm_password: Yup.string()
      .required("confirm password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const toogleNewPasswordVisibiity = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toogleConfirmPasswordVisibiity = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };
  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars"></i>
            </a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            {roleId === USERROLEID.SCHOOL_ID ||
            roleId === USERROLEID.BRANCH_ID ? (
              <a
                href={`/school/${schoolId}/branch/${selectedBranch}/dashboard`}
                className="nav-link"
              >
                Home
              </a>
            ) : isSchoolBranchPath ? (
              <a
                href={`/school/${schoolId}/branch/${selectedBranch}/dashboard`}
                className="nav-link"
              >
                Home
              </a>
            ) : (
              <a href={`/admin/dashboard`} className="nav-link">
                Home
              </a>
            )}
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          {roleId === USERROLEID.ADMIN_ID && (
            <li className="nav-item dropdown" style={{ marginRight: "6px" }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSwitchClick}
              >
                <i class="fa fa-university" style={{ marginRight: "5px" }}></i>
                Switch School
              </button>
            </li>
          )}
          {roleId === USERROLEID.SCHOOL_ID && (
            <li className="nav-item dropdown">
              <Formik>
                <Form style={{ marginRight: "6px" }}>
                  <Field
                    as="select"
                    name="branch"
                    className="form-control border-dark dropdown-select"
                    style={{ width: "250px" }}
                    value={selectedBranch}
                    onChange={handleBranchChange}
                  >
                    <option value="">Select Branch</option>
                    {branches.length > 0 ? (
                      branches.map((branch) => (
                        <option
                          key={branch.id}
                          value={branch.id}
                          className="ellipsis-option"
                        >
                          {branch.branch_name}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No branches available
                      </option>
                    )}
                  </Field>
                </Form>
              </Formik>
            </li>
          )}
          <li className="nav-item dropdown">
            <Formik>
              <Form style={{ marginRight: "6px" }}>
                <Field
                  as="select"
                  name="year"
                  className="form-control border-dark dropdown-select"
                  style={{ width: "200px" }}
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  {academic.map((year) => {
                    const startDate = new Date(year.start_date);
                    const endDate = new Date(year.end_date);
                    const startMonthYear = startDate.toLocaleString("default", {
                      month: "short",
                      year: "numeric",
                    });
                    const endMonthYear = endDate.toLocaleString("default", {
                      month: "short",
                      year: "numeric",
                    });
                    const formattedDateRange = `${startMonthYear} - ${endMonthYear}`;
                    return (
                      <option
                        key={year.id}
                        value={year.id}
                        className="ellipsis-option"
                      >
                        {formattedDateRange}
                      </option>
                    );
                  })}
                </Field>
              </Form>
            </Formik>
          </li>

          <li className="nav-item dropdown">
            <Formik>
              <Form style={{ marginRight: "6px" }}>
                <Field
                  as="select"
                  name="language"
                  className="form-control border-dark dropdown-select"
                  style={{ width: "150px" }}
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                >
                  {languageOptions.map((lang) => (
                    <option
                      key={lang.code}
                      value={lang.code}
                      className="ellipsis-option"
                    >
                      {lang.name}
                    </option>
                  ))}
                </Field>
              </Form>
            </Formik>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-bell"></i>
              <span className="badge badge-warning navbar-badge">15</span>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">
                15 Notifications
              </span>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item">
                <i className="fas fa-envelope mr-2"></i> 4 new messages
                <span className="float-right text-muted text-sm">3 mins</span>
              </a>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item">
                <i className="fas fa-users mr-2"></i> 8 friend requests
                <span className="float-right text-muted text-sm">12 hours</span>
              </a>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item">
                <i className="fas fa-file mr-2"></i> 3 new reports
                <span className="float-right text-muted text-sm">2 days</span>
              </a>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item dropdown-footer">
                See All Notifications
              </a>
            </div>
          </li>

          <li className="nav-item dropdown align-items-center d-flex">
            {userData?.image ? (
              <img
                src={`${REACT_APP_IMAGEURL}/${userData?.image}`}
                alt=""
                className="profile-image"
              />
            ) : (
              <img
                src="/plugins/dist/img/profile-dummy-image.jpg"
                alt=""
                className="profile-image"
              />
            )}
            <a
              href="#"
              className="dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              style={{ marginLeft: "6px" }}
            >
              {userData?.first_name && userData?.last_name
                ? `Hi ${capitalizeFirstLetter(
                    userData?.first_name
                  )} ${capitalizeFirstLetter(userData?.last_name).charAt(0)}`
                : "Hi User Name"}{" "}
            </a>
            <ul className="dropdown-menu dropdown-menu-right">
              <li>
                <a
                  className="dropdown-item ml-2"
                  href="/user/preferences"
                  style={{ color: "black" }}
                >
                  <i className="fas fa-cog"></i> Preferences
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item ml-2"
                  href="/admin/help/support"
                  style={{ color: "black" }}
                >
                  <i className="fas fa-envelope"></i> Contact Support
                </a>
              </li>
              <li className="divider"></li>

              {/* chnage password */}
              <li>
                <a
                  className="dropdown-item ml-2"
                  // href="/change-password"
                  style={{ color: "black" }}
                  onClick={handleChangePassword}
                >
                  <i class="fa-solid fa-lock"></i> Change Password
                </a>
              </li>
              <li>
                <a className="dropdown-item ml-2" onClick={handleLogout}>
                  <i className="fas fa-power-off"></i> Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Switch School</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ switchOption: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.switchOption) {
                errors.switchOption =
                  "Please select a school before switching.";
              }
              return errors;
            }}
            onSubmit={(values) => {
              if (values.switchOption) {
                setSelectedSchoolId(values.switchOption);
                navigate(`/admin/school/${values.switchOption}/branch-list`);
                setIsPopup(true);
                setShowModal(false);
              }
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="switchOption">
                    Select School <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    name="switchOption"
                    className={`form-control ${
                      errors.switchOption && touched.switchOption
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <option value="">Select an option</option>
                    {schoolData.map((school) => (
                      <option key={school.id} value={school.id}>
                        {school.name}
                      </option>
                    ))}
                  </Field>
                  {errors.switchOption && touched.switchOption && (
                    <div className="invalid-feedback">
                      {errors.switchOption}
                    </div>
                  )}
                </div>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleModalClose}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Switch
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Change Password modal */}
      <Modal
        show={showChangePasswordModal}
        onHide={handleModalClose}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handelpasswordsubmit(values);
          }}
        >
          {({ handleSubmit, errors, touched, setFieldValue, values }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label>Password</label>
                  <span className="mandatory">* </span>
                  <div className="input-group">
                    <Field
                      type={newPasswordVisible ? "text" : "password"}
                      name="password"
                      className={`form-control ${
                        errors.password && touched.password ? "is-invalid" : ""
                      }`}
                      placeholder="Password"
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text cursor-pointer"
                        onClick={toogleNewPasswordVisibiity}
                      >
                        <i
                          className={`fa ${
                            newPasswordVisible ? "fa-eye-slash" : "fa-eye"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-danger"
                    style={{ marginTop: "0.25rem", fontSize: "0.9rem" }}
                  />
                </div>

                <div className="form-group">
                  <label>Confirm Password</label>
                  <span className="mandatory">* </span>
                  <div className="input-group">
                    <Field
                      type={confirmPasswordVisible ? "text" : "password"}
                      name="confirm_password"
                      className={`form-control ${
                        errors.confirm_password && touched.confirm_password
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Confirm Password"
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text cursor-pointer"
                        onClick={toogleConfirmPasswordVisibiity}
                      >
                        <i
                          className={`fa ${
                            confirmPasswordVisible ? "fa-eye-slash" : "fa-eye"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <ErrorMessage
                    name="confirm_password"
                    component="div"
                    className="text-danger"
                    style={{ marginTop: "0.25rem", fontSize: "0.9rem" }}
                  />
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowChangePasswordModal(false)}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
});

export default Header;
