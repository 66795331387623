import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "../../Styles/Calendar.css";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getAllHolidays } from "../../Services/CommonApis/ParentPortalApi";

const localizer = momentLocalizer(moment);

const CalendarComponent = () => {
  const { branchId } = useParams(); 
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (branchId) {
      fetchHolidays(branchId);
    }
  }, [branchId]);

  const fetchHolidays = async (branchId) => {
    try {
      const response = await getAllHolidays(branchId);
      const holidays = response.data.data.map((holiday) => ({
        title: holiday.name, 
        start: new Date(holiday.from_date),
        end: new Date(holiday.to_date), 
        allDay: true, 
      }));

      setEvents(holidays); 
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  const handleEventDrop = ({ event, start, end }) => {
    const updatedEvents = events.map((existingEvent) => {
      if (existingEvent.title === event.title) {
        return { ...event, start, end };
      }
      return existingEvent;
    });
    setEvents(updatedEvents);
  };

  const handleSelectSlot = (slotInfo) => {
    const title = prompt("Enter Event Title");
    if (title) {
      setEvents([
        ...events,
        {
          title,
          start: slotInfo.start,
          end: slotInfo.end,
          allDay: slotInfo.action === "select",
        },
      ]);
    }
  };

  return (
    <div className="calendar-container" style={{ padding: "20px" }}>
      <DndProvider backend={HTML5Backend}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "80vh" }}
          selectable
          resizable
          onEventDrop={handleEventDrop}
          onSelectSlot={handleSelectSlot}
          defaultView={Views.MONTH}
          views={[Views.MONTH, Views.WEEK, Views.DAY]} 
          draggableAccessor={(event) => true}
        />
      </DndProvider>
    </div>
  );
};

export default CalendarComponent;
