import React, { useState, useEffect } from "react";
import { Field, useFormikContext, ErrorMessage } from "formik";
import { getAllId } from "../../Services/AdminApis/studentIdCard";
import DragNdrop from "./DragandDrop";
import { useParams } from "react-router-dom";

const MultiStepForm = () => {
  const { values, setFieldValue } = useFormikContext();
  const REACT_APP_IMAGEURL = process.env.REACT_APP_IMAGEURL;
  const [activeTab, setActiveTab] = useState("reportCard");
  const [selectedReportCard, setSelectedReportCard] = useState("");
  const [selectedIdCard, setSelectedIdCard] = useState("");
  const [selectedReceipt, setSelectedReceipt] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [selectedReportCardCheck, setSelectedReportCardCheck] = useState(
    !!values.report_card_template
  );
  const [selectedIdCardCheck, setSelectedIdCardCheck] = useState(
    !!values.id_card_template
  );
  const [selectedReceiptCheck, setSelectedReceiptCheck] = useState(
    !!values.receipt_template
  );
  const [idCardTemplates, setIdCardTemplates] = useState([]);
  const [reportCardTemplates, setReportCardTemplates] = useState([]);
  const [receiptTemplates, setReceiptTemplates] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [currentStep, setCurrentStep] = useState(0);
  const [logoFileError, setLogoFileError] = useState("");
  const [showAdditionalCheckboxes, setShowAdditionalCheckboxes] =
    useState(false);

  const [fileStates, setFileStates] = useState({
    print_file: null,
    report_card: null,
    logo_file: null,
    text_logo: null,
    id_card_template: values.id_card_template || null,
    report_card_template: values.report_card_template || null,
    receipt_template: values.receipt_template || null,
  });
  const { id } = useParams();
  const iddup = values.id_card_template;
  const report_card_id = values.report_card_template;
  const reciept_card_id = values.receipt_template;

  console.log(values.principal_signature);
  const [showPrincipalSignature, setShowPrincipalSignature] = useState(false);
  const [showStudentPhotos, setShowStudentPhotos] = useState(
    !!values.student_photo
  );
  const id_card_template_file_path = values.id_card_template_file_path;
  const receipt_template_file_path = values.receipt_template_file_path;
  const report_card_template_file_path = values.report_card_template_file_path;
  useEffect(() => {
    const fetchTemplates = async () => {
      setLoading(true);
      try {
        const idCardData = await getAllId("student");
        setIdCardTemplates(
          idCardData.data.map((item) => ({
            id: item.id,
            name: item.name,
            image: `/${item.file_path}`,
          }))
        );
        console.log(values.principalSignature);
        const reportCardData = await getAllId("report");
        setReportCardTemplates(
          reportCardData.data.map((item) => ({
            id: item.id,
            name: item.name,
            image: `/${item.file_path}`,
          }))
        );

        const receiptData = await getAllId("reciept");
        setReceiptTemplates(
          receiptData.data.map((item) => ({
            id: item.id,
            name: item.name,
            image: `/${item.file_path}`,
          }))
        );
      } catch (error) {
        setError("Failed to load templates. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setModalOpen(false);
  };

  const handleCheckboxChange = (event) => {
    const { checked, name } = event.target;

    if (name === "principalSignature") {
      setShowPrincipalSignature(checked);
    } else if (name === "student_photo") {
      setShowStudentPhotos(checked);
    } else {
      switch (activeTab) {
        case "reportCard":
          setSelectedReportCardCheck(checked);
          if (!checked) {
            setFieldValue("report_card_template", null);
          }
          break;
        case "idCard":
          setSelectedIdCardCheck(checked);
          if (!checked) {
            setFieldValue("id_card_template", null);
          }
          break;
        case "receipt":
          setSelectedReceiptCheck(checked);
          if (!checked) {
            setFieldValue("receipt_template", null);
          }
          break;
        default:
          break;
      }
    }
  };
  const handleTemplateSelect = (event) => {
    console.log(event.target.value);
    const templateId = parseInt(event.target.value, 10);
    const templates = {
      reportCard: reportCardTemplates,
      idCard: idCardTemplates,
      receipt: receiptTemplates,
    };
    const template = templates[activeTab].find((t) => t.id === templateId);
    if (!template) return;

    switch (activeTab) {
      case "reportCard":
        setSelectedReportCard(template);
        setFileStates((prevState) => ({
          ...prevState,
          report_card_template: template.id,
        }));
        setFieldValue("report_card_template", template.id);
        break;
      case "idCard":
        setSelectedIdCard(template);
        setFileStates((prevState) => ({
          ...prevState,
          id_card_template: template.id,
        }));
        setFieldValue("id_card_template", template.id);
        break;
      case "receipt":
        setSelectedReceipt(template);
        setFileStates((prevState) => ({
          ...prevState,
          receipt_template: template.id,
        }));
        setFieldValue("receipt_template", template.id);
        break;
      default:
        break;
    }
  };

  const handleViewButtonClick = () => {
    setModalOpen(true);
    setZoomLevel(1);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalImage(null);
  };

  const handleImageClick = (imageUrl) => {
    setModalOpen(true);
    setModalImage(imageUrl);
    setActiveTab("principalSignature");
    setActiveTab("logoFile");
    setActiveTab("ReportCard");
    setActiveTab("textLogo");
    setActiveTab("printLogo");
    setZoomLevel(1);
  };

  const handleZoom = (event) => {
    event.preventDefault();
    const zoomDirection = event.deltaY < 0 ? 0.1 : -0.1;
    setZoomLevel((prevZoom) =>
      Math.min(Math.max(prevZoom + zoomDirection, 1), 3)
    );
  };

  const next = () => {
    let error = "";
    if (currentStep === 0 && !fileStates.print_file && !values.print_file) {
      error = "Please select the School Logo file.";
    } else if (
      currentStep === 1 &&
      !fileStates.logo_file &&
      !values.logo_file
    ) {
      error = "Please select the Report Card Logo file.";
    } else if (
      currentStep === 2 &&
      !fileStates.report_card &&
      !values.report_card
    ) {
      error = "Please select the Text Logo file.";
    } else if (
      currentStep === 3 &&
      !fileStates.text_logo &&
      !values.text_logo
    ) {
      error = "Please select the Printing Logo file.";
    }
    if (error) {
      setLogoFileError(error);
      return;
    }
    setLogoFileError("");
    if (currentStep < 3) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const back = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  const handleFileChange = (fieldName, file) => {
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      localStorage.setItem(`${fieldName}_url`, imageUrl);
      setFileStates((prevState) => ({
        ...prevState,
        [fieldName]: imageUrl,
      }));
      setFieldValue(fieldName, file);
    }
  };
  const handleprincipalFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      console.log('image',imageUrl);
      setFieldValue("principal_signature", file);
      setImagePreviewUrl(imageUrl);
      return () => URL.revokeObjectURL(imageUrl);
    }
  };

  useEffect(() => {
    const fields = ["logo_file", "report_card", "text_logo", "print_file"];
    const savedFileStates = {};
  
    fields.forEach((field) => {
      const savedUrl = localStorage.getItem(`${field}_url`);
      savedFileStates[field] =
        savedUrl ||
        (values[field] ? `${REACT_APP_IMAGEURL}/${values[field]}` : null);
    });
  
    setFileStates((prevState) => ({ ...prevState, ...savedFileStates }));
  
    const clearLocalStorage = () => {
      fields.forEach((field) => {
        localStorage.removeItem(`${field}_url`);
      });
    };
  
    window.addEventListener("beforeunload", clearLocalStorage);
  
    return () => {
      window.removeEventListener("beforeunload", clearLocalStorage);
    };
  }, [values]);

  useEffect(() => {
    const fullUrl =
      fileStates.logo_file ||
      (values.logo_file ? `${REACT_APP_IMAGEURL}/${values.logo_file}` : null);
    console.log("Full image URL:", fullUrl);
  }, [fileStates, values]);
  
  
  useEffect(() => {
    if (values.report_card_template) {
      const template = reportCardTemplates.find(
        (t) => t.id === values.report_card_template
      );
      if (template) setSelectedReportCard(template);
    }

    if (values.id_card_template) {
      const template = idCardTemplates.find(
        (t) => t.id === values.id_card_template
      );
      if (template) setSelectedIdCard(template);
    }

    if (values.receipt_template) {
      const template = receiptTemplates.find(
        (t) => t.id === values.receipt_template
      );
      if (template) setSelectedReceipt(template);
    }
  }, [values, reportCardTemplates, idCardTemplates, receiptTemplates]);
  const existingSignatureUrl = values.principal_signature
    ? `${REACT_APP_IMAGEURL}/${values.principal_signature}`
    : null;

  return (
    <div className="form-step">
      <fieldset>
        <div className="form-card">
          <div className="ml-4 mr-4 mt-3">
            <div
              className="form-group col-12"
              style={{ border: "1px solid skyblue" }}
            >
              {/* Navigation Tabs */}
              <nav className="nav nav-pills d-flex justify-content-between mt-2">
                {["reportCard", "idCard", "receipt"].map((tab) => (
                  <a
                    key={tab}
                    className={`nav-link tab-pills flex-fill text-center ${
                      activeTab === tab ? "active" : ""
                    }`}
                    onClick={() => handleTabChange(tab)}
                    style={{ cursor: "pointer" }}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)} Template
                  </a>
                ))}
              </nav>

              {/* Tab Content */}
              {activeTab === "reportCard" && (
                <div className="tab-content" key="reportCard">
                  <div className="form-group d-flex col-6 mt-3">
                    <Field
                      type="checkbox"
                      className="form-control mt-3 ml-0"
                      id="report_card_template"
                      name="report_card_template"
                      style={{ width: "20px", height: "20px" }}
                      checked={selectedReportCardCheck}
                      onChange={handleCheckboxChange}
                    />

                    <label className="mt-3 ml-1" htmlFor="report_card_template">
                      Enable Report Card Template
                    </label>
                    <ErrorMessage
                      name="report_card_template"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  {selectedReportCardCheck && (
                    <div className="form-group d-flex flex-column col-6 mt-3">
                      <label htmlFor="report_card_select">
                        Select Report Card <span className="mandatory">*</span>
                      </label>
                      <select
                        className="form-control mt-2"
                        id="report_card_select"
                        name="report_card_select"
                        onChange={handleTemplateSelect}
                        value={values.report_card_template}
                        style={{
                          borderColor: "skyblue",
                          marginRight: "10px",
                          flexShrink: 0,
                        }}
                      >
                        <option value="">Select Template</option>
                        {reportCardTemplates.map((template) => (
                          <option key={template.id} value={template.id}>
                            {template.name}
                          </option>
                        ))}
                      </select>
                      {(selectedReportCard?.image || report_card_id) && (
                        <img
                          src={
                            selectedReportCard?.image
                              ? `${REACT_APP_IMAGEURL}/${selectedReportCard.image}`
                              : `${REACT_APP_IMAGEURL}/${report_card_template_file_path}`
                          }
                          alt={selectedReportCard?.name}
                          style={{
                            maxWidth: "300px",
                            maxHeight: "200px",
                            objectFit: "contain",
                            cursor: "pointer",
                            marginTop: "10px",
                          }}
                          onClick={handleViewButtonClick}
                        />
                      )}
                    </div>
                  )}
                  {selectedReportCard && (
                    <div className="form-group d-flex flex-column mt-3">
                      <div className="d-flex align-items-center">
                        <Field
                          type="checkbox"
                          id="principalSignature"
                          name="principalSignature"
                          checked={values.principalSignature}
                          onChange={(event) => {
                            handleCheckboxChange(event);
                            setFieldValue(
                              "principalSignature",
                              event.target.checked
                            );
                          }}
                          style={{ marginRight: "8px" }}
                        />
                        <label htmlFor="principalSignature" className="mb-0">
                          Include Principal Signature{" "}
                          <span className="mandatory">*</span>
                        </label>
                      </div>

                      <div className="form-group mt-3 d-flex align-items-center">
                        {values.principalSignature && (
                          <div className="form-group mt-3 col-2">
                            <label
                              htmlFor="uploadSignature"
                              className="btn-primary p-2 d-flex align-items-center"
                            >
                              <i className="fas fa-cloud-upload-alt mr-2"></i>{" "}
                              Browse files
                            </label>
                            <input
                              type="file"
                              hidden
                              className="form-control"
                              id="uploadSignature"
                              name="principal_signature"
                              onChange={handleprincipalFileChange}
                            />
                          </div>
                        )}
                        {(imagePreviewUrl || existingSignatureUrl) && (
                          <div className="ml-3">
                            <img
                              src={imagePreviewUrl || existingSignatureUrl}
                              style={{
                                maxWidth: "300px",
                                maxHeight: "200px",
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleImageClick(
                                  imagePreviewUrl || existingSignatureUrl
                                )
                              }
                              // onClick={handleViewButtonClick}
                            />
                          </div>
                        )}
                      </div>

                      <div className="d-flex">
                        <Field
                          type="checkbox"
                          id="student_photo"
                          name="student_photo"
                          checked={showStudentPhotos}
                          onChange={handleCheckboxChange}
                          style={{ marginRight: "8px" }}
                        />
                        <label htmlFor="student_photo" className="mb-0">
                          Include Student Photos
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {activeTab === "idCard" && (
                <div className="tab-content" key="idCard">
                  <div className="form-group d-flex col-6 mt-3">
                    <Field
                      type="checkbox"
                      className="form-control mt-3 ml-0"
                      id="id_card_template"
                      name="id_card_template"
                      style={{ width: "20px", height: "20px" }}
                      checked={selectedIdCardCheck}
                      onChange={handleCheckboxChange}
                    />

                    <label className="mt-3 ml-1" htmlFor="id_card_template">
                      Enable ID Card Template{" "}
                    </label>
                  </div>

                  {selectedIdCardCheck && (
                    <div className="form-group d-flex flex-column col-6 mt-3">
                      <label htmlFor="id_card_select">
                        Select ID Card <span className="mandatory">*</span>
                      </label>
                      <select
                        className="form-control mt-2"
                        id="id_card_select"
                        name="id_card_select"
                        onChange={handleTemplateSelect}
                        value={values.id_card_template}
                        style={{
                          borderColor: "skyblue",
                          marginRight: "10px",
                          flexShrink: 0,
                        }}
                      >
                        <option value="">Select Template</option>
                        {idCardTemplates.map((template) => (
                          <option key={template.id} value={template.id}>
                            {template.name}
                          </option>
                        ))}
                      </select>
                      {(selectedIdCard?.image || iddup) && (
                        <img
                          src={
                            selectedIdCard?.image
                              ? `${REACT_APP_IMAGEURL}/${selectedIdCard.image}`
                              : `${REACT_APP_IMAGEURL}/${id_card_template_file_path}`
                          }
                          alt={selectedIdCard?.name}
                          style={{
                            maxWidth: "300px",
                            maxHeight: "200px",
                            objectFit: "contain",
                            cursor: "pointer",
                            marginTop: "10px",
                          }}
                          onClick={handleViewButtonClick}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}

              {activeTab === "receipt" && (
                <div className="tab-content" key="receipt">
                  <div className="form-group d-flex col-6 mt-3">
                    <Field
                      type="checkbox"
                      className="form-control mt-3 ml-0"
                      id="receipt_template"
                      name="receipt_template"
                      style={{ width: "20px", height: "20px" }}
                      checked={selectedReceiptCheck}
                      onChange={handleCheckboxChange}
                    />
                    <label className="mt-3 ml-1" htmlFor="receipt_template">
                      Enable Receipt Template
                    </label>
                  </div>

                  {selectedReceiptCheck && (
                    <div className="form-group d-flex flex-column col-6 mt-3">
                      <label htmlFor="receipt_select">
                        Select Receipt <span className="mandatory">*</span>
                      </label>
                      <select
                        className="form-control mt-2"
                        id="receipt_select"
                        name="receipt_select"
                        onChange={handleTemplateSelect}
                        value={values.receipt_template}
                        style={{
                          borderColor: "skyblue",
                          marginRight: "10px",
                          flexShrink: 0,
                        }}
                      >
                        <option value="">Select Template</option>
                        {receiptTemplates.map((template) => (
                          <option key={template.id} value={template.id}>
                            {template.name}
                          </option>
                        ))}
                      </select>
                      {(selectedReceipt?.image || reciept_card_id) && (
                        <img
                          src={
                            selectedReceipt?.image
                              ? `${REACT_APP_IMAGEURL}/${selectedReceipt.image}`
                              : `${REACT_APP_IMAGEURL}/${receipt_template_file_path}`
                          }
                          alt={selectedReceipt?.name}
                          style={{
                            maxWidth: "300px",
                            maxHeight: "200px",
                            objectFit: "contain",
                            cursor: "pointer",
                            marginTop: "10px",
                          }}
                          onClick={handleViewButtonClick}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </fieldset>
      <div
        className="form-step"
        style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
      >
        {modalOpen && (
          <div className="modal-overlaynew" onClick={handleCloseModal}>
            <div
              className="modal-contentnew"
              onClick={(e) => e.stopPropagation()}
              onWheel={handleZoom}
            >
              <img
                src={
                  activeTab === "reportCard"
                    ? `${REACT_APP_IMAGEURL}/${selectedReportCard?.image}`
                    : activeTab === "idCard"
                    ? `${REACT_APP_IMAGEURL}/${selectedIdCard?.image}`
                    : activeTab === "receipt"
                    ? `${REACT_APP_IMAGEURL}/${selectedReceipt?.image}`
                    : activeTab === "principalSignature"
                    ? modalImage
                    : activeTab === "logoFile"
                    ? modalImage
                    : activeTab === "reportCard"
                    ? modalImage
                    : activeTab === "textLogo"
                    ? modalImage
                    : activeTab === "printLogo"
                    ? modalImage
                    : ""
                }
                alt={
                  activeTab === "reportCard"
                    ? selectedReportCard?.name
                    : activeTab === "idCard"
                    ? selectedIdCard?.name
                    : activeTab === "receipt"
                    ? selectedReceipt?.name
                    : activeTab === "principalSignature"
                    ? "Principal Signature"
                    : activeTab === "logoFile"
                    ? "Logo File"
                    : activeTab === "reportCard"
                    ? "Report File"
                    : activeTab === "textLogo"
                    ? " Text File"
                    : activeTab === "printLogo"
                    ? " Text File"
                    : "Select Template"
                }
                style={{
                  transform: `scale(${zoomLevel})`,
                }}
              />
            </div>
          </div>
        )}
      </div>

      <div className="card-header">
        <nav className="nav nav-pills nav-fill">
          <a
            className={`nav-link tab-pills ${
              currentStep === 0 ? "active" : ""
            }`}
            onClick={() => setCurrentStep(0)}
            style={{ cursor: "pointer" }}
          >
            School Logo
          </a>
          <a
            className={`nav-link tab-pills ${
              currentStep === 1 ? "active" : ""
            }`}
            onClick={() => setCurrentStep(1)}
            style={{ cursor: "pointer" }}
          >
            Report Card Logo
          </a>
          <a
            className={`nav-link tab-pills ${
              currentStep === 2 ? "active" : ""
            }`}
            onClick={() => setCurrentStep(2)}
            style={{ cursor: "pointer" }}
          >
            Text Logo
          </a>
          <a
            className={`nav-link tab-pills ${
              currentStep === 3 ? "active" : ""
            }`}
            onClick={() => setCurrentStep(3)}
            style={{ cursor: "pointer" }}
          >
            Printing Logo
          </a>
        </nav>
      </div>
      <div className="card-body" style={{ height: "300px" }}>
        {currentStep === 0 && (
          <div className="tab">
            <div className="mb-3" style={{ textAlign: "center" }}>
              {fileStates.logo_file || values.logo_file ? (
                <div>
                  <img
                    className="form-label"
                    style={{ width: "10%", height: "10%", cursor: "pointer" }}
                    src={
                      

                      fileStates.logo_file // New upload preview
                        ? fileStates.logo_file
                        : values.logo_file // Existing image during update
                        ? `${REACT_APP_IMAGEURL}/${values.logo_file}` // Existing image URL
                        : ""
                    }
                    alt="Logo File"
                    onClick={() =>
                      handleImageClick(
                        fileStates.logo_file ||
                          `${REACT_APP_IMAGEURL}/${values.logo_file}`
                      )
                    }
                  />
                </div>
              ) : null}

              <DragNdrop
                setFieldValue={setFieldValue}
                fieldName="logo_file"
                file={fileStates.logo_file}
                onFileChange={handleFileChange}
              />
              <ErrorMessage
                name="logo_file"
                component="div"
                className="text-danger"
              />
              {logoFileError && (
                <div className="text-danger logo_error">{logoFileError}</div>
              )}
            </div>
          </div>
        )}

        {currentStep === 1 && (
          <div className="tab">
            <div className="mb-3" style={{ textAlign: "center" }}>
              {fileStates.report_card || values.report_card ? (
                <div>
                  <img
                    className="form-label"
                    style={{ width: "10%", height: "10%" }}
                    src={
                      fileStates.report_card ||
                      `${REACT_APP_IMAGEURL}/${values.report_card}`
                    }
                    alt="Report Card"
                    onClick={() =>
                      handleImageClick(
                        fileStates.report_card ||
                          `${REACT_APP_IMAGEURL}/${values.report_card}`
                      )
                    }
                  />
                </div>
              ) : null}

              <DragNdrop
                setFieldValue={setFieldValue}
                fieldName="report_card"
                file={fileStates.report_card}
                onFileChange={handleFileChange}
                style={{ margin: "0 auto" }}
              />
              <ErrorMessage
                name="report_card"
                component="div"
                className="text-danger"
              />
              {logoFileError && (
                <div className="text-danger logo_error">{logoFileError}</div>
              )}
            </div>
          </div>
        )}
        {currentStep === 2 && (
          <div className="tab">
            <div className="mb-3" style={{ textAlign: "center" }}>
              {fileStates.text_logo || values.text_logo ? (
                <div>
                  <img
                    className="form-label"
                    style={{ width: "10%", height: "10%" }}
                    src={
                      fileStates.text_logo ||
                      `${REACT_APP_IMAGEURL}/${values.text_logo}`
                    }
                    alt="Text Logo"
                    onClick={() =>
                      handleImageClick(
                        fileStates.text_logo ||
                          `${REACT_APP_IMAGEURL}/${values.text_logo}`
                      )
                    }
                  />
                </div>
              ) : null}

              <DragNdrop
                setFieldValue={setFieldValue}
                fieldName="text_logo"
                file={fileStates.text_logo}
                onFileChange={handleFileChange}
              />
              <ErrorMessage
                name="text_logo"
                component="div"
                className="text-danger"
              />
              {logoFileError && (
                <div className="text-danger logo_error">{logoFileError}</div>
              )}
            </div>
          </div>
        )}
        {currentStep === 3 && (
          <div className="tab">
            <div className="mb-3" style={{ textAlign: "center" }}>
              {fileStates.print_file || values.print_file ? (
                <div>
                  <img
                    className="form-label"
                    style={{ width: "10%", height: "10%" }}
                    src={
                      fileStates.print_file ||
                      `${REACT_APP_IMAGEURL}/${values.print_file}`
                    }
                    alt="print Logo"
                    onClick={() =>
                      handleImageClick(
                        fileStates.print_file ||
                          `${REACT_APP_IMAGEURL}/${values.print_file}`
                      )
                    }
                  />
                </div>
              ) : null}

              <DragNdrop
                setFieldValue={setFieldValue}
                fieldName="print_file"
                file={fileStates.print_file}
                onFileChange={handleFileChange}
              />
              <ErrorMessage
                name="print_file"
                component="div"
                className="text-danger"
              />
              {logoFileError && (
                <div className="text-danger logo_error">{logoFileError}</div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="card-footer d-flex justify-content-between mb-2">
        <div className="w-100 d-flex">
          {currentStep > 0 && (
            <button
              type="button"
              id="back_button"
              className="btn btn-secondary me-auto"
              onClick={back}
            >
              <i className="fas fa-arrow-left"></i>
            </button>
          )}
        </div>
        <div className="w-100 d-flex justify-content-end">
          <button type="button" onClick={next} className="btn btn-primary">
            <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MultiStepForm;
