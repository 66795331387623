import React from "react";
import { useState, useEffect } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { getParentsByNum } from "../../Services/CommonApis/StudentApi";
import { getAllLanguages } from "../../Services/AdminApis/LanguagesApi";
import { getAllOccupations } from "../../Services/AdminApis/occupationApi";

const AdmissionPersonalInfo = ({ steps, current }) => {
  const { setFieldValue, values } = useFormikContext();
  const [occupation, setOccupation] = useState([]);
  const [language, setLanguage] = useState([]);
  console.log(values);
  const [showParentPhone, setShowParentPhone] = useState(false);
  const { touched, errors } = useFormikContext();

  const handleSiblingsChange = (e) => {
    setShowParentPhone(e.target.checked);
  };

  useEffect(() => {
    fetchLanguages();
    fetchOccupation();
  }, []);

  const getBorderColor = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? "red" : "skyblue";
  };

  const fetchLanguages = async () => {
    try {
      const response = await getAllLanguages();
      setLanguage(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchOccupation = async () => {
    try {
      const response = await getAllOccupations();
      setOccupation(response.data.occupations);
    } catch (error) {
      console.timeLog(error);
    }
  };
  const handleParentSubmit = async () => {
    console.log(values);
    try {
      const response = await getParentsByNum(values.num);
      console.log("API response: ", response);
    } catch (error) {
      console.error("Error fetching parent data: ", error);
    }
  };
  const handleFocus = (event) => {
    if (event.target.type === "date") {
      event.target.showPicker();
    }
  };

  const handleDateClick = (event) => {
    if (event.target.type === "date") {
      event.target.focus();
    }
  };
  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === 2 && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Student Details:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 2 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="first_name" className="form-label">
                    First Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    type="text"
                    name="first_name"
                    className="form-control"
                    style={{ borderColor: getBorderColor("first_name") }}

                  />
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="middle_name" className="form-label">
                    Middle Name
                  </label>
                  <Field
                    type="text"
                    name="middle_name"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="middle_name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="last_name" className="form-label">
                    Last Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    type="text"
                    name="last_name"
                    className="form-control"
                    style={{ borderColor: getBorderColor("last_name") }}
                  />
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="gender" className="form-label">
                    Gender <span className="mandatory">*</span>
                  </label>
                  <Field as="select" name="gender" className="form-control">
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Field>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="date_of_birth" className="form-label">
                    Date Of Birth <span className="mandatory">*</span>
                  </label>
                  <Field
                    type="date"
                    name="date_of_birth"
                    className="form-control"
                    onFocus={handleFocus}
                    onClick={handleDateClick}
                    max={new Date().toISOString().split("T")[0]}
                  />
                  <ErrorMessage
                    name="date_of_birth"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="blood_group" className="form-label">
                    Blood Group
                  </label>
                  <Field
                    as="select"
                    name="blood_group"
                    className="form-control"
                  >
                    <option value="">Select Blood Group</option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                  </Field>
                  <ErrorMessage
                    name="blood_group"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="place_of_birth" className="form-label">
                    Place Of Birth
                  </label>
                  <Field
                    type="text"
                    name="place_of_birth"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="place_of_birth"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="father_name" className="form-label">
                    Father Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    type="text"
                    name="father_name"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="father_name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="father_last_name" className="form-label">
                    Father Last Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    type="text"
                    name="father_last_name"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="father_last_name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="fatherOccupation" className="form-label">
                    Father Occupation
                  </label>
                  <Field
                    name="father_occupation"
                    as="select"
                    className="form-control"
                  >
                    <option value="">Select Language</option>
                    {occupation.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fatherOccupation"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="father_aadhaar_no" className="form-label">
                    Father Aadhar
                  </label>
                  <Field
                    type="text"
                    name="father_aadhaar_no"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="father_aadhaar_no"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="father_pan_card" className="form-label">
                    Father Pan Number
                  </label>
                  <Field
                    type="text"
                    name="father_pan_card"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="father_pan_card"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="mother_name" className="form-label">
                    Mother Name
                  </label>
                  <Field
                    type="text"
                    name="mother_name"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mother_name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="mother_phone" className="form-label">
                    Mother Phone
                  </label>
                  <Field
                    type="text"
                    name="mother_phone"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mother_phone"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="mother_email" className="form-label">
                    Mother Email
                  </label>
                  <Field
                    type="text"
                    name="mother_email"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mother_email"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="mother_education" className="form-label">
                    Mother Education
                  </label>
                  <Field
                    type="text"
                    name="mother_education"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mother_education"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="mother_occupation" className="form-label">
                    Mother Occupation
                  </label>
                  <Field
                    name="mother_occupation"
                    as="select"
                    className="form-control"
                  >
                    <option value="">Select Language</option>
                    {occupation.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="mother_occupation"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="mother_annual_income" className="form-label">
                    Mother Income
                  </label>
                  <Field
                    type="number"
                    name="mother_annual_income"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mother_annual_income"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="mother_aadhaar_no" className="form-label">
                    Mother Aadhar
                  </label>
                  <Field
                    type="text"
                    name="mother_aadhaar_no"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mother_aadhaar_no"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="mother_pan_card" className="form-label">
                    Mother pan No
                  </label>
                  <Field
                    type="text"
                    name="mother_pan_card"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mother_pan_card"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="annual_income" className="form-label">
                    Annual Income
                  </label>
                  <Field
                    type="number"
                    name="annual_income"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="annual_income"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>



              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="religion" className="form-label">
                    Religion
                  </label>
                  <Field type="text" name="religion" className="form-control" />
                  <ErrorMessage
                    name="religion"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="cast" className="form-label">
                    Caste
                  </label>
                  <Field type="text" name="cast" className="form-control" />
                  <ErrorMessage
                    name="cast"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>


              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="mother_tounge" className="form-label">
                    Mother Tongue
                  </label>
                  <Field
                    name="mother_tounge"
                    as="select"
                    className="form-control"
                  >
                    <option value="">Select Language</option>
                    {language.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="mother_tounge"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="first_lang_id" className="form-label">
                    First Language
                  </label>
                  <Field
                    name="first_lang_id"
                    as="select"
                    className="form-control"
                  >
                    <option value="">Select Language</option>
                    {language.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="first_lang_id"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="second_lang_id" className="form-label">
                    Second Language
                  </label>
                  <Field
                    name="second_lang_id"
                    as="select"
                    className="form-control"
                  >
                    <option value="">Select Language</option>
                    {language.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="second_lang_id"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="third_lang_id" className="form-label">
                    Third Language
                  </label>
                  <Field
                    name="third_lang_id"
                    as="select"
                    className="form-control"
                  >
                    <option value="">Select Language</option>
                    {language.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="third_lang_id"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default AdmissionPersonalInfo;
