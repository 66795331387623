import React, { useState } from "react";
import UtilityList from "./UtilityList";
import AssetsManagement from "./AssetsManagement";

function AssestManagementTab() {
  const [activeTab, setActiveTab] = useState("booksissuedlist");
  const handleTabSwitch = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "booksissuedlist" ? "active" : ""}`}
              onClick={() => handleTabSwitch("booksissuedlist")}
            >
              Assets List
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "utilitylist" ? "active" : ""}`}
              onClick={() => handleTabSwitch("utilitylist")}
            >
              Utility List
            </button>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          {activeTab === "booksissuedlist" && (
            <div className="tab-pane active">
              <AssetsManagement />
            </div>
          )}
          {activeTab === "utilitylist" && (
            <div className="tab-pane active">
              <UtilityList key={activeTab} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default AssestManagementTab;