import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import hii from "../../Assests/8d52c5c35382908832ffedb21c1e63b0.jpg";
import me from "../../Assests/eeaf332912b0e2fed25e819ef7a16d8d.jpg";
import { useParams } from "react-router-dom";
import {
  getDashboardAttendanceClassCount,
  getDashboardHomeworkCount,
  getDashboardBirthdayCount,
  getDashboardDetails,
} from "../../Services/AdminApis/dashboardApi";
import { Carousel } from "primereact/carousel";
import { Button } from "primereact/button";
import vinayak from "../../Assests/vinayak.jpg";
import christ from "../../Assests/crist.jpg";
import ram from "../../Assests/rama.webp";

const Dashboard = () => {
  const { schoolId, branchId } = useParams();
  const [tasks, setTasks] = useState([]);
  const REACT_APP_IMAGEURL = process.env.REACT_APP_IMAGEURL;

  const [dashboardData, setDashboardData] = useState({
    total_students: 0,
    total_staff: 0,
    total_classes: 0,
    total_parents: 0,
    total_branches: 0,
    total_schools: 0,
    girls_count: 0,
    boys_count: 0,
    attendance_class_count: 0,
    home_work_class_count: 0,
    birthday_count: 0,
    holidays: [],
    birthdayDetails: { birthday_students: [], birthday_staff: [] },
    achievements: [],
  });

  useEffect(() => {
    const fetchDashboardDetails = async () => {
      try {
        const response = await getDashboardDetails({
          school_id: schoolId,
          branch_id: branchId,
        });
        const data = response.data.data || {
          total_students: 0,
          total_staff: 0,
          total_classes: 0,
          total_parents: 0,
          total_branches: 0,
          total_schools: 0,
          girls_count: 0,
          boys_count: 0,
          home_work_class_count: 0,
          birthday_count: { todayBirthdayCount: 0, monthlyBirthdayCount: 0 },
          attendance_class_count: 0,
          holidays: 0,
          achievements: 0,
          birthdayDetails: { birthday_students: [], birthday_staff: [] },
        };
        setDashboardData(data);
      } catch (error) {
        console.error("Error fetching dashboard details:", error);
      }
    };

    fetchDashboardDetails();
    const intervalId = setInterval(fetchDashboardDetails, 30000);
    return () => clearInterval(intervalId);
  }, [schoolId, branchId]);
  useEffect(() => {
    if (!dashboardData) return;

    const updatedTasks = [
      {
        description: `Daily H/W notification has been sent for ${dashboardData.home_work_class_count} classes today`,
        icon: "fas fa-bell",
        status: "success",
      },
      {
        description: `${dashboardData.birthday_count.todayBirthdayCount} birthday notifications need to be sent today`,
        icon: "fas fa-birthday-cake",
        status: "success",
      },
      {
        description: `${dashboardData.birthday_count.monthlyBirthdayCount} monthly birthday notifications need to be sent this month.`,
        icon: "fas fa-birthday-cake",
        status: "success",
      },
      {
        description: `There are ${dashboardData.attendance_class_count} classes marked for attendance today`,
        icon: "fas fa-user-check",
        status: "success",
      },
      {
        description:
          "12 Report card notifications are pending for publishing today",
        icon: "fas fa-file-alt",
        status: "failure",
      },
    ];

    setTasks(updatedTasks);
  }, [dashboardData]);

  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const getDaysArray = (year, month) => {
    const daysInMonth = getDaysInMonth(year, month);
    const firstDay = getFirstDayOfMonth(year, month);
    const daysArray = new Array(firstDay)
      .fill(null)
      .concat(Array.from({ length: daysInMonth }, (_, i) => i + 1));
    return daysArray;
  };

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);

  const handlePrevMonth = () => {
    setMonth((prevMonth) => {
      if (prevMonth === 0) {
        setYear((prevYear) => prevYear - 1);
        return 11;
      } else {
        return prevMonth - 1;
      }
    });
  };

  const handleNextMonth = () => {
    setMonth((prevMonth) => {
      if (prevMonth === 11) {
        setYear((prevYear) => prevYear + 1);
        return 0;
      } else {
        return prevMonth + 1;
      }
    });
  };

  // Sample news data with icons
  const latestNews = [
    {
      title: "New Library Opening",
      description: "Our new library will be inaugurated next week.",
      icon: "fas fa-book",
    },
    {
      title: "Sports Day Results",
      description: "Check out the results of our annual sports day.",
      icon: "fas fa-football-ball",
    },
    {
      title: "Parent-Teacher Meeting",
      description: "Scheduled for next Friday at 3 PM.",
      icon: "fas fa-chalkboard-teacher",
    },
    {
      title: "Summer Camp Registration",
      description: "Register now for our exciting summer camp activities.",
      icon: "fas fa-sun",
    },
  ];

  const studentBirthdays = [
    {
      id: 1,
      name: "John Doe",
      date: "15",
      month: "AUG",
      class: "10th Grade",
      section: "Section B",
    },
    {
      id: 2,
      name: "Jane Smith",
      date: "22",
      month: "AUG",
      class: "9th Grade",
      section: "Section A",
    },
  ];

  const staffBirthdays = [
    {
      id: 1,
      name: "Mr. Adams",
      date: "10",
      month: "AUG",
      role: "Principal",
      profileImage: "path/to/profileImage3.jpg",
    },
    {
      id: 2,
      name: "Ms. Baker",
      date: "18",
      month: "AUG",
      role: "Teacher",
      profileImage: "path/to/profileImage4.jpg",
    },
  ];

  const staffOnLeave = [
    {
      id: 1,
      name: "Ms. Olivia Watson",
      role: "Mathematics Teacher",
    },
    {
      id: 2,
      name: "Mr. Henry Green",
      role: "Science Teacher",
    },
    {
      id: 3,
      name: "Ms. Alice White",
      role: "Librarian",
    },
  ];

  const [activeTab, setActiveTab] = useState("students");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const shuffleTasks = (tasks) => {
    return tasks.sort(() => Math.random() - 0.5);
  };

  const upcomingEvents = [
    {
      date: "05",
      month: "NOV",
      title: "Annual Sports Day",
      description: "Join us for an exciting day of sports and activities.",
      icon: "fas fa-running",
    },
    {
      date: "07",
      month: "NOV",
      title: "Science Fair",
      description: "Showcase your science projects and innovations.",
      icon: "fas fa-flask",
    },
    {
      date: "12",
      month: "NOV",
      title: "Graduation Ceremony",
      description: "Celebrate the achievements of our graduating students.",
      icon: "fas fa-graduation-cap",
    },
  ];

  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "1024px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "768px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const holidayTemplate = (holiday) => {
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = date.toLocaleString("en-GB", { month: "short" });
      const year = String(date.getFullYear()).slice(-2);
      return `${day} ${month} ${year}`;
    };

    return (
      <div
        className="border-1 surface-border border-round m-2 text-center p-3"
        style={{
          backgroundColor: holiday.bgColor || "#f5f5f5",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "20px",
          height: "200px",
          width: "420px",
          overflow: "hidden",
        }}
      >
        {holiday.image && (
          <div style={{ width: "40%" }}>
            <img
              src={`${process.env.REACT_APP_IMAGEURL}/holiday_images/${holiday.image}`}
              alt={holiday.name}
              style={{ width: "100%", borderRadius: "10px" }}
            />
          </div>
        )}
        <div
          style={{
            textAlign: "left",
            width: holiday.image ? "55%" : "100%",
            color: "#333",
          }}
        >
          <p>
            <span style={{ fontWeight: "bold" }}>Date:</span>&nbsp;
            {formatDate(holiday.from_date)} - {formatDate(holiday.to_date)}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Day:</span>&nbsp;
            {holiday.from_day} - {holiday.to_day}
          </p>

          <p>
            <span style={{ fontWeight: "bold" }}>HolidayType:</span>&nbsp;
            {holiday.type}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Dashboard</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Dashboard v1</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>{dashboardData.total_students}</h3>
                  <p>Total Students</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>0</h3>
                  <p>TC Students</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>{dashboardData.total_classes}</h3>
                  <p>Total Classes</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>{dashboardData.total_staff}</h3>
                  <p>Teaching Staff</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>{dashboardData.total_schools}</h3>
                  <p>Total Schools</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>

            {!branchId && (
              <div className="col-lg-3 col-6">
                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>{dashboardData.total_branches}</h3>
                    <p>Total Branches</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-stats-bars"></i>
                  </div>
                  <a href="#" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            )}
            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>{dashboardData.girls_count}</h3>
                  <p>Total Girls</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3>{dashboardData.boys_count}</h3>
                  <p>Total Boys</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph"></i>
                </div>
                <a href="#" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="row">
            <section className="col-lg-6 connectedSortable">
              <div className="card" style={{ height: "300px" }}>
                <div className="card-header bg-warning">
                  <h3 className="card-title">
                    <i className="fas fa-tasks"></i>
                    {"    "} To Do
                  </h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 news-ticker">
                      <ul className="list-unstyled news-container">
                        {tasks.map((task, index) => (
                          <li
                            key={index}
                            style={{
                              marginBottom: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              color:
                                task.status === "success" ? "green" : "red",
                              fontSize: "18px", // Set font size here
                            }}
                          >
                            <span>
                              <i
                                className={task.icon}
                                style={{ marginRight: "8px" }}
                              ></i>
                              {"  "}
                              {task.description}
                            </span>
                            <i
                              className={
                                task.status === "success"
                                  ? "fas fa-check-circle mt-1 ml-3"
                                  : "fas fa-exclamation-triangle mt-1 ml-3"
                              }
                              style={{
                                color:
                                  task.status === "success" ? "green" : "red",
                              }}
                            ></i>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card" style={{ height: "300px" }}>
                <div className="card-header bg-blue">
                  <h3 className="card-title">
                    <i className="fas fa-tasks"></i>
                    {"    "} Holiday List
                  </h3>
                </div>

                <div className="dashboard-container">
                  <div className="card-body">
                    {dashboardData.holidays &&
                    dashboardData.holidays.length > 0 ? (
                      <Carousel
                        value={dashboardData.holidays}
                        numVisible={1}
                        numScroll={1}
                        circular
                        autoplayInterval={3000}
                        itemTemplate={holidayTemplate}
                        style={{ width: "520px" }}
                      />
                    ) : (
                      <p>No holidays available</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header"
                  style={{ backgroundColor: "#e297e071" }}
                >
                  <h3 className="card-title">
                    <i className="fas fa-award mr-1"></i>
                    Achievements
                  </h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    {dashboardData.achievements.map((achievement, index) => (
                      <div className="col-md-6" key={index}>
                        <div className="info-box">
                          <span
                            className="info-box-icon"
                            style={{ backgroundColor: "#e297e071" }}
                          >
                            <i className="fas fa-trophy"></i>
                          </span>
                          <div className="info-box-content">
                            <span className="info-box-text">
                              <span style={{ fontWeight: "bold" }}>NAME:</span>
                              &nbsp;{achievement.first_name}&nbsp;
                              {achievement.last_name}
                            </span>
                            <span className="info-box-number">
                              {achievement.achievement_description}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header"
                  style={{ backgroundColor: "#e27070" }}
                >
                  <h3 className="card-title">
                    <i className="fas fa-calendar-alt mr-1"></i>
                    Upcoming Events
                  </h3>
                </div>
                <div className="card-body">
                  <div className="events-widget">
                    <div className="header">
                      <h2>Events</h2>
                      <button className="view-more">VIEW MORE</button>
                    </div>
                    {upcomingEvents.map((event, index) => (
                      <div key={index} className="event">
                        <div className="event-date">
                          <span className="month">{event.month}</span>
                          <span className="day">{event.date}</span>
                        </div>
                        <div className="event-details">
                          <h3 className="event-title">{event.title}</h3>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
            <section className="col-lg-6 connectedSortable">
              <div className="card" style={{ height: "300px" }}>
                <div
                  className="card-header"
                  style={{ backgroundColor: "yellowgreen" }}
                >
                  <h3 className="card-title">
                    <i className="fas fa-newspaper mr-1"></i>
                    Latest News
                  </h3>
                </div>
                <div className="card-body news-ticker">
                  <div className="news-container">
                    {latestNews.map((news, index) => (
                      <div key={index} className="news-item">
                        <i className={`mr-2 ${news.icon}`}></i>
                        <strong>{news.title}:</strong> {news.description}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="card">
                <div
                  className="card-header"
                  style={{ backgroundColor: "#d86a6a6e" }}
                >
                  <h3 className="card-title">
                    <i className="fas fa-birthday-cake mr-1"></i> Birthdays
                  </h3>
                  <div className="card-tools">
                    <ul className="nav nav-pills ml-auto">
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            activeTab === "students" ? "activeTab" : ""
                          }`}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleTabChange("students");
                          }}
                        >
                          Students
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            activeTab === "staff" ? "activeTab" : ""
                          }`}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleTabChange("staff");
                          }}
                        >
                          Staff
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="card-body">
                  {activeTab === "students" && (
                    <div className="birthday-list">
                      {dashboardData.birthdayDetails.birthday_students.length >
                      0 ? (
                        dashboardData.birthdayDetails.birthday_students.map(
                          (student) => (
                            <div className="birthday-item" key={student.id}>
                              <div style={{ display: "flex" }}>
                                <img
                                  src={hii}
                                  alt=""
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    borderRadius: "50px",
                                  }}
                                />
                                <div className="birthday-details">
                                  <h5>
                                    {student.first_name}&nbsp;
                                    {student.last_name}
                                  </h5>
                                  <p>
                                    <span style={{ fontWeight: "bold" }}>
                                      Class:
                                    </span>
                                    &nbsp;{student.class_name}
                                  </p>
                                  <p>
                                    <span style={{ fontWeight: "bold" }}>
                                      Section:
                                    </span>
                                    &nbsp;{student.section_name}
                                  </p>
                                </div>
                              </div>
                              <p>
                                <i className="fas fa-birthday-cake"></i>
                                {"    "}
                                {new Intl.DateTimeFormat("en-GB", {
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                })
                                  .formatToParts(
                                    new Date(student.date_of_birth)
                                  )
                                  .map((part) => part.value)
                                  .join(" ")}
                              </p>
                            </div>
                          )
                        )
                      ) : (
                        <p>No birthdays today for students</p>
                      )}
                    </div>
                  )}

                  {activeTab === "staff" && (
                    <div className="birthday-list">
                      {dashboardData.birthdayDetails.birthday_staff.length >
                      0 ? (
                        dashboardData.birthdayDetails.birthday_staff.map(
                          (staff) => (
                            <div className="birthday-item" key={staff.id}>
                              <div style={{ display: "flex" }}>
                                <img
                                  src={me}
                                  alt=""
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    borderRadius: "50px",
                                  }}
                                />
                                <div className="birthday-details">
                                  <h5>
                                    {staff.first_name}&nbsp;{staff.last_name}
                                  </h5>
                                  <p style={{ fontSize: "14px" }}>
                                    <span style={{ fontWeight: "bold" }}>
                                      Department:&nbsp;
                                    </span>
                                    {staff.department_name}
                                  </p>
                                </div>
                              </div>
                              <p>
                                <i className="fas fa-birthday-cake"></i>
                                {"  "}
                                {new Intl.DateTimeFormat("en-GB", {
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                })
                                  .formatToParts(new Date(staff.date_of_birth))
                                  .map((part) => part.value)
                                  .join(" ")}
                              </p>
                            </div>
                          )
                        )
                      ) : (
                        <p>No birthdays today for staff</p>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header"
                  style={{ backgroundColor: "green" }}
                >
                  <h3 className="card-title">
                    <i className="fas fa-user-clock mr-1"></i>
                    Staff on Leave
                  </h3>
                </div>
                <div className="card-body">
                  <ul className="staff-on-leave-list">
                    {staffOnLeave.map((staff) => (
                      <li key={staff.id} className="staff-item">
                        <div>
                          <h5 className="staff-name">
                            <i className="fa fa-bookmark"></i> {staff.name}
                          </h5>
                          <p className="staff-role">{staff.role}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="card">
                <div
                  className="card-header "
                  style={{ backgroundColor: "#007bff" }}
                >
                  <h3 className="card-title">
                    <i className="fas fa-calendar mr-1"></i>
                    Calendar
                  </h3>
                </div>
                <div className="card-body">
                  <div className="month-navigation">
                    <button onClick={handlePrevMonth} className="">
                      &lt;
                    </button>
                    <span>
                      {monthsOfYear[month]} {year}
                    </span>
                    <button onClick={handleNextMonth} className="">
                      &gt;
                    </button>
                  </div>
                  <div className="days-of-week">
                    {daysOfWeek.map((day) => (
                      <div key={day} className="day">
                        {day}
                      </div>
                    ))}
                  </div>
                  <div className="days">
                    {getDaysArray(year, month).map((day, index) => (
                      <div
                        key={index}
                        className={`day ${day === null ? "null" : ""}`}
                      >
                        {day}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};
export default Dashboard;
