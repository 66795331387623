import Api from "../Api";
export const getAllAssestManagement = (params) => {
  return Api.get(`/get-all-asset-management`, { params });
};
export const createAssestManagement = (remarkData, branchId) => {
  return Api.post(`/create-asset-management`, { ...remarkData, branch_id: branchId });
};
export const getAssestManagementById = async (id) => {
  try {
    const response = await Api.get(`/get-all-asset-management/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateAssestManagementById = async (id, data) => {
  return await Api.post(`/update-asset-management/${id}`, data);
};
export const deleteAssestManagementById = async (id) => {
  try {
    const response = await Api.delete(`/delete-asset-management/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};