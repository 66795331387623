import React, { useEffect, useState } from "react";
import Swal from "sweetalert2"; // Import SweetAlert
import {
  getSubjectsByBranchId,
  createSubjectTopic,
  getSubjectTopicById,
} from "../../Services/CommonApis/subjectApi";
import { useParams } from "react-router-dom";

const SyllabusBook = () => {
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [chapters, setChapters] = useState([]);
  const { branchId } = useParams();
  const [syllabus, setSyllabus] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await getSubjectsByBranchId(branchId);
        const subjects = response.branchsubject.map((subject) => ({
          id: subject.subject_id,
          name: subject.subject_name,
        }));
        setSubjects(subjects);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    };

    fetchSubjects();
  }, [branchId]);

  const fetchSyllabus = async (subjectId) => {
    try {
      Swal.fire({
        title: "Fetching Syllabus...",
        allowOutsideClick: false,
        didOpen: () => Swal.showLoading(),
      });
      const response = await getSubjectTopicById(subjectId);
      setSyllabus(response.chapters || []);
      Swal.close();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to fetch syllabus. Please try again.",
      });
      console.error("Error fetching syllabus:", error);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedSubject(event.target.value);
    const subjectId = event.target.value;
    setSelectedSubject(subjectId);
    if (subjectId) {
      fetchSyllabus(subjectId);
    } else {
      setSyllabus([]);
    }
  };
  const onSyllabusChange = (e, index, prefix, key) => {
    const value = e.target.value;
    setSyllabus((prevSyllabus) => {
      const updateNested = (items, currentPrefix) =>
        items.map((item, idx) => {
          const itemPrefix = `${currentPrefix}${currentPrefix ? "." : ""}${
            idx + 1
          }`;
          if (itemPrefix === prefix) {
            return {
              ...item,
              [key]: value,
            };
          }
          if (item.nested_syllabus || item.syllabus) {
            return {
              ...item,
              nested_syllabus: updateNested(
                item.nested_syllabus || item.syllabus,
                itemPrefix
              ),
            };
          }
          return item;
        });
      return updateNested(prevSyllabus, "");
    });
  };

  const renderSyllabusRows = (
    syllabus,
    prefix = "",
    onSyllabusChange = () => {}
  ) => {
    return syllabus.flatMap((topic, index) => {
      const currentNumber = `${prefix}${prefix ? "." : ""}${index + 1}`;
      const row = (
        <tr key={currentNumber}>
          <td style={{ paddingLeft: `${prefix.split(".").length * 20}px` }}>
            <strong>{currentNumber}</strong>{" "}
            <input
              type="text"
              value={topic.subtopic || topic.title || "Untitled"}
              onChange={(e) => onSyllabusChange(e, index, prefix, "title")}
              placeholder="Enter title"
              style={{ width: "80%" }}
            />
          </td>
          <td className="text-end">
            <input
              type="number"
              value={topic.page_no || ""}
              onChange={(e) => onSyllabusChange(e, index, prefix, "page_no")}
              placeholder="Page No"
              style={{ width: "80px", textAlign: "right" }}
            />
          </td>
        </tr>
      );

      const nestedRows = topic.nested_syllabus
        ? renderSyllabusRows(
            topic.nested_syllabus,
            currentNumber,
            onSyllabusChange
          )
        : topic.syllabus
        ? renderSyllabusRows(topic.syllabus, currentNumber, onSyllabusChange)
        : [];

      return [row, ...nestedRows];
    });
  };

  const renderSyllabus = (
    chapters,
    onChapterChange = () => {},
    onSyllabusChange = () => {}
  ) => {
    return chapters.map((chapter, index) => {
      const chapterNumber = `${index + 1}`;
      return (
        <React.Fragment key={chapter.id}>
          <tr>
            <th colSpan="2">
              <strong>{chapterNumber}</strong>{" "}
              <input
                type="text"
                value={chapter.title || "Untitled Chapter"}
                onChange={(e) => onChapterChange(e, index, "title")}
                placeholder="Enter chapter title"
                style={{ width: "80%" }}
              />
            </th>
          </tr>
          {chapter.syllabus &&
            renderSyllabusRows(
              chapter.syllabus,
              chapterNumber,
              onSyllabusChange
            )}
        </React.Fragment>
      );
    });
  };

  const handleAddChapter = () => {
    const updatedChapters = [
      ...chapters,
      { id: Date.now(), name: "", pageNo: "", children: [] },
    ];
    setChapters(generateNumbering(updatedChapters));
  };

  const handleAddChild = (parentId) => {
    const updatedChapters = addChild(chapters, parentId);
    setChapters(generateNumbering(updatedChapters));
  };

  const addChild = (structure, parentId) => {
    return structure.map((item) => {
      if (item.id === parentId) {
        return {
          ...item,
          children: [
            ...item.children,
            { id: Date.now(), name: "", pageNo: "", children: [] },
          ],
        };
      }
      if (item.children) {
        return { ...item, children: addChild(item.children, parentId) };
      }
      return item;
    });
  };

  const removeChild = (structure, id) => {
    return structure
      .map((item) => {
        if (item.children) {
          return {
            ...item,
            children: removeChild(item.children, id),
          };
        }
        return item;
      })
      .filter((item) => item.id !== id);
  };

  const updateStructure = (structure, id, key, value) => {
    return structure.map((item) => {
      if (item.id === id) {
        return { ...item, [key]: value };
      }
      if (item.children) {
        return {
          ...item,
          children: updateStructure(item.children, id, key, value),
        };
      }
      return item;
    });
  };

  const handleRemoveChild = (id) => {
    const updatedChapters = removeChild(chapters, id);
    setChapters(generateNumbering(updatedChapters));
  };

  const handleUpdateName = (id, value) => {
    const updatedChapters = updateStructure(chapters, id, "name", value);
    setChapters(generateNumbering(updatedChapters));
  };

  const handleUpdatePageNo = (id, value) => {
    const updatedChapters = updateStructure(chapters, id, "pageNo", value);
    setChapters(generateNumbering(updatedChapters));
  };

  const generateNumbering = (structure, parentNumber = "") => {
    return structure.map((item, index) => {
      const currentNumber = parentNumber
        ? `${parentNumber}.${index + 1}`
        : `${index + 1}`;

      item.subtopic_id = currentNumber;
      return {
        ...item,
        number: currentNumber,
        children: generateNumbering(item.children, currentNumber),
      };
    });
  };

  const renderStructure = (structure) => {
    return structure.map((item) => {
      const level = item.number.split(".").length;
      const isMainChapter = level === 1;
      const isDeepLevel = level > 6;

      return (
        <div
          key={item.id}
          className={`chapter-wrapper ${isDeepLevel ? "deep-level" : ""}`}
          style={{
            marginLeft: isDeepLevel ? "0px" : `${level * 20}px`,
          }}
        >
          <div className="d-flex align-items-center chapter-content">
            <label className="chapter-label">
              {isMainChapter
                ? `Chapter ${item.number}`
                : `Subtopic ${item.number}`}
            </label>
            <input
              type="text"
              className="form-control chapter-input"
              value={item.name}
              placeholder={
                isMainChapter
                  ? "Enter Chapter Name"
                  : "Enter Subtopic/Topic Name"
              }
              onChange={(e) => handleUpdateName(item.id, e.target.value)}
            />
            <input
              type="text"
              className="form-control chapter-input"
              value={item.pageNo}
              placeholder="Page No (e.g., 1 to 5)"
              onChange={(e) => handleUpdatePageNo(item.id, e.target.value)}
            />
            <div className="chapter-buttons">
              <button
                className="chapter-button success"
                onClick={() => handleAddChild(item.id)}
              >
                +
              </button>
              <button
                className="chapter-button danger"
                onClick={() => handleRemoveChild(item.id)}
              >
                -
              </button>
            </div>
          </div>
          {item.children.length > 0 && renderStructure(item.children)}
        </div>
      );
    });
  };

  const prepareChaptersForAPI = (chapters) => {
    return chapters.map((chapter, index) => {
      if (!chapter.name) {
        throw new Error("Chapter name is required.");
      }
      if (!chapter.pageNo) {
        throw new Error(`Page number is required for chapter: ${chapter.name}`);
      }

      const formattedSubtopics = chapter.children.map((subtopic, subIndex) => {
        if (!subtopic.name) {
          throw new Error(
            `Subtopic name is required in chapter: ${chapter.name}`
          );
        }
        if (!subtopic.pageNo) {
          throw new Error(
            `Page number is required for subtopic: ${subtopic.name} in chapter: ${chapter.name}`
          );
        }

        return {
          subtopic_id: subtopic.subtopic_id, // Include the subtopic_id
          subtopic: subtopic.name,
          status: 1,
          page_no: subtopic.pageNo,
          nested_syllabus:
            subtopic.children.length > 0
              ? subtopic.children.map((nestedSubtopic) => ({
                  subtopic_id: nestedSubtopic.subtopic_id, // Include nested subtopic_id
                  subtopic: nestedSubtopic.name,
                  status: 1,
                  page_no: nestedSubtopic.pageNo,
                }))
              : [],
        };
      });

      return {
        topic_id: index + 1, // Use the index as topic_id (chapter number)
        title: chapter.name,
        status: 1,
        page_no: chapter.pageNo,
        syllabus: formattedSubtopics,
      };
    });
  };

  const handleSaveSyllabus = async () => {
    if (!selectedSubject) {
      Swal.fire({
        icon: "warning",
        title: "No Subject Selected",
        text: "Please select a subject before saving.",
      });
      return;
    }

    try {
      const preparedChapters = prepareChaptersForAPI(chapters);

      const payload = {
        subject_id: selectedSubject,
        chapters: preparedChapters,
      };

      Swal.fire({
        title: "Saving Syllabus...",
        text: "Please wait while the syllabus is being saved.",
        allowOutsideClick: false,
        didOpen: () => Swal.showLoading(),
      });

      const response = await createSubjectTopic(payload);

      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Syllabus Saved",
        text: "The syllabus has been saved successfully.",
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message || "Failed to save the syllabus. Please try again.",
      });
    }
  };

  const numberedChapters = generateNumbering(chapters);

  return (
    <div className="container mt-5">
      <div className="card shadow">
        <div className="card-header bg-primary text-white">
          <h5 className="mb-0">Create Syllabus</h5>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="subject-dropdown" className="form-label">
              Select Subject:
            </label>
            <select
              id="subject-dropdown"
              className="form-control"
              value={selectedSubject}
              onChange={handleSelectChange}
            >
              <option value="" disabled>
                -- Select a Subject --
              </option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>
          </div>
          {syllabus.length > 0 && (
            <div className="mt-4">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th className="text-end">Page No.</th>
                  </tr>
                </thead>
                <tbody>
                  {renderSyllabus(syllabus, null, onSyllabusChange)}
                </tbody>
              </table>
            </div>
          )}
          {selectedSubject && (
            <>
              <button
                className="btn btn-success mt-3"
                onClick={handleAddChapter}
              >
                Add Chapter
              </button>
              <button
                className="btn btn-primary mt-3 ml-2"
                onClick={handleSaveSyllabus}
              >
                Save Syllabus
              </button>
              <div className="mt-4">{renderStructure(chapters)}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SyllabusBook;
