import Api from "../Api";
export const createClasswiseConcession = (data) => {
  return Api.post(`/create-fees-discount-type`, data);
};

export const getAllClassWiseConcessions = (params) => {
  return Api.get(`/get-fees-discount-types`, { params });
};


export const deleteClasswiseConcession = (id) => {
  return Api.get(`/destroy-fees-discount-type/${id}`);
};
