import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  getAllFeesSubComponent,
  createFeesSubComponent,
  getFeesSubComponentById,
  updateFeesSubComponentById,
  deleteFeesSubComponent,
} from "../../Services/CommonApis/FeesApi";
import { getAllFees } from "../../Services/CommonApis/FeesApi";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { FieldArray } from "formik";
const FeeSubTypes = () => {
  const tableRef = useRef(null);
  const [fees, setFees] = useState();
  const tableInstance = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFeesId, setEditFeesId] = useState(null);
  const [editFeesTypeName, setEditFeesTypeName] = useState("");
  const [editFcid, setEditFcid] = useState("");
  useEffect(() => {
    fetchFeeComponents();
  }, []);
  const handleDeleteFeesList = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, Delete!",
      "No, Cancel!"
    );
    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteFeesSubComponent(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The fees type has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
        window.location.reload();
      } catch (error) {
        Swal.close();
        showErrorAlert(error.response.data.message, error.response.data);
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The fees type deletion was cancelled.");
    }
  };

  const fetchFeeComponents = async (branchId) => {
    try {
      const response = await getAllFees(branchId);
      setFees(response.data.data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };
  const handleCreateFeesList = async (values) => {
    console.log(values);
    const result = await showSweetAlert(
      "Are you sure?",
      "Do you want to create fees type?",
      "info",
      "Yes, Create!",
      "No, Cancel!"
    );
    if (result.isConfirmed) {
      try {
        const formattedData = {
          fcid: values.fcid,
          name: values.subcomponents.map((subcomponent) => subcomponent.name),
        };
        showLoadingAlert();
        await createFeesSubComponent(formattedData);
        Swal.close();
        showSuccessAlert("Created!", "The fees type has been created.");
        setShowCreateModal(false);
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
        window.location.reload();
      } catch (error) {
        Swal.close();
        showErrorAlert(error.response.data.message, error.response.data);
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The fee type creation was cancelled!");
    }
  };
  const handleEditFeesList = async (id) => {
    try {
      const response = await getFeesSubComponentById(id);
      const { name } = response.data;
      const { fcid } = response.data;
      setEditFcid(fcid);
      setEditFeesId(id);
      setEditFeesTypeName(name);
      setShowEditModal(true);
    } catch (error) {
      console.error("Error fetching fees type details:", error);
      showErrorAlert(
        "Error!",
        "There was an error fetching the fees type details."
      );
    }
  };
  const handleUpdateFeesList = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "Do you want to update fees type?",
      "info",
      "Yes, Update!",
      "No, Cancel!"
    );
    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await updateFeesSubComponentById(editFeesId, {
          name: values.name,
          fcid: values.fcid,
        });
        Swal.close();
        showSuccessAlert("Updated!", "The fees type has been updated.");
        setShowEditModal(false);
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert(error.response.data.message, error.response.data);
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The fees type updation was cancelled ");
    }
  };
  useEffect(() => {
    if (tableInstance.current) {
      tableInstance.current.destroy(true);
      tableInstance.current = null;
    }
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "fcid_name" },
        { data: "name" },
        {
          data: "status",
          render: (data) => (data === "1" ? "Active" : "Inactive"),
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "Inactive"
                ? ""
                : `<a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>`;
            return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
                ${deleteIcon}
              </div>
            `;
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        { extend: "copy", exportOptions: { columns: ":visible" } },
        { extend: "csv", exportOptions: { columns: ":visible" } },
        { extend: "excel", exportOptions: { columns: ":visible" } },
        { extend: "pdf", exportOptions: { columns: ":visible" } },
        { extend: "print", exportOptions: { columns: ":visible" } },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;
          const response = await getAllFeesSubComponent();
          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;
          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          showErrorAlert(error.response.data.message);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });
    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDeleteFeesList(id);
    });
    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEditFeesList(id);
    });
  }, []);
  const initialValues = {
    fcid: "",
    name: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Fees sub component is required"),
    fcid: Yup.string().required("Fees component is required"),
  });
  return (
    <>
      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Fees Sub Components
          </h3>
          <button
            className="btn btn-primary float-right"
            onClick={() => setShowCreateModal(true)}
          >
            Create New
          </button>
        </div>
        <div className="card-body">
          <table
            ref={tableRef}
            className="table table-striped table-bordered"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Fee Component Name </th>
                <th>Fee Sub Component </th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>ID</th>
                <th>Fee Component Name </th>
                <th>Fee Sub Component Name </th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <Modal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Fees Subcomponent</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            fcid: "",
            subcomponents: [
              { name: "" }, // Default row for FieldArray
            ],
          }}
          // validationSchema={validationSchema}
          onSubmit={handleCreateFeesList}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="fcid" className="form-label">
                    Select Fee Component
                  </label>
                  <Field
                    as="select"
                    name="fcid"
                    className="form-control"
                    style={{ borderColor: "skyblue" }}
                    onChange={(event) => {
                      const selectedValue = event.target.value;
                      setFieldValue("fcid", selectedValue);
                    }}
                  >
                    <option value="" label="Select " />
                    {fees.map((fee) => (
                      <option key={fee.id} value={fee.id} label={fee.name} />
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fcid"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <FieldArray name="subcomponents">
                  {({ push, remove }) => (
                    <>
                      <label htmlFor="subcomponents">Subcomponent Names</label>
                      {values.subcomponents.map((subcomponent, index) => (
                        <div
                          key={index}
                          className="form-group d-flex align-items-center"
                        >
                          <Field
                            type="text"
                            name={`subcomponents.${index}.name`}
                            style={{ borderColor: "skyblue" }}
                            className="form-control col-10"
                            placeholder="Enter Subcomponent Name"
                          />
                          <ErrorMessage
                            name={`subcomponents.${index}.name`}
                            component="div"
                            className="text-danger"
                          />
                          <button
                            type="button"
                            className="btn btn-danger ml-2 mb-2"
                            onClick={() => remove(index)}
                          >
                            -
                          </button>
                          {index === values.subcomponents.length - 1 && (
                            <button
                              type="button"
                              className="btn btn-primary ml-2"
                              onClick={() => push({ name: "" })}
                            >
                              +
                            </button>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowCreateModal(false)}
                >
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Fees Type</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{ name: editFeesTypeName, fcid: editFcid }}
          validationSchema={validationSchema}
          onSubmit={handleUpdateFeesList}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="fcid" className="form-label">
                    Select Fee Component
                  </label>
                  <Field
                    as="select"
                    name="fcid"
                    className="form-control"
                    style={{ borderColor: "skyblue" }}
                    onChange={(event) => {
                      const selectedValue = event.target.value;
                      setFieldValue("fcid", selectedValue);
                    }}
                  >
                    <option value="" label="Select State" />
                    {fees.map((fee) => (
                      <option key={fee.id} value={fee.id} label={fee.name} />
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fcid"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Fees Sub component Name</label>
                  <span className="mandatory">* </span>
                  <Field
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter fees type"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowEditModal(false)}
                >
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  Update Fees Type
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
export default FeeSubTypes;
