import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getcertificatestudentbyclassandsection, generateCertificatePdf } from "../../Services/AdminApis/CertificateApi";
import { certificatepreviewPdf, DeleteCertificateList } from "../../Services/AdminApis/CertificateApi";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";

function GeneratedcertificateList() {
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [students, setStudents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const { branchId } = useParams();
  const [error, setError] = useState('')

  useEffect(() => {
    fetchClasses();
  }, []);

  const handleDeleteCertificateList = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, Cancel!"
    );
    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await DeleteCertificateList(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The Student Certificate has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert(
          "Error!",
          "There was an error deleting the Student Certificate."
        );
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The Student Certificate is safe :)");
    }
  };
  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "first_name" },
        { data: "certificate_type" },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "0"
                ? ""
                : `<a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>`;
            return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="#" title="Preview" data-id="${row.student_id}" data-certificateid="${row.certificate_type_id}" class="preview-icon">
                  <i class="fas fa-eye"></i>
                </a>
                
                <a href="#" title="Print" data-id="${row.student_id}" data-certificateid="${row.certificate_type_id}" class="print-icon">
                  <i class="fas fa-print"></i>
                </a>
                ${deleteIcon}
              </div>
            `;
          },
        },
      ],
      lengthChange: true,
      order:true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "csv",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "excel",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "pdf",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "print",
          exportOptions: { columns: ":visible" },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
        emptyTable: "No certificate data found",
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const response = await getcertificatestudentbyclassandsection(branchId, selectedClass, selectedSection);
          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;
          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          callback({
            draw: data.draw,
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          });
        }
      },
    });

    $(tableRef.current).off("click", ".delete-icon");
    $(tableRef.current).off("click", ".print-icon");
    $(tableRef.current).off("click", ".preview-icon");
    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDeleteCertificateList(id);
    });
    $(tableRef.current).on("click", ".print-icon", function (e) {
      e.preventDefault();
      const student_id = $(this).data("id");
      const certificate_type = $(this).data("certificateid");
      generateCertificatePdf(student_id, certificate_type);
    });

    $(tableRef.current).on("click", ".preview-icon", function (e) {
      e.preventDefault();
      const student_id = $(this).data("id");
      const certificate_type = $(this).data("certificateid");
      generateCertificatePdf(student_id, certificate_type);
    });
    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [branchId, selectedClass, selectedSection]);
  const fetchClasses = async () => {
    try {
      const response = await getClassByBranchId("1");
      setClasses(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSections(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };
  const generateCertificatePdf = async (studentId, certificateType) => {
    try {
      const response = await certificatepreviewPdf(certificateType, studentId);
      const url = URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
      setPdfData(url);
      setShowModal(true);
    } catch (err) {
      setError('Error generating PDF');
      console.error(err);
    }
  };

  const handleClassChange = (event, setFieldValue) => {
    const selectedClassId = event.target.value;
    setSelectedClass(selectedClassId);
    setFieldValue("class", selectedClassId);
    fetchSections(selectedClassId);
  };

  const handleSectionChange = (event, setFieldValue) => {
    const selectedSectionId = event.target.value;
    setSelectedSection(selectedSectionId);
    setFieldValue("section", selectedSectionId);
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Generated Certificate </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 style={{ paddingTop: "5px" }} className="card-title">
                    Generated Certificates
                  </h3>
                </div>
                <Formik
                  initialValues={{
                    class: "",
                    section: "",
                  }}
                  onSubmit={(values) => {
                    console.log(values);
                  }}
                >
                  {({ setFieldValue }) => (
                    <Form>
                      <div className="d-flex flex-row flex-wrap p-5">
                        <div className="col-4">
                          <label htmlFor="classDropdown">Select Class:</label>
                          <Field
                            as="select"
                            name="class"
                            className="form-control"
                            id="classDropdown"
                            onChange={(e) => handleClassChange(e, setFieldValue)}
                          >
                            <option value="">Select Class</option>
                            {classes.map((classItem) => (
                              <option key={classItem.id} value={classItem.id}>
                                {classItem.name}
                              </option>
                            ))}
                          </Field>
                        </div>

                        <div className="col-4">
                          <label htmlFor="sectionDropdown">Select Section:</label>
                          <Field
                            as="select"
                            name="section"
                            className="form-control"
                            id="sectionDropdown"
                            onChange={(e) => handleSectionChange(e, setFieldValue)}
                          >
                            <option value="">Select Section</option>
                            {sections.map((section) => (
                              <option key={section.id} value={section.id}>
                                {section.name}
                              </option>
                            ))}
                          </Field>
                        </div>

                        <div className="col-12 mt-4">
                          <table ref={tableRef} className="table table-bordered table-striped">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Student Name</th>
                                <th>Certificate Name</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal}
         backdrop="static"
         centered
        onHide={() => setShowModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Preview Certificate</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {pdfData ? (
              <iframe
                title="Certificate PDF"
                src={pdfData}
                width="100%"
                height="600px"
              />
            ) : (
              <p>Loading...</p>
            )}
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}
export default GeneratedcertificateList;
