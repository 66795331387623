import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { getAllMenuList, getAllAssetList, getAllReturntype } from "../../Services/AdminApis/SchoolManagerApi";
import { useParams } from "react-router-dom";
import { createInventory } from "../../Services/CommonApis/InventoryApi";
import { getStaffByBranchId } from "../../Services/AdminApis/StaffApi";
import { getAllAssestManagement } from "../../Services/CommonApis/AssetsManagemntApi";
function Inventory() {
  const { branchId } = useParams()
  const [assetTypes, setAssetTypes] = useState([])
  const [inventoryItems, setInventoryItems] = useState([])
  const [staffList, setStaffList] = useState([])
  const [selectedAssetType, setSelectedAssetType] = useState("")
  const [todayDate, setTodayDate] = useState("");
  const [filteredAssetNames, setFilteredAssetNames] = useState([])
  const bookInitialValues = {
    asset_type: "",
    assets_id: "",
    user_id: "",
    staff_user_id: "",
    assign_date: "",
    return_status: "1",
  }
  useEffect(() => {
    fetchMenuList()
    fetchStaffList()
  }, [])
  const fetchMenuList = async () => {
    try {
      const response = await getAllAssetList()
      setAssetTypes(response.data)
    } catch (error) {
      console.error("Error fetching menu list:", error)
    }
  }
  const fetchInventoryItems = async (selectedValue) => {
    try {
      const params = selectedValue === "1"
        ? { asset_type: selectedValue }
        : { asset_type: '' };
      const response = await getAllAssestManagement(params);
      setInventoryItems(response.data.data)

    } catch (error) {
      console.error("Error fetching inventory items:", error)
    }
  }

  const fetchStaffList = async () => {
    try {
      const response = await getStaffByBranchId({ branch_id: branchId });
      setStaffList(response.data.data)

    } catch (error) {
      console.error("Error fetching inventory items:", error)
    }
  }
  const bookValidationSchema = Yup.object().shape({
    asset_type: Yup.string().required("Asset Type is required"),
    assets_id: Yup.string().required("Asset Name is required"),
    user_id: Yup.string().required("User Name is required"),
    staff_user_id: Yup.string().required("Staff Name is required"),
    return_status: Yup.string().required("Return Status is required"),
  })
  const handleSubmit = async (values) => {
    if (values.assign_date) {
      const date = new Date(values.assign_date)
      const day = String(date.getDate()).padStart(2, "0")
      const month = String(date.getMonth() + 1).padStart(2, "0")
      const year = date.getFullYear()
      values.assign_date = `${day}/${month}/${year}`
    }
    values.branch_id = branchId
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to create the Inventory",
      "info",
      "Yes, Create",
      "No, Cancel!",
    )

    if (result.isConfirmed) {
      showLoadingAlert()
      try {
        const response = await createInventory(values)

        if (response.status === 200) {
          showSuccessAlert("Created!", response.data?.message || "The Inventory has been created successfully.")
          window.location.reload()
        } else {
          const errorMessage = response.data?.message || "Inventory creation failed. Please try again."
          showErrorAlert("Error!", errorMessage)
        }
      } catch (err) {
        console.error("API error:", err)
        let errorMessage = "An unexpected error occurred. Please try again."
        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again."
        }
        showErrorAlert("Error", errorMessage)
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Inventory creation has been cancelled.")
    }
  }
  const handleFocus = (event) => {
    if (event.target.type === "date") {
      event.target.showPicker();
    }
  };

  const handleDateClick = (event) => {
    if (event.target.type === "date") {
      event.target.focus();
    }
  };

  return (
    <>
      <div className="card card-primary">
        <div className="card-header">
          <h3 className="card-title">
            Inventory Creation <small>Form</small>
          </h3>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize
            initialValues={bookInitialValues}
            validationSchema={bookValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form className="form-card">
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="asset_type" className="form-label">
                        Asset Type <span className="mandatory">* </span>
                      </label>
                      <Field
                        as="select"
                        name="asset_type"
                        className="form-control"
                        style={{ borderColor: "skyblue" }}
                        onChange={(e) => {
                          const selectedValue = e.target.value; // Get the selected value
                          setFieldValue("asset_type", selectedValue);
                          setSelectedAssetType(selectedValue);
                          setFieldValue("assets_id", ""); // Reset asset name when type changes
                          fetchInventoryItems(selectedValue); // Call fetchInventoryItems with the selected value
                        }}
                      >
                        <option value="">Select Asset Type</option>
                        {assetTypes.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="asset_type" component="div" className="text-error" />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="assets_id" className="form-label">
                        Asset Name <span className="mandatory">* </span>
                      </label>
                      <Field
                        as="select"
                        name="assets_id"
                        className="form-control"
                        style={{ borderColor: "skyblue" }}
                        disabled={!selectedAssetType}
                      >
                        <option value="">Select Asset Name</option>
                        {inventoryItems.map((asset) => (
                          <option key={asset.id} value={asset.id}>
                            {asset.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="assets_id" component="div" className="text-error" />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="user_id" className="form-label">
                        User Name <span className="mandatory">* </span>
                      </label>
                      <Field
                        as="select"
                        name="user_id"
                        className="form-control"
                        style={{ borderColor: "skyblue" }}
                      >
                        <option value="">Select User Name</option>
                        {staffList.map((staff) => (
                          <option key={staff.user_id} value={staff.user_id}>
                            {staff.first_name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="user_id" component="div" className="text-error" />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="staff_user_id" className="form-label">
                        Staff Name <span className="mandatory">* </span>
                      </label>
                      <Field
                        as="select"
                        name="staff_user_id"
                        className="form-control"
                        style={{ borderColor: "skyblue" }}
                      >
                        <option value="">Select User Name</option>
                        {staffList.map((staff) => (
                          <option key={staff.id} value={staff.id}>
                            {staff.first_name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="staff_user_id" component="div" className="text-error" />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="assign_date" className="form-label">
                        Assign Date <span className="mandatory">* </span>
                      </label>
                      <Field
                        type="date"
                        name="assign_date"
                        className="form-control"
                        onFocus={handleFocus}
                        onClick={handleDateClick}
                        style={{ borderColor: "skyblue" }}
                      />
                      <ErrorMessage name="assign_date" component="div" className="text-error" />
                    </div>
                  </div>

                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
export default Inventory;