import React from "react";
import { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import handleClassDownload from "../../Utility/AdminUtility/ClassExcel";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getmediums } from "../../Services/CommonApis/StudentApi";
import { getGroups } from "../../Services/CommonApis/GroupApi";
import { getStates } from "../../Services/AdminApis/StateandDistrictApi";

import { useParams } from "react-router-dom";
import {
  createStudent,
  getstudentByid,
  updatestudent,
} from "../../Services/CommonApis/StudentApi";
import {
  showLoadingAlert,
  showSweetAlert,
  showSuccessAlert,
  showErrorAlert,
} from "../../Modals/SweetAlertModel";
import { getAllAcademics } from "../../Services/AdminApis/AcademicApi";
import { SimpleStudentValidation } from "../../Utility/CommonUtility/Validation";
import { getParentsByNum } from "../../Services/CommonApis/StudentApi";
import {
  getSession,
  removeAllSession,
  setSession,
} from "../../Services/Storage";
import { COOKIE } from "../../Schemas/cookieNames";

const Studentsimpleform = () => {
  const [sectionOptions, setSectionOptions] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");

  const [states, setStates] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [academics, setAcademics] = useState([]);

  const [medium, setmedium] = useState([]);
  const { branchId } = useParams();
  const customFileInputStyle = {
    display: "none",
  };

  useEffect(() => {
    setSelectedYear(getSession(COOKIE.ACADEMIC_YEAR));
  }, []);
  useEffect(() => {
    if (branchId) {
      fetchGroups(branchId);
    }
  }, [branchId]);
  const getBorderColor = (fieldName) => {
    return "skyblue";
  };
  const fetchGroups = async (branchId) => {
    try {
      const response = await getGroups(branchId);
      setGroups(response.data.data);
    } catch (error) {
      console.error("Error fetching schools:", error);
    }
  };
  useEffect(() => {
    fetchClasses(branchId);
  }, [branchId]);
  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      const classes = response.data.classes.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }));
      setClassOptions(classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };
  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      const response = await getStates();
      setStates(response.data.states);
    } catch (error) {
      console.error("Error fetching schools:", error);
    }
  };
  useEffect(() => {
    getmedium();
    Academics();
  }, []);
  const getmedium = async () => {
    const response = await getmediums();
    setmedium(response.data.data);
  };
  const customButtonStyle = {
    border: "1px solid lightskyblue",
    padding: "10px",
    backgroundColor: "white",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
  };
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  const handleclasssection = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      const sections = response.data.sections.map((section) => ({
        value: section.id,
        label: section.name,
      }));
      setSectionOptions(sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };
  const mapStudentFields = (values) => {
    const mappedValues = {};
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        if (key.startsWith("st")) {
          const newKey = key.replace(/^st/, "");
          mappedValues[newKey] = values[key];
        } else {
          mappedValues[key] = values[key];
        }
      }
    }
    return mappedValues;
  };
  const { id } = useParams();
  const Academics = async () => {
    const response = await getAllAcademics();
    setAcademics(response.data.data);
  };

  const handleSubmit = async (values) => {
    const updatedValues = { ...values, branch_id: branchId };
    const originalvalues = { ...values, branch_id: branchId };
    const mappedValues = mapStudentFields(updatedValues);
    if (mappedValues.admission_date) {
      mappedValues.admission_date = formatDate(mappedValues.admission_date);
    }

    setLoading(true);
    try {
      let response;
      if (id) {
        response = await updatestudent(id, mappedValues);
      } else {
        if (originalvalues.admission_date) {
          originalvalues.admission_date = formatDate(
            originalvalues.admission_date
          );
        }
        if (originalvalues.stdate_of_birth) {
          originalvalues.stdate_of_birth = formatDate(
            originalvalues.stdate_of_birth
          );
        }
        if (originalvalues.date_of_birth) {
          originalvalues.date_of_birth = formatDate(
            originalvalues.date_of_birth
          );
        }
        response = await createStudent(originalvalues);
      }
      setApiMessage(response.data.message);
      showSuccessAlert("Success", response.data);
      window.location.reload();
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.[0]?.message ||
        error.response?.data?.message ||
        "An error occurred while creating/updating the student.";
      showErrorAlert(errorMessage);
      console.log(error.response.data.errors);
      setApiMessage("Error creating/updating student: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="card p-4"
        style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
      >
        <h2 className="fs-title mt-3">Student Details:</h2>

        <Formik
          enableReinitialize
          initialValues={{
            academic_year_id: selectedYear,
            roll_no: "",
            stfirst_name: "",
            stlast_name: "",
            fee_book_no: "",
            physically_challenge: "no",
            transport_required: "",
            medium_id: "",
            class_id: "",
            section_id: "",
            group_id: "",

            admission_no: "",

            stdate_of_birth: "",
            stgender: "",

            admission_date: "",
            neet_applicable: "no",
            delivery_mode: [],
            primary_login: "",
            notify: "",
            achievements: "",
            mother_name: "",
            mother_phone: "",
            mother_email: "",
          }}
          validationSchema={SimpleStudentValidation}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <div className="row">
                <div className="form-group col-4">
                  <label htmlFor="academic_year_id">
                    Academic Year <span className="mandatory">* </span>{" "}
                  </label>
                  <Field
                    name="academic_year_id"
                    as="select"
                    className="form-control"
                    style={{ borderColor: "skyblue" }}
                  >
                    <option value="">Select Year</option>
                    {academics.map((year) => {
                      const startDate = new Date(year.start_date);
                      const endDate = new Date(year.end_date);

                      const startMonthYear = startDate.toLocaleString(
                        "default",
                        {
                          month: "short",
                          year: "numeric",
                        }
                      );
                      const endMonthYear = endDate.toLocaleString("default", {
                        month: "short",
                        year: "numeric",
                      });

                      const formattedDateRange = `${startMonthYear} - ${endMonthYear}`;

                      return (
                        <option key={year.id} value={year.id}>
                          {formattedDateRange}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    name="academic_year_id"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="stfirst_name">
                    First Name <span className="mandatory">* </span>{" "}
                  </label>
                  <Field
                    name="stfirst_name"
                    type="text"
                    className="form-control"
                    placeholder="Enter First Name"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="stfirst_name"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="stlast_name">
                    Last Name <span className="mandatory">* </span>{" "}
                  </label>
                  <Field
                    name="stlast_name"
                    type="text"
                    className="form-control"
                    placeholder="Enter Last Name"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="stlast_name"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="roll_no">Roll No </label>
                  <Field
                    name="roll_no"
                    type="text"
                    className="form-control"
                    placeholder="Enter Roll No"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="roll_no"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="fee_book_no">Fee Book No </label>
                  <Field
                    name="fee_book_no"
                    type="text"
                    className="form-control"
                    placeholder="Enter Fee Book No"
                  />
                  <ErrorMessage
                    name="fee_book_no"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="class_id">
                    Class <span className="mandatory">* </span>{" "}
                  </label>
                  <Field
                    as="select"
                    name="class_id"
                    className="form-control"
                    onChange={(e) => {
                      const classId = e.target.value;
                      handleclasssection(classId);
                      setFieldValue("class_id", classId);
                    }}
                  >
                    <option value="">Select Class</option>
                    {classOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="class_id"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="section_id">
                    Section <span className="mandatory">* </span>{" "}
                  </label>
                  <Field
                    as="select"
                    name="section_id"
                    className="form-control"
                    style={{ borderColor: "skyblue" }}
                    value={values.section_id}
                    onChange={(e) =>
                      setFieldValue("section_id", e.target.value)
                    }
                  >
                    <option value="">Select Section</option>
                    {sectionOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="section_id"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="medium_id">
                    Medium <span className="mandatory">* </span>{" "}
                  </label>
                  <Field
                    as="select"
                    name="medium_id"
                    className="form-control"
                    style={{ borderColor: "skyblue" }}
                  >
                    <option value="">Select Medium</option>
                    {medium.map((medium) => (
                      <option key={medium.id} value={medium.id}>
                        {medium.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="medium_id"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="group_id">Group </label>
                  <Field
                    as="select"
                    name="group_id"
                    className="form-control"
                    style={{ borderColor: "skyblue" }}
                  >
                    <option value="">Select Group</option>

                    {groups.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="group_id"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="stgender">
                    Gender <span className="mandatory">* </span>
                  </label>
                  <Field name="stgender" as="select" className="form-control">
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Field>
                  <ErrorMessage
                    name="stgender"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="stdate_of_birth">
                    Date of Birth <span className="mandatory">* </span>
                  </label>
                  <Field
                    name="stdate_of_birth"
                    type="date"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="stdate_of_birth"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="admission_no">
                    Admission No <span className="mandatory">* </span>{" "}
                  </label>
                  <Field
                    name="admission_no"
                    type="text"
                    className="form-control"
                    placeholder="Enter Admission No"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="admission_no"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="admission_date">
                    Admission Date <span className="mandatory">* </span>
                  </label>
                  <Field
                    name="admission_date"
                    type="date"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="admission_date"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="phone">
                    Phone No <span className="mandatory">*</span>
                  </label>
                  <Field
                    name="phone"
                    type="text"
                    className="form-control mr-2"
                    placeholder="Enter Phone No"
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/\D/g, "")
                        .slice(0, 10);
                    }}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                    }}
                    onChange={async (event) => {
                      const inputValue = event.target.value;
                      setFieldValue("phone", inputValue);

                      // if (inputValue.length === 10) {
                      //   try {
                      //     // Clear existing parent fields
                      //     setFieldValue("pfirst_name", "");
                      //     setFieldValue("plast_name", "");
                      //     setFieldValue("phone", "");
                      //     setFieldValue("email", "");

                      //     setFieldValue("aadhaar_card_no", "");
                      //     setFieldValue("pan_card_no", "");

                      //     // Fetch parent details based on phone number
                      //     const response = await getParentsByNum(inputValue);
                      //     if (response.data.data && response.data.data.id) {
                      //       const parentData = response.data.data;
                      //       const fullname =
                      //         parentData.first_name +
                      //         " " +
                      //         parentData.last_name +
                      //         " (#" +
                      //         parentData.id +
                      //         ")";

                      //       // Show SweetAlert for confirmation
                      //       const result = await showSweetAlert(
                      //         "Parent Already Exists with #" + fullname,
                      //         "Do you want to map with existing parents?",
                      //         "You want to delete the Student!",
                      //         "Yes",
                      //         "No"
                      //       );

                      //       if (result.isConfirmed) {
                      //         setFieldValue("parent_id", parentData.id || "");
                      //         setFieldValue(
                      //           "pfirst_name",
                      //           parentData.first_name || ""
                      //         );
                      //         setFieldValue(
                      //           "plast_name",
                      //           parentData.last_name || ""
                      //         );
                      //         setFieldValue("phone", parentData.phone || "");
                      //         setFieldValue(
                      //           "email",
                      //           parentData.alt_email || ""
                      //         );

                      //         setFieldValue(
                      //           "aadhaar_card_no",
                      //           parentData.aadhaar_card_no || ""
                      //         );
                      //         setFieldValue(
                      //           "pan_card_no",
                      //           parentData.pan_card_no || ""
                      //         );
                      //       }
                      //     }
                      //   } catch (error) {
                      //     console.error("Error fetching parent data: ", error);
                      //     // Optionally show an alert or error message
                      //   }
                      // }
                    }}
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="field-error text-danger mr-2"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="pfirst_name">
                    Parent First Name <span className="mandatory">* </span>
                  </label>
                  <Field
                    name="pfirst_name"
                    type="text"
                    className="form-control"
                    placeholder="Parent First Name"
                  />
                  <ErrorMessage
                    name="pfirst_name"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="plast_name">Parent's Last Name</label>
                  <Field
                    name="plast_name"
                    type="text"
                    className="form-control"
                    placeholder="Parent last Name"
                  />
                  <ErrorMessage
                    name="plast_name"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="mother_name">Mother's Name</label>
                  <Field
                    name="mother_name"
                    type="text"
                    className="form-control"
                    placeholder="Enter Mothers Name"
                  />
                  <ErrorMessage
                    name="mother_name"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="mother_phone">Mother's Phone No</label>
                  <Field
                    name="mother_phone"
                    type="text"
                    className="form-control"
                    placeholder="Enter Mothers Mobile No"
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/\D/g, "")
                        .slice(0, 10);
                    }}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                    }}
                  />
                  <ErrorMessage
                    name="mother_phone"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="mother_email">Mother's Email</label>
                  <Field
                    name="mother_email"
                    type="text"
                    className="form-control"
                    placeholder="rebecca@gmail.com"
                  />
                  <ErrorMessage
                    name="mother_email"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="guardian_name">Guardian's Name</label>
                  <Field
                    name="guardian_name"
                    type="text"
                    className="form-control"
                    placeholder="Guardian's Name"
                  />
                  <ErrorMessage
                    name="guardian_name"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="num">Father Phone</label>
                  <Field
                    name="num"
                    type="number"
                    className="form-control"
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/\D/g, "")
                        .slice(0, 10);
                    }}
                    style={{
                      WebkitAppearance: "none",
                      MozAppearance: "textfield",
                    }}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                    }}
                    placeholder="Enter Father Mobile No"
                  />
                  <ErrorMessage
                    name="num"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="email">
                    Email <span className="mandatory">* </span>
                  </label>
                  <Field
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Enter father's Email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="aadhaar_card_no">
                    Parent Aadhaar Card No
                  </label>
                  <Field
                    name="aadhaar_card_no"
                    type="text"
                    className="form-control"
                    placeholder="Enter parent Aadhar Number "
                  />
                  <ErrorMessage
                    name="aadhaar_card_no"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="pan_card_no">Parent PAN Card No</label>
                  <Field
                    name="pan_card_no"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="pan_card_no"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>

                <div className="form-group col-12">
                  <h3 className="fs-title mt-2">Permanent Address</h3>
                </div>

                <div className="form-group col-4">
                  <label htmlFor="stcity">City</label>
                  <Field
                    type="text"
                    className="form-control"
                    id="stcity"
                    name="stcity"
                    placeholder="Enter city"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="stcity"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="ststate">State</label>
                  <Field
                    as="select"
                    className="form-control"
                    id="ststate"
                    name="ststate"
                    placeholder="Enter state"
                    style={{
                      borderColor: getBorderColor("state"),
                      borderRadius: "0px",
                    }}
                  >
                    <option value="" label="Select State" />
                    {states.map((state) => (
                      <option
                        key={state.id}
                        value={state.id}
                        label={state.name}
                      />
                    ))}
                  </Field>
                  <ErrorMessage
                    name="ststate"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="stpin">Pin</label>
                  <Field
                    type="text"
                    className="form-control"
                    id="stpin"
                    name="stpin"
                    placeholder="Enter pin"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="stpin"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="stcountry">Country</label>
                  <Field
                    type="text"
                    className="form-control"
                    id="stcountry"
                    name="stcountry"
                    placeholder="Enter country"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="stcountry"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group col-12">
                  <label htmlFor="address">Address</label>
                  <Field
                    as="textarea"
                    className="form-control"
                    id="staddress"
                    name="staddress"
                    placeholder="Enter address"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="staddress"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group col-12">
                  <input
                    type="checkbox"
                    id="sameAsPermanent"
                    checked={isSameAddress}
                    onChange={(e) => {
                      setIsSameAddress(e.target.checked);
                      if (e.target.checked) {
                        setFieldValue("sttemp_city", values.stcity);
                        setFieldValue("sttemp_state", values.ststate);
                        setFieldValue("sttemp_pin", values.stpin);
                        setFieldValue("sttemp_country", values.stcountry);
                        setFieldValue("sttmp_address", values.staddress);
                      } else {
                        setFieldValue("sttemp_city", "");
                        setFieldValue("sttemp_state", "");
                        setFieldValue("sttemp_pin", "");
                        setFieldValue("sttemp_country", "");
                        setFieldValue("sttmp_address", "");
                      }
                    }}
                  />

                  <label htmlFor="sameAsPermanent" className="ml-2">
                    Same as Permanent Address
                  </label>
                </div>

                <div className="form-group col-12">
                  <h3 className="fs-title mt-2">Temporary Address</h3>
                </div>

                <div className="form-group col-4">
                  <label htmlFor="temp_city">City</label>
                  <Field
                    type="text"
                    className="form-control"
                    id="sttemp_city"
                    name="sttemp_city"
                    placeholder="Enter city"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="sttemp_city"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="sttemp_state">State</label>
                  <Field
                    as="select"
                    className="form-control"
                    id="sttemp_state"
                    name="sttemp_state"
                    placeholder="Enter state"
                    style={{
                      borderColor: getBorderColor("sttemp_state"),
                      borderRadius: "0px",
                    }}
                  >
                    <option value="" label="Select State" />
                    {states.map((state) => (
                      <option
                        key={state.id}
                        value={state.id}
                        label={state.name}
                      />
                    ))}
                  </Field>
                  <ErrorMessage
                    name="sttemp_state"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="sttemp_pin">Pin</label>
                  <Field
                    type="text"
                    className="form-control"
                    id="sttemp_pin"
                    name="sttemp_pin"
                    placeholder="Enter pin"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="sttemp_pin"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="sttemp_country">Country</label>
                  <Field
                    type="text"
                    className="form-control"
                    id="sttemp_country"
                    name="sttemp_country"
                    placeholder="Enter country"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="sttemp_country"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group col-12">
                  <label htmlFor="sttmp_address">Address</label>
                  <Field
                    as="textarea"
                    className="form-control"
                    id="sttmp_address"
                    name="sttmp_address"
                    placeholder="Enter address"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="sttmp_address"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="stimage" className="d-block">
                    Upload Image
                  </label>
                  <div style={customButtonStyle}>
                    <label htmlFor="stimage" style={{ cursor: "pointer" }}>
                      <i
                        className="fas fa-upload"
                        style={{ marginRight: "8px" }}
                      ></i>
                      Browse
                    </label>
                    <input
                      type="file"
                      id="stimage"
                      name="stimage"
                      onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          setFieldValue("stimage", file);
                          setSelectedFileName(file.name);
                        } else {
                          setFieldValue("stimage", null);
                          setSelectedFileName("");
                        }
                      }}
                      style={customFileInputStyle}
                    />
                  </div>
                  {selectedFileName && (
                    <div style={{ marginTop: "8px" }}>{selectedFileName}</div> // Display the filename
                  )}
                  <ErrorMessage
                    name="stimage"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group col-4 d-flex ml-0 mt-4">
                  <label className="mt-3 ml-1" htmlFor="primary_login">
                    Primary Login
                  </label>
                  <div className="form-check mt-3 ml-3">
                    <Field
                      type="radio"
                      className="form-check-input mt-2"
                      id="primary_login_father"
                      name="primary_login"
                      value="father"
                      onChange={(e) => {
                        setFieldValue("primary_login", e.target.value);
                      }}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="primary_login_father"
                    >
                      Father
                    </label>
                  </div>

                  <div className="form-check mt-3 ml-3">
                    <Field
                      type="radio"
                      className="form-check-input mt-2"
                      id="primary_login_mother"
                      name="primary_login"
                      value="mother"
                      onChange={(e) => {
                        setFieldValue("primary_login", e.target.value);
                      }}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="primary_login_mother"
                    >
                      Mother
                    </label>
                  </div>

                  <div className="form-check mt-3 ml-3">
                    <Field
                      type="radio"
                      className="form-check-input mt-2"
                      id="primary_login_guardian"
                      name="primary_login"
                      value="guardian"
                      onChange={(e) => {
                        setFieldValue("primary_login", e.target.value);
                      }}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="primary_login_guardian"
                    >
                      Guardian
                    </label>
                  </div>
                </div>
                <div className="form-group col-4 d-flex ml-0 mt-4">
                  <label className="mt-3 ml-1" htmlFor="notify">
                    Notify
                  </label>
                  <div className="form-check mt-3 ml-3">
                    <Field
                      type="radio"
                      className="form-check-input mt-2"
                      id="notify_father"
                      name="notify"
                      value="father"
                      onChange={(e) => {
                        setFieldValue("notify", e.target.value);
                      }}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="notify_father"
                    >
                      Father
                    </label>
                  </div>

                  <div className="form-check mt-3 ml-3">
                    <Field
                      type="radio"
                      className="form-check-input mt-2"
                      id="notify_mother"
                      name="notify"
                      value="mother"
                      onChange={(e) => {
                        setFieldValue("notify", e.target.value);
                      }}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="notify_mother"
                    >
                      Mother
                    </label>
                  </div>

                  <div className="form-check mt-3 ml-3">
                    <Field
                      type="radio"
                      className="form-check-input mt-2"
                      id="notify_guardian"
                      name="notify"
                      value="guardian"
                      onChange={(e) => {
                        setFieldValue("notify", e.target.value);
                      }}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="notify_guardian"
                    >
                      Guardian
                    </label>
                  </div>
                </div>
                <div className="form-group col-4 d-flex ml-0 mt-4">
                  <label className="mt-3 ml-1" htmlFor="delivery_mode">
                    Delivery Mode
                  </label>

                  <div className="form-check mt-3 ml-3">
                    <Field
                      type="checkbox"
                      className="form-check-input mt-2"
                      id="delivery_mode_sms"
                      name="delivery_mode"
                      value="sms"
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const currentValues = values.delivery_mode || [];
                        if (isChecked) {
                          setFieldValue("delivery_mode", [
                            ...currentValues,
                            e.target.value,
                          ]); // Add to array
                        } else {
                          setFieldValue(
                            "delivery_mode",
                            currentValues.filter(
                              (mode) => mode !== e.target.value
                            ) // Remove from array
                          );
                        }
                      }}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="delivery_mode_sms"
                    >
                      SMS
                    </label>
                  </div>

                  <div className="form-check mt-3 ml-3">
                    <Field
                      type="checkbox"
                      className="form-check-input mt-2"
                      id="delivery_mode_app"
                      name="delivery_mode"
                      value="app"
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const currentValues = values.delivery_mode || [];
                        if (isChecked) {
                          setFieldValue("delivery_mode", [
                            ...currentValues,
                            e.target.value,
                          ]); // Add to array
                        } else {
                          setFieldValue(
                            "delivery_mode",
                            currentValues.filter(
                              (mode) => mode !== e.target.value
                            ) // Remove from array
                          );
                        }
                      }}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="delivery_mode_app"
                    >
                      App
                    </label>
                  </div>
                </div>

                <div className="form-group col-4 d-flex ml-0 mt-4">
                  <label className="mt-3 ml-1" htmlFor="neet_applicable">
                    Neet Application?
                  </label>
                  <div className="form-check mt-3 ml-3">
                    <Field
                      type="radio"
                      className="form-check-input mt-2"
                      id="neet_applicable_yes"
                      name="neet_applicable"
                      value="yes"
                      onChange={(e) => {
                        setFieldValue("neet_applicable", e.target.value);
                      }}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="neet_applicable_yes"
                    >
                      Yes
                    </label>
                  </div>

                  <div className="form-check mt-3 ml-3">
                    <Field
                      type="radio"
                      className="form-check-input mt-2"
                      id="neet_applicable_no"
                      name="neet_applicable"
                      value="no"
                      checked={values.neet_applicable === "no"}
                      onChange={(e) => {
                        setFieldValue("neet_applicable", e.target.value);
                      }}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="neet_applicable_no"
                    >
                      No
                    </label>
                  </div>
                </div>
                <div className="form-group col-4 d-flex ml-0 mt-4">
                  <label className="mt-3 ml-1" htmlFor="transport_required">
                    Transport Required?
                  </label>
                  <div className="form-check mt-3 ml-3">
                    <Field
                      type="radio"
                      className="form-check-input mt-2"
                      id="transport_required_yes"
                      name="transport_required"
                      value="yes"
                      onChange={(e) => {
                        setFieldValue("transport_required", e.target.value);
                      }}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="transport_required_yes"
                    >
                      Yes
                    </label>
                  </div>

                  <div className="form-check mt-3 ml-3">
                    <Field
                      type="radio"
                      className="form-check-input mt-2"
                      id="transport_required_no"
                      name="transport_required"
                      value="no"
                      checked={values.transport_required === "no"}
                      onChange={(e) => {
                        setFieldValue("transport_required", e.target.value);
                      }}
                    />
                    <label
                      className="form-check-label ml-1"
                      htmlFor="transport_required_no"
                    >
                      No
                    </label>
                  </div>
                </div>
                <div className="form-group ml-2">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default Studentsimpleform;
