import React, { useState } from "react";
import StudentList from "../CommonPages/StudentList";
import SchoolUpload from "./SchoolUpload";
import SchoolGrid from "./SchoolGrid";
import StudentCreate from "../CommonPages/StudentCreate";
import Studentsimpleform from "../CommonPages/StudentSimpleForm";
import StudentUpload from "./StudentUpload";

function StudentManagement() {
  const [activeTab, setActiveTab] = useState("listview");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "listview" ? "active" : ""}`}
              onClick={() => handleTabClick("listview")}
            >
              Student List
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "createstudents" ? "active" : ""
              }`}
              onClick={() => handleTabClick("createstudents")}
            >
              Create Students
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "simpleform" ? "active" : ""
              }`}
              onClick={() => handleTabClick("simpleform")}
            >
              Simple Form
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "uploadstudents" ? "active" : ""
              }`}
              onClick={() => handleTabClick("uploadstudents")}
            >
              Upload Students
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "gapdetails" ? "active" : ""
              }`}
              onClick={() => handleTabClick("gapdetails")}
            >
              Gap Details
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "inactivestudents" ? "active" : ""
              }`}
              onClick={() => handleTabClick("inactivestudents")}
            >
              Inactive Students
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "tcstudents" ? "active" : ""
              }`}
              onClick={() => handleTabClick("tcstudents")}
            >
              TC Students
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "holdstudents" ? "active" : ""
              }`}
              onClick={() => handleTabClick("holdstudents")}
            >
              Hold Students
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === "listview" ? "active" : ""}`}
            id="listview"
          >
            {activeTab === "listview" && <StudentList />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "createstudents" ? "active" : ""
            }`}
            id="createstudents"
          >
            {activeTab === "createstudents" && <StudentCreate />}
          </div>
          <div
            className={`tab-pane ${activeTab === "simpleform" ? "active" : ""}`}
            id="simpleform"
          >
            {activeTab === "simpleform" && <Studentsimpleform />}
          </div>
          <div
            className={`tab-pane ${
              activeTab === "uploadstudents" ? "active" : ""
            }`}
            id="uploadstudents"
          >
            {activeTab === "uploadstudents" && <StudentUpload />}
          </div>

          <div
            className={`tab-pane ${activeTab === "gapdetails" ? "active" : ""}`}
            id="gapdetails"
          >
            {activeTab === "gapdetails" && (
              <h3>Currently the feature is not available Comming Soon...!</h3>
            )}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "inactivestudents" ? "active" : ""
            }`}
            id="inactivestudents"
          >
            {activeTab === "inactivestudents" && (
              <h3>Currently the feature is not available Comming Soon...!</h3>
            )}
          </div>

          <div
            className={`tab-pane ${activeTab === "tcstudents" ? "active" : ""}`}
            id="tcstudents"
          >
            {activeTab === "tcstudents" && (
              <h3>Currently the feature is not available Comming Soon...!</h3>
            )}
          </div>
          <div
            className={`tab-pane ${
              activeTab === "holdstudents" ? "active" : ""
            }`}
            id="holdstudents"
          >
            {activeTab === "holdstudents" && (
              <h3>Currently the feature is not available Comming Soon...!</h3>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentManagement;
