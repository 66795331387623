import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Select from "react-select";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  getSchools,
  deleteSchoolById,
} from "../../Services/AdminApis/schoolApi";
import { useState } from "react";
import { getFeesCollectionReport } from "../../Services/CommonApis/FeesApi";
import { useParams } from "react-router-dom";
import {
  getAllFees,
  getAllConcession,
  getFeesSubComponentsByFCID,
  getFeesTransactionReport,
} from "../../Services/CommonApis/FeesApi";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";

const FeeCollectionReport = () => {
  const tableRef = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const { branchId } = useParams();
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [classId, setClassId] = useState([]);
  const [fees, setFees] = useState([]);
  const [concessions, setConcessions] = useState([]);
  const [feesSubComponents, setFeesSubComponents] = useState([]);
  const [selectedHeaderType, setSelectedHeaderType] = useState("1");
  const [selectedComponet, setSelectedComponent] = useState("");
  const [selectedfromDate, setSelectedfromDate] = useState("");
  const [selectedtoDate, setSelectedToDate] = useState("");

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    fetchClassByBranchId(branchId);
    fetchFeeComponents();
  }, [branchId]);

  const fetchClassByBranchId = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchFeeComponents = async () => {
    try {
      const response = await getAllFees(branchId);
      setFees(response.data.data);
    } catch (error) {
      console.error("Error fetching fees:", error);
    }
  };

  const handleFeeComponentChange = (event, setFieldValue) => {
    const selectedfcId = event.target.value;
    setSelectedComponent(selectedfcId);
    setFieldValue("fcid", selectedfcId);
    fetchSubComponentsByFCID(selectedfcId);
  };

  const handleFromDateChange = (event, setFieldValue) => {
    const selectedfromDate = event.target.value;
    setSelectedfromDate(selectedfromDate);
    setFieldValue("from_date", selectedfromDate);
  };

  const handleToDateChange = (event, setFieldValue) => {
    const selectedtoDate = event.target.value;
    setSelectedToDate(selectedtoDate);
    setFieldValue("to_date", selectedtoDate);
  };

  const fetchSubComponentsByFCID = async (id) => {
    try {
      const response = await getFeesSubComponentsByFCID(id);
      setFeesSubComponents(response);
    } catch (error) {
      console.error("Error fetching fee subcomponents:", error);
    }
  };

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        {
          data: null,
          title: "S.No",
          render: (data, type, row, meta) => meta.row + 1,
        },
        {
          data: "student_name",
          title: "Student Info",
          render: (data, type, row) => {
            return `
              <div>
                <div>${row.student_name || "N/A"}</div>
                <small style="color: gray; display: block;">
                  Admission No: ${row.admission_no || "N/A"}
                </small>
                <small style="font-weight: bold; display: block;">
                  ${row.class_section_name || "N/A"}
                </small>
              </div>
            `;
          },
        },
        {
          data: "sub_component_name",
          title: "Fee Sub Component",
        },
        {
          data: "fees_amount",
          title: "Amount",
        },
        {
          data: "discount",
          title: "Concession Amount",
        },
        {
          data: "amount_paid",
          title: "Received",
        },
        {
          data: "transaction_id",
          title: "Receipt No",
        },

        {
          data: "balance",
          title: "Balance",
        },
        {
          data: null,
          title: "Paid Status & Date",
          render: (data, type, row) => {
            return `
              <div>
                <div><strong>Status:</strong> ${row.paid_status || "N/A"}</div>
                <div><strong>Date:</strong> ${row.payment_date || "N/A"}</div>
              </div>
            `;
          },
        },

        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            return `
                  <a href="#" title="Print" data-id="${row.student_id}" class="print-icon">
                    <i class="fas fa-print"></i>
                  </a>
            `;
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "csv",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "excel",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "pdf",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "print",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
        emptyTable: "No Data Found",
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getFeesCollectionReport({
            branch_id: branchId,
            ...(selectedfromDate && {
              from_date: formatDate(selectedfromDate),
            }),
            ...(selectedtoDate && { to_date: formatDate(selectedtoDate) }),
            class_id: classId,
            page: page,
            _limit: length,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
            component_id: selectedComponet,
          });

          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;
          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (err) {
          console.error("Error fetching data:", error);
          showErrorAlert(err.response.data.message);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".print-icon", function (e) {
      e.preventDefault();
      const student_id = $(this).data("id");

      getFeesTransactionReport(branchId, student_id);
    });

    return () => {
      table.destroy();
    };
  }, [selectedfromDate, selectedComponet, selectedtoDate, classId]);

  const initialValues = {
    class_id: "",
    section_id: "",
    fcap_id: "",
    fsap_id: "",
    concession_id: "",
    amount: "0",
    date: "",
    type: "amount",
  };

  return (
    <>
      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 className="card-title">Fee payments List Table</h3>
        </div>

        <div className="card-body">
          <Formik initialValues={initialValues}>
            {({ setFieldValue, values }) => (
              <Form id="form" className="row">
                <div className="form-group col-md-4">
                  <label htmlFor="class_id">
                    Class Name <span className="mandatory">*</span>
                  </label>
                  <Field name="class_id">
                    {({ field, form }) => {
                      const options = classList.map((cls) => ({
                        value: cls.id,
                        label: cls.name,
                      }));

                      const selectAllOption = {
                        value: "selectAll",
                        label: "Select All",
                      };

                      const handleChange = (selectedOptions) => {
                        let selectedClassIds = selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : [];

                        // Handle "Select All"
                        if (selectedClassIds.includes("selectAll")) {
                          selectedClassIds = options.map(
                            (option) => option.value
                          );
                        }

                        form.setFieldValue("class_id", selectedClassIds);
                        setClassId(selectedClassIds);
                      };

                      return (
                        <Select
                          id="class_id"
                          options={[selectAllOption, ...options]}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          onChange={handleChange}
                          value={options.filter((option) =>
                            (form.values.class_id || []).includes(option.value)
                          )}
                          placeholder="Select Class"
                          getOptionLabel={(e) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  e.value === "selectAll"
                                    ? options.length ===
                                      form.values.class_id.length
                                    : form.values.class_id.includes(e.value)
                                }
                                readOnly
                                style={{ marginRight: "10px" }}
                              />
                              {e.label}
                            </div>
                          )}
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage
                    name="class_id"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="fcid">
                    Fee Component <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="fcap_id"
                    name="fcap_id"
                    className="form-control"
                    onChange={(e) => handleFeeComponentChange(e, setFieldValue)}
                  >
                    <option value="">Select Fee Type</option>
                    {fees.map((fee) => (
                      <option key={fee.id} value={fee.id}>
                        {fee.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fcap_id"
                    component="div"
                    className="text-error"
                  />
                </div>

                {/* Fee Subcomponent Dropdown */}
                <div className="form-group col-md-4">
                  <label htmlFor="fcap_id">SubComponent Name</label>
                  <Field
                    as="select"
                    id="fsap_id"
                    name="fsap_id"
                    className="form-control"
                  >
                    <option value="">Select Fee Subcomponent</option>
                    {feesSubComponents.map((subComponent) => (
                      <option key={subComponent.id} value={subComponent.id}>
                        {subComponent.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fsap_id"
                    component="div"
                    className="text-error"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="from_date">
                    From Date <span className="mandatory">*</span>
                  </label>
                  <Field
                    type="date"
                    name="from_date"
                    className="form-control"
                    onChange={(e) => handleFromDateChange(e, setFieldValue)}
                  />
                  <ErrorMessage
                    name="from_date"
                    component="div"
                    className="text-error"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="to_date">
                    To Date <span className="mandatory">*</span>
                  </label>
                  <Field
                    type="date"
                    name="to_date"
                    className="form-control"
                    onChange={(e) => handleToDateChange(e, setFieldValue)}
                  />
                  <ErrorMessage
                    name="to_date"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="payment_mode">Payment Mode</label>
                  <Field
                    as="select"
                    id="payment_mode"
                    name="payment_mode"
                    className="form-control"
                  >
                    <option value="">Select Payment Mode</option>
                    <option value="cash">Cash</option>
                    <option value="cheque">Cheque</option>
                    <option value="dd">DD</option>
                    <option value="challan">Challan</option>
                    <option value="online">Online</option>
                    <option value="swipe">Swipe</option>
                  </Field>
                  <ErrorMessage
                    name="payment_mode"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="download_type">
                    Download Type <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="download_type"
                    name="download_type"
                    className="form-control"
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      setFieldValue("download_type", selectedValue);
                    }}
                  >
                    <option value="">Select Download Type</option>
                    <option value="0_all">Download with header</option>
                    <option value="1_all">Download without header </option>
                    <option value="1_sub_component_wise">All - Excel</option>
                    <option value="0_sub_component_wise">
                      Sub Component Wise-Excel
                    </option>
                    <option value="0_component_wise">
                      Component Wise - Excel
                    </option>
                  </Field>
                  <ErrorMessage
                    name="download_type"
                    component="div"
                    className="text-error"
                  />
                </div>
              </Form>
            )}
          </Formik>

          {/* DataTable */}
          <table
            ref={tableRef}
            id="example1"
            className="table table-bordered table-striped"
          >
            <tbody></tbody>
          </table>

          {/* Error Alert */}
          {showError && (
            <div
              className={`error-alert error-alert-danger ${
                showError ? "fade-in" : "fade-out"
              }`}
              onAnimationEnd={() => {
                if (!showError) setError("");
              }}
            >
              {error}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default FeeCollectionReport;
