import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  getSubjectsByBranchId,
  getSubjectTopicById,
  updateSubjectTopic,
} from "../../Services/CommonApis/subjectApi";
import { useParams } from "react-router-dom";

const SyllabusView = () => {
  const { branchId } = useParams();
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [syllabus, setSyllabus] = useState([]);
  const [editingTopic, setEditingTopic] = useState(null);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await getSubjectsByBranchId(branchId);

        const subjects = response.branchsubject.map((subject) => ({
          id: subject.subject_id,
          name: subject.subject_name,
        }));
        setSubjects(subjects);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    };
    fetchSubjects();
  }, [branchId]);

  const fetchSyllabus = async (subjectId) => {
    try {
      Swal.fire({
        title: "Fetching Syllabus...",
        allowOutsideClick: false,
        didOpen: () => Swal.showLoading(),
      });
      const response = await getSubjectTopicById(subjectId);
      setSyllabus(response.chapters || []);
      Swal.close();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to fetch syllabus. Please try again.",
      });
      console.error("Error fetching syllabus:", error);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedSubject(event.target.value);
    const subjectId = event.target.value;
    setSelectedSubject(subjectId);
    if (subjectId) {
      fetchSyllabus(subjectId);
    } else {
      setSyllabus([]);
    }
  };

  const handleCompleteClick = (topic) => {
    setEditingTopic(topic);
  };

  const handleClosePopup = () => {
    setEditingTopic(null);
  };

  const handleSaveChanges = async (updatedTopic) => {
    try {
      if (!selectedSubject) {
        Swal.fire("Error", "The subject id field is required.", "error");
        return;
      }

      const payload = {
        id: selectedSubject,
        subtopic_id: updatedTopic.subtopic_id,
        completion_date: updatedTopic.completion_date,
        comments: updatedTopic.comments || "",
      };
      console.log("Payload being sent:", payload);

      Swal.fire({
        title: "Saving Changes...",
        allowOutsideClick: false,
        didOpen: () => Swal.showLoading(),
      });

      await updateSubjectTopic(payload);

      Swal.close();
      Swal.fire("Success", "Subtopic updated successfully!", "success");

      fetchSyllabus(selectedSubject);
      handleClosePopup();
    } catch (error) {
      console.error("Error updating subtopic:", error);

      const errorMessage =
        error.response?.data?.message || "An unexpected error occurred.";
      Swal.fire("Error", errorMessage, "error");
    }
  };

  const renderSyllabusRows = (syllabus, prefix = "") => {
    return syllabus.flatMap((topic, index) => {
      const currentNumber = `${prefix}${prefix ? "." : ""}${index + 1}`;
      const row = (
        <tr key={topic.subtopic_id || currentNumber}>
          <td style={{ paddingLeft: `${prefix.split(".").length * 20}px` }}>
            <strong>{currentNumber}</strong> {topic.subtopic || "No Subtopic"}
          </td>
          <td className="text-end">
            {topic.completion_date ? (
              topic.completion_date
            ) : (
              <button
                className="btn btn-sm btn-danger"
                onClick={() => handleCompleteClick(topic)}
              >
                Complete
              </button>
            )}
          </td>
          <td className="text-end">{topic.page_no || "N/A"}</td>
        </tr>
      );

      const nestedRows = topic.nested_syllabus
        ? renderSyllabusRows(topic.nested_syllabus, currentNumber)
        : [];

      return [row, ...nestedRows];
    });
  };

  const renderChapterRows = (chapters) => {
    return chapters.map((chapter, index) => {
      const chapterNumber = `${index + 1}`;
      return (
        <React.Fragment key={chapter.id}>
          <tr>
            <th colSpan="3">
              <strong>{chapterNumber}</strong>{" "}
              {chapter.title || "No Title Available"}
            </th>
          </tr>
          {chapter.syllabus &&
            chapter.syllabus.map((topic, idx) => (
              <React.Fragment key={topic.topic_id || idx}>
                <tr>
                  <td colSpan="3" style={{ paddingLeft: "20px" }}>
                    <strong>{`Topic ${idx + 1}: `}</strong>
                    {topic.title || "No Title Available"}
                  </td>
                </tr>
                {renderSyllabusRows(
                  topic.syllabus,
                  `${chapterNumber}.${idx + 1}`
                )}
              </React.Fragment>
            ))}
        </React.Fragment>
      );
    });
  };

  const renderSyllabus = (chapters) => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Subtopic</th>
            <th className="text-end">Completion</th>
            <th className="text-end">Page No</th>
          </tr>
        </thead>
        <tbody>{renderChapterRows(chapters)}</tbody>
      </table>
    );
  };

  return (
    <div className="container mt-5">
      <div className="card shadow">
        <div className="card-header bg-primary text-white">
          <h5 className="mb-0">View Syllabus</h5>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="subject-dropdown" className="form-label">
              Select Subject:
            </label>
            <select
              id="subject-dropdown"
              className="form-control"
              value={selectedSubject}
              onChange={handleSelectChange}
            >
              <option value="" disabled>
                -- Select a Subject --
              </option>
              {subjects.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>
          </div>
          {syllabus.length > 0 && (
            <div className="mt-4">{renderSyllabus(syllabus)}</div>
          )}
        </div>
      </div>

      {editingTopic && (
        <CompletePopup
          topic={editingTopic}
          onClose={handleClosePopup}
          onSave={handleSaveChanges}
        />
      )}
    </div>
  );
};

const CompletePopup = ({ topic, onClose, onSave }) => {
  const [completionDate, setCompletionDate] = useState("");
  const [comment, setComment] = useState("");

  const handleSave = () => {
    const updatedTopic = {
      ...topic,
      completion_date: completionDate,
      comments: comment,
    };
    onSave(updatedTopic);
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <h5>Complete Topic</h5>
        <div className="form-group">
          <label>Completion Date</label>
          <input
            type="date"
            className="form-control"
            value={completionDate}
            onChange={(e) => setCompletionDate(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Comments</label>
          <textarea
            className="form-control"
            value={comment}
            onChange={(e) => {
              console.log("Comment changed:", e.target.value);
              setComment(e.target.value);
            }}
          />
        </div>
        <div className="form-actions">
          <button className="btn btn-secondary" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SyllabusView;
