import React, { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getStaffByBranchId } from "../../Services/AdminApis/StaffApi";
import { useParams } from "react-router-dom";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import {
  createEvent,
  getAllNotificationTypes,
  getEventById,
  recievertype,
  updateEventById,
} from "../../Services/CommonApis/EventsApi";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { getGroups } from "../../Services/CommonApis/GroupApi";

function ShiftAssignment() {
  const { schoolId, branchId, id } = useParams();
  const [staff, setStaff] = useState([]);
  const [EventEdit, setEventEdit] = useState([]);
  const [reciever, setRecievers] = useState([]);
  const [notification, setNotification] = useState([]);
  const navigate = useNavigate();
  const [notificationOptions, setNotificationOptions] = useState([]);
  const [selectedReciever, setSelectedReciever] = useState(null);
  const [group, setGroup] = useState([]);
  useEffect(() => {
    fetchRecieverTypes();
    fetchAllNotificationTypes();
  }, []);
  const fetchRecieverTypes = async () => {
    try {
      const response = await recievertype();
      setRecievers(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = Yup.object().shape({
    type_id: Yup.string().required("Staff type is required"),
    notification_type: Yup.array().min(
      1,
      "At least one notification method must be selected"
    ),
    message: Yup.string().required("Notification message is required"),

    events_date: Yup.date().required("Event date is required"),
  });

  useEffect(() => {
    if (id) {
      fetchEventbyid(id);
    }
  }, [id]);
  const fetchEventbyid = async (id) => {
    try {
      const response = await getEventById(id);
      const eventData = response.data.data[0]; // Extract the first item from the response

      setEventEdit({
        type: eventData.type[0]?.id || "",
        notification_type:
          eventData.notification_type.map((item) => String(item.id)) || [],
        message: eventData.message || "",
        events_date: eventData.events_date || "",
      });
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };
  const fetchAllNotificationTypes = async () => {
    try {
      const response = await getAllNotificationTypes(); // Replace with your actual API call
      const options = response.data.map((item) => ({
        label: item.type,
        value: item.id.toString(),
      }));
      // Add "Select All" option at the top
      setNotificationOptions([
        { label: "Select All", value: "all" },
        ...options,
      ]);
    } catch (error) {
      console.error("Error fetching notification types:", error);
    }
  };

  const handleSubmit = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "Do you want to create Event Notification?",
      "info",
      "Yes, Create!",
      "No, Cancel!"
    );
    if (result.isConfirmed) {
      const notificationData = {
        type_id: values.type_id,
        notification_type: values.notification_type,
        message: values.message,
        branch_id: branchId,
        events_date: values.events_date,
        notification_data_id: [2],
      };
      let response;
      try {
        if (id) {
          response = await updateEventById(id, notificationData);
          console.log(response);
        } else {
          response = await createEvent(notificationData);
          if (response.status === 201) {
            showSuccessAlert(response.data.message);
          }
          window.location.reload();
        }

        if (response.status === 200) {
          showSuccessAlert(response.data.message);
          navigate(`/school/${schoolId}/branch/${branchId}/event-notification`);
        }
      } catch (error) {
        console.error("Error sending event notification:", error);
        // Show an error message to the user
        showErrorAlert(
          "Failed to send event notification. Please try again later."
        );
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert(
        "Cancelled",
        "The  Event Notification creation was cancelled!"
      );
    }
  };
  useEffect(() => {
    if (selectedReciever == 1) {
      fetchGroups(branchId);
    }
  }, [selectedReciever]);
  const fetchGroups = async (branchId) => {
    try {
      const response = await getGroups(branchId);
      console.log(response.data);
      setGroup(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(selectedReciever);
  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title"> Event</h3>
                </div>

                <div className="card-body">
                  <Formik
                    initialValues={{
                      type_id: EventEdit.type || "",
                      notification_type: EventEdit.notification_type || [],
                      message: EventEdit.message || "",
                      events_date: EventEdit.events_date || "",
                    }}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <div className="row">
                          {/* Dropdown for staff type selection */}
                          <div className="form-group col-md-4 mb-4 d-flex flex-column">
                            <label
                              htmlFor="type_id"
                              className="form-label fw-bold"
                            >
                              Receiver Type
                            </label>
                            <Field
                              as="select"
                              name="type_id"
                              className="form-control"
                              onChange={(e) => {
                                const selectedType = e.target.value;
                                setFieldValue("type_id", selectedType);
                                setSelectedReciever(selectedType);
                              }}
                            >
                              <option value="0">Select reciever type</option>
                              {reciever.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="type_id"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          {selectedReciever == 1 && (
                            <div className="form-group col-md-4 mb-4 d-flex flex-column">
                              <label
                                htmlFor="group_id"
                                className="form-label fw-bold"
                              >
                                Group
                              </label>
                              <Field
                                as="select"
                                name="group_id"
                                className="form-control"
                                onChange={(e) => {
                                  const selectedType = e.target.value;
                                  setFieldValue("group_id", selectedType);
                                  setSelectedReciever(selectedType);
                                }}
                              >
                                <option value="0">Select Group</option>
                                {group.length > 0 &&
                                  group.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                              </Field>
                              <ErrorMessage
                                name="group_id"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          )}
                          {/* MultiSelect for Notification Methods with Select All */}
                          <div className="form-group col-md-4 mb-4 d-flex flex-column">
                            <label
                              htmlFor="notification_type"
                              className="form-label fw-bold"
                            >
                              Notification Methods
                            </label>
                            <Field name="notification_type">
                              {({ field, form }) => {
                                const selectedValues =
                                  form.values.notification_type || [];

                                // Handle Change within the Field
                                const handleChange = (selectedOptions) => {
                                  const isSelectAll = selectedOptions.some(
                                    (option) => option.value === "all"
                                  );
                                  if (isSelectAll) {
                                    const allValues =
                                      selectedOptions.length ===
                                      notificationOptions.length
                                        ? [] // Deselect all
                                        : notificationOptions
                                            .slice(1)
                                            .map((option) => option.value); // Select all except "Select All"
                                    form.setFieldValue(
                                      "notification_type",
                                      allValues
                                    );
                                  } else {
                                    const selectedValues = selectedOptions.map(
                                      (option) => option.value
                                    );
                                    form.setFieldValue(
                                      "notification_type",
                                      selectedValues
                                    );
                                  }
                                };

                                return (
                                  <Select
                                    id="notification_type"
                                    options={notificationOptions}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={handleChange}
                                    value={notificationOptions.filter(
                                      (option) =>
                                        selectedValues.includes(option.value)
                                    )}
                                    placeholder="Select Notification Methods"
                                    getOptionLabel={(e) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedValues.includes(
                                            e.value
                                          )}
                                          readOnly
                                          style={{ marginRight: "10px" }}
                                        />
                                        {e.label}
                                      </div>
                                    )}
                                  />
                                );
                              }}
                            </Field>
                            <ErrorMessage
                              name="notification_type"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          {/* events_date Field */}
                          <div className="form-group col-md-4">
                            <label htmlFor="events_date">
                              {" "}
                              Event Date<span className="mandatory">
                                *
                              </span>{" "}
                            </label>
                            <Field
                              name="events_date"
                              type="date"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="events_date"
                              component="div"
                              className="field-error text-danger"
                            />
                          </div>

                          {/* Message box */}
                          <div className="form-group col-12 mb-4">
                            <label
                              htmlFor="message"
                              className="form-label fw-bold"
                            >
                              Notification Message
                            </label>
                            <InputTextarea
                              value={values.message}
                              onChange={(e) =>
                                setFieldValue("message", e.target.value)
                              }
                              rows={5}
                              placeholder="Enter your notification message..."
                              className="form-control shadow-sm rounded"
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="form-group text-left">
                          <Button
                            label="Submit"
                            type="submit"
                            className="btn btn-primary"
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ShiftAssignment;
