import React, { useEffect, useState } from "react";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getAllExams } from "../../Services/AdminApis/ExamApi";
import { useParams } from "react-router-dom";
import {
  generateOverallExcel,
  getExamMarks,
} from "../../Services/CommonApis/ReportcardApi";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import { generateOverallPdf } from "../../Services/CommonApis/ReportcardApi";
import { useRef } from "react";

function OverallReport() {
  const tableRef = useRef(null);
  const { branchId } = useParams();
  const [students, setStudents] = useState([]);
  const [branchName, setBranchName] = useState("");
  const [ClassName, setClassName] = useState("");
  const [mediumName, setMediumName] = useState("");
  const [academicName, setacademicName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [sectionId, setSectionId] = useState("");
  const [exams, setExams] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [selectedExamId, setSelectedExamId] = useState("");
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    fetchExams();
    if (branchId) {
      fetchClasses(branchId);
    }
  }, [branchId]);

  const fetchExams = async () => {
    try {
      const response = await getAllExams();
      setExams(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSections(response.data.sections);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMarks = async (branchId, sectionId, classId, examId) => {
    try {
      const response = await getExamMarks(branchId, sectionId, classId, examId);
      setBranchName(response.data.data[0].branch_name);
      setClassName(response.data.data[0].marks_data.class_info.class_name);
      setSectionName(response.data.data[0].marks_data.class_info.section_name);
      setacademicName(
        response.data.data[0].marks_data.class_info.academic_year
      );
      setMediumName(response.data.data[0].marks_data.class_info.medium_name);

      setStudents(response.data.data);

      if (response.data.data.length > 0) {
        const subjectsList = response.data.data[0].marks_data.marks.map(
          (mark) => ({
            subject_id: mark.subject_id,
            subject_name: mark.subject_name,
          })
        );
        setSubjects(subjectsList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initialValues = {
    class_id: "",
    exam_id: "",
  };

  const validationSchema = Yup.object({
    class_id: Yup.string().required("Class is required"),
    exam_id: Yup.string().required("Exam is required"),
  });

  const handleGeneratePdf = () => {
    generateOverallPdf(branchId, selectedClassId, sectionId, selectedExamId);
  };
  const handleGenerateExcel = () => {
    generateOverallExcel(branchId, selectedClassId, sectionId, selectedExamId);
  };

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      destroy: true,
      data: students.map((student, index) => ({
        id: index + 1,
        details: `<strong>${student.student_name}</strong><br>${student.admission_no}`,
        ranksAttendance: `Class Rank: ${
          student.marks_data.class_rank || "N/A"
        }<br>
                          Section Rank: ${
                            student.marks_data.section_rank || "N/A"
                          }<br>
                          Attendance: ${
                            student.attendance.present_count || "N/A"
                          }`,
        marksPercentage: `Total Marks: ${student.marks_data.marks.reduce(
          (sum, mark) =>
            sum +
            (mark.internal ? parseInt(mark.internal) : 0) +
            (mark.external ? parseInt(mark.external) : 0),
          0
        )}<br>Percentage: ${student.marks_data.percentage || "N/A"}`,
        marks: subjects.map((subject) => {
          const mark = student.marks_data.marks.find(
            (m) => m.subject_id === subject.subject_id
          );
          return mark
            ? parseInt(mark.internal) + parseInt(mark.external)
            : "N/A";
        }),
        result: student.marks_data.result,
      })),
      columns: [
        { title: "S.No", data: "id" },
        { title: "Details", data: "details" },
        ...subjects.map((subject, idx) => ({
          title: subject.subject_name,
          data: (row) => row.marks[idx] || "N/A",
        })),
        { title: "Marks & Percentage", data: "marksPercentage" },
        { title: "Ranks & Attendance", data: "ranksAttendance" },
        { title: "Result", data: "result" },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "csv",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "excel",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "pdf",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "print",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
        emptyTable: "No Marks Data Found",
      },
    });

    return () => {
      table.destroy();
    };
  }, [students, subjects]);

  return (
    <div className="p-3">
      <div className="card ">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Overall Marks Report
          </h3>
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="card-body">
                <div className="row" style={{ flexWrap: "wrap" }}>
                  <div className="form-group col-4">
                    <label htmlFor="class_id">
                      Class Name <span className="mandatory">*</span>
                    </label>
                    <Field
                      as="select"
                      id="class_id"
                      name="class_id"
                      className="form-control"
                      onChange={(e) => {
                        const selectedClassId = e.target.value;
                        setFieldValue("class_id", selectedClassId);
                        setSelectedClassId(selectedClassId);
                        if (selectedClassId) {
                          fetchSections(selectedClassId);
                        }
                      }}
                    >
                      <option value="">Select Class</option>
                      {classes.map((cls) => (
                        <option key={cls.id} value={cls.id}>
                          {cls.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="class_id"
                      component="div"
                      className="text-error"
                    />
                  </div>

                  <div className="form-group col-4">
                    <label htmlFor="section_id">
                      Section Name <span className="mandatory">*</span>
                    </label>
                    <Field
                      as="select"
                      id="section_id"
                      name="section_id"
                      className="form-control"
                      value={sectionId || ""}
                      onChange={(e) => {
                        const selectedSectionId = e.target.value;
                        setFieldValue("section_id", selectedSectionId);
                        setSectionId(selectedSectionId);
                      }}
                      disabled={!selectedClassId}
                    >
                      <option value="">Select Section</option>
                      {sections.map((section) => (
                        <option key={section.id} value={section.id}>
                          {section.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="section_id"
                      component="div"
                      className="text-error"
                    />
                  </div>

                  <div className="form-group col-4">
                    <label htmlFor="exam_id">
                      Exam Name <span className="mandatory">*</span>
                    </label>
                    <Field
                      as="select"
                      id="exam_id"
                      name="exam_id"
                      className="form-control"
                      onChange={(e) => {
                        const selectedExamId = e.target.value;
                        setFieldValue("exam_id", selectedExamId);
                        setSelectedExamId(selectedExamId);
                        fetchMarks(
                          branchId,
                          selectedClassId,
                          sectionId,
                          selectedExamId
                        );
                      }}
                    >
                      <option value="">Select Exam</option>
                      {exams.map((exam) => (
                        <option key={exam.id} value={exam.id}>
                          {exam.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="exam_id"
                      component="div"
                      className="text-error"
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <button
            className="btn"
            style={{
              backgroundColor: "#6f42c1",
              color: "white",
              marginRight: "10px",
              marginLeft: "30px",
              marginBottom: "30px",
            }}
            onClick={handleGeneratePdf}
          >
            Download Pdf
          </button>
          <button
            className="btn btn-success"
            style={{
              marginRight: "10px",
              marginBottom: "30px",
            }}
            onClick={handleGenerateExcel}
          >
            Download Excel
          </button>
        </div>
        <h3 style={{ textAlign: "center" }}>{branchName}</h3>
        <div className="row">
          <div className="col-3 text-center row ml-3">
            <p>Class:</p>
            <p>{ClassName}</p>
          </div>
          <div className="col-3 text-center row">
            <p>Section:</p>
            <p>{sectionName}</p>
          </div>
          <div className="col-3 text-center row">
            <p>Medium:</p> <p>{mediumName}</p>
          </div>
          <div className="col-3 text-center row">
            <p>Academic year:</p> <p>{academicName}</p>
          </div>
        </div>
        <div className="card-body">
          <table
            ref={tableRef}
            id="example1"
            className="table table-bordered table-striped"
          ></table>
        </div>
      </div>
    </div>
  );
}

export default OverallReport;
