import React, { useState, useEffect } from "react";
import { Field, useFormikContext, ErrorMessage } from "formik";
import { getAllId } from "../../Services/AdminApis/studentIdCard";
import DragNdrop from "../CommonComponents/DragandDrop";
import MultiStepForm from "../CommonComponents/Multistepform";

const SchoolBranchSettings = ({ current, steps }) => {
  
  
  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      <MultiStepForm />
    </div>
  );
};

export default SchoolBranchSettings;
