import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Button } from "@mui/material";
import Swal from 'sweetalert2';

import { getSchools } from "../../Services/AdminApis/schoolApi";
import { showLoadingAlert, showSuccessAlert, showErrorAlert, showSweetAlert } from "../../Modals/SweetAlertModel";
import {
  createSchoolManager,
  updateSchoolManagerById,
  getSchoolManagerById,
  getAllMenuList,
} from "../../Services/AdminApis/SchoolManagerApi";

const formStyles = {
  container: {
    padding: '20px'
  },
  header: {
    color: '#17a2b8',
    marginBottom: '30px',
    fontSize: '24px'
  },
  formGroup: {
    marginBottom: '10px'
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    fontSize: '14px',
    fontWeight: '500'
  },
  input: {
    width: '100%',
    height: '42px',
    padding: '8px 12px',
    border: '1px solid #ced4da',
    borderRadius: '4px',
    fontSize: '14px'
  },
  password: {
    width: '49%',
    height: '42px',
    padding: '8px 12px',
    border: '1px solid #ced4da',
    borderRadius: '4px',
    fontSize: '14px'
  },
  mandatory: {
    color: '#dc3545',
    marginLeft: '3px'
  },
  error: {
    color: '#dc3545',
    fontSize: '12px',
    marginTop: '4px'
  },
  row: {
    display: 'flex',
    gap: '20px',
    marginBottom: '20px'
  },
  column: {
    flex: 1
  },
  buttonGroup: {
    display: 'flex',
    gap: '8px',
    marginTop: '29px'
  },
  actionButton: {
    minWidth: '35px',
    width: '35px',
    height: '35px',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  generateButton: {
    height: '42px',
    marginLeft: '8px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '0 15px',
    cursor: 'pointer'
  },
  submitButton: {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '10px 20px',
    cursor: 'pointer',
    marginTop: '5px',
    float: 'right'
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'center'
  }
};

const SchoolManager = () => {
  const navigate = useNavigate();
  const { user_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [menuOptions, setMenuOptions] = useState([]);
  const [initialData, setInitialData] = useState({
    full_name: "",
    phone_no: "",
    email: "",
    username: "",
    password: "",
    scool_list: [{ school_id: "", menu_id: [] }]
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(true);
      try {
        const [allSchoolsResponse, allMenusResponse] = await Promise.all([
          getSchools({ school_manager: 1 }),
          getAllMenuList()
        ]);

        if (allSchoolsResponse?.data) {
          const allSchools = allSchoolsResponse.data.map((school) => ({
            value: school.id,
            label: school.name,
          }));
          setSchoolOptions(allSchools);
        }

        if (allMenusResponse?.data) {
          const allMenus = allMenusResponse.data.map((menu) => ({
            value: menu.id,
            label: menu.name,
          }));
          setMenuOptions(allMenus);
        }

        if (user_id) {
          const managerResponse = await getSchoolManagerById(user_id);
          if (managerResponse.data) {
            const { full_name, username, email, phone_no, schools } = managerResponse.data;
            setInitialData({
              full_name: full_name || "",
              username: username || "",
              email: email || "",
              phone_no: phone_no || "",
              password: "",
              scool_list: schools.map((school) => ({
                school_id: school.id,
                menu_id: school.menu.map((item) => item["menu-id"]) || [],
              })),
            });
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        Swal.fire("Error", "There was an issue fetching the data.", "error");
      } finally {
        setLoadingData(false);
      }
    };

    fetchData();
  }, [user_id]);
  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Name is required"),
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone_no: Yup.string().required("Phone number is required"),
    password: user_id ? Yup.string() : Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
    scool_list: Yup.array().of(
      Yup.object().shape({
        school_id: Yup.number().required("School is required"),
        menu_id: Yup.array().min(1, "At least one menu item must be selected").required("Menu items are required")
      })
    ).min(1, "At least one school must be selected")
  });

  const generateRandomPassword = () => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 12;
    let password = "";
    for (let i = 0; i <= passwordLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const result = await showSweetAlert(
      "Are you sure?",
      `You want to ${user_id ? "update" : "create"} the school manager.`,
      "info",
      "Yes, Proceed",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      // setLoading(true);
      showLoadingAlert();

      try {
        const formattedData = {
          full_name: values.full_name,
          phone_no: values.phone_no,
          email: values.email,
          username: values.username,
          password: values.password,
          scool_list: values.scool_list.map(school => ({
            school_id: school.school_id,
            menu_id: school.menu_id
          }))
        };

        let response;

        if (user_id) {
          response = await updateSchoolManagerById(user_id, formattedData);
        } else {
          response = await createSchoolManager(formattedData);
        }

        if (response.status === 200 || response.status === 201) {
          const successMessage = response.data.message || `School manager ${user_id ? "updated" : "created"} successfully.`;
          showSuccessAlert(user_id ? "Updated!" : "Created!", successMessage);
          navigate("/admin/manager-list");
        } else {
          const errorMessage = response.data?.message || "School manager operation failed. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (error) {
        console.error("API Error:", error);
        const errorMessage = error.response?.data?.message || "An error occurred. Please try again.";
        showErrorAlert("Error", errorMessage);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      const errorMessage = user_id ? "School manager update cancelled." : "School manager creation cancelled.";
      showErrorAlert("Cancelled", errorMessage);
    }
  };

  return (
    <div style={formStyles.container}>
      <h2 style={formStyles.header}>
        {user_id ? "Update School Manager" : "Add School Manager"}
      </h2>
      <Formik
        initialValues={initialData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <div style={formStyles.row}>
              <div style={formStyles.column}>
                <div style={formStyles.formGroup}>
                  <label style={formStyles.label}>
                    Name <span style={formStyles.mandatory}>*</span>
                  </label>
                  <Field
                    type="text"
                    name="full_name"
                    style={formStyles.input}
                    placeholder="Enter Name"
                  />
                  <ErrorMessage name="full_name" component="div" style={formStyles.error} />
                </div>
              </div>
              <div style={formStyles.column}>
                <div style={formStyles.formGroup}>
                  <label style={formStyles.label}>
                    Username <span style={formStyles.mandatory}>*</span>
                  </label>
                  <Field
                    type="text"
                    name="username"
                    style={formStyles.input}
                    placeholder="Enter Username"
                  />
                  <ErrorMessage name="username" component="div" style={formStyles.error} />
                </div>
              </div>
            </div>

            <div style={formStyles.row}>
              <div style={formStyles.column}>
                <div style={formStyles.formGroup}>
                  <label style={formStyles.label}>
                    Email <span style={formStyles.mandatory}>*</span>
                  </label>
                  <Field
                    type="email"
                    name="email"
                    style={formStyles.input}
                    placeholder="Enter Email"
                  />
                  <ErrorMessage name="email" component="div" style={formStyles.error} />
                </div>
              </div>
              <div style={formStyles.column}>
                <div style={formStyles.formGroup}>
                  <label style={formStyles.label}>
                    Phone Number <span style={formStyles.mandatory}>*</span>
                  </label>
                  <Field
                    type="text"
                    name="phone_no"
                    style={formStyles.input}
                    placeholder="Enter Phone Number"
                  />
                  <ErrorMessage name="phone_no" component="div" style={formStyles.error} />
                </div>
              </div>
            </div>

            <div style={formStyles.formGroup}>
              <label style={formStyles.label}>
                Password {!user_id && <span style={formStyles.mandatory}>*</span>}
              </label>
              <div style={formStyles.inputGroup}>
                <Field
                  type="text"
                  name="password"
                  style={formStyles.password}
                  placeholder={user_id ? "Leave blank to keep current password" : "Enter Password"}
                />
                <button
                  type="button"
                  onClick={() => setFieldValue("password", generateRandomPassword())}
                  style={formStyles.generateButton}
                >
                  Generate
                </button>
              </div>
              <ErrorMessage name="password" component="div" style={formStyles.error} />
            </div>

            {values.scool_list.map((school, index) => (
              <div key={index} style={{ ...formStyles.row, alignItems: 'flex-start' }}>
                <div style={formStyles.column}>
                  <label style={formStyles.label}>
                    School <span style={formStyles.mandatory}>*</span>
                  </label>
                  <Select
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: '42px',
                        height: '42px'
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        height: '42px',
                        padding: '0 8px'
                      })
                    }}
                    options={schoolOptions}
                    value={schoolOptions.find(option => option.value === school.school_id)}
                    onChange={(selectedOption) => {
                      setFieldValue(`scool_list.${index}.school_id`, selectedOption ? selectedOption.value : "");
                    }}
                    placeholder="Select..."
                  />
                  <ErrorMessage
                    name={`scool_list.${index}.school_id`}
                    component="div"
                    style={formStyles.error}
                  />
                </div>
                <div style={formStyles.column}>
                  <label style={formStyles.label}>
                    Menu Items <span style={formStyles.mandatory}>*</span>
                  </label>
                  <Select
                    isMulti
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: '42px'
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        padding: '0 8px'
                      })
                    }}
                    options={menuOptions}
                    value={menuOptions.filter(option => school.menu_id.includes(option.value))}
                    onChange={(selectedOptions) => {
                      setFieldValue(
                        `scool_list.${index}.menu_id`,
                        selectedOptions ? selectedOptions.map(option => option.value) : []
                      );
                    }}
                    placeholder="Select..."
                  />
                  <ErrorMessage
                    name={`scool_list.${index}.menu_id`}
                    component="div"
                    style={formStyles.error}
                  />
                </div>
                <div style={formStyles.buttonGroup}>
                  <Button
                    variant="contained"
                    style={{
                      ...formStyles.actionButton,
                      backgroundColor: values.scool_list.length > 1 ? '#dc3545' : '#dc3545',
                      color: values.scool_list.length > 1 ? 'white' : 'white'
                    }}
                    onClick={() => {
                      if (values.scool_list.length > 1) {
                        const newList = [...values.scool_list];
                        newList.splice(index, 1);
                        setFieldValue('scool_list', newList);
                      }
                    }}
                    disabled={values.scool_list.length === 1}
                  >
                  <i class="fas fa-minus"></i>
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      ...formStyles.actionButton,
                      backgroundColor: '#007bff'
                    }}
                    onClick={() => {
                      setFieldValue('scool_list', [
                        ...values.scool_list,
                        { school_id: "", menu_id: [] }
                      ]);
                    }}
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </div>
              </div>
            ))}

            <button
              type="submit"
              disabled={isSubmitting || loading}
              style={formStyles.submitButton}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SchoolManager;