import Api from "../Api";

export const createAttendance = async (data) => {
  try {
    const response = await Api.post("/create-attendance", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const markCorrection = async (data) => {
  try {
    const response = await Api.post("/markCorrection", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createSubjectAttendance = async (data) => {
  try {
    const response = await Api.post("/create-attendance-subjectwise", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createBulkAttendance = async (data) => {
  try {
    const response = await Api.post("/bulk-attendance", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAttendanceByDate = async (branch_id, date) => {
  try {
    const response = await Api.get("/get-attendance-date", {
      params: {
        branch_id: branch_id,
        date: date,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getConsolidatedAttendance = async (
  branchId,
  startDate,
  endDate
) => {
  try {
    const response = await Api.get("get-attendance-consolidated", {
      params: {
        branch_id: branchId,
        start_date: startDate,
        end_date: endDate,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDaywiseAttendance = async (branchId, datewise) => {
  try {
    const response = await Api.get("/get-attendance-report", {
      params: {
        branch_id: branchId,
        date: datewise,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDaywiseAttendanceClassSection = async (
  branchId,
  datewise,
  classId,
  sectionId
) => {
  try {
    const response = await Api.get("/get-attendence-report", {
      params: {
        branch_id: branchId,
        date: datewise,
        class_id: classId,
        section_id: sectionId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAttendanceByDateSection = async (
  branchId,
  date,
  section_id
) => {
  console.log("Fetching attendance for:", branchId, date, section_id);

  try {
    const response = await Api.get("/get-attend-student", {
      params: {
        branch_id: branchId,
        date: date,
        section_id: section_id,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching attendance:", error);
    throw error;
  }
};
