import { React, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { createClasswiseConcession } from "../../Services/CommonApis/ClasswiseconcessionApi";
import {
  getAllFees,
  getAllConcession,
  getFeesSubComponentsByFCID,
} from "../../Services/CommonApis/FeesApi";
import { showSuccessAlert, showErrorAlert } from "../../Modals/SweetAlertModel";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { useNavigate } from "react-router-dom";

function ClassWiseConcession() {
  const navigate = useNavigate();
  const { schoolId, branchId } = useParams();
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [classId, setClassId] = useState(null);
  const [fees, setFees] = useState([]);
  const [concessions, setConcessions] = useState([]);
  const [feesSubComponents, setFeesSubComponents] = useState([]);

  // Set today's date as default
  const today = new Date().toISOString().split("T")[0];

  const initialValues = {
    class_id: "",
    section_id: "",
    fcap_id: "",
    fsap_id: "",
    concession_id: "",
    amount: "0",
    date: today, // Set default date to today's date
    type: "amount",
  };

  const validationSchema = Yup.object().shape({
    class_id: Yup.string().required("Class is required"),
    section_id: Yup.string().required("Section is required"),
    fcap_id: Yup.string().required("Fee Type is required"),
    concession_id: Yup.string().required("Concession is required"),
    amount: Yup.number()
      .required("Amount is required")
      .min(0, "Amount must be positive"),
    date: Yup.date().required("Date is required"),
    type: Yup.string()
      .oneOf(
        ["amount", "percentage"],
        "Type must be either amount or percentage"
      )
      .required("Type is required"),
  });

  const handleSubmit = async (values) => {
    try {
      values = { ...values, branch_id: branchId };
      const response = await createClasswiseConcession(values);
      showSuccessAlert("Success", response.data.message);
      if (response.status === 201) {
        navigate(`/school/${schoolId}/branch/${branchId}/fee-concession`);
      }
    } catch (errors) {
      showErrorAlert(errors.response.data);
      console.log(errors.response.data.message);
    }
  };

  // Fetching functions
  const fetchClassByBranchId = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchSectionByClassId = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSectionList(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  const fetchFeeComponents = async () => {
    try {
      const response = await getAllFees(branchId);
      setFees(response.data.data);
    } catch (error) {
      console.error("Error fetching fees:", error);
    }
  };

  const fetchSubComponentsByFCID = async (id) => {
    try {
      const response = await getFeesSubComponentsByFCID(id);
      setFeesSubComponents(response);
    } catch (error) {
      console.error("Error fetching fee subcomponents:", error);
    }
  };

  const fetchConcessions = async () => {
    try {
      const response = await getAllConcession();
      setConcessions(response.data); // Assuming response has data
    } catch (error) {
      console.error("Error fetching concessions:", error);
    }
  };

  useEffect(() => {
    fetchClassByBranchId(branchId);
    fetchFeeComponents();
    fetchConcessions();
  }, [branchId]);

  return (
    <>
      <div className="card card-primary p-3">
        <div className="card-header">
          <h3 className="card-title">Classwise Concession</h3>
        </div>
        <div className="card-body">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form id="form">
                <div className="card p-4">
                  <div className="row">
                    {/* Class Dropdown */}
                    <div className="form-group col-md-4">
                      <label htmlFor="class_id">
                        {" "}
                        Class Name <span className="mandatory">*</span>{" "}
                      </label>
                      <Field
                        as="select"
                        id="class_id"
                        name="class_id"
                        className="form-control"
                        onChange={(e) => {
                          const selectedClassId = e.target.value;
                          fetchSectionByClassId(selectedClassId);
                          setFieldValue("class_id", selectedClassId);
                          setClassId(selectedClassId);
                        }}
                      >
                        <option value="">Select Class</option>
                        {classList.map((cls) => (
                          <option key={cls.id} value={cls.id}>
                            {" "}
                            {cls.name}{" "}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="class_id"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    {/* Section Dropdown */}
                    <div className="form-group col-md-4">
                      <label htmlFor="section_id">
                        {" "}
                        Section Name <span className="mandatory">*</span>{" "}
                      </label>
                      <Field
                        as="select"
                        id="section_id"
                        name="section_id"
                        className="form-control"
                        disabled={!classId}
                      >
                        <option value="">Select Section</option>
                        {sectionList.map((section) => (
                          <option key={section.id} value={section.id}>
                            {" "}
                            {section.name}{" "}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="section_id"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    {/* Fee Type Dropdown */}
                    <div className="form-group col-md-4">
                      <label htmlFor="fcap_id">
                        {" "}
                        Fee Type <span className="mandatory">*</span>{" "}
                      </label>
                      <Field
                        as="select"
                        id="fcap_id"
                        name="fcap_id"
                        className="form-control"
                        onChange={(e) => {
                          const selectedFeeType = e.target.value;
                          setFieldValue("fcap_id", selectedFeeType);
                          fetchSubComponentsByFCID(selectedFeeType);
                        }}
                      >
                        <option value="">Select Fee Type</option>
                        {fees.map((fee) => (
                          <option key={fee.id} value={fee.id}>
                            {" "}
                            {fee.name}{" "}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="fcap_id"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    {/* SubComponent Dropdown */}
                    <div className="form-group col-md-4">
                      <label htmlFor="fcap_id"> SubComponent Name </label>
                      <Field
                        as="select"
                        id="fsap_id"
                        name="fsap_id"
                        className="form-control"
                        disabled={!values.fcap_id}
                      >
                        <option value="">Select Fee Subcomponent</option>
                        {feesSubComponents.map((subComponent) => (
                          <option key={subComponent.id} value={subComponent.id}>
                            {" "}
                            {subComponent.name}{" "}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="fsap_id"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    {/* Concession Dropdown */}
                    <div className="form-group col-md-4">
                      <label htmlFor="concession_id">
                        {" "}
                        Concession Name <span className="mandatory">
                          *
                        </span>{" "}
                      </label>
                      <Field
                        as="select"
                        id="concession_id"
                        name="concession_id"
                        className="form-control"
                      >
                        <option value="">Select Concession</option>
                        {concessions.map((concession) => (
                          <option key={concession.id} value={concession.id}>
                            {" "}
                            {concession.name}{" "}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="concession_id"
                        component="div"
                        className="text-error"
                      />
                    </div>
                    {/* Type */}
                    <div className="form-group col-md-4">
                      <label htmlFor="type">
                        Type <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        id="type"
                        name="type"
                        className="form-control"
                      >
                        <option value="amount">Amount</option>
                        <option value="percentage">Percentage</option>
                      </Field>
                      <ErrorMessage
                        name="type"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    {/* Amount */}
                    <div className="form-group col-md-4">
                      <label htmlFor="amount">
                        Amount <span className="mandatory">*</span>
                      </label>
                      <Field
                        type="number"
                        id="amount"
                        name="amount"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="text-error"
                      />
                    </div>

                    {/* Date */}
                    <div className="form-group col-md-4">
                      <label htmlFor="date">
                        Date <span className="mandatory">*</span>
                      </label>
                      <Field
                        type="date"
                        id="date"
                        name="date"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="date"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>

                  <div className="text-right">
                    <button type="submit" className="btn btn-primary ">
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default ClassWiseConcession;
