import React from "react";
import { useState, useEffect } from "react";
import { useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FieldArray } from "formik";
import Header from "../CommonPages/Header";
import * as Yup from "yup";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { PrimeIcons } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import SchoolGrid from "../AdminPages/SchoolGrid";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import { Button } from "@mui/icons-material";
import { Card, CardContent } from "@mui/icons-material";
import { Plus, Minus } from "@mui/icons-material";
import { Input } from "@mui/material";
import { Label } from "@mui/icons-material";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from 'sweetalert2';
import { useParams } from "react-router-dom";
import { useData } from '../../Utility/CommonUtility/DataContext';
import { getAllLanguages } from "../../Services/AdminApis/LanguagesApi";
import {
  createAnnouncement,
  getAllAnnouncements,
  getAnnouncementById,
  updateAnnouncementById,
} from "../../Services/CommonApis/AnnouncementApi";
import { getAllAcademics } from "../../Services/AdminApis/AcademicApi";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import AnnouncementGrid from "./AnnouncementGrid";
const Announcement = () => {
  const [activeTab, setActiveTab] = useState("announcements");
  const [apiMessage, setApiMessage] = useState("");
  const [classes, setClasses] = useState([]);
  const [showannouncementForm, setShowannouncementForm] = useState(false);
  const [showeditannouncementForm, setEditShowannouncementForm] =
    useState(false);

  const [formData, setFormData] = useState(null);
  const [academics, setAcademics] = useState([]);

  const [showForm, setShowForm] = useState(false);
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const { academic } = useData();
  const { branchId } = useParams();
  const academicId = academic.length > 0 ? academic[0]?.id : null;
  const { id } = useParams();

  const fetchAnnouncementById = async (id) => {
    try {
      const response = await getAnnouncementById(id);
      if (response.status === 200) {
        const announcementData = response.data.data;
        const latestData = await getAllAnnouncements(branchId, academicId);
        const updatedAnnouncement = latestData.data.data.find(item => item.id === id);
        setFormData(updatedAnnouncement || announcementData);
        setEditShowannouncementForm(true);
      } else {
        console.error("Failed to fetch announcement data");
      }
    } catch (error) {
      console.error("Error fetching announcement by ID:", error);
    }
  };
  useEffect(() => {
    Academics();
    getclassbybranchID(branchId);
  }, []);
  const Academics = async () => {
    const response = await getAllAcademics();
    setAcademics(response.data.data);
  };
  const getclassbybranchID = async (branchId) => {
    console.log(branchId);
    try {
      const response = await getClassByBranchId(branchId);
      const formattedClasses = response.data.classes.map((cls) => ({
        id: cls.id.toString(),
        name: cls.name
      }));
      setClasses(formattedClasses);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (activeTab === "newannouncements") {
      tableInstance.current = $(tableRef.current).DataTable({
        destroy: true,
        columns: [
          { data: "id" },
          { data: "name" },
          { data: "academic_years" },
          { data: "application_fee" },
          { data: "start_date" },
          { data: "end_date" },
          { data: "last_submission_date" },
          {
            data: "class_name",
            render: (data, type, row) => {
              const classIds = row.class_id.split(",").map((id) => id.trim());
              return classIds
                .map((id, index) => {
                  const classNameEntry = data[index] ? data[index][0] : "";
                  const [className] = classNameEntry.split(":");
                  return `<div>${className}</div>`;
                })
                .join("");
            },
          },
          { data: "admission_fees" },
          { data: "quota" },
          { data: "seats_available" },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data, type, row) => {
              const editIcon = `
                <a title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
              `;
              return `
          <div class="icon-group d-flex justify-content-center align-items-center" style="height: 100%; display: flex;">
            ${editIcon}
          </div>
              `;
            },
          },
        ],
        lengthChange: true,
        order: true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          { extend: "copy", exportOptions: { columns: ":visible" } },
          { extend: "csv", exportOptions: { columns: ":visible" } },
          { extend: "excel", exportOptions: { columns: ":visible" } },
          { extend: "pdf", exportOptions: { columns: ":visible" } },
          { extend: "print", exportOptions: { columns: ":visible" } },
        ],
        serverSide: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 75, 100, -1],
          [10, 25, 50, 75, 100, "All"],
        ],
        processing: true,
        language: {
          paginate: {
            previous: "Back",
            next: "Next",
            first: "First",
            last: "Last",
          },
        },
        ajax: async function (data, callback) {
          try {
            const start = data.start || 0;
            const length = data.length || 10;
            const page = Math.floor(start / length) + 1;
            const orderColumnIndex =
              data.order && data.order.length > 0 ? data.order[0].column : 0;
            const orderDirection =
              data.order && data.order.length > 0 ? data.order[0].dir : "desc";
            const orderColumn = data.columns[orderColumnIndex].data;
            const searchValue = data.search.value;

            if (branchId && academicId) {
              const response = await getAllAnnouncements(branchId, academicId,
                {
                  page,
                  pageSize: length,
                  sortField: orderColumn,
                  sortDirection: orderDirection,
                  search: searchValue,
                });
              const jsonData = response.data.data
              const totalCount = response.data.total || 0;
              callback({
                draw: data.draw,
                recordsTotal: totalCount,
                recordsFiltered: totalCount,
                data: jsonData,
              });
            }
          } catch (error) {
            console.error("Error fetching data:", error);
            showErrorAlert(error.response.data.message);
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }
        },
      });
      $(tableRef.current).on("click", ".edit-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
        fetchAnnouncementById(id);
      });
      return () => {
        if (tableInstance.current) {
          tableInstance.current.destroy();
        }
      };
    }
  }, [activeTab, branchId, academicId]);
  const showSuccessAlert = (message, callback) => {
    Swal.fire({
      title: 'Success',
      text: message,
      icon: 'success',
      confirmButtonText: 'OK',
    }).then((result) => {
      if (result.isConfirmed && callback) {
        callback();
      }
    });
  };
  const showErrorAlert = (errorMessage) => {
    Swal.fire({
      title: 'Error',
      text: errorMessage,
      icon: 'error',
      confirmButtonText: 'OK',
    });
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    const formatDate = (date) => {
      const d = new Date(date);
      return `${d.getDate().toString().padStart(2, "0")}/${(d.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${d.getFullYear()}`;
    };

    const id = values.id;
    const formattedValues = {
      ...values,
      branch_id: branchId,
      start_date: formatDate(values.start_date),
      end_date: formatDate(values.end_date),
      last_submission_date: formatDate(values.last_submission_date),
    };

    Swal.fire({
      title: id
        ? "Are you sure?"
        : "Are you sure?",
      text: id
        ? "Do you really want to Update this announcement?"
        : "Do you really want to Create this announcement?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: id ? "Yes, Update It!" : "Yes, Create It!",
      cancelButtonText: "No, Cancel!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (id) {
            response = await updateAnnouncementById(id, formattedValues);
          } else {
            response = await createAnnouncement(formattedValues);
          }

          if (response.status === 200 || response.status === 201) {
            setShowannouncementForm(false);
            tableInstance.current.ajax.reload(null, false);

            Swal.fire({
              title: id
                ? "Updated Successfully"
                : "Created Successfully",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              window.location.reload();
            });
          } else {
            const errorMessage =
              response.data.message ||
              "Failed to create/update the announcement. Please try again.";
            showErrorAlert("error", errorMessage);
          }
        } catch (error) {
          const errorMessage =
            error.response?.data?.message ||
            "An error occurred during announcement update.";
          showErrorAlert(errorMessage);
        }
      }
    });
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Announcement Name is required"),
    academic_year_id: Yup.string().required("Academic Year is required"),
    application_fee: Yup.number()
      .typeError("Application Fee must be a number")
      .required("Application Fee is required")
      .positive("Application Fee must be positive"),
    start_date: Yup.date()
      .required("Start Date is required")
      .typeError("Invalid date format"),
    end_date: Yup.date()
      .required("End Date is required")
      .min(Yup.ref("start_date"), "End Date must be after Start Date")
      .typeError("Invalid date format"),
    last_submission_date: Yup.date()
      .required("Last Submission Date is required")
      .max(Yup.ref("end_date"), "Submission Date must be before End Date")
      .typeError("Invalid date format"),
    announcement_data: Yup.array().of(
      Yup.object().shape({
        class: Yup.string().required(" Fields is required"),
        quota: Yup.string().required(" Fields is required"),
        seats_available: Yup.number().required(" Fields is required"),
        admission_fee: Yup.number().required(" Fields is required")
      })
    ).min(1, "At least one seat allocation is required"),
  });
  const handleFocus = (event) => {
    if (event.target.type === "date") {
      event.target.showPicker();
    }
  };

  const handleDateClick = (event) => {
    if (event.target.type === "date") {
      event.target.focus();
    }
  };
  return (
    <>
      <div className="custom-tabs-container">
        <ul className="custom-nav-tabs container" id="myTab" role="tablist">
          <li className="custom-nav-item">
            <a
              className={`custom-nav-link ${activeTab === "announcements" ? "active" : ""
                }`}
              id="announcements-tab"
              data-toggle="tab"
              href="#announcements"
              role="tab"
              aria-controls="announcements"
              aria-selected={activeTab === "announcements"}
              onClick={() => setActiveTab("announcements")}
            >
              <i className="fas fa-book"></i> Announcements
            </a>
          </li>
          <li className="custom-nav-item">
            <a
              className={`custom-nav-link ${activeTab === "newannouncements" ? "active" : ""
                }`}
              id="newannouncements-tab"
              data-toggle="tab"
              href="#newannouncements"
              role="tab"
              aria-controls="newannouncements"
              aria-selected={activeTab === "newannouncements"}
              onClick={() => setActiveTab("newannouncements")}
            >
              <i className="fas fa-book"></i> New Announcements
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-contentt">
        {activeTab === "announcements" && (
          <div id="announcements-tab" className="tab-pane fade show active">
            <div className="d-flex justify-content-between ml-3 mt-4 mb-3">
              <h5>Announcements </h5>
            </div>
            <AnnouncementGrid />
          </div>
        )}
      </div>

      <div className="tab-contentt">
        {activeTab === "newannouncements" && (
          <div id="newannouncements-tab" className="tab-pane fade show active">
            <div className="card ml-3 mr-3 mt-3">
              <div className="d-flex justify-content-between ml-3 mt-4 mb-3">
                <button
                  className="btn btn-primary mr-4"
                  onClick={() => {
                    if (showeditannouncementForm || showannouncementForm) {
                      setShowannouncementForm(false);
                      setEditShowannouncementForm(false);
                      setFormData(null);
                    } else {
                      setShowannouncementForm(true);
                      setFormData(null);
                    }
                  }}
                >
                  {showeditannouncementForm || showannouncementForm ? (
                    <>
                      <i className="fas fa-arrow-left mr-2"></i> Back
                    </>
                  ) : (
                    "+New Announcement"
                  )}
                </button>
              </div>

              {showeditannouncementForm && (
                <div className="card ml-3 mr-3">
                  <div className="mt-3 ml-3 mb-3">
                    <h5>Announcement Updation Form</h5>

                    <Formik
                      initialValues={
                        formData || {
                          name: "",
                          academic_year_id: "",
                          application_fee: "",
                          start_date: "",
                          end_date: "",
                          last_submission_date: "",
                        }
                      }
                      enableReinitialize={true}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ values, handleChange, isSubmitting }) => (
                        <Form>
                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-4">
                              <div className="form-group">
                                <label htmlFor="name" className="form-label">
                                  Announcement Name{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="name"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="academic_year_id"
                                  className="form-label"
                                >
                                  Academic Year{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  name="academic_year_id"
                                  as="select"
                                  className="form-control"
                                  style={{ borderColor: "skyblue" }}
                                >
                                  <option value="">Select Academic Year</option>
                                  {academics.map((year) => {
                                    const startDate = new Date(year.start_date);
                                    const endDate = new Date(year.end_date);

                                    const startMonthYear =
                                      startDate.toLocaleString("default", {
                                        month: "short",
                                        year: "numeric",
                                      });
                                    const endMonthYear = endDate.toLocaleString(
                                      "default",
                                      {
                                        month: "short",
                                        year: "numeric",
                                      }
                                    );

                                    const formattedDateRange = `${startMonthYear} - ${endMonthYear}`;

                                    return (
                                      <option key={year.id} value={year.id}>
                                        {formattedDateRange}
                                      </option>
                                    );
                                  })}
                                </Field>
                                <ErrorMessage
                                  name="academic_year_id"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="application_fee"
                                  className="form-label"
                                >
                                  Application Fees{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="application_fee"
                                  className="form-control"
                                  placeholder="Application fee"
                                />
                                <ErrorMessage
                                  name="application_fee"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="start_date"
                                  className="form-label"
                                >
                                  Start Date{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="start_date"
                                  className="form-control"
                                  onFocus={handleFocus}
                                  onClick={handleDateClick}
                                />
                                <ErrorMessage
                                  name="start_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="end_date"
                                  className="form-label"
                                >
                                  End Date <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="end_date"
                                  className="form-control"
                                  onFocus={handleFocus}
                                  onClick={handleDateClick}
                                />
                                <ErrorMessage
                                  name="end_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="last_submission_date"
                                  className="form-label"
                                >
                                  Last Date of Submission{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="last_submission_date"
                                  className="form-control"
                                  onFocus={handleFocus}
                                  onClick={handleDateClick}
                                />
                                <ErrorMessage
                                  name="last_submission_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-12 text-right">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
              {showannouncementForm && (
                <div className="card ml-3 mr-3">
                  <div className="mt-3 ml-3 mb-3">
                    <h5>Announcement Creation Form</h5>

                    <Formik
                      initialValues={
                        formData || {
                          name: "",
                          academic_year_id: "",
                          application_fee: "",
                          start_date: "",
                          end_date: "",
                          last_submission_date: "",
                          announcement_data: [
                            {
                              class: "",
                              quota: "",
                              seats_available: "",
                              exam_required: "no",
                              admission_fees: "",
                            },
                          ],
                        }
                      }
                      enableReinitialize={true}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ values, handleChange, isSubmitting }) => (
                        <Form>
                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-4">
                              <div className="form-group">
                                <label htmlFor="name" className="form-label">
                                  Announcement Name{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="name"
                                  className="form-control"
                                  placeholder="Announcement Name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="academic_year_id"
                                  className="form-label"
                                >
                                  Academic Year{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  name="academic_year_id"
                                  as="select"
                                  className="form-control"
                                  style={{ borderColor: "skyblue" }}
                                >
                                  <option value="">Select Academic Year</option>
                                  {academics.map((year) => {
                                    const startDate = new Date(year.start_date);
                                    const endDate = new Date(year.end_date);
                                    const startMonthYear =
                                      startDate.toLocaleString("default", {
                                        month: "short",
                                        year: "numeric",
                                      });
                                    const endMonthYear = endDate.toLocaleString(
                                      "default",
                                      {
                                        month: "short",
                                        year: "numeric",
                                      }
                                    );

                                    const formattedDateRange = `${startMonthYear} - ${endMonthYear}`;

                                    return (
                                      <option key={year.id} value={year.id}>
                                        {formattedDateRange}
                                      </option>
                                    );
                                  })}
                                </Field>
                                <ErrorMessage
                                  name="academic_year_id"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="application_fee"
                                  className="form-label"
                                >
                                  Application Fees{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="number"
                                  name="application_fee"
                                  className="form-control"
                                  placeholder="Application fee"
                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                  onKeyPress={(e) => {
                                    if (
                                      !/[0-9]/.test(e.key) &&
                                      e.key !== "Backspace"
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  name="application_fee"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="start_date"
                                  className="form-label"
                                >
                                  Start Date{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="start_date"
                                  min={new Date().toISOString().split("T")[0]}
                                  className="form-control"
                                  onKeyDown={(e) => e.preventDefault()}
                                  onFocus={handleFocus}
                                  onClick={handleDateClick}
                                />
                                <ErrorMessage
                                  name="start_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="end_date"
                                  className="form-label"
                                >
                                  End Date <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="end_date"
                                  min={new Date().toISOString().split("T")[0]}
                                  className="form-control"
                                  onKeyDown={(e) => e.preventDefault()}
                                  onFocus={handleFocus}
                                  onClick={handleDateClick}
                                />
                                <ErrorMessage
                                  name="end_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <label
                                  htmlFor="last_submission_date"
                                  className="form-label"
                                >
                                  Last Date of Submission{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <Field
                                  type="date"
                                  name="last_submission_date"
                                  min={new Date().toISOString().split("T")[0]}
                                  className="form-control"
                                  onKeyDown={(e) => e.preventDefault()}
                                  onFocus={handleFocus}
                                  onClick={handleDateClick}
                                />
                                <ErrorMessage
                                  name="last_submission_date"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                          </div>
                          {/* Seat Allocation Section */}
                          <div className="mt-3 mb-3">
                            <h5>Seat Allocation</h5>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Class <span className="mandatory">*</span></th>
                                  <th>Quota <span className="mandatory">*</span></th>
                                  <th>Admission Fee <span className="mandatory">*</span></th>
                                  <th>Seats Available <span className="mandatory">*</span></th>
                                  <th>Exam Required</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <FieldArray name="announcement_data">
                                  {({ remove, push }) => (
                                    <>
                                      {values.announcement_data && values.announcement_data.length > 0 ? (
                                        values.announcement_data.map((seat, index) => (
                                          <tr key={index}>
                                            <td>
                                              <Field
                                                as="select"
                                                name={`announcement_data.${index}.class`}
                                                className="form-control"
                                              >
                                                <option value="">Select Class</option>
                                                {classes
                                                  .filter(
                                                    (classItem) =>
                                                      !values.announcement_data
                                                        .filter((_, i) => i !== index)
                                                        .map((item) => item.class)
                                                        .includes(classItem.id)
                                                  )
                                                  .map((classItem) => (
                                                    <option key={classItem.id} value={classItem.id}>
                                                      {classItem.name}
                                                    </option>
                                                  ))}
                                              </Field>
                                              <ErrorMessage
                                                name={`announcement_data.${index}.class`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                as="select"
                                                name={`announcement_data.${index}.quota`}
                                                className="form-control"
                                              >
                                                <option value="">Select Quota</option>
                                                <option value="general">General</option>
                                                <option value="special">Special</option>
                                              </Field>
                                              <ErrorMessage
                                                name={`announcement_data.${index}.quota`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                type="number"
                                                name={`announcement_data.${index}.admission_fee`}
                                                className="form-control"
                                                placeholder="Admission fee"
                                              />
                                              <ErrorMessage
                                                name={`announcement_data.${index}.admission_fee`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                type="number"
                                                name={`announcement_data.${index}.seats_available`}
                                                className="form-control"
                                                placeholder="No of seats"
                                              />
                                              <ErrorMessage
                                                name={`announcement_data.${index}.seats_available`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </td>
                                            <td>
                                              <div role="group" aria-labelledby="exam_required" className="d-flex">
                                                <div className="form-check form-check-inline">
                                                  <Field
                                                    type="radio"
                                                    name={`announcement_data.${index}.exam_required`}
                                                    value="no"
                                                    className="form-check-input"
                                                  />
                                                  <label className="form-check-label">No</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                  <Field
                                                    type="radio"
                                                    name={`announcement_data.${index}.exam_required`}
                                                    value="yes"
                                                    className="form-check-input"
                                                  />
                                                  <label className="form-check-label">Yes</label>
                                                </div>
                                              </div>
                                              <ErrorMessage
                                                name={`announcement_data.${index}.exam_required`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </td>
                                            <td>
                                              {index === (values.announcement_data?.length || 0) - 1 ? (
                                                <button
                                                  type="button"
                                                  className="btn btn-primary btn-sm"
                                                  onClick={() => push({
                                                    class: "",
                                                    quota: "",
                                                    seats_available: "",
                                                    admission_fee: "",
                                                    exam_required: "no",
                                                  })}
                                                >
                                                  +
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn btn-danger btn-sm"
                                                  onClick={() => remove(index)}
                                                >
                                                  -
                                                </button>
                                              )}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td colSpan={6}>No seat allocations added yet.</td>
                                        </tr>
                                      )}
                                    </>
                                  )}
                                </FieldArray>
                              </tbody>
                            </table>
                          </div>

                          <div className="row ml-3 mr-5 mt-3">
                            <div className="col-12 text-right">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
              <div className="mt-3">
                <div className="card-body" >
                  <table
                    ref={tableRef}
                    id="announcementTable"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Year</th>
                        <th>Fees</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Submission Date</th>
                        <th>Class</th>
                        <th>Admission Fees</th>
                        <th>Quota</th>
                        <th>No Seats</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                    <tfoot>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Year</th>
                        <th>Fees</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Submission Date</th>
                        <th>Class</th>
                        <th>Admission Fees</th>
                        <th>Quota</th>
                        <th>No Seats</th>
                        <th>Actions</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Announcement;
