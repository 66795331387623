import * as XLSX from "xlsx";

const handleDownloadStaffExcel = () => {
  const fileName = "staff.xlsx";
  const headers = [
    "First Name",
    "Last Name",
    "Email",
    "DOB",
    "Role",
    "Gender",
    "Aadhaar Card",
    "Pan Card",
    "Employee No",
    "Joining date",
    "Address",
  ];

  const data = [
    [
      "Aarav",
      "Sharma",
      "aarav.sharma@example.com",
      "15.05.2010",
      "Teaching",
      "Male",
      "123456789012",
      "DFHPM2771H",
      "001",
      "15.05.2020",
      "123, Marine Drive, Mumbai, Maharashtra, 400001, India",
    ],
    [
      "Anaya",
      "Verma",
      "anaya.verma@example.com",
      "15.05.2000",
      "Management",
      "Female",
      "567891012345",
      "DFHPM2771V",
      "002",
      "15.07.2021",
      "45, Connaught Place, Delhi, 110001, India",
    ],
    [
      "Ishaan",
      "Patel",
      "ishaan.patel@example.com",
      "15.08.2020",
      "Admin",
      "Male",
      "789012345678",
      "DFHPM2771M",
      "003",
      "19.05.2020",
      "23, SG Highway, Ahmedabad, Gujarat, 380015, India",
    ],
  ];

  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

  const headerRange = XLSX.utils.decode_range(worksheet["!ref"]);
  for (let C = headerRange.s.c; C <= headerRange.e.c; C++) {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
    if (worksheet[cellAddress]) {
      worksheet[cellAddress].s = {
        font: { bold: true },
      };
    }
  }

  worksheet["!cols"] = headers.map(() => ({ width: 20 })); // Adjust column width if needed

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Schools");

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
    cellStyles: true,
  });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export default handleDownloadStaffExcel;
