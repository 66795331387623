
export const setSession = (key, token) => {
  const item = {
    value: token,
  };
  sessionStorage.setItem(key, JSON.stringify(item));
};

export const getSession = (key) => {
  const itemStr = sessionStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date().getTime();
  if (now > item.expiry) {
    sessionStorage.removeItem(key);
    return undefined;
  }
  return item.value; 
};

export const removeSession = (key) => {
  sessionStorage.removeItem(key);
};

export const removeAllSession = () => {
  sessionStorage.clear()
}

export const getCookie = (name) =>{
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if(parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

export const setCookie = (name, value, days) =>{
  const expires = days ? `; expires=${new Date(Date.now() + days * 864e5).toUTCString()}` : '';
  document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/`;
}