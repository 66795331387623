import React from "react";
import { useLocation } from "react-router-dom";
import SchoolSideBar from "./SchoolSideBar";
import AdminSideBar from "../AdminPages/AdminSideBar";
import ParentSideBar from "../AdminPages/ParentSideBar";
import SchoolManagerSidebar from "../AdminPages/SchoolManagerSidebar";
import SchoolManagerAdminSidebar from "../AdminPages/SchoolManagerAdminSidebar";
import StaffSidebar from "../AdminPages/StaffSidebar";

const LeftSidebar = () => {
  const location = useLocation();
  const base = location.pathname.split("/")[1];

  const renderSidebar = () => {
    switch (base) {
      case "school":
        return <SchoolSideBar />;
      case "parent":
        return <ParentSideBar />;
      case "school-manager":
        return <SchoolManagerSidebar />;
      case "manager-dashboard":
        return <SchoolManagerAdminSidebar />;
      case "teaching-staff":
        return <StaffSidebar />;
      default:
        return <AdminSideBar />;
    }
  };

  return <>{renderSidebar()}</>;
};
export default LeftSidebar;
