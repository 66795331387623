import React, { useState, useEffect } from "react";
import { Routes, Route, json, useNavigate } from "react-router-dom";
import { getSession } from "../../Services/Storage";
import { COOKIE } from "../../Schemas/cookieNames";
import ScaleLoader from "react-spinners/ScaleLoader";
import Header from "../CommonPages/Header";
import Footer from "../CommonPages/Footer";
import LeftSidebar from "../CommonPages/LeftSidebar";
import RightSidebar from "../CommonPages/RightSidebar";
import Dashboard from "../CommonPages/Dashboard";
import SchoolCreate from "../AdminPages/SchoolCreate";
import PageTitle from "../../Utility/CommonUtility/PageTitle";
import SchoolList from "../AdminPages/SchoolList";
import SchoolUpload from "../AdminPages/SchoolUpload";
import BranchList from "../CommonPages/BranchList";
import SchoolView from "../AdminPages/SchoolView";
import AcademicDetails from "../AdminPages/AcademicCreate";
import LanguageForm from "../AdminPages/Language";
import BranchCreate from "../CommonPages/BranchCreate";
import BranchEdit from "../CommonPages/BranchEdit";
import BranchUpload from "../CommonPages/BranchUpload";
import StaffCreate from "../CommonPages/StaffCreate";
import SectionCreate from "./SectionCreate";
import StudentCreate from "./StudentCreate";
import Department from "../AdminPages/Department";
import DepartmentList from "../AdminPages/DepartmentList";
import AcademicList from "../AdminPages/AcademicDetailsList";
import Qualification from "../AdminPages/Qualification";
import QualificationList from "../AdminPages/QualificationList";
import ExamGradeConfiguration from "../../Components/CommonComponents/ExamGradeConfiguration";
import ExamConfiguration from "../../Components/CommonComponents/ExamConfiguration";
import StudentList from "../CommonPages/StudentList";
import SchoolDashboard from "../SchoolPages/SchoolDashboard";
import StudentManagement from "../AdminPages/StudentManagement";
import ClassManagement from "../AdminPages/ClassManagement";
import SectionManagement from "../AdminPages/SectionManagement";
import SubjectManagement from "../AdminPages/SubjectManagement";
import StaffManagement from "../AdminPages/StaffManagement";
import { setGlobalLoading } from "../../Services/Api";
import PageNotFound from "../../Components/CommonComponents/PageNotFound";
import ProtectedRoute from "../../Utility/CommonUtility/Protectedroute";
import AdminIndividualDashboard from "../AdminPages/AdminIndividualDashboard";
import HomeworkCreateTab from "../AdminPages/HomeCreateTab";
import HomeworkManagement from "../AdminPages/HomeworkManagement";
import AttendanceCreate from "../AdminPages/AttendanceCreate";
import SubjectList from "../AdminPages/SubjectList";
import LanguageList from "../AdminPages/LanguageList";
import ParentManagement from "./ParentManagement";
import BranchSubjectCreate from "./BranchSubjectCreate";
import ParentCreate from "./ParentCreate";
import CreateClass from "./CreateClass";
import BranchSubjectList from "./BranchSubjectList";
import AdmissionManagement from "../AdminPages/Admissionmanagement";
import GroupList from "../AdminPages/GroupList";
import MediumList from "../AdminPages/MediumList";
import HolidayList from "./HolidayNotification";
import ShiftAssignment from "./ShiftNotifications";
import GroupNotifications from "../AdminPages/GroupNotifications";
import CertificateConfiguration from "../AdminPages/CertificateConfiguration";
import GenerateCertificate from "../AdminPages/GenerateCertificate";
import CertificateFieldList from "../AdminPages/CertificateFieldList";
import AcademicFeeCreate from "./AcademicFeeCreate";
import AcademicFeePayments from "./AcademicFeePayments";
import ParentDashboard from "./ParentDashboard";
import StudentProfile from "./StudentProfile";
import AttendanceManagement from "./AttendanceManagement";
import StudentLeaveList from "./StudentLeaveList";
import ConcernsList from "./ConcernsList";
import FeedbackComponent from "./FeedbackList";
import TCRequest from "../AdminPages/TCRequest";
import FeesList from "../AdminPages/FeesList";
import FeesTimeLines from "../AdminPages/FeesTimeLines";
import FeePayTypes from "../AdminPages/FeePayTypes";
import FeesDiscount from "../AdminPages/FeesDiscount";
import FeeReceipt from "../../Components/CommonComponents/FeeReciept";
import ParentFeeManagement from "./ParentFeeManagement";
import { DataProvider } from "../../Utility/CommonUtility/DataContext";
import Remark from "../CommonPages/Remark";
import ReportcardConfiguration from "../AdminPages/Reportcardconfiguration";
import EditExamConfig from "../AdminPages/EditExamConfig";
import ExamGradeLock from "../CommonPages/ExamReportCardLock";
import AcademicFeeList from "./AcademicFeeList";
import AcademicFeeEdit from "./AcademicFeeEdit";
import SubjectWiseMarksEntry from "./SubjectWiseMarksEntry";
import DownloadFeePdf from "./DownloadPdf";
import AcademicTransaction from "./AcademicTransaction";
import FeesDashboard from "./FeesDashboard";
import Idcardlist from "./Idcardcreate";
import ReportCardManagement from "../AdminPages/ReportcardManagement";
import ReportCardDashboard from "./ReportCardDashboard";
import StoppagesList from "../AdminPages/StoppagesList";
import Report from "./Report";
import PersonalityTraits from "../AdminPages/PersonalityTraits";
import TransportRoutes from "./TransportRoutes";
import TransportDetails from "./TransportDetails";
import TransportManagement from "../AdminPages/TransportManagement";
import Transport from "./CreateStoppages";
import Stoppagesedit from "./Stoppagesedit";
import TransportVehiclesDetails from "./TransportVehiclesDetails";
import TransportPilotDetails from "./TransportPilotDetails";
import ViewMarksEntry from "../AdminPages/ViewMarksEntry";
import GeneratedcertificateList from "../AdminPages/GeneratedCertificatesList";
import ParentTransportList from "./ParentTransportList";
import TransportRouteManagement from "./TransportManagement";
import TransportDetailsManagement from "./TransportDetailsManagement";
import OverallReport from "../AdminPages/OverallReport";
import LibraryManagement from "./LibraryManagement";
import AdminLibraryManagement from "../AdminPages/AdminLibraryManagement";
import HolidayCarousel from "./HolidayNotification";
import JobDetails from "../AdminPages/JobDetails";
import JobNotificationDetails from "../AdminPages/JobNotificationDetails";
import SyllabusBook from "./SyllabusBook";
import SyllabusView from "./SyllabusView";
import HolidayManagement from "./HolidayManagement";
import FeeSubTypes from "../AdminPages/FeeSubTypes";
import AdminFeeManagement from "../AdminPages/AdminFeeManagement";
import ClassWiseConcession from "../AdminPages/ClasswiseConcession";
import LeaveApproval from "./LeaveApproval";
import PayStudentFees from "../AdminPages/PayStudentFees";
import FeeSetupManagement from "../AdminPages/FeeSetupManagement";
import LibraryIssueBook from "./LibraryBookIssueList";
import LibraryBookIssueManagement from "./LibraryBookIssueManagement";
import ReturnBookList from "./ReturnBookList";
import SchoolManagerList from "../AdminPages/SchoolManagerList";
import SchoolManager from "../AdminPages/SchoolManagers";
import FeeReportManagement from "../AdminPages/FeeReportManagement";
import EventNotificationList from "./EventNotificationList";
import EventNotificationManagement from "../AdminPages/EventNotificationManagement";
import StudentListProfile from "../AdminPages/StudentProfileAdmin";
import ActivityLog from "../AdminPages/ActivityLog";
import AssestManagementTab from "./AssestmanagemnetTab";
import InventoryManagement from "./InventoryManagement";
import BlockedIP from "../AdminPages/BlockedIP";
import PickUpRequest from "../AdminPages/PIckUpRequest";
import PickUpRequestList from "../AdminPages/PickUpRequestList";
import ParentTransportManagement from "../AdminPages/ParentTransportManagement";
import AdminPickUpRequestList from "../AdminPages/AdminPickUpRequestList";
import UserVisits from "../AdminPages/UserVisits";
const MainPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate("/admin/dashboard");
    }
  }, [navigate]);
  useEffect(() => {
    setGlobalLoading(setLoading);
  }, []);
  return (
    <DataProvider>
      <body className="hold-transition sidebar-mini">
        <div class="wrapper">
          <Header />
          <LeftSidebar />
          <div className="content-wrapper">
            {loading && (
              <div className="loader-container">
                <ScaleLoader color="#007bff" loading={loading} size={50} />
              </div>
            )}
            <div className={loading ? "blurred-content" : ""}>
              <PageTitle />
              <Routes>
                <Route element={<ProtectedRoute allowedRoles={[1]} />}>
                  <Route
                    path="/admin/*"
                    element={
                      <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/school-list" element={<SchoolList />} />
                        <Route
                          path="/create-stoppages"
                          element={<Transport />}
                        />
                        <Route
                          path="/edit-stoppages/:id"
                          element={<Stoppagesedit />}
                        />

                        <Route
                          path="/stoppages-list"
                          element={<StoppagesList />}
                        />
                        
                        <Route
                          path="/uservisits"
                          element={<UserVisits />}
                        />
                        <Route
                          path="/school-create"
                          element={<SchoolCreate />}
                        />
                        <Route
                          path="/school-upload"
                          element={<SchoolUpload />}
                        />
                        <Route
                          path="/manager-list"
                          element={<SchoolManagerList />}
                        />
                        <Route
                          path="/manager-create"
                          element={<SchoolManager />}
                        />
                        <Route
                          path="/manager-edit/:user_id"
                          element={<SchoolManager />}
                        />
                        <Route
                          path="/school-view/:id"
                          element={<SchoolView />}
                        />
                        <Route
                          path="/school/:id/branch-list"
                          element={<BranchList />}
                        />

                        <Route
                          path="/school/:id/branch-create"
                          element={<BranchCreate />}
                        />
                        <Route
                          path="/school/:id/branch-upload"
                          element={<BranchUpload />}
                        />
                        <Route
                          path="/school/:id/branch-edit/:branchId"
                          element={<BranchEdit />}
                        />

                        <Route
                          path="/academic-create"
                          element={<AcademicDetails />}
                        />
                        <Route
                          path="/academic-edit/:id"
                          element={<AcademicDetails />}
                        />
                        <Route
                          path="/academic-list"
                          element={<AcademicList />}
                        />
                        <Route
                          path="/language-list"
                          element={<LanguageList />}
                        />
                        <Route
                          path="/language-create"
                          element={<LanguageForm />}
                        />
                        <Route
                          path="/language-edit/:id"
                          element={<LanguageForm />}
                        />

                        <Route
                          path="/school-edit/:id"
                          element={<SchoolCreate />}
                        />

                        <Route
                          path="/attendance"
                          element={<AttendanceCreate />}
                        />
                        <Route path="/leave-list" element={<LeaveApproval />} />
                        <Route
                          path="/school-delete/:id"
                          element={<SchoolList />}
                        />
                        <Route
                          path="/department-create"
                          element={<Department />}
                        />
                        <Route
                          path="/department-list"
                          element={<DepartmentList />}
                        />
                        <Route
                          path="/department-edit/:id"
                          element={<Department />}
                        />
                        <Route
                          path="/department-delete/:id"
                          element={<DepartmentList />}
                        />
                        <Route
                          path="/qualification-create"
                          element={<Qualification />}
                        />
                        <Route
                          path="/qualification-list"
                          element={<QualificationList />}
                        />

                        <Route
                          path="/qualification-edit/:id"
                          element={<Qualification />}
                        />
                        <Route
                          path="/individual-dashboard"
                          element={<AdminIndividualDashboard />}
                        />
                        <Route
                          path="/admin-dashboard"
                          element={<AdminIndividualDashboard />}
                        />
                        <Route path="/subject-list" element={<SubjectList />} />
                        <Route path="/group-list" element={<GroupList />} />
                        <Route path="/medium-list" element={<MediumList />} />
                        <Route path="/id-card" element={<Idcardlist />} />
                        <Route path="/activity-log" element={<ActivityLog />} />
                        <Route path="/blocked-ip" element={<BlockedIP />} />
                        <Route
                          path="/fees-timelines"
                          element={<FeesTimeLines />}
                        />
                        <Route path="/job-details" element={<JobDetails />} />
                        <Route
                          path="/job-notification-details"
                          element={<JobNotificationDetails />}
                        />
                        <Route
                          path="/exam-setup"
                          element={<ExamConfiguration />}
                        />
                        <Route
                          path="/exam-grade-setup"
                          element={<ExamGradeConfiguration />}
                        />
                        <Route
                          path="/fees-paytypes"
                          element={<FeePayTypes />}
                        />
                        <Route path="/remarks" element={<Remark />} />
                        <Route
                          path="/exam-report-lock"
                          element={<ExamGradeLock />}
                        />
                        <Route
                          path="/fees-dashboard"
                          element={<FeesDashboard />}
                        />
                        <Route
                          path="/reportcard-dashboard"
                          element={<ReportCardDashboard />}
                        />
                        <Route
                          path="/certificate-list"
                          element={<CertificateConfiguration />}
                        />
                        <Route
                          path="/certificate-field-list"
                          element={<CertificateFieldList />}
                        />
                        <Route
                          path="/library"
                          element={<AdminLibraryManagement />}
                        />
                        <Route
                          path="/library-book-issue"
                          element={<LibraryBookIssueManagement />}
                        />
                        <Route path="*" element={<PageNotFound />} />
                      </Routes>
                    }
                  />
                </Route>
                <Route element={<ProtectedRoute allowedRoles={[2, 3]} />}>
                  <Route
                    path="/school/:schoolId/branch/:branchId/*"
                    element={
                      <Routes>
                        <Route path="/download" element={<DownloadFeePdf />} />
                        <Route
                          path="/fee-reciept/:id"
                          element={<FeeReceipt />}
                        />
                        <Route
                          path="/create-classwise-concession"
                          element={<ClassWiseConcession />}
                        />

                        <Route
                          path="/staff-edit/:id"
                          element={<StaffCreate />}
                        />
                        <Route path="/report" element={<Report />} />
                        <Route
                          path="/exam-report-lock"
                          element={<ExamGradeLock />}
                        />
                        <Route
                          path="/homework-create"
                          element={<HomeworkCreateTab />}
                        />
                        <Route
                          path="/attendance"
                          element={<AttendanceCreate />}
                        />
                        <Route path="/leave-list" element={<LeaveApproval />} />
                        <Route
                          path="/view-marks-entry/:id"
                          element={<ViewMarksEntry />}
                        />
                        <Route
                          path="/pay-fees/:clsid/:id/:fcid"
                          element={<PayStudentFees />}
                        />
                        <Route
                          path="/section-create"
                          element={<SectionCreate />}
                        />
                        <Route
                          path="/student-edit/:id"
                          element={<StudentCreate />}
                        />
                        <Route
                          path="/student-edit/:id"
                          element={<StudentCreate />}
                        />
                        <Route
                          path="/section/edit-:id"
                          element={<SectionCreate />}
                        />
                        <Route
                          path="/edit-stoppages/:id"
                          element={<Stoppagesedit />}
                        />
                        <Route
                          path="/student-view/:id"
                          element={<StudentListProfile />}
                        />

                        <Route
                          path="/subject-create"
                          element={<BranchSubjectCreate />}
                        />
                        <Route
                          path="/parent-create"
                          element={<ParentCreate />}
                        />
                        <Route path="/class-create" element={<CreateClass />} />
                        <Route path="/student-list" element={<StudentList />} />
                        <Route path="/fees-list" element={<FeesList />} />
                        <Route
                          path="/assets-management"
                          element={<AssestManagementTab />}
                        />
                        <Route
                          path="/fees-sub-components-list"
                          element={<FeeSubTypes />}
                        />
                        <Route
                          path="/student"
                          element={<StudentManagement />}
                        />
                        <Route path="/class" element={<ClassManagement />} />
                        <Route
                          path="/academic-fee-create"
                          element={<FeeSetupManagement />}
                        />
                        <Route
                          path="/section"
                          element={<SectionManagement />}
                        />
                        <Route
                          path="/subject"
                          element={<SubjectManagement />}
                        />
                        <Route path="/staff" element={<StaffManagement />} />
                        <Route
                          path="/homework"
                          element={<HomeworkManagement />}
                        />
                        <Route
                          path="/exam-setup"
                          element={<ExamConfiguration />}
                        />
                        <Route
                          path="/exam-grade-setup"
                          element={<ExamGradeConfiguration />}
                        />
                        <Route
                          path="/admission"
                          element={<AdmissionManagement />}
                        />
                        <Route
                          path="/subject-list"
                          element={<BranchSubjectList />}
                        />
                        <Route
                          path="/holiday"
                          element={<HolidayManagement />}
                        />
                        <Route
                          path="/event-notification"
                          element={<EventNotificationManagement />}
                        />
                        <Route
                          path="/event-notification-edit/:id"
                          element={<ShiftAssignment />}
                        />
                        <Route
                          path="/groups"
                          element={<GroupNotifications />}
                        />
                        <Route path="/parent" element={<ParentManagement />} />
                        <Route
                          path="/dashboard"
                          element={<SchoolDashboard />}
                        />
                        <Route
                          path="/fees-dashboard"
                          element={<FeesDashboard />}
                        />
                        <Route
                          path="/academic-fee-edit/:id"
                          element={<AcademicFeeEdit />}
                        />
                        <Route
                          path="/academic-fee-setup"
                          element={<AcademicFeeList />}
                        />
                        <Route
                          path="/fee-payments"
                          element={<AcademicFeePayments />}
                        />
                        <Route
                          path="/fee-reports"
                          element={<FeeReportManagement />}
                        />
                        <Route
                          path="/fee-concession"
                          element={<AdminFeeManagement />}
                        />
                        <Route
                          path="/fee-transaction"
                          element={<AcademicTransaction />}
                        />
                        <Route path="/remarks" element={<Remark />} />
                        <Route
                          path="/report-configuration"
                          element={<ReportCardManagement />}
                        />
                        <Route
                          path="/exam-config/edit/:id"
                          element={<EditExamConfig />}
                        />
                        <Route path="*" element={<PageNotFound />} />
                        <Route
                          path="/marks-entry"
                          element={<SubjectWiseMarksEntry />}
                        />
                        <Route
                          path="/reportcard-dashboard"
                          element={<ReportCardDashboard />}
                        />
                        <Route
                          path="/transport"
                          element={<TransportManagement />}
                        />
                        <Route
                          path="/transport-pickuprequest"
                          element={<AdminPickUpRequestList />}
                        />
                        <Route
                          path="/personality-traits"
                          element={<PersonalityTraits />}
                        />
                        <Route
                          path="/transport-vehicles"
                          element={<TransportVehiclesDetails />}
                        />
                        <Route
                          path="/transport-pilot"
                          element={<TransportPilotDetails />}
                        />
                        <Route
                          path="/generate-certificate"
                          element={<GenerateCertificate />}
                        />
                        <Route
                          path="/generate-certificate-list"
                          element={<GeneratedcertificateList />}
                        />
                        <Route
                          path="/certificate-list"
                          element={<CertificateConfiguration />}
                        />
                        <Route
                          path="/certificate-field-list"
                          element={<CertificateFieldList />}
                        />
                        <Route
                          path="/transport-details"
                          element={<TransportDetailsManagement />}
                        />
                        <Route path="/overall" element={<OverallReport />} />
                        <Route
                          path="/route"
                          element={<TransportRouteManagement />}
                        />
                        <Route
                          path="/library"
                          element={<LibraryManagement />}
                        />
                        <Route
                          path="/library-book-issue"
                          element={<LibraryBookIssueManagement />}
                        />
                        <Route
                          path="/library-book-return"
                          element={<ReturnBookList />}
                        />

                        <Route path="/books" element={<SyllabusBook />} />
                        <Route
                          path="/inventory"
                          element={<InventoryManagement />}
                        />

                        <Route
                          path="/view-syllabus"
                          element={<SyllabusView />}
                        />

                        <Route path="*" element={<PageNotFound />} />
                      </Routes>
                    }
                  />
                </Route>
                <Route element={<ProtectedRoute allowedRoles={[6]} />}>
                  <Route
                    path="/parent/*"
                    element={
                      <Routes>
                        <Route
                          path="/dashboard"
                          element={<ParentDashboard />}
                        />
                        <Route
                          path="/student-leaves"
                          element={<StudentLeaveList />}
                        />
                        <Route
                          path="/student-profile"
                          element={<StudentProfile />}
                        />
                        <Route
                          path="/fees-pay"
                          element={<ParentFeeManagement />}
                        />
                        <Route
                          path="/student-attendance"
                          element={<AttendanceManagement />}
                        />
                        <Route path="/tc-request" element={<TCRequest />} />
                        <Route
                          path="/holiday-list"
                          element={<HolidayCarousel />}
                        />
                        <Route
                          path="/school-transport"
                          element={<ParentTransportList />}
                        />

                        <Route
                          path="/feedback-list"
                          element={<FeedbackComponent />}
                        />
                        <Route path="/concerns" element={<ConcernsList />} />
                        <Route
                          path="/student-pickuprequest"
                          element={<ParentTransportManagement />}
                        />
                      </Routes>
                    }
                  />
                </Route>
                <Route element={<ProtectedRoute allowedRoles={[4]} />}>
                  <Route
                    path="/teaching-staff/school/:schoolId/branch/:branchId/*"
                    element={
                      <Routes>
                        <Route path="/class" element={<ClassManagement />} />
                        <Route
                          path="/section"
                          element={<SectionManagement />}
                        />
                        <Route
                          path="/homework"
                          element={<HomeworkManagement />}
                        />
                        <Route
                          path="/attendance"
                          element={<AttendanceCreate />}
                        />
                        <Route
                          path="/reportcard-dashboard"
                          element={<ReportCardDashboard />}
                        />
                        <Route
                          path="/exam-setup"
                          element={<ExamConfiguration />}
                        />
                        <Route
                          path="/exam-grade-setup"
                          element={<ExamGradeConfiguration />}
                        />
                        <Route
                          path="/exam-report-lock"
                          element={<ExamGradeLock />}
                        />
                        <Route path="/remarks" element={<Remark />} />
                        <Route
                          path="/personality-traits"
                          element={<PersonalityTraits />}
                        />
                        <Route
                          path="/report-configuration"
                          element={<ReportCardManagement />}
                        />
                        <Route
                          path="/marks-entry"
                          element={<SubjectWiseMarksEntry />}
                        />
                        <Route path="/report" element={<Report />} />
                        <Route path="/overall" element={<OverallReport />} />
                      </Routes>
                    }
                  />
                </Route>

                <Route element={<ProtectedRoute allowedRoles={[8]} />}>
                  <Route
                    path="/school-manager/*"
                    element={
                      <Routes>
                        <Route path="/school-list" element={<SchoolList />} />
                        <Route
                          path="/school-view/:id"
                          element={<SchoolView />}
                        />
                        <Route
                          path="/school/:id/branch-list"
                          element={<BranchList />}
                        />
                        <Route
                          path="/manager-dashboard/school/:id/branch/:id/dashboard"
                          element={<Dashboard />}
                        />
                      </Routes>
                    }
                  />
                  <Route
                    path="/manager-dashboard/*"
                    element={
                      <Routes>
                        <Route
                          path="/school/:id/branch/:id/dashboard"
                          element={<Dashboard />}
                        />
                      </Routes>
                    }
                  />
                </Route>
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
          </div>
          <RightSidebar />
          <Footer />
        </div>
      </body>
    </DataProvider>
  );
};

export default MainPage;
