import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import parentavatar from "../../Assests/avatar-icon0002_750950-43.avif";
import StaffGridView from "../CommonPages/StaffGridView";
import {
  deleteEventById,
  getAllEvents,
  recievertype,
} from "../../Services/CommonApis/EventsApi";
import ShiftAssignment from "./ShiftNotifications";

const EventNotificationList = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const { branchId, schoolId } = useParams();
  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        const response = await deleteEventById(id);
        Swal.close();
        showSuccessAlert(
          "Deleted!",
          response.data.message || "Data Deleted Successfully"
        );
        // setRefresh((prev) => !prev);
        navigate(`/school/${schoolId}/branch/${branchId}/event-notification`);
        window.location.reload();
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";

        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The Event Notification is safe :)");
    }
  };
  const [EventId, setEventId] = useState(null);
  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id", title: "S.No" },

        {
          data: "type",
          title: "Type",
          render: (data) => {
            if (Array.isArray(data) && data.length > 0) {
              // Map through the array and extract type names
              return data
                .map((item) => {
                  switch (item.id) {
                    case 2:
                      return "Teaching";
                    case 3:
                      return "Non-Teaching";
                    case 4:
                      return "Girl Students in that School";
                    case 5:
                      return "Boy Students in that School";
                    case 6:
                      return "Catholic Students in that School";
                    default:
                      return "Unknown";
                  }
                })
                .join(", "); // Combine names with commas
            } else {
              return "Unknown"; // Fallback for empty or invalid data
            }
          },
        },

        {
          data: "notification_type",
          title: "Notification Type",
          render: (data) => {
            return data && data.length > 0
              ? data.map((item) => item.name).join(", ")
              : "Unknown";
          },
        },
        { data: "message", title: "Message" },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            return `
                <div class="icon-group" style="display: flex; gap: 10px;">
                  <a href="/school/${schoolId}/branch/${branchId}/event-notification-edit/${row.id}" title="Edit">
                    <i class="fas fa-edit"></i>
                  </a>
                  <a href="#" title="Delete" data-id="${row.id}" class="delete-icon">
                    <i class="fas fa-trash"></i>
                  </a>
                </div>
              `;
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        { extend: "copy", exportOptions: { columns: ":visible" } },
        { extend: "csv", exportOptions: { columns: ":visible" } },
        { extend: "excel", exportOptions: { columns: ":visible" } },
        { extend: "pdf", exportOptions: { columns: ":visible" } },
        { extend: "print", exportOptions: { columns: ":visible" } },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
        emptyTable: "No Event Data Found",
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllEvents({ branch_id: branchId });

          let jsonData = response.data.data || [];
          let totalCount = response.data.total || 0;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          showErrorAlert(error.response.data.message);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy(true);
      }
    };
  }, [refresh, branchId]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Event list</h3>
        </div>

        <div className="card-body">
          <table
            ref={tableRef}
            id="example6"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Type</th>
                <th>notification Type</th>
                <th>message</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>S.No</th>
                <th>Type</th>
                <th>notification Type</th>
                <th>message</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default EventNotificationList;
