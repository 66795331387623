import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button"; // Added PrimeReact Button
import { getClassByBranchId } from "../../Services/CommonApis/classApi";

import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { createnonworkingday } from "../../Services/CommonApis/WorkingDaysApi";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
const WorkingDaysSetup = () => {
  const { branchId, schoolId } = useParams();
  const [classList, setClassList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    fetchClassByBranchId(branchId);
  }, [branchId]);

  const fetchClassByBranchId = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };
  const handleSubmit = async (values) => {
    const formattedData = classList
      .map((cls, index) => ({
        class_id: cls.id,
        school_id: schoolId,
        selected: values.classSelection[index],
        week_days_no: values.nonWorkingDay[index].includes("all")
          ? nonWorkingDayOptions
              .filter((day) => day.value !== "all")
              .map((day) => day.value)
          : values.nonWorkingDay[index],
        saturday_week_off: values.saturdayOffs[index].includes("all")
          ? saturdayOffOptions
              .filter((sat) => sat.value !== "all")
              .map((sat) => sat.value)
          : values.saturdayOffs[index],
      }))
      .filter((item) => item.selected);

    console.log("Selected Values based on Class ID:", formattedData);

    try {
      const response = await createnonworkingday(formattedData);
      console.log(response);
      if(response.status==200){
      showSuccessAlert(response.data.message);


      }
      
    } catch (error) {
      console.log(error);
    }
  };

  const nonWorkingDayOptions = [
    { value: "0", label: "Monday" },
    { value: "1", label: "Tuesday" },
    { value: "2", label: "Wednesday" },
    { value: "3", label: "Thursday" },
    { value: "4", label: "Friday" },
    { value: "5", label: "Saturday" },
    { value: "6", label: "Sunday" },
  ];

  const saturdayOffOptions = [
    { value: "1", label: "1st Saturday" },
    { value: "2", label: "2nd Saturday" },
    { value: "3", label: "3rd Saturday" },
    { value: "4", label: "4th Saturday" },
  ];

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        classSelection: new Array(classList.length).fill(false),
        nonWorkingDay: new Array(classList.length).fill([]),
        saturdayOffs: new Array(classList.length).fill([]),
      }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, handleSubmit }) => {
        const handleSelectAll = (checked) => {
          setFieldValue(
            "classSelection",
            values.classSelection.map(() => checked)
          );
          setSelectAll(checked);
        };

        return (
          <div className="card p-4">
            <h3 className="card-title mb-3">Non-Working Days Setup</h3>
            <DataTable value={classList} className="p-datatable-striped">
              <Column
                header={
                  <Checkbox
                    inputId="selectAll"
                    onChange={(e) => handleSelectAll(e.checked)}
                    checked={selectAll}
                  />
                }
                body={(rowData, { rowIndex }) => (
                  <Checkbox
                    inputId={`classSelection[${rowIndex}]`}
                    onChange={(e) => {
                      const updatedSelection = [...values.classSelection];
                      updatedSelection[rowIndex] = e.checked;
                      setFieldValue("classSelection", updatedSelection);
                    }}
                    checked={values.classSelection[rowIndex]}
                  />
                )}
                style={{ width: "10%" }}
              />

              <Column
                field="name"
                header="Class Name"
                style={{ width: "20%" }}
              />

              <Column
                header="Non-Working Days"
                body={(rowData, { rowIndex }) => (
                  <MultiSelect
                    id={`nonWorkingDay[${rowIndex}]`}
                    value={values.nonWorkingDay[rowIndex]}
                    options={nonWorkingDayOptions}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => {
                      const selectedValues = e.value;
                      if (selectedValues.includes("all")) {
                        setFieldValue(
                          `nonWorkingDay[${rowIndex}]`,
                          nonWorkingDayOptions.map((day) => day.value)
                        );
                      } else {
                        setFieldValue(
                          `nonWorkingDay[${rowIndex}]`,
                          selectedValues
                        );
                      }
                    }}
                    placeholder="Select Days"
                    style={{ width: "100%", maxWidth: "300px" }}
                    panelHeaderTemplate={() => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "5px",
                          marginLeft: "13px",
                        }}
                      >
                        <Checkbox
                          inputId={`selectAll-${rowIndex}`}
                          checked={
                            values.nonWorkingDay[rowIndex]?.length ===
                            nonWorkingDayOptions.length
                          }
                          onChange={(e) => {
                            if (e.checked) {
                              setFieldValue(
                                `nonWorkingDay[${rowIndex}]`,
                                nonWorkingDayOptions.map((day) => day.value)
                              );
                            } else {
                              setFieldValue(`nonWorkingDay[${rowIndex}]`, []);
                            }
                          }}
                        />
                        <label
                          htmlFor={`selectAll-${rowIndex}`}
                          style={{ marginLeft: "8px", cursor: "pointer" }}
                        >
                          Select All
                        </label>
                      </div>
                    )}
                  />
                )}
                style={{ width: "35%" }}
              />

              <Column
                header="Saturday Offs"
                body={(rowData, { rowIndex }) => (
                  <MultiSelect
                    id={`saturdayOffs[${rowIndex}]`}
                    value={values.saturdayOffs[rowIndex]}
                    options={saturdayOffOptions}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => {
                      const selectedValues = e.value;
                      if (selectedValues.includes("all")) {
                        setFieldValue(
                          `saturdayOffs[${rowIndex}]`,
                          saturdayOffOptions.map((sat) => sat.value)
                        );
                      } else {
                        setFieldValue(
                          `saturdayOffs[${rowIndex}]`,
                          selectedValues
                        );
                      }
                    }}
                    placeholder="Select Saturdays"
                    style={{ width: "100%", maxWidth: "300px" }}
                    panelHeaderTemplate={() => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "5px",
                          marginLeft: "13px",
                        }}
                      >
                        <Checkbox
                          inputId={`selectAll-${rowIndex}`}
                          checked={
                            values.saturdayOffs[rowIndex]?.length ===
                            saturdayOffOptions.length
                          }
                          onChange={(e) => {
                            if (e.checked) {
                              setFieldValue(
                                `saturdayOffs[${rowIndex}]`,
                                saturdayOffOptions.map((sat) => sat.value)
                              );
                            } else {
                              setFieldValue(`saturdayOffs[${rowIndex}]`, []);
                            }
                          }}
                        />
                        <label
                          htmlFor={`selectAll-${rowIndex}`}
                          style={{ marginLeft: "8px", cursor: "pointer" }}
                        >
                          Select All
                        </label>
                      </div>
                    )}
                  />
                )}
                style={{ width: "35%" }}
              />
            </DataTable>

            {/* Submit Button */}
            <div className="d-flex justify-content-end mt-3">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default WorkingDaysSetup;
