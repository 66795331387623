import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import Swal from "sweetalert2";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import {
  createPilot,
  getPilotById,
  getPilots,
  deletePilotById,
  updatePilotById,
} from "../../Services/CommonApis/TransportPilot";
import { getDropDownRoute } from "../../Services/CommonApis/TransportApi";
import { useParams } from "react-router-dom";
import { getVehicles } from "../../Services/CommonApis/TransportVehicleApi";

const TransportPilotDetails = () => {
  const { branchId } = useParams();
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editGroupId, setEditGroupId] = useState(null);
  const [editGroupName, setEditGroupName] = useState("");
  const [editPhone, setEditPhone] = useState("");
  const [editAltPhone, setEditAltPhone] = useState("");
  const [editLicenseType, setEditLicenseType] = useState("");
  const [editLicenseNo, setEditLicenseNo] = useState("");
  const [editInsurance, setEditinsurance] = useState("");
  const [editRouteId, setEditRouteId] = useState("");
  const [editVehicle, setEditVehicle] = useState("");

  const [editLicenseExpire, setLicenseExpire] = useState("");
  const [routes, setRoutes] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    if (branchId) {
      fetchRoutes(branchId);
      fetchVehicles();
    }
  }, [branchId]);

  const fetchVehicles = async () => {
    try {
      if (!branchId) return; // Ensure branchId is defined
      console.log("Branch ID:", branchId);
      const response = await getVehicles({ branch_id: branchId }); // Wrap branchId in an object
      console.log(response, "frontend");
      setVehicles(response.data.data);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deletePilotById(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The pilot has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the pilot.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The pilot is safe :)");
    }
  };

  const handleCreatePilot = async (values) => {
    try {
      showLoadingAlert();
      await createPilot({
        ...values,
        branch_id: branchId,
        license_expire: formatDate(values.license_expire),
      });
      Swal.close();
      showSuccessAlert("Success!", "The Pilot has been created.");
      setShowCreateModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      Swal.close();
      showErrorAlert("Error!", "There was an error creating the pilot.");
      console.error(error);
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await getPilotById(id);
      const {
        name,
        license_no,
        license_expire,
        license_type,
        alt_phone,
        phone,
        life_insurance,
        vehicle_id,
        route_id,
      } = response.data;
      setEditGroupId(id);
      setEditGroupName(name);
      setEditAltPhone(alt_phone);
      setEditPhone(phone);
      setEditLicenseType(license_type);
      setEditLicenseNo(license_no);
      setLicenseExpire(license_expire);
      setEditinsurance(life_insurance);
      setEditVehicle(vehicle_id);
      setEditRouteId(route_id);
      setShowEditModal(true);
    } catch (error) {
      console.error("Error fetching pilot details:", error);
      showErrorAlert(
        "Error!",
        "There was an error fetching the pilot details."
      );
    }
  };

  const handleUpdateExam = async (values) => {
    try {
      showLoadingAlert();
      await updatePilotById(editGroupId, {
        ...values,
        branch_id: branchId,
        license_expire: formatDate(values.license_expire),
      });
      Swal.close();
      showSuccessAlert("Success!", "The pilot has been updated.");
      setShowEditModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      Swal.close();
      showErrorAlert("Error!", "There was an error updating the exam.");
      console.error(error);
    }
  };
  const fetchRoutes = async () => {
    try {
      console.log(branchId);
      const response = await getDropDownRoute(branchId);
      console.log(response.data.data, "frontend");
      setRoutes(response.data.data);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "name" },
        { data: "phone" },
        { data: "alt_phone" },
        { data: "license_type" },
        { data: "license_no" },
        { data: "license_expire" },
        {
          data: "life_insurance",
          render: (data) => (data == "1" ? "yes" : "no"),
        },

        {
          data: "status",
          render: (data) => (data == "1" ? "Active" : "Inactive"),
        },
        {
          data: null,
          title: "Actions",
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "0"
                ? ""
                : `<a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>`;

            const assignTempPilotButton = `
      <a href="#" title="Assign Temp Pilot" data-id="${row.id}" class="assign-temp-pilot-icon">
          <i class="fas fa-plus"></i>
        
      </a>`;

            return `
      <div class="icon-group" style="display: flex; gap: 10px;">
        <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
        ${deleteIcon}
        ${assignTempPilotButton}
      </div>
    `;
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "csv",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "excel",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "pdf",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "print",
          exportOptions: { columns: ":visible" },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getPilots({
            branch_id: branchId,
            _limit: length,
            page: page,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });

          const jsonData = response.data.data;
          const totalCount = response.data.total;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEdit(id);
    });

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, []);

  const initialValues = {
    name: "",
    phone: "",
    alt_phone: "",
    license_type: "",
    license_no: "",
    license_expire: "",
    life_insurance: "",
    route_id: "",
    vehicle_id: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    phone: Yup.string()
      .length(10, "Mobile No must be exactly 10 digits")
      .required("Mobile No is required"),
    alt_phone: Yup.string()
      .length(10, "Mobile No must be exactly 10 digits")
      .required("Mobile No is required"),
    license_type: Yup.string().required("License Type is required"),
    license_no: Yup.string().required("License No is required"),
    license_expire: Yup.date().required("License Expiration is required"),
    life_insurance: Yup.string().required("Life Insurance is required"),
  });

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Pilot Details</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 style={{ paddingTop: "5px" }} className="card-title">
                    Pilot List
                  </h3>
                  <button
                    className="btn btn-primary float-right"
                    onClick={() => setShowCreateModal(true)}
                  >
                    Create New
                  </button>
                </div>
                <div className="card-body">
                  <table
                    ref={tableRef}
                    className="table table-striped table-bordered"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Phone No</th>
                        <th>Alternate Phone No</th>
                        <th>License Type</th>
                        <th>License No</th>
                        <th>License expiration</th>
                        <th>Life Insurance</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Phone No</th>
                        <th>Alternate Phone No</th>
                        <th>License Type</th>
                        <th>License No</th>
                        <th>License expiration</th>
                        <th>Life Insurance</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Create Group Modal */}
        <Modal
          show={showCreateModal}
          onHide={() => setShowCreateModal(false)}
          backdrop="static"
          className="swal2-blur-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Pilot</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCreatePilot}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body
                  style={{
                    maxWidth: "1000px",
                    maxHeight: "500px",
                    padding: "20px",
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <Field
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-error"
                    />
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="phone">Phone No</label>
                        <Field
                          type="text"
                          name="phone"
                          className="form-control"
                          placeholder="Enter Phone no"
                          style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                          }}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="alt_phone">Alternate Phone No</label>
                        <Field
                          type="text"
                          name="alt_phone"
                          className="form-control"
                          placeholder="Enter Alternate Phone no"
                          style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                          }}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <ErrorMessage
                          name="alt_phone"
                          component="div"
                          className="text-error"
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <label>Select Route:</label>
                      <Field
                        as="select"
                        name="route_id"
                        className="form-control"
                      >
                        <option value="">Select Route</option>
                        {routes.map((route) => (
                          <option key={route.id} value={route.id}>
                            {route.route}
                          </option>
                        ))}
                      </Field>
                    </div>

                    <div className="col-6">
                      <label>Select Vehicle:</label>
                      <Field
                        as="select"
                        name="vehicle_id"
                        className="form-control"
                      >
                        <option value="">Select Vehicle</option>
                        {vehicles.map((vehicle) => (
                          <option key={vehicle.id} value={vehicle.id}>
                            {`${vehicle.vehicle_type} - ${vehicle.vehicle_no}`}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="license_type">License Type</label>
                        <Field
                          as="select"
                          name="license_type"
                          className="form-control"
                        >
                          <option value="">Select License Type</option>
                          <option value="car">Car</option>
                          <option value="bus">Bus</option>
                          <option value="mini-bus">Mini Bus</option>
                        </Field>
                        <ErrorMessage
                          name="license_type"
                          component="div"
                          className="text-error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="license_no">License No</label>
                        <Field
                          type="text"
                          name="license_no"
                          className="form-control"
                          placeholder="Enter License No"
                        />
                        <ErrorMessage
                          name="license_no"
                          component="div"
                          className="text-error"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="license_expire">
                          License Expiration
                        </label>
                        <Field
                          type="date"
                          name="license_expire"
                          className="form-control"
                          placeholder="Enter License Expiration"
                        />
                        <ErrorMessage
                          name="license_expire"
                          component="div"
                          className="text-error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="life_insurance">Life Insurance</label>
                        <div
                          role="group"
                          aria-labelledby="life-insurance-group"
                        >
                          <label>
                            <Field
                              type="radio"
                              name="life_insurance"
                              value="1"
                              style={{ marginRight: "10px" }}
                            />
                            Yes
                          </label>
                          <label style={{ marginLeft: "10px" }}>
                            <Field
                              type="radio"
                              name="life_insurance"
                              value="0"
                              style={{ marginRight: "10px" }}
                            />
                            No
                          </label>
                        </div>
                        <ErrorMessage
                          name="life_insurance"
                          component="div"
                          className="text-error"
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowCreateModal(false)}
                  >
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>

        <Modal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Pilot</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={{
              name: editGroupName,
              phone: editPhone,
              alt_phone: editAltPhone,
              license_expire: editLicenseExpire,
              license_no: editLicenseNo,
              license_type: editLicenseType,
              life_insurance: editInsurance,
              vehicle_id: editVehicle,
              route_id: editRouteId,
            }}
            validationSchema={validationSchema}
            onSubmit={handleUpdateExam}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body
                  style={{
                    maxWidth: "1000px",
                    maxHeight: "500px",
                    padding: "20px",
                  }}
                >
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <Field
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-error"
                    />
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="phone">Phone No</label>
                        <Field
                          type="text"
                          name="phone"
                          className="form-control"
                          placeholder="Enter Phone no"
                          style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                          }}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="alt_phone">Alternate Phone No</label>
                        <Field
                          type="text"
                          name="alt_phone"
                          className="form-control"
                          placeholder="Enter Alternate Phone no"
                          style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                          }}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <ErrorMessage
                          name="alt_phone"
                          component="div"
                          className="text-error"
                        />
                      </div>
                    </div>

                    <div className="col-6 mb-2">
                      <label>Select Route:</label>
                      <Field
                        as="select"
                        name="route_id"
                        className="form-control"
                      >
                        <option value="">Select Route</option>
                        {routes.map((route) => (
                          <option key={route.id} value={route.id}>
                            {route.route}
                          </option>
                        ))}
                      </Field>
                    </div>

                    <div className="col-6 mb-2">
                      <label>Select Vehicle:</label>
                      <Field
                        as="select"
                        name="vehicle_id"
                        className="form-control"
                      >
                        <option value="">Select Vehicle</option>
                        {vehicles.map((vehicle) => (
                          <option key={vehicle.id} value={vehicle.id}>
                            {`${vehicle.vehicle_type} - ${vehicle.vehicle_no}`}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="license_type">License Type</label>
                        <Field
                          as="select"
                          name="license_type"
                          className="form-control"
                        >
                          <option value="">Select License Type</option>
                          <option value="car">Car</option>
                          <option value="bus">Bus</option>
                          <option value="mini-bus">Mini Bus</option>
                        </Field>
                        <ErrorMessage
                          name="license_type"
                          component="div"
                          className="text-error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="license_no">License No</label>
                        <Field
                          type="text"
                          name="license_no"
                          className="form-control"
                          placeholder="Enter License No"
                        />
                        <ErrorMessage
                          name="license_no"
                          component="div"
                          className="text-error"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="license_expire">
                          License Expiration
                        </label>
                        <Field
                          type="date"
                          name="license_expire"
                          className="form-control"
                          placeholder="Enter License Expiration"
                        />
                        <ErrorMessage
                          name="license_expire"
                          component="div"
                          className="text-error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="life_insurance">Life Insurance</label>
                        <div
                          role="group"
                          aria-labelledby="life-insurance-group"
                        >
                          <label>
                            <Field
                              type="radio"
                              name="life_insurance"
                              value="1"
                              style={{ marginRight: "10px" }}
                              className="mt-2"
                            />
                            Yes
                          </label>
                          <label style={{ marginLeft: "10px" }}>
                            <Field
                              type="radio"
                              name="life_insurance"
                              value="0"
                              style={{ marginRight: "10px" }}
                              className="mt-2"
                            />
                            No
                          </label>
                        </div>
                        <ErrorMessage
                          name="life_insurance"
                          component="div"
                          className="text-error"
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowEditModal(false)}
                  >
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </section>
    </>
  );
};

export default TransportPilotDetails;
