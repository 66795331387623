import Api from "../Api";

export const CreateLibraryBook = async (data) => {
  return await Api.post(`/create-library-book`, data);
};

export const getLibraryBooksList = async (params) => {
  return await Api.get(`/getall-lib-books`, { params })
}

export const getLibraryBookById = async (id) => {
  return await Api.get(`/get-lib-book/${id}`)
}

export const updateLibraryBookById = async (id, data) => {
  return await Api.post(`/update-lib-book/${id}`, data)
}

export const deleteLibraryBookById = async (id) => {
  return await Api.delete(`/delete-lib-book/${id}`)
}

export const getIssuedBookList = async (branchId) => {
  return await Api.get(`/get-all-library-book-issue`, {
    params: {
      branch_id: branchId,
    },
  });
};

export const createBookIssue = async (data) => {
  return await Api.post(`/create-library-book-issue`, data);
}

// for return book 
export const createBookReturn = async (data) => {
  return await Api.post(`/library-return`, data);
};
export const updateBookIssueBookById = async (id, data) => {
  const response = await Api.post(`/library-return/${id}`, data);
  return response;
};
export const getBookIssueById = async (id) => {
  return await Api.get(`/get-library-book-issue/${id}`)
}
export const getRetuenBooksList = async (params) => {
  return await Api.get(`/get-all-library-book-issue-except-issued`, { params })
}