import Api from "../Api";
export const createFees = (name) => {
  return Api.post("/create-fees-components", { name });
};
export const getAllFees = (params) => {
  return Api.get("/get-fees-components", { params });
};
export const getAllConcession = () => {
  return Api.get("/get-concession");
};
export const getFeesById = async (id) => {
  const response = await Api.get(`/get-fees-components/${id}`);
  return response.data;
};
export const updateFeesById = async (id, data) => {
  const response = await Api.post(`/update-fees-components/${id}`, data);
  return response.data;
};
export const deleteFees = (id) => {
  return Api.delete(`/delete-fees-components/${id}`);
};

export const createFeesSubComponent = (data) => {
  return Api.post("/create-fees-sub-components", data);
};
export const getAllFeesSubComponent = () => {
  return Api.get("/get-fees-sub-components");
};
export const getFeesSubComponentById = async (id) => {
  const response = await Api.get(`/get-fees-sub-components/${id}`);
  return response.data;
};
export const updateFeesSubComponentById = async (id, data) => {
  const response = await Api.post(`/update-fees-sub-components/${id}`, data);
  return response.data;
};
export const deleteFeesSubComponent = (id) => {
  return Api.delete(`/delete-fees-sub-components/${id}`);
};
// api for fees timeline
export const createFeesTimeline = (name, installments) => {
  return Api.post("/create-fees-timeline", { name, installments });
};
export const getAllFeesTimelines = (params) => {
  return Api.get("/get-fees-timelines", { params });
};
export const getFeesTimeLinesById = async (id) => {
  const response = await Api.get(`/get-fees-timeline/${id}`);
  return response.data;
};
export const updateFeesTimeLinesById = async (id, data) => {
  const response = await Api.post(`/update-fees-timeline/${id}`, data);
  return response.data;
};
export const deleteFeesTimeLines = (id) => {
  return Api.delete(`/delete-fees-timeline/${id}`);
};
// api for feesPayTypes
export const createFeesPay = (name) => {
  return Api.post("/create-fees-pay-types", { name });
};
export const getAllFeesPay = (params) => {
  return Api.get("/get-fees-pay-types", { params });
};
export const getFeesPayById = async (id) => {
  const response = await Api.get(`/get-fees-pay-types/${id}`);
  return response.data;
};
export const updateFeesPayById = async (id, data) => {
  const response = await Api.post(`/update-fees-pay-types/${id}`, data);
  return response.data;
};
export const deleteFeesPay = (id) => {
  return Api.delete(`/destroy-fees-pay-types/${id}`);
};
// api for feeDiscount
export const createFeesDiscount = (data) => {
  return Api.post("/create-fees-discount-type", data);
};
export const getAllFeesDiscount = (params) => {
  return Api.get("/get-fees-discount-types", { params });
};
export const getFeesDiscountsById = async (id) => {
  const response = await Api.get(`/get-fees-discount-type/${id}`);
  return response.data;
};
export const updateFeesDiscountById = async (id, data) => {
  const response = await Api.post(`/update-fees-discount-type/${id}`, data);
  return response.data;
};
export const deleteFeesDiscount = (id) => {
  return Api.delete(`/destroy-fees-discount-type/${id}`);
};

export const createAcademicSetup = (data) => {
  return Api.post(`/create-academic-setup`, data);
};

export const getAcademicSetups = async (branch_id) => {
  return Api.get(`/get-academic-setups`, {
    params: {
      branch_id: branch_id,
    },
  });
};

export const getAcademicSetup = async (id) => {
  return Api.get(`/get-academic-setup/${id}`);
};

export const updateAcademicSetup = async (id, data) => {
  return Api.post(`/update-academic-setup/${id}`, data);
};

export const createAcademicPayments = async (data) => {
  return Api.post(`/create-academic-payments`, data);
};

export const getAcademicPayments = async (params) => {
  return Api.get(`/get-academic-payments`, { params });
};

export const getStudentAcademicFee = async (params) => {
  return Api.get(`/get-student-academic-fees`, { params });
};

export const getStudentConcessionHistory = async (student_id) => {
  return Api.get(`/get-fee-concession`, {
    params: {
      student_id: student_id,
    },
  });
};
export const getPaymentMethod = async (params) => {
  return Api.get(`/get-academic-payment-method`, { params });
};

export const getFeesDashboardData = async (params) => {
  return await Api.get("/get-fees-dashboard", { params });
};
export const getFeesSubComponentsByFCID = async (id) => {
  const response = await Api.get(`/fees-sub-components`, {
    params: {
      fcid: id,
    },
  });
  return response.data;
};
export const getFeesSubComponentByFCIDMulti = async (ids) => {
  try {
    const response = await Api.get(`/fees-sub-components`, {
      params: {
        fcid: ids,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createConcession = async (data) => {
  return Api.post(`/create-fees-discount-type`, data);
};

export const getFeesCollectionReport = async (params) => {
  return await Api.get("/get-academic-payments", { params });
};

export const getDefaultersReport = async (params) => {
  return await Api.get("/get-default-payments", { params });
};

export const getConcessionReport = async (params) => {
  return await Api.get("/get-consession-report", { params });
};

export const generateDefaultersReportPdf = async (
  branch_id,
  class_id,
  section_id
) => {
  const url = `/generate-default-report-pdf?branch_id=${branch_id}&class_id=${class_id}&section_id=${section_id}`;

  return Api.get(url, {
    responseType: "blob",
  }).then((response) => {
    const blob = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = fileUrl;

    const filename = `fee_defaulterslist_${section_id}.pdf`;
    link.setAttribute("download", filename);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
  });
};

export const generateConcessionReportPdf = async (
  branch_id,
  selectedClassIds
) => {
  const params = new URLSearchParams();
  params.append("branch_id", branch_id);
  selectedClassIds.forEach((id) => params.append("class_id[]", id));

  const url = `/generate-consession-report-pdf?${params.toString()}`;

  return Api.get(url, {
    responseType: "blob",
  }).then((response) => {
    const blob = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = fileUrl;

    const filename = `fee_concessionlist_${branch_id}.pdf`;
    link.setAttribute("download", filename);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
  });
};

export const getFeesTransactionReport = async (branch_id, student_id) => {
  if (!student_id) {
    return;
  }

  const url = `/generate-transacton-report-pdf?branch_id=${branch_id}&student_id=${student_id}`;

  return Api.get(url, {
    responseType: "blob",
  }).then((response) => {
    const blob = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = fileUrl;

    const filename = `fee_invoice_${student_id}.pdf`;
    link.setAttribute("download", filename);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
  });
};

export const getOverAllFeeReports = async (params) => {
  return Api.get(`/get-overall-payments`, { params });
};

export const generateOverallFeeReport = async (branchId) => {
  console.log(branchId);

  const url = `/generate-overall-report-pdf?branch_id=${branchId}`;

  return Api.get(url, {
    responseType: "blob",
  }).then((response) => {
    const blob = new Blob([response.data], { type: "application/pdf" });
    const fileUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = fileUrl;

    const filename = `OverallFeeReport${branchId}.pdf`;
    link.setAttribute("download", filename);

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(fileUrl);
  });
};
