import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { getIssuedBookList, getBookIssueById, updateBookIssueBookById } from "../../Services/AdminApis/LibraryApi";
import { Modal, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

const LibraryBookIssueList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editLibraryId, setEditLibraryId] = useState(null);
  const [editReturnStatus, setEditReturnStatus] = useState("");
  const [editIssueDate, setEditIssueDate] = useState("");
  const [editComments, setEditComments] = useState("");
  const { branchId } = useParams();
  // Handle Edit
  const handleEdit = async (id) => {
    try {
      const response = await getBookIssueById(id);
      const { return_status, issue_date, comments } = response.data;
      setEditLibraryId(id);
      setEditReturnStatus(return_status);
      setEditIssueDate(issue_date);
      setEditComments(comments);
      setShowEditModal(true);
    } catch (error) {
      console.error("Error fetching book issue details:", error);
      showErrorAlert("Error", "Unable to fetch book issue details.");
    }
  };

  // Handle Update
  const handleUpdate = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      `You want to update the Library Book Issue.`,
      "info",
      "Yes, Proceed",
      "No, cancel!"
    );
    if (result.isConfirmed) {
      showLoadingAlert();
      try {
        const formattedIssueDate = new Date(values.issue_date).toLocaleDateString('en-GB');
        await updateBookIssueBookById(editLibraryId, {
          return_status: values.return_status,
          return_date: formattedIssueDate,
          return_comments: values.comments,
        });
        Swal.close();
        showSuccessAlert("Success!", "The Library book issue has been updated.");
        setShowEditModal(false);
        window.location.reload(tableInstance.current)
        if (tableInstance.current) {
          try {
            if ($.fn.dataTable.isDataTable(tableInstance.current)) {
              // tableInstance.current.clear(); 
              tableInstance.current.draw();
              tableInstance.current.ajax.reload(null, true);
            }
          } catch (error) {
            console.error('Error during DataTable reload:', error);
          }
        }

      } catch (err) {
        showErrorAlert("Error", err);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The book issue has not been updated.");
    }
  };
  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id", title: "S.No" },
        { data: "book_name", title: "Book Name" },
        { data: "student_name", title: "Student Name" },
        { data: "staff_name", title: "Staff Name" },
        { data: "issue_date", title: "Issued Date" },
        { data: "return_status_name", title: "Return Status" },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon = row.status === "inactive" ? "" : `<a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>`;
            return `
              <div class="icon-group" style="display: flex; gap: 10px; justify-content: center;">
                <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-journal-whills"></i></a>
              </div>
            `;
          },
        },
      ],
      lengthChange: true,
      ordering: true,
      responsive: true,
      ajax: async function (data, callback) {
        try {
          const response = await getIssuedBookList(branchId);
          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;
          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          showErrorAlert(error.response.data.message);
          callback({ draw: data.draw, recordsTotal: 0, recordsFiltered: 0, data: [] });
        }
      },
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEdit(id);
    });
    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, [branchId]);

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Book Issue List</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 className="card-title">Book Issue List</h3>
        </div>
        <div className="card-body">
          <table ref={tableRef} className="table table-striped table-hover table-bordered" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Book Name</th>
                <th>Student Name</th>
                <th>Staff Name</th>
                <th>Issued Date</th>
                <th>Return Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>S.No</th>
                <th>Book Name</th>
                <th>Student Name</th>
                <th>Staff Name</th>
                <th>Issued Date</th>
                <th>Return Status</th>
                <th>Action</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <Modal show={showEditModal} 
       backdrop="static"
       centered
      onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Return Book</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => { e.preventDefault(); handleUpdate({ return_status: editReturnStatus, issue_date: editIssueDate, comments: editComments }); }}>
            <div className="form-group">
              <label>Return Status</label>
              <select className="form-control" value={editReturnStatus} onChange={(e) => setEditReturnStatus(e.target.value)}>
                <option value="1">Issued</option>
                <option value="0">Returned</option>
                <option value="2">Lost</option>
                <option value="3">Not Returned</option>
                <option value="4">Paid</option>
              </select>
            </div>
            <div className="form-group">
              <label>Return Date</label>
              <input type="date" className="form-control" value={editIssueDate} onChange={(e) => setEditIssueDate(e.target.value)} />
            </div>
            <div className="form-group">
              <label>Comments</label>
              <input type="text" className="form-control" value={editComments} onChange={(e) => setEditComments(e.target.value)} />
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowEditModal(false)}>Cancel</Button>
              <Button variant="primary" type="submit">Save</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LibraryBookIssueList;