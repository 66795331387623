import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import Swal from "sweetalert2";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import {
  getStudentByParentId,
  getLeaveById,
  getLeave,
  deleteLeave,
  CreateLeave,
  updateLeave,
} from "../../Services/CommonApis/ParentPortalApi";
import { COOKIE } from "../../Schemas/cookieNames";
import { getSession } from "../../Services/Storage";
import { getParentId } from "../../Utility/CommonUtility/extractUserDetailsFromToken";

const StudentLeaveList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [leaves, setLeaves] = useState([]);
  const [studentId, setStudentId] = useState("");
  console.log("bye", studentId);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editGroupId, setEditGroupId] = useState(null);
  const [editGroupName, setEditGroupName] = useState("");
  const [editToDate, setEditToDate] = useState("");
  const [editReason, setEditReason] = useState("");
  const token = getSession(COOKIE.ADMIN_TOKEN);
  const parentId = getParentId(token);

  useEffect(() => {
    if (parentId) {
      fetchStudentByParent(parentId);
    }
  }, [parentId]);

  const fetchStudentByParent = async (parentId) => {
    try {
      const response = await getStudentByParentId({ parent_id: parentId });
      console.log(response.data.data[0].id);
      setStudentId(response.data.data[0].id);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteLeave(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The Leave has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the Leave.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The subject is safe :)");
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await getLeaveById(id);
      console.log(response.data.data);
      const { from_date, to_date, reason } = response.data.data;
      setEditGroupId(id);
      setEditGroupName(from_date);
      setEditToDate(to_date);
      setEditReason(reason);
      setShowEditModal(true);
    } catch (error) {
      console.error("Error fetching Leave details:", error);
      showErrorAlert(
        "Error!",
        "There was an error fetching the Leave details."
      );
    }
  };

  const handleCreateLeave = async (values) => {
    try {
      showLoadingAlert();
      await CreateLeave({ ...values, student_id: studentId });
      Swal.close();
      showSuccessAlert("Success!", "The Leave has been created.");
      setShowCreateModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      Swal.close();
      showErrorAlert("Error!", "There was an error creating the Leave.");
      console.error(error);
    }
  };

  const handleUpdateLeave = async (values) => {
    try {
      showLoadingAlert();
      await updateLeave(editGroupId, {
        from_date: values.from_date,
        to_date: values.to_date,
        reason: values.reason,
        student_id: studentId,
      });
      Swal.close();
      showSuccessAlert("Success!", "The Leave has been updated.");
      setShowEditModal(false);
      if (tableInstance.current) {
        tableInstance.current.ajax.reload();
      }
    } catch (error) {
      Swal.close();
      showErrorAlert("Error!", "There was an error updating the Medium.");
      console.error(error);
    }
  };

  useEffect(() => {
    if (!studentId) return;

    if (tableInstance.current) {
      tableInstance.current.destroy();
    }

    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      data: leaves,
      columns: [
        {
          data: null,
          title: "S.No",
          render: (data, type, row, meta) => {
            return meta.row + 1; // meta.row gives the row index
          },
          orderable: false, // Disable sorting on this column
          searchable: false, // Disable searching on this column
        },
        {
          data: null,
          title: "Duration",
          render: (data) => {
            const formatDate = (dateString) => {
              const date = new Date(dateString);
              const day = String(date.getDate()).padStart(2, "0");
              const monthAbbreviations = [
                "JAN", "FEB", "MAR", "APR", "MAY", "JUN", 
                "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
              ];
              const month = monthAbbreviations[date.getMonth()];
              const year = date.getFullYear();
              return `${day}/${month}/${year}`;
            };
      
            const fromDateFormatted = formatDate(data.from_date);
            const toDateFormatted = formatDate(data.to_date);
      
            const fromDate = new Date(data.from_date);
            const toDate = new Date(data.to_date);
            const diffTime = Math.abs(toDate - fromDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
            return `${fromDateFormatted} TO ${toDateFormatted} (${diffDays} Days)`;
          },
        },
        { 
          data: "reason", 
          title: "Reason" 
        },
        {
          data: "created_at",
          title: "Applied On",
          render: (data) => {
            if (data) {
              const dateObj = new Date(data);
              const day = String(dateObj.getDate()).padStart(2, "0");
              const month = dateObj
                .toLocaleString("default", { month: "short" })
                .toUpperCase();
              const year = dateObj.getFullYear();
              return `${day}/${month}/${year}`;
            }
            return "N/A";
          },
        },
        {
          data: "status",
          title: "Status",
          render: (data) => (data === "1" ? "Active" : "Inactive"),
        },
        {
          data: null,
          title: "Actions",
          orderable: false,
          render: (data, type, row) => {
            const currentDate = new Date();
            const toDate = new Date(row.to_date);
      
            const canDelete = toDate > currentDate && row.status === "1";
      
            return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                ${
                  canDelete
                    ? `<a href="#" title="Delete" data-id="${row.id}" class="delete-icon">
                          <i class="fas fa-trash"></i>
                       </a>`
                    : ""
                }
                <a href="#" title="Edit" data-id="${row.id}" class="edit-icon">
                  <i class="fas fa-edit"></i>
                </a>
              </div>`;
          },
        },
      ],
      
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "csv",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "excel",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "pdf",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "print",
          exportOptions: { columns: ":visible" },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getLeave({
            student_id: studentId,
            _limit: length,
            page: page,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });

          const jsonData = response.data.data;
          const totalCount = jsonData.length;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEdit(id);
    });

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, [leaves, studentId]);

  const initialValues = {
    from_date: "",
    to_date: "",
    reason: "",
  };

  const validationSchema = Yup.object({
    from_date: Yup.string().required("From Date is required"),
    to_date: Yup.string().required("To Date is required"),
    reason: Yup.string().required("Reason is required"),
  });

  return (
    <>
      <div className="card mt-3">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Leave List
          </h3>
          <button
            className="btn btn-primary float-right"
            onClick={() => setShowCreateModal(true)}
          >
            Create New
          </button>
        </div>
        <div className="card-body">
          <table
            ref={tableRef}
            className="table table-striped table-bordered"
            style={{ width: "100%" }}
          />
        </div>
      </div>

      {/* Modal for applying leave */}
      <Modal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Apply Leave Request</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleCreateLeave}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="fromDate">From Date</label>
                  <Field
                    type="date"
                    name="from_date"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="from_date"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="toDate">To Date</label>
                  <Field type="date" name="to_date" className="form-control" />
                  <ErrorMessage
                    name="to_date"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="reason">Reason</label>
                  <Field as="textarea" name="reason" className="form-control" />
                  <ErrorMessage
                    name="reason"
                    component="div"
                    className="text-error"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowCreateModal(false)}
                >
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={showEditModal}
       backdrop="static"
       centered
      onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Leave Request</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            from_date: editGroupName,
            to_date: editToDate,
            reason: editReason,
          }}
          validationSchema={validationSchema}
          onSubmit={handleUpdateLeave}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="fromDate">From Date</label>
                  <Field
                    type="date"
                    name="from_date"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="from_date"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="toDate">To Date</label>
                  <Field type="date" name="to_date" className="form-control" />
                  <ErrorMessage
                    name="to_date"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="reason">Reason</label>
                  <Field as="textarea" name="reason" className="form-control" />
                  <ErrorMessage
                    name="reason"
                    component="div"
                    className="text-error"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowEditModal(false)}
                >
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default StudentLeaveList;
