import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  deleteClasswiseConcession,
  getAllClassWiseConcessions,
} from "../../Services/CommonApis/ClasswiseconcessionApi";

const ClassWiseConcessionList = () => {
  const tableRef = useRef(null);
  const { id, branchId, schoolId } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [showError, setShowError] = useState(false); // State to manage error message visibility
  const [error, setError] = useState("");

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        const response = await deleteClasswiseConcession(id);
        Swal.close(); // Close loading alert
        showSuccessAlert("Deleted!", "The department has been deleted.");
        setRefresh((prev) => !prev);
        // Handle successful deletion (e.g., refresh the list)
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";

        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The Concession is safe :)");
    }
  };

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        {
          data: null,
          render: function (data, type, row) {
            return `<span>${row.class_name}<br>${row.section_name}</span>`;
          },
        },
        { data: "component_name" },
        {
          data: "fees_sub_component",
          title: "Fee Details",
          render: (data, type, row) => {
            if (Array.isArray(data) && data.length > 0) {
              return data.map((sub) => sub.name).join(", ");
            }
            return "No Details";
          },
        },
        { data: "amount" },
        { data: "concession_name" },
        {
          data: "status",
          render: (data, type, row) => {
            return data === "1" ? "Active" : "Inactive";
          },
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="/admin/department-edit/${row.id}" title="Edit"><i class="fas fa-edit"></i></a>
                <a href="#" title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
              </div>
            `;
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "csv",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "excel",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "pdf",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "print",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
        emptyTable: "No Concession Data Found",
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllClassWiseConcessions({
            branch_id: branchId,
            school_id: schoolId,
            page: page,
            _limit: length,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });
          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    return () => {
      table.destroy();
    };
  }, [refresh]);

  return (
    <>
      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 className="card-title">Classwise Concession List</h3>
          <div className="card-tools">
            <ul className="nav nav-pills ml-auto">
              <li className="nav-item p-1">
                <a
                  className="btn btn-primary active"
                  href={`/school/${schoolId}/branch/${branchId}/create-classwise-concession`}
                >
                  Create New
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="card-body">
          <table
            ref={tableRef}
            id="example1"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Class Name</th>
                <th>Component Name</th>
                <th>Sub Component Name</th>
                <th>Concession Amount</th>
                <th>Concession Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>S.No</th>
                <th>Class Name</th>
                <th>Component Name</th>
                <th>Sub Component Name</th>
                <th>Concession Amount</th>
                <th>Concession Type</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
          {showError && (
            <div
              className={`error-alert error-alert-danger ${
                showError ? "fade-in" : "fade-out"
              }`}
              onAnimationEnd={() => {
                if (!showError) setError("");
              }}
            >
              {error}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ClassWiseConcessionList;
