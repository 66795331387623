import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import {
  getAllLanguages,
  deleteLanguageById,
} from "../../Services/AdminApis/LanguagesApi";

const LanguageList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to delete the Language!",
      "warning",
      "Yes, Delete!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        const response = await deleteLanguageById(id);
        Swal.close();
        showSuccessAlert("Deleted!", response.data.message || "Data Seleted Successfully");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the Langauge.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Language is safe 😊");
    }
  };

  const { id } = useParams();
  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "name" },
        {
          data: "status",
          render: (data) => {
            const text = data === "1" ? "Active" : "Inactive";
            return `<div>${text}</div>`;
          },
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "1"
                ? `
                <a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
              `
                : "";
            return `
                <div class="icon-group" style="display: flex; gap: 10px;">
                  <a href="/admin/language-edit/${row.id}" title="Edit"><i class="fas fa-edit"></i></a>
                  ${deleteIcon}
                </div>
              `;
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "csv",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "excel",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "pdf",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "print",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
        emptyTable: "No Language Data Found",
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllLanguages({
            page: page,
            _limit: length,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });
          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          showErrorAlert(error.response.data.message);
          callback({
            draw: data.draw,
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          });
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    return () => {
      tableInstance.current.destroy();
    };
  }, [id]);

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Langauges Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Langauge Details</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 className="card-title">Langauge List Table</h3>
          <div className="card-tools">
            <ul className="nav nav-pills ml-auto">
              <li className="nav-item p-1">
                <a
                  className="btn btn-primary active"
                  href={`/admin/language-create`}
                >
                  Create New
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="card-body">
          <table
            ref={tableRef}
            id="academicTable"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default LanguageList;
