import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "../../Styles/SchoolCreate.css";
// import { ParentValidation } from "../../Utility/CommonUtility/Validation";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { createBranch } from "../../Services/CommonApis/branchApi";

const ParentCreate = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);
  const progressBarRef = useRef(null);
  const steps = 4;

  const [copyAddress, setCopyAddress] = useState(false);
  const [addressData, setAddressData] = useState({
    address: "",
    city: "",
    state: "",
    country: "",
    pin: "",
  });
  const [tempAddressData, setTempAddressData] = useState({
    address: "",
    city: "",
    state: "",
    country: "",
    pin: "",
  });

  // Use effect to update addressData when tempAddressData changes
  useEffect(() => {
    if (copyAddress) {
      setAddressData(tempAddressData);
    }
  }, [tempAddressData, copyAddress]);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setCopyAddress(checked);
    if (!checked) {
      // If unchecked, clear addressData
      setAddressData({
        address: "",
        city: "",
        state: "",
        country: "",
        pin: "",
      });
    }
  };
  const handleFocus = (event) => {
    if (event.target.type === "date") {
      event.target.showPicker();
    }
  };

  const handleDateClick = (event) => {
    if (event.target.type === "date") {
      event.target.focus();
    }
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTempAddressData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const prevStep = () => {
    setCurrent((prevCurrent) =>
      prevCurrent > 1 ? prevCurrent - 1 : prevCurrent
    );
  };

  const goToStep = (step) => {
    setCurrent(step);
  };

  const setProgressBar = useCallback(
    (curStep) => {
      const percent = ((100 / steps) * curStep).toFixed();
      if (progressBarRef.current) {
        progressBarRef.current.style.width = `${percent}%`;
      }
    },
    [steps]
  );

  useEffect(() => {
    setProgressBar(current);
  }, [current, setProgressBar]);

  const handleSubmit = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to create the parent profile",
      "info",
      "Yes, Create",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      showLoadingAlert();
      try {
        const response = await createBranch(values);
        if (response.status === 201) {
          showSuccessAlert(
            "Created!",
            "The parent profile has been created successfully."
          );
          navigate(`/parent/list`);
        } else {
          const errorMessage =
            response.data?.message ||
            "Parent creation failed. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (error) {
        console.error("API error:", error);
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred during parent creation.";
        showErrorAlert("Error!", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Parent creation has been cancelled.");
    }
  };

  const initialValues = {
    first_name: "",
    middle_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    blood_group: "",
    religion: "",
    cast: "",
    nationality: "",
    mother_tounge: "",
    image: "",
    phone: "",
    alt_phone: "",
    alt_email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pin: "",
    tmp_address: "",
    temp_city: "",
    temp_state: "",
    temp_pin: "",
    temp_country: "",
    addhar_card_no: "",
    pan_card_no: "",
    education: "",
    occupation: "",
    annual_income: "",
    mother_name: "",
    mother_phone: "",
    mother_email: "",
    mother_education: "",
    mother_occupation: "",
    mother_annual_income: "",
    mother_aadhar_no: "",
    mother_pan_card: "",
    mother_dob: "",
    mother_image: "",
    upload_pic: "",
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Parent Create</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Parent Create</li>
              </ol>
            </div>
          </div>
          <div className="card">
            <div className="row ml-5 text-center">
              <div className="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
                <Formik
                  initialValues={initialValues}
                  // validationSchema={ParentValidation}
                  onSubmit={handleSubmit}
                >
                  {() => (
                    <Form id="msform">
                      <ul id="progressbar">
                        <li
                          className={current >= 1 ? "active" : ""}
                          id="account"
                          onClick={() => goToStep(1)}
                          style={{ width: "287px" }}
                        >
                          <strong>Personal Information</strong>
                        </li>

                        <li
                          className={current >= 2 ? "active" : ""}
                          id="account"
                          onClick={() => goToStep(3)}
                          style={{ width: "287px" }}
                        >
                          <strong>Education and Occupation</strong>
                        </li>
                        <li
                          className={current >= 3 ? "active" : ""}
                          id="payment"
                          onClick={() => goToStep(2)}
                          style={{ width: "287px" }}
                        >
                          <strong>Contact Information</strong>
                        </li>
                        <li
                          className={current >= 4 ? "active" : ""}
                          id="account"
                          onClick={() => goToStep(2)}
                          style={{ width: "287px" }}
                        >
                          <strong>Additional Information</strong>
                        </li>
                      </ul>
                      <div className="progress">
                        <div
                          ref={progressBarRef}
                          className="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <br />
                      {current === 1 && (
                        <div
                          className="form-step"
                          style={{
                            border: "2px solid #007bff",
                            borderRadius: "8px",
                          }}
                        >
                          <fieldset>
                            <div className="form-card">
                              <div className="row">
                                <div className="col-7">
                                  <h2 className="fs-title mt-3 ml-3">
                                    Personal Information:
                                  </h2>
                                </div>
                                <div className="col-5">
                                  <h2 className="steps mr-3 mt-3">
                                    Step 1 - {steps}
                                  </h2>
                                </div>
                              </div>
                              <div className="row ml-3 mr-3 mt-3">
                                <div className="form-group col-4">
                                  <label htmlFor="first_name">
                                    First Name{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="first_name"
                                    className="form-control"
                                    placeholder="John"
                                  />
                                  <ErrorMessage
                                    name="first_name"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="middle_name">
                                    Middle Name{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="middle_name"
                                    className="form-control"
                                    placeholder="Michael"
                                  />
                                  <ErrorMessage
                                    name="middle_name"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="last_name">
                                    Last Name{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="last_name"
                                    className="form-control"
                                    placeholder="Doe"
                                  />
                                  <ErrorMessage
                                    name="last_name"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="date_of_birth">
                                    Date of Birth{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="date"
                                    name="date_of_birth"
                                    className="form-control"
                                    onFocus={handleFocus}
                                    onClick={handleDateClick}
                                  />
                                  <ErrorMessage
                                    name="date_of_birth"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="gender">
                                    Gender <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    as="select"
                                    name="gender"
                                    className="form-control"
                                  >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                  </Field>
                                  <ErrorMessage
                                    name="gender"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="blood_group">
                                    Blood Group{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="blood_group"
                                    className="form-control"
                                    placeholder="O+"
                                  />
                                  <ErrorMessage
                                    name="blood_group"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="religion">
                                    Religion{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="religion"
                                    className="form-control"
                                    placeholder="Christianity"
                                  />
                                  <ErrorMessage
                                    name="religion"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="cast">
                                    Caste{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="cast"
                                    className="form-control"
                                    placeholder="General"
                                  />
                                  <ErrorMessage
                                    name="cast"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="nationality">
                                    Nationality{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="nationality"
                                    className="form-control"
                                    placeholder="American"
                                  />
                                  <ErrorMessage
                                    name="nationality"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="mother_tounge">
                                    Mother Tongue{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="mother_tounge"
                                    className="form-control"
                                    placeholder="English"
                                  />
                                  <ErrorMessage
                                    name="mother_tounge"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div class="form-group col-4">
                                  <label htmlFor="upload_pic">
                                    Parent Image
                                  </label>
                                  <div class="input-group">
                                    <div class="custom-file">
                                      <Field
                                        type="file"
                                        class="custom-file-input"
                                        name="upload_pic"
                                      />

                                      <label
                                        class="custom-file-label"
                                        htmlFor="mother_pic"
                                      >
                                        Choose file
                                      </label>
                                    </div>
                                  </div>
                                  <ErrorMessage
                                    name="mother_pic"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      )}
                      {current === 2 && (
                        <div
                          className="form-step"
                          style={{
                            border: "2px solid #007bff",
                            borderRadius: "8px",
                          }}
                        >
                          <fieldset>
                            <div className="form-card">
                              <div className="row">
                                <div className="col-7">
                                  <h2 className="fs-title mt-3 ml-3">
                                    Education and Occupation:
                                  </h2>
                                </div>
                                <div className="col-5">
                                  <h2 className="steps mr-3 mt-3">
                                    Step 3 - {steps}
                                  </h2>
                                </div>
                              </div>
                              <div className="row ml-3 mr-3 mt-3">
                                <div className="form-group col-4">
                                  <label htmlFor="addhar_card_no">
                                    Aadhar No{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="addhar_card_no"
                                    className="form-control"
                                    placeholder="1234-5678-9012"
                                  />
                                  <ErrorMessage
                                    name="addhar_card_no"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>

                                <div className="form-group col-4">
                                  <label htmlFor="pan_card_no">
                                    PAN Card{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="pan_card_no"
                                    className="form-control"
                                    placeholder="AABCT1234E"
                                  />
                                  <ErrorMessage
                                    name="pan_card_no"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>

                                <div className="form-group col-4">
                                  <label htmlFor="education">
                                    Education{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="education"
                                    className="form-control"
                                    placeholder="B.Sc."
                                  />
                                  <ErrorMessage
                                    name="education"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="occupation">
                                    Occupation{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="occupation"
                                    className="form-control"
                                    placeholder="Software Engineer"
                                  />
                                  <ErrorMessage
                                    name="occupation"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="annual_income">
                                    Annual Income{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="annual_income"
                                    className="form-control"
                                    placeholder="$50,000"
                                  />
                                  <ErrorMessage
                                    name="annual_income"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      )}
                      {current === 3 && (
                        <div
                          className="form-step"
                          style={{
                            border: "2px solid #007bff",
                            borderRadius: "8px",
                          }}
                        >
                          <fieldset>
                            <div className="form-card">
                              <div className="row">
                                <div className="col-7">
                                  <h2 className="fs-title mt-3 ml-3">
                                    Contact Information:
                                  </h2>
                                </div>
                                <div className="col-5">
                                  <h2 className="steps mr-3 mt-3">
                                    Step 2 - {steps}
                                  </h2>
                                </div>
                              </div>
                              <div className="row ml-3 mr-3 mt-3">
                                <div className="form-group col-4">
                                  <label htmlFor="phone">
                                    Phone <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="number"
                                    name="phone"
                                    className="form-control"
                                    placeholder="1234567890"
                                    onInput={(e) => {
                                      e.target.value = e.target.value
                                        .replace(/\D/g, "")
                                        .slice(0, 10);
                                    }}
                                    style={{
                                      WebkitAppearance: "none",
                                      MozAppearance: "textfield",
                                    }}
                                    onKeyPress={(e) => {
                                      if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  <ErrorMessage
                                    name="phone"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="alt_phone">
                                    Alternate Phone{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="number"
                                    name="alt_phone"
                                    className="form-control"
                                    placeholder="0987654321"
                                    onInput={(e) => {
                                      e.target.value = e.target.value
                                        .replace(/\D/g, "")
                                        .slice(0, 10);
                                    }}
                                    style={{
                                      WebkitAppearance: "none",
                                      MozAppearance: "textfield",
                                    }}
                                    onKeyPress={(e) => {
                                      if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  <ErrorMessage
                                    name="alt_phone"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="alt_email">
                                    Alternate Email{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="email"
                                    name="alt_email"
                                    className="form-control"
                                    placeholder="alternate@example.com"
                                  />
                                  <ErrorMessage
                                    name="alt_email"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>

                                <div className="form-group col-12">
                                  <label htmlFor="tmp_address">
                                    Temporary Address{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    as="textarea"
                                    name="address"
                                    className="form-control"
                                    placeholder="456 Secondary St"
                                    value={tempAddressData.address}
                                    onChange={handleInputChange}
                                  />
                                  <ErrorMessage
                                    name="address"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-6">
                                  <label htmlFor="temp_city">
                                    Temporary City{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="city"
                                    className="form-control"
                                    placeholder="Shelbyville"
                                    value={tempAddressData.city}
                                    onChange={handleInputChange}
                                  />
                                  <ErrorMessage
                                    name="city"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-6">
                                  <label htmlFor="temp_state">
                                    Temporary State{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="state"
                                    className="form-control"
                                    placeholder="Indiana"
                                    value={tempAddressData.state}
                                    onChange={handleInputChange}
                                  />
                                  <ErrorMessage
                                    name="state"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-6">
                                  <label htmlFor="temp_country">
                                    Temporary Country{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="country"
                                    className="form-control"
                                    placeholder="USA"
                                    value={tempAddressData.country}
                                    onChange={handleInputChange}
                                  />
                                  <ErrorMessage
                                    name="country"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-6">
                                  <label htmlFor="temp_pin">
                                    Temporary PIN Code{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="number"
                                    name="pin"
                                    className="form-control"
                                    placeholder="46077"
                                    value={tempAddressData.pin}
                                    onChange={handleInputChange}
                                    onInput={(e) => {
                                      e.target.value = e.target.value
                                        .replace(/\D/g, "")
                                        .slice(0, 6);
                                    }}
                                    style={{
                                      WebkitAppearance: "none",
                                      MozAppearance: "textfield",
                                    }}
                                    onKeyPress={(e) => {
                                      if (
                                        !/[0-9]/.test(e.key) &&
                                        e.key !== "Backspace"
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  <ErrorMessage
                                    name="pin"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <div className="d-flex align-items-center">
                                    <Field
                                      type="checkbox"
                                      id="copy_address"
                                      className="form-control mt-2 ml-0"
                                      style={{ width: "22px", height: "22px" }}
                                      checked={copyAddress}
                                      onChange={handleCheckboxChange}
                                    />
                                    <label
                                      htmlFor="copy_address"
                                      className="form-check-label ml-2 mt-1"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Copy temporary address to main address
                                    </label>
                                  </div>
                                </div>
                                <div className="form-group col-12">
                                  <label htmlFor="address">
                                    Address <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    as="textarea"
                                    name="address"
                                    className="form-control"
                                    placeholder="123 Main St"
                                    value={addressData.address}
                                    disabled={copyAddress}
                                  />
                                  <ErrorMessage
                                    name="address"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-6">
                                  <label htmlFor="city">
                                    City <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="city"
                                    className="form-control"
                                    placeholder="Springfield"
                                    value={addressData.city}
                                    disabled={copyAddress}
                                  />
                                  <ErrorMessage
                                    name="city"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-6">
                                  <label htmlFor="state">
                                    State <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="state"
                                    className="form-control"
                                    placeholder="Illinois"
                                    value={addressData.state}
                                    disabled={copyAddress}
                                  />
                                  <ErrorMessage
                                    name="state"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-6">
                                  <label htmlFor="country">
                                    Country <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="country"
                                    className="form-control"
                                    placeholder="USA"
                                    value={addressData.country}
                                    disabled={copyAddress}
                                  />
                                  <ErrorMessage
                                    name="country"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-6">
                                  <label htmlFor="pin">
                                    PIN Code{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="number"
                                    name="pin"
                                    className="form-control"
                                    placeholder="62704"
                                    value={addressData.pin}
                                    disabled={copyAddress}
                                    onInput={(e) => {
                                      e.target.value = e.target.value
                                        .replace(/\D/g, "")
                                        .slice(0, 6);
                                    }}
                                    style={{
                                      WebkitAppearance: "none",
                                      MozAppearance: "textfield",
                                    }}
                                    onKeyPress={(e) => {
                                      if (
                                        !/[0-9]/.test(e.key) &&
                                        e.key !== "Backspace"
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  <ErrorMessage
                                    name="pin"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      )}

                      {current === 4 && (
                        <div
                          className="form-step"
                          style={{
                            border: "2px solid #007bff",
                            borderRadius: "8px",
                          }}
                        >
                          <fieldset>
                            <div className="form-card">
                              <div className="row">
                                <div className="col-7">
                                  <h2 className="fs-title mt-3 ml-3">
                                    Additional Information:
                                  </h2>
                                </div>
                                <div className="col-5">
                                  <h2 className="steps mr-3 mt-3">
                                    Step 4 - {steps}
                                  </h2>
                                </div>
                              </div>
                              <div className="row ml-3 mr-3 mt-3">
                                <div className="form-group col-4">
                                  <label htmlFor="mother_name">
                                    Mother’s Name{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="mother_name"
                                    className="form-control"
                                    placeholder="Emily Johnson"
                                  />
                                  <ErrorMessage
                                    name="mother_name"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="mother_phone">
                                    Mother’s Phone{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="mother_phone"
                                    className="form-control"
                                    placeholder="1234567890"
                                  />
                                  <ErrorMessage
                                    name="mother_phone"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="mother_email">
                                    Mother’s Email{" "}
                                    <span className="mandatory">*</span>
                                  </label>
                                  <Field
                                    type="email"
                                    name="mother_email"
                                    className="form-control"
                                    placeholder="example@gmail.com"
                                  />
                                  <ErrorMessage
                                    name="mother_email"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="mother_education">
                                    Mother’s Education{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="number"
                                    name="mother_education"
                                    className="form-control"
                                    placeholder="12 (for 12th grade)"
                                  />
                                  <ErrorMessage
                                    name="mother_education"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="mother_occupation">
                                    Mother’s Occupation{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="number"
                                    name="mother_occupation"
                                    className="form-control"
                                    placeholder="1 (for housewife)"
                                  />
                                  <ErrorMessage
                                    name="mother_occupation"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="mother_annual_income">
                                    Mother’s Annual Income{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="mother_annual_income"
                                    className="form-control"
                                    placeholder="$30,000"
                                  />
                                  <ErrorMessage
                                    name="mother_annual_income"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="mother_aadhar_no">
                                    Mother’s Aadhar No{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="mother_aadhar_no"
                                    className="form-control"
                                    placeholder="1234-5678-9012"
                                  />
                                  <ErrorMessage
                                    name="mother_aadhar_no"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="mother_pan_card">
                                    Mother’s PAN Card{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="text"
                                    name="mother_pan_card"
                                    className="form-control"
                                    placeholder="AABCT1234E"
                                  />
                                  <ErrorMessage
                                    name="mother_pan_card"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div className="form-group col-4">
                                  <label htmlFor="mother_dob">
                                    Mother’s Date of Birth{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Field
                                    type="date"
                                    name="mother_dob"
                                    className="form-control"
                                    onFocus={handleFocus}
                                    onClick={handleDateClick}
                                  />
                                  <ErrorMessage
                                    name="mother_dob"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                                <div class="form-group col-4">
                                  <label htmlFor="mother_pic">
                                    Mother Image
                                  </label>
                                  <div class="input-group">
                                    <div class="custom-file">
                                      <Field
                                        type="file"
                                        class="custom-file-input"
                                        name="mother_pic"
                                      />

                                      <label
                                        class="custom-file-label"
                                        htmlFor="mother_pic"
                                      >
                                        Choose file
                                      </label>
                                    </div>
                                  </div>
                                  <ErrorMessage
                                    name="mother_pic"
                                    component="div"
                                    className="text-error"
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      )}

                      <div className="form-navigation">
                        {current > 1 && (
                          <button
                            type="button"
                            className="previous action-button-previous"
                            onClick={prevStep}
                          >
                            Previous
                          </button>
                        )}
                        {current < steps && (
                          <button
                            type="button"
                            className="next action-button"
                            onClick={() => setCurrent(current + 1)}
                          >
                            Next
                          </button>
                        )}
                        {current === steps && (
                          <button type="submit" className="next action-button">
                            Submit
                          </button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ParentCreate;
