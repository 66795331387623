import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  deleteStaffById,
  getStaffByBranchId,
} from "../../Services/AdminApis/StaffApi";
import StaffCreate from "../CommonPages/StaffCreate";
import parentavatar from "../../Assests/avatar-icon0002_750950-43.avif";
import SchoolGrid from "./SchoolGrid";
import StaffGridView from "../CommonPages/StaffGridView";
import {
  deletePickUpRequest,
  getAllPickUpRequests,
} from "../../Services/AdminApis/PickupRequestApi";
import PickUpRequest from "./PIckUpRequest";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";

const PickUpRequestList = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const { branchId, schoolId } = useParams();
  const [requestEdit, setRequesteEdit] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [staff, setStaff] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        const response = await deletePickUpRequest(id);
        Swal.close();
        showSuccessAlert(
          "Deleted!",
          response.data.message || "Data Deleted Successfully"
        );
        // setRefresh((prev) => !prev);
        window.location.reload();
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";

        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The staff member is safe :)");
    }
  };

  const [requestId, setRequestiId] = useState(null);

  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id", title: "S.No" },
        { data: "student_id.name", title: "First Name" },
        { data: "pickup_date", title: "Pick Up Date" },
        {
          data: "pickup_time",
          title: "PickUp Time",
        },
        { data: "reason", title: "Reason" },
        { data: "pickup_by.name", title: "Pickup By" },
        { data: "drop_status.name", title: "Drop Status" },
        { data: "comments", title: "PickUp Comments" },
        { data: "drop_comments", title: "Drop Comments" },

        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const isDisabled =
              row.drop_status.id == 2 || row.status == 0
                ? 'style="pointer-events: none; opacity: 0.5;"'
                : "";

            return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="#" title="Edit" data-id="${row.id}" class="edit-icon" ${isDisabled}>
                  <i class="fas fa-edit"></i>
                </a>
                <a href="#" title="Delete" data-id="${row.id}" class="delete-icon" ${isDisabled}>
                  <i class="fas fa-trash"></i>
                </a>
              </div>
            `;
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "csv",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "excel",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "pdf",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "print",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
        emptyTable: "No Pick Up Requests Found",
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllPickUpRequests();

          let jsonData = response.data.data || [];
          setStaff(response.data.data);
          let totalCount = response.data.total || 0;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      console.log(id);
      setRequestiId(id);
      setRequesteEdit(true);
    });
    $(tableRef.current).on("click", ".drop-status-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      console.log(id);
      setModalOpen(true);
    });

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy(true);
      }
    };
  }, [refresh, branchId]);

  return (
    <>
      {requestEdit ? (
        <PickUpRequest requestId={requestId} />
      ) : (
        <div className="card p-3 mt-4">
          <div className="card-header">
            <h3 className="card-title">PickUp Request List</h3>
          </div>

          <div className="card-body">
            <table
              ref={tableRef}
              id="example6"
              className="table table-bordered table-striped"
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>First Name</th>
                  <th>Pick Up Date</th>
                  <th>Pick Time</th>
                  <th>Reason</th>
                  <th>pickUp By</th>
                  <th>Drop Status</th>
                  <th>PickUpCommments</th>
                  <th>Drop Comments</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody></tbody>
              <tfoot>
                <tr>
                  <th>S.No</th>
                  <th>First Name</th>
                  <th>Pick Up Date</th>
                  <th>Pick Up Time</th>
                  <th>Reason</th>
                  <th>pickUp By</th>
                  <th>Drop Status</th>
                  <th>PickUp Comments</th>
                  <th>Drop Comments</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default PickUpRequestList;
