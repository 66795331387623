import React, { useState, useEffect } from "react";
import { useLocation, Link, useParams } from "react-router-dom";
import { getSession } from "../../Services/Storage";
import { COOKIE } from "../../Schemas/cookieNames";
import { USERROLEID } from "../../Schemas/userRoles";
import { getBranchById } from "../../Services/CommonApis/branchApi";
import {
  getBranchId,
  getSchoolId,
  getUserRoleId,
  getUserId
} from "../../Utility/CommonUtility/extractUserDetailsFromToken";
import Swal from "sweetalert2";
import { getMenu } from "../../Services/AdminApis/SchoolManagersidebarApi";

function SchoolManagerAdminSidebar() {
  const [schoolId, setSchoolId] = useState(null);
  const [branchId, setBranchId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const location = useLocation();
  const adminToken = getSession(COOKIE.ADMIN_TOKEN);
  const [branchData, setBranchData] = useState({});
  const [branchImage, setBranchImage] = useState(null);
  const [menuList, setMenuList] = useState([]);
  const { id, schoolIds } = useParams();
  useEffect(() => {
    if (adminToken) {
      setRoleId(getUserRoleId(adminToken));
      if (roleId === USERROLEID.BRANCH_ID) {
        setSchoolId(getSchoolId(adminToken));
        setBranchId(getBranchId(adminToken));
      } else {
        setSchoolId(getSession(COOKIE.SCHOOL_ID));
        setBranchId(getSession(COOKIE.BRANCH_ID));
      }
    }
  }, [adminToken, roleId]);
  useEffect(() => {
    if (schoolId) {
      getMenuList();
    }
  }, [schoolId]);
  const getMenuList = async () => {
    try {
      const userId = getUserId(adminToken);
      const response = await getMenu(userId, schoolId);
      setMenuList(response);
    } catch (error) {
      console.error("Error fetching menu list:", error);
    }
  };
  useEffect(() => {
    if (branchId) {
      fetchBranchDetails(branchId);
    }
  }, [branchId]);
  const fetchBranchDetails = async (id) => {
    try {
      const response = await getBranchById(id);
      const branch = response.data.branch;
      setBranchData(branch);
      setBranchImage(branch.branch_meta.logo_file);
      const savedPopupState = localStorage.getItem("isPopup") === "true";
      if (savedPopupState) {
        Swal.fire({
          html: `Welcome! <strong>${branch.branch_name}</strong>`,
          icon: "success",
          confirmButtonText: "Okay",
        });

        localStorage.setItem("isPopup", "false");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getActiveLinkStyles = (path) => ({
    backgroundColor: location.pathname === path ? "white" : "",
    color: location.pathname === path ? "#808080" : "",
  });
  const getIconForMenu = (menuName) => {
    switch (menuName) {
      case "School Settings": return "fa-solid fa-school-flag";
      case "Users": return "fa fa-users";
      case "Admission": return "fas fa-user-plus";
      case "Notifications": return "fas fa-bell";
      case "Fees Setup": return "fas fa-money-bill-wave";
      case "Report Card Config": return "fas fa-file-alt";
      case "Certificates": return "fas fa-certificate";
      case "Transport Setup": return "fas fa-bus";
      case "Library": return "fas fa-book";
      default: return "fas fa-circle";
    }
  };
  const handleToggle = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu);
  };
  const links = [
    {
      path: `/school/${schoolId}/branch/${branchId}/dashboard`,
      label: "Dashboard",
      icon: "fas fa-home",
    },
  ];
  const SchoolSettings = [
    {
      path: `/school/${schoolId}/branch/${branchId}/class`,
      label: "Class",
      icon: "fas fa-chalkboard-teacher",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/section`,
      label: "Section",
      icon: "fas fa-columns",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/subject`,
      label: "Subject",
      icon: "fas fa-book",
    },
  ];
  const Certificates = [
    {
      path: `/school/${schoolId}/branch/${branchId}/certificate-list`,
      label: "Certificate List",
      icon: "fa fa-certificate",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/certificate-field-list`,
      label: " Certificate Field List",
      icon: "fas fa-list",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/generate-certificate`,
      label: "Generate Certificate",
      icon: "fas fa-file",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/generate-certificate-list`,
      label: "Student Certificate",
      icon: "fas fa-award",
    },
  ];
  const Users = [
    {
      path: `/school/${schoolId}/branch/${branchId}/staff`,
      label: "Staff",
      icon: "fa-solid fa-user",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/student`,
      label: "Student",
      icon: "fas fa-user-graduate",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/parent`,
      label: "Parent",
      icon: "fas fa-book-open",
    },
  ];
  const academicNotificationsLinks = [
    {
      path: `/school/${schoolId}/branch/${branchId}/attendance`,
      label: "Attendance",
      icon: "fas fa-user-check",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/homework`,
      label: "Homework",
      icon: "fas fa-book-open",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/holiday`,
      label: "Holiday",
      icon: "fas fa-calendar-day",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/leave-list`,
      label: "Leave Notifications",
      icon: "fas fa-tasks",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/shifts`,
      label: "Staff Notifications",
      icon: "fas fa-clock",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/groups`,
      label: "Groups",
      icon: "fa-solid fa-user-group",
    },
  ];
  const Admission = [
    {
      path: `/school/${schoolId}/branch/${branchId}/admission`,
      label: "Announcement",
      icon: "fas fa-book-open",
    },
  ];
  const academicFeesSetup = [
    {
      path: `/school/${schoolId}/branch/${branchId}/fees-dashboard`,
      label: "Fee Dashboard",
      icon: "fa fa-wallet",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/fees-list`,
      label: "Fee Components",
      icon: "fas fa-dollar-sign",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/fees-sub-components-list`,
      label: "Fee Sub Components",
      icon: "fas fa-credit-card",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/academic-fee-setup`,
      label: "Fee Setup",
      icon: "fa fa-wallet",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/fee-concession`,
      label: "Fee Concession",
      icon: "fa fa-receipt",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/fee-payments`,
      label: "Fee Payments",
      icon: "fa fa-money-bill-wave",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/fee-transaction`,
      label: "Fee Transaction",
      icon: "fa fa-receipt",
    },
  ];
  const ReportCardMenu = [
    {
      path: `/school/${schoolId}/branch/${branchId}/reportcard-dashboard`,
      label: "ReportCard Dashboard",
      icon: "fas fa-clipboard-list",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/exam-setup`,
      label: "Exam Setup",
      icon: "fas fa-clipboard-list",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/exam-grade-setup`,
      label: "Exam Grade Setup",
      icon: "fa fa-check-circle",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/exam-report-lock`,
      label: "Report Lock",
      icon: "fa fa-lock",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/remarks`,
      label: "Remark",
      icon: "fas fa-pencil-alt",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/personality-traits`,
      label: "Personality Traits",
      icon: "fas fa-lightbulb",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/report-configuration`,
      label: "Report Card Config",
      icon: "fa fa-wallet",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/marks-entry`,
      label: "Marks Entry",
      icon: "fa fa-keyboard",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/report`,
      label: "Report Cards",
      icon: "fa fa-check-circle",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/overall`,
      label: "Overall Reports",
      icon: "fa-solid fa-bars-progress",
    },
  ];
  const transportSetup = [
    {
      path: `/school/${schoolId}/branch/${branchId}/route`,
      label: "Route",
      icon: "fas fa-route",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/transport`,
      label: "Stoppages",
      icon: "fas fa-map-marker-alt",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/transport-vehicles`,
      label: "Transport Vehicle",
      icon: "fas fa-truck",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/transport-pilot`,
      label: "Transport Pilot",
      icon: "fas fa-user-tie",
    },
    {
      path: `/school/${schoolId}/branch/${branchId}/transport-details`,
      label: "Transport Details",
      icon: "fas fa-user-tiefas fa-clipboard-list",
    },
  ];
  const libraryPaths = [
    {
      path: `/school/${schoolId}/branch/${branchId}/library`,
      label: "Books",
      icon: "fas fa-book",
    },
  ];
  const getSubmenuForMainMenu = (menuName) => {
    switch (menuName) {
      case "School Settings":
        return SchoolSettings;
      case "Certificates":
        return Certificates;
      case "Users":
        return Users;
      case "Notifications":
        return academicNotificationsLinks;
      case "Admission":
        return Admission;
      case "Fees Setup":
        return academicFeesSetup;
      case "Report Card Config":
        return ReportCardMenu;
      case "Transport Setup":
        return transportSetup;
      case "Library":
        return libraryPaths;
      default:
        return [];
    }
  };
  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {roleId === USERROLEID.SCHOOL_ID || roleId === USERROLEID.BRANCH_ID || roleId === USERROLEID.SCHOOLMANAGER_ID ? (
          <Link
            to={`/school/${schoolId}/branch/${branchId}/dashboard`}
            className="brand-link"
          >
            {branchImage ? (
              <img
                src={`${process.env.REACT_APP_IMAGEURL}/${branchImage}`}
                alt="School Logo"
                className="brand-image img-circle elevation-3"
              />
            ) : (
              <img
                src="/plugins/dist/img/profile-dummy-image.jpg"
                alt="Default Logo"
                className="brand-image img-circle elevation-3"
              />
            )}
            <span className="brand-text font-weight-light">
              {branchData.branch_name || "Loading..."}
            </span>
          </Link>
        ) : (
          <Link to="/admin/dashboard" className="brand-link">
            <img
              src="/plugins/dist/img/Logo.png"
              alt="Admin Logo"
              className="brand-image img-circle elevation-3"
            />
            <span className="brand-text font-weight-light">SR EDU KYC</span>
          </Link>
        )}
        <div className="sidebar">
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              {links.map((link) => (
                <li key={link.path} className="nav-item">
                  <Link
                    to={link.path}
                    className={`nav-link ${location.pathname === link.path ? "active-sub" : ""}`}
                    style={getActiveLinkStyles(link.path)}
                  >
                    <i className={`nav-icon ${link.icon}`}></i>
                    <p>{link.label}</p>
                  </Link>
                </li>
              ))}
              {menuList.map((menuItem) => (
                <li key={menuItem["menu-id"]} className={`nav-item has-treeview ${openMenu === menuItem.name ? "menu-open" : ""}`}>
                  <a
                    className={`nav-link ${openMenu === menuItem.name ? "active" : ""}`}
                    onClick={() => handleToggle(menuItem.name)}
                  >
                    <i className={`nav-icon ${getIconForMenu(menuItem.name)}`}></i>
                    <p>
                      {menuItem.name}
                      <i className="fas fa-angle-left right"></i>
                    </p>
                  </a>
                  {openMenu === menuItem.name && (
                    <ul className="nav nav-treeview">
                      {getSubmenuForMainMenu(menuItem.name).map((subItem) => (
                        <li key={subItem.path} className="nav-item">
                          <Link
                            to={subItem.path}
                            className={`nav-link ${location.pathname === subItem.path ? "active-sub" : ""}`}
                            style={getActiveLinkStyles(subItem.path)}
                          >
                            <i className={`nav-icon ${subItem.icon}`}></i>
                            <p>{subItem.label}</p>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
}
export default SchoolManagerAdminSidebar;