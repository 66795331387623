import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { getSession } from "../../Services/Storage";
import { COOKIE } from "../../Schemas/cookieNames";
import { USERROLEID } from "../../Schemas/userRoles";

function ParentSideBar() {
  const [schoolId, setSchoolId] = useState(null);
  const [branchId, setBranchId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const location = useLocation();

  const getActiveLinkStyles = (path) => ({
    backgroundColor: location.pathname === path ? "white" : "",
    color: location.pathname === path ? "#808080" : "",
  });

  const handleToggle = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu);
  };

  const links = [
    {
      path: `/parent/student-profile`,
      label: "Student Profile",
      icon: "fas fa-chalkboard-teacher",
    },
    {
      path: `/parent/fees-pay`,
      label: "Fees Pay",
      icon: "fas fa-dollar-sign",
    },
  ];

  const feesSubmenu = [
    {
      path: `/parent/academic-fee`,
      label: "Academic Fee",
      icon: "fas fa-book",
    },
    {
      path: `/parent/transport-fee`,
      label: "Transport Fee",
      icon: "fas fa-bus",
    },
    {
      path: `/parent/hostel-fee`,
      label: "Hostel Fee",
      icon: "fas fa-bed",
    },
    {
      path: `/parent/other-fee`,
      label: "Other Fee",
      icon: "fas fa-clipboard-list",
    },
  ];

  const attendanceSubmenu = [
    {
      path: `/parent/student-attendance`,
      label: " View Attendance",
      icon: "fas fa-user-check",
    },
    {
      path: `/parent/student-leaves`,
      label: " Leaves",
      icon: "fas fa-calendar-alt",
    },
  ];

  const TransportSubmenu = [
    {
      path: `/parent/student-pickuprequest`,
      label: "Pickup Request",
      icon: "fas fa-truck-pickup",
    },
  ];

  const requestSubmenu = [
    {
      path: `/parent/it-form-request`,
      label: "IT Form Request",
      icon: "fas fa-file-alt",
    },
    {
      path: `/parent/bonafide-request`,
      label: "Bonafide Request",
      icon: "fas fa-certificate",
    },
    {
      path: `/parent/tc-request`,
      label: "TC Request",
      icon: "fas fa-graduation-cap",
    },
  ];

  const helpdeskSubmenu = [
    {
      path: `/parent/feedback-list`,
      label: "Concerns/Feedback List",
      icon: "fas fa-comment-dots",
    },
    {
      path: `/parent/concerns`,
      label: "Concerns From School",
      icon: "fas fa-school",
    },
  ];

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {roleId === USERROLEID.PARENT_ID ? (
        <Link to={`/parent/student-profile`} className="brand-link">
          <img
            src="/plugins/dist/img/Logo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
          />
          <span className="brand-text font-weight-light">SR EDU KYC</span>
        </Link>
      ) : (
        <Link to="/parent/student-profile" className="brand-link">
          <img
            src="/plugins/dist/img/Logo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
          />
          <span className="brand-text font-weight-light">SR EDU KYC</span>
        </Link>
      )}

      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="/plugins/dist/img/user2-160x160.jpg"
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info">
            <Link to={`/parent/student-profile`} className="d-block">
              Alexander Pierce
            </Link>
          </div>
        </div>

        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {links.map(({ path, label, icon }) => (
              <li className="nav-item" key={path}>
                <Link
                  to={path}
                  className={`nav-link ${
                    location.pathname === path ? "active-sub" : ""
                  }`}
                  style={getActiveLinkStyles(path)}
                >
                  <i className={`nav-icon ${icon}`}></i>
                  <p>{label}</p>
                </Link>
              </li>
            ))}

            {/* Fees Menu */}
            <li
              className={`nav-item has-treeview ${
                openMenu === "fees" ? "menu-open" : ""
              }`}
            >
              <a
                className={`nav-link ${openMenu === "fees" ? "active" : ""}`}
                onClick={() => handleToggle("fees")}
              >
                <i className="nav-icon fas fa-money-bill-wave"></i>
                <p>
                  Fees
                  <i className="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                {feesSubmenu.map(({ path, label, icon }) => (
                  <li className="nav-item" key={path}>
                    <Link
                      to={path}
                      className={`nav-link ${
                        location.pathname === path ? "active-sub" : ""
                      }`}
                      style={getActiveLinkStyles(path)}
                    >
                      <i className={`nav-icon ${icon}`}></i>
                      <p>{label}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            {/* Student Attendance Menu */}
            <li
              className={`nav-item has-treeview ${
                openMenu === "attendance" ? "menu-open" : ""
              }`}
            >
              <a
                className={`nav-link ${
                  openMenu === "attendance" ? "active" : ""
                }`}
                onClick={() => handleToggle("attendance")}
              >
                <i className="nav-icon fas fa-user-check"></i>
                <p>
                  Student Attendance
                  <i className="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                {attendanceSubmenu.map(({ path, label, icon }) => (
                  <li className="nav-item" key={path}>
                    <Link
                      to={path}
                      className={`nav-link ${
                        location.pathname === path ? "active-sub" : ""
                      }`}
                      style={getActiveLinkStyles(path)}
                    >
                      <i className={`nav-icon ${icon}`}></i>
                      <p>{label}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            {/* Student Transport Menu */}
            <li
              className={`nav-item has-treeview ${
                openMenu === "Transport" ? "menu-open" : ""
              }`}
            >
              <a
                className={`nav-link ${
                  openMenu === "Transport" ? "active" : ""
                }`}
                onClick={() => handleToggle("Transport")}
              >
                <i className="nav-icon fas fa-bus-alt"></i>
                <p>
                   Transport
                  <i className="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                {TransportSubmenu.map(({ path, label, icon }) => (
                  <li className="nav-item" key={path}>
                    <Link
                      to={path}
                      className={`nav-link ${
                        location.pathname === path ? "active-sub" : ""
                      }`}
                      style={getActiveLinkStyles(path)}
                    >
                      <i className={`nav-icon ${icon}`}></i>
                      <p>{label}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            {/* Request Menu */}
            <li
              className={`nav-item has-treeview ${
                openMenu === "request" ? "menu-open" : ""
              }`}
            >
              <a
                className={`nav-link ${openMenu === "request" ? "active" : ""}`}
                onClick={() => handleToggle("request")}
              >
                <i className="nav-icon fas fa-file-alt"></i>
                <p>
                  Request
                  <i className="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                {requestSubmenu.map(({ path, label, icon }) => (
                  <li className="nav-item" key={path}>
                    <Link
                      to={path}
                      className={`nav-link ${
                        location.pathname === path ? "active-sub" : ""
                      }`}
                      style={getActiveLinkStyles(path)}
                    >
                      <i className={`nav-icon ${icon}`}></i>
                      <p>{label}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            {/* Download Documents Menu */}
            <li className="nav-item">
              <Link
                to="/parent/holiday-list"
                className={`nav-link ${
                  location.pathname === "/parent/holiday-list" ? "active" : ""
                }`}
                style={getActiveLinkStyles("/parent/holiday-list")}
              >
                <i className="nav-icon fas fa-calendar"></i>
                <p>Holiday List</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/parent/id-card"
                className={`nav-link ${
                  location.pathname === "/parent/id-card" ? "active" : ""
                }`}
                style={getActiveLinkStyles("/parent/id-card")}
              >
                <i className="nav-icon fas fa-id-card"></i>
                <p>ID Card</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/parent/health-form"
                className={`nav-link ${
                  location.pathname === "/parent/health-form" ? "active" : ""
                }`}
                style={getActiveLinkStyles("/parent/health-form")}
              >
                <i className="nav-icon fas fa-file-medical"></i>
                <p>Health Form</p>
              </Link>
            </li>

            {/* Helpdesk Menu */}
            <li
              className={`nav-item has-treeview ${
                openMenu === "helpdesk" ? "menu-open" : ""
              }`}
            >
              <a
                className={`nav-link ${
                  openMenu === "helpdesk" ? "active" : ""
                }`}
                onClick={() => handleToggle("helpdesk")}
              >
                <i className="nav-icon fas fa-headset"></i>
                <p>
                  Helpdesk
                  <i className="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                {helpdeskSubmenu.map(({ path, label, icon }) => (
                  <li className="nav-item" key={path}>
                    <Link
                      to={path}
                      className={`nav-link ${
                        location.pathname === path ? "active-sub" : ""
                      }`}
                      style={getActiveLinkStyles(path)}
                    >
                      <i className={`nav-icon ${icon}`}></i>
                      <p>{label}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default ParentSideBar;
