import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import {
  deleteLibraryBookById,
  getLibraryBooksList,
} from "../../Services/AdminApis/LibraryApi";
import { useParams } from "react-router-dom";
import AdminBookEdit from "../AdminPages/AdminBookEdit";


const AdminBooksList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [bookEdit, setBookEdit] = useState(false);
  const {branchId} = useParams()

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to delete the book!",
      "warning",
      "Yes, Delete!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteLibraryBookById(id);
        showSuccessAlert("Deleted!", "The book has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        showErrorAlert("Error!", "There was an error deleting the book.");
        console.error(error);
      } finally {
        Swal.close();
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The book is safe 😊");
    }
  };

  const [bookId, setBookId] = useState(null);

  useEffect(() => {
      tableInstance.current = $(tableRef.current).DataTable({
        destroy: true,
        columns: [
          { data: "id", title: "S.No" },
          { data: "name", title: "Name" },
          { data: "title", title: "Title" },
          { data: "description", title: "Description" },
          { data: "price", title: "Price" },
          { data: "author", title: "Author" },
          { data: "publisher", title: "Publisher" },
          { data: "display_name", title: "Display Name" },
          { data: "published_date", title: "Published Date" },

          {
            data: "status",
            title: "Status",
            render: (data) => (data === "1" ? "Active" : "Inactive"),
          },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data, type, row) => `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="#" title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
                <a href="" title="Edit" data-id=${row.id} class="edit-icon"><i class="fas fa-edit"></i></a>
              </div>
            `,
          },
        ],
        lengthChange: true,
        order:true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          { extend: "copy", exportOptions: { columns: ":visible" } },
          { extend: "csv", exportOptions: { columns: ":visible" } },
          { extend: "excel", exportOptions: { columns: ":visible" } },
          { extend: "pdf", exportOptions: { columns: ":visible" } },
          { extend: "print", exportOptions: { columns: ":visible" } },
        ],
        serverSide: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 75, 100, -1],
          [10, 25, 50, 75, 100, "All"],
        ],
        language: {
          paginate: {
            previous: "Back",
            next: "Next",
            first: "First",
            last: "Last",
          },
          emptyTable: "No Books Data Found",
        },
        ajax: async function (data, callback) {
          try {
            const start = data.start || 0;
            const length = data.length || 10;
            const page = Math.floor(start / length) + 1;
            const orderColumnIndex =
              data.order && data.order.length > 0 ? data.order[0].column : 0;
            const orderDirection =
              data.order && data.order.length > 0 ? data.order[0].dir : "desc";
            const orderColumn = data.columns[orderColumnIndex].data;
            const searchValue = data.search.value;

            const response = await getLibraryBooksList({
              branch_id: branchId,
              page: page,
              _limit: length,
              _sort: orderColumn,
              _order: orderDirection,
              q: searchValue,
            });

            const jsonData = response.data.books || [];
            const totalCount = response.data.total || 0;

            callback({
              draw: data.draw,
              recordsTotal: totalCount,
              recordsFiltered: totalCount,
              data: jsonData.length > 0 ? jsonData : [],
            });
          } catch (error) {
            console.error("Error fetching data:", error);
            showErrorAlert(error.response.data.message || "Data Not Found");
            setTimeout(() => {
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }, 5000);
          }
        },
      });

      $(tableRef.current).on("click", ".delete-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
        handleDelete(id);
      });

      $(tableRef.current).on("click", ".edit-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
        setBookId(id);
        setBookEdit(true);
      });

      return () => {
        if (tableInstance.current) {
          tableInstance.current.destroy(true);
        }
      };
  }, []);

  return (
    <>
      {bookEdit ? (
        <AdminBookEdit bookId={bookId}/>
      ) : (
        <div className="card">
          <div className="card-header">
            <h3 style={{ paddingTop: "5px" }} className="card-title">
              Library Books List
            </h3>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <table
                    ref={tableRef}
                    className="table table-striped table-bordered"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Author</th>
                        <th>Publisher</th>
                        <th>Display Name</th>
                        <th>Published Date</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                    <tfoot>
                      <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Author</th>
                        <th>Publisher</th>
                        <th>Display Name</th>
                        <th>Published Date</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminBooksList;
