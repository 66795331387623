import React, { useState, useEffect, useRef } from "react";
import * as XLSX from "xlsx";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../../Styles/SchoolUpload.css";
import handleDownload from "../../Utility/CommonUtility/BranchExcel";
import { BranchExcelValidation } from "../../Utility/CommonUtility/Validation";
import { uploadBranch } from "../../Services/CommonApis/branchApi";
import { getUploadProgress } from "../../Services/CommonApis/ProgressApi";
import { useParams } from "react-router-dom";
import {
  showSweetAlert,
  showLoadingAlert,
  showSuccessAlert,
  showErrorAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useData } from "../../Utility/CommonUtility/DataContext";

const BranchUpload = () => {
  const [fileName, setFileName] = useState("No file chosen");
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [isUploadEnabled, setIsUploadEnabled] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [message, setMessage] = useState({ type: "", content: "" });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [batchId, setBatchId] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progressIntervalId, setProgressIntervalId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { id } = useParams();
  const { academic } = useData();
  const academicId = academic.length > 0 ? academic[0]?.id : null;

  useEffect(() => {
    return () => {
      if (progressIntervalId) clearInterval(progressIntervalId);
    };
  }, [progressIntervalId]);

  useEffect(() => {
    if (message.type === "success") {
      const duration = 3000;
      const fadeDuration = 500;

      const showTimer = setTimeout(() => {
        setMessage((prev) => ({
          ...prev,
          type: "fade-out",
        }));
      }, duration);

      const clearTimer = setTimeout(() => {
        setMessage({ type: "", content: "" });
      }, duration + fadeDuration);

      return () => {
        clearTimeout(showTimer);
        clearTimeout(clearTimer);
      };
    }
  }, [message]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFileName(selectedFile ? selectedFile.name : "No file chosen");
    setMessage({ type: "", content: "" });
    handleShowData(selectedFile);
    setFile(selectedFile);
  };

  const handleShowData = (selectedFile) => {
    if (!selectedFile) {
      setMessage({ type: "error", content: "No file selected" });
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const errors = BranchExcelValidation(jsonData);

      if (errors.length === 0) {
        setTableData(jsonData);
        setMessage({
          type: "confirmation",
          content: "Please upload data by clicking the upload button",
        });
        setValidationErrors([]);
        setIsUploadEnabled(true);
      } else {
        setMessage({
          type: "error",
          content: "Data validation failed. Please check the file.",
        });
        setValidationErrors(errors);
        setTableData(jsonData);
        setIsUploadEnabled(false);
      }
    };

    reader.readAsArrayBuffer(selectedFile);
  };

  const handleClear = () => {
    setFileName("No file chosen");
    setTableData([]);
    setMessage({ type: "", content: "" });
    setValidationErrors([]);
    setIsUploadEnabled(false);
    setFile(null);
  };

  const handleUpload = async (event) => {
    event.preventDefault();

    if (!file) {
      showErrorAlert("Error", "No file selected for upload.");
      return;
    }

    const result = await showSweetAlert(
      "Are you sure?",
      "You want to upload the data",
      "info",
      "Yes, upload",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("school_id", id);
        formData.append("academic_id", academicId);

        setIsUploading(true);
        setUploadProgress(0);

        const response = await uploadBranch(formData);

        if (response.status === 200) {
          const batchId = response.data.batch_id;
          setBatchId(batchId);
          setFileName("No file chosen");
          setMessage({
            type: "success",
            content: "File uploaded successfully.",
          });
          setTableData([]);
          setValidationErrors([]);
          setIsUploading(true);
          pollUploadProgress(batchId);
        }
      } catch (error) {
        const errors = error.response?.data?.message || {};
        for (const field in errors) {
          if (errors.hasOwnProperty(field)) {
            const fieldErrors = errors[field];
            if (Array.isArray(fieldErrors)) {
              fieldErrors.forEach((errorMessage, index) => {
                showErrorAlert(
                  `Validation Error for ${field} - ${index + 1}`,
                  errorMessage
                );
              });
            } else {
              showErrorAlert(`Validation Error for ${field}`, fieldErrors);
            }
          }
        }
        setIsUploading(false);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Your file upload has been cancelled.");
    }
  };

  const pollUploadProgress = (batchId) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await getUploadProgress(batchId);
        const progress = response?.data?.batch?.progress;

        setUploadProgress(progress);

        if (progress >= 100) {
          clearInterval(intervalId);
          setProgressIntervalId(null);
          setIsUploading(false);

          setTimeout(() => {
            showSuccessAlert("Upload Completed", "File uploaded successfully.");
            setMessage({
              type: "confirmation",
              content: "File upload completed successfully!",
            });
          }, 1000);
        }
      } catch (error) {
        console.error("Error fetching progress:", error);

        clearInterval(intervalId);
        setProgressIntervalId(null);
        setIsUploading(false);
        setIsUploadEnabled(false);
        showErrorAlert("Error", "There was an issue fetching upload progress.");
      }
    }, 1000);
    setProgressIntervalId(intervalId);
  };

  const onPageChange = (event) => {
    setCurrentPage(event.page);
    setRowsPerPage(event.rows);
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Branch Excel Upload</h3>
          <div className="card-tools">
            <button className="btn btn-primary ml-5" onClick={handleDownload}>
              <i className="pr-1 nav-icon fa fa-file-excel"></i>
              Download Sample
            </button>
          </div>
          <br />
          <hr />
          <div className="form-group">
            <label htmlFor="exampleInputFile">File input</label>
            <div className="row">
              <div className="input-group col-6">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="exampleInputFile"
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="exampleInputFile"
                  >
                    {fileName}
                  </label>
                </div>
                <div className="card-tools" style={{ marginLeft: "10px" }}>
                  <button className="btn btn-secondary" onClick={handleClear}>
                    <i className="pr-1 nav-icon fa fa-ban"></i>
                    Clear
                  </button>
                  <button
                    className="btn btn-primary"
                    style={{ marginLeft: "5px" }}
                    disabled={isUploading}
                    onClick={handleUpload}
                  >
                    <i className="pr-1 nav-icon fa fa-upload"></i>
                    Upload
                  </button>
                </div>
              </div>
              {message.content && (
                <div
                  className={`excel-alert col-6 ${
                    message.type === "error"
                      ? "error-alert-danger"
                      : message.type === "confirmation"
                      ? "excel-alert-warning"
                      : message.type === "success"
                      ? "excel-alert-success"
                      : ""
                  } ${message.type === "fade-out" ? "fade-out" : "fade-in"}`}
                  role="alert"
                >
                  {message.content}
                </div>
              )}
            </div>
          </div>
        </div>

        {isUploading && (
          <div
            className="progress"
            style={{
              width: "100%",
              marginTop: "15px",
              marginBottom: "15px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow={uploadProgress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${uploadProgress}%` }}
            >
              {uploadProgress}%
            </div>
          </div>
        )}

        <div className="card-body">
          <DataTable
            value={tableData.slice(1)}
            paginator
            responsiveLayout="scroll"
            rows={rowsPerPage}
            first={currentPage * rowsPerPage}
            onPage={onPageChange}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(e.rows);
              setCurrentPage(0);
            }}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            totalRecords={tableData.length}
            className="p-datatable-sm p-datatable-gridlines custom-datatable"
          >
            {tableData[0]?.map((col, index) => (
              <Column
                key={index}
                field={col}
                header={col}
                style={{ textAlign: "center" }}
                body={(rowData) => {
                  const error = validationErrors.find(
                    (err) => err.rowIndex === tableData.indexOf(rowData)
                  );
                  const isEmpty =
                    rowData[index] === undefined || rowData[index] === "";
                  const hasError =
                    error && Object.keys(error).some((key) => error[key]);

                  return (
                    <span
                      className={`cell-content ${isEmpty ? "empty-cell" : ""} ${
                        hasError ? "error-cell" : ""
                      }`}
                    >
                      {isEmpty ? "Empty" : rowData[index]}
                    </span>
                  );
                }}
              />
            ))}
          </DataTable>

          {validationErrors.length > 0 && (
            <div className="validation-errors mt-2">
              <h4>Validation Errors:</h4>
              <ul>
                {validationErrors.map((error, index) => (
                  <li key={index} className="row-error">
                    <strong>Row {error.rowIndex}:</strong>
                    <ul className="error-detail">
                      {Object.keys(error)
                        .filter((key) => key !== "rowIndex")
                        .map((key, errorIndex) => (
                          <li key={errorIndex}>{error[key]}</li>
                        ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BranchUpload;
