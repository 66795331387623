import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import { useData } from "../../Utility/CommonUtility/DataContext";
import {
  getUserRoleId,
  getUserRoleName,
} from "../../Utility/CommonUtility/extractUserDetailsFromToken";
import { getSession, setSession } from "../../Services/Storage";
import { COOKIE } from "../../Schemas/cookieNames";

import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import { useState } from "react";
import { Cookie } from "@mui/icons-material";
import { getAllUserVisits } from "../../Services/AdminApis/UserVisitApi";
import "primeicons/primeicons.css";

const UserVisits = () => {
  const tableRef = useRef(null);
  const { academic } = useData();
  const [refresh, setRefresh] = useState(false);
  const [showError, setShowError] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const adminToken = getSession(COOKIE.ADMIN_TOKEN);
  const [error, setError] = useState("");

  let academicId = getSession(COOKIE.ACADEMIC_YEAR);
  if (!academicId) {
    academicId = academic.length > 0 ? academic[0]?.id : null;
    setSession(COOKIE.ACADEMIC_YEAR, academicId);
  }
  let roleName = getUserRoleName(adminToken).toLowerCase();
  useEffect(() => {
    if (adminToken) {
      setRoleId(getUserRoleId(adminToken));
    }
  }, [roleId, roleName]);
  const showAllButtons = roleName !== "school-manager";
  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "user_name" },
        { data: "total_visits" },
        {
          data: (row) => row.last_visited_ip ?? null,
        },
        { data: "last_visited_at" },
        {
          data: (row) => {
            const isPresent = row.using_app === "yes";
            return `<i class="pi ${isPresent ? "pi-check" : "pi-times"}" 
                      style="color: ${isPresent ? "green" : "red"}; margin-right: 5px;">
                    </i>`;
          },
        },
      ],
      
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "csv",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "excel",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "pdf",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "print",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
        emptyTable: "No Data Found",
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllUserVisits({
            page: page,
            _limit: length,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });

          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;
          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (err) {
          console.error("Error fetching data:", error);
          showErrorAlert(err.response.data.message);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    return () => {
      table.destroy();
    };
  }, [refresh]);

  return (
    <>
      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 className="card-title">User Visit Log List</h3>
        </div>
        <div className="card-body">
          <table
            ref={tableRef}
            id="example1"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>User Name</th>
                <th>Total Visits</th>
                <th>Last Visited IP</th>
                <th>Last Visited At</th>
                <th>App Download</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>ID</th>
                <th>User Name</th>
                <th>Total Visits</th>
                <th>Last Visited IP</th>
                <th>Last Visited At</th>
                <th>App Download</th>
              </tr>
            </tfoot>
          </table>
          {showError && (
            <div
              className={`error-alert error-alert-danger ${
                showError ? "fade-in" : "fade-out"
              }`}
              onAnimationEnd={() => {
                if (!showError) setError("");
              }}
            >
              {error}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default UserVisits;
