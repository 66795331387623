import Api from "../Api";

export const getAllPersonalityTraits = (params) => {
  return Api.get(`/get-all-personality-traits`, {
    params
  });
};

export const createPersonalityTraits = (PersonalityTraitsData) => {
  return Api.post(`/create-personality`, PersonalityTraitsData);
};

export const getPersonalityTraitsById = async (id) => {
  const response = await Api.get(`/get-personality-traits/${id}`);
  return response.data;
};

export const updatePersonalityTraitskById = async (id, data) => {
  const response = await Api.post(`/update-personality-traits/${id}`, data);
  return response;
};

export const deletePersonalityTraitsById = async (id) => {
  const response = await Api.delete(`/delete-personality_Traits/${id}`);
  return response.data;
};
