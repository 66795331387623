import Api from "../Api";
export const getMenu = async (user_id, schoolIds) => {
    try {
      const response = await Api.get(`/school-manager-menu/${user_id}`, {
        params: {
          school_id: schoolIds,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };