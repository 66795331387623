import Api from "../Api";

export const getBranchBySchoolId = async (id, params) => {
  try {
    const response = await Api.get(`/get-school-branches/${id}`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createBranch = async (data, id) => {
  const formData = new FormData();
  formData.append("school_id", id);
  formData.append("academic_id", data.academic_id);
  formData.append("branch_address", data.branch_address);
  formData.append("branch_city", data.branch_city);
  formData.append("branch_code", data.branch_code);
  formData.append("branch_dist", data.branch_dist);
  formData.append("branch_email", data.branch_email);
  formData.append("branch_name", data.branch_name);
  formData.append("branch_phone", data.branch_phone);
  formData.append("branch_pin", data.branch_pin);
  formData.append("branch_state", data.branch_state);
  formData.append("print_file", data.print_file);
  formData.append("report_card", data.report_card);
  formData.append("logo_file", data.logo_file);
  formData.append("text_logo", data.text_logo);
  formData.append("subject_select", data.subject_select);
  formData.append("report_card_template", data.report_card_template);
  formData.append("receipt_template", data.receipt_template);
  formData.append("id_card_template", data.id_card_template);
  formData.append("personality_traits", data.personality_traits);
  formData.append("period_attendance", data.period_attendance);
  console.log("formdata",formData)

  const response = await Api.post(`create-branch`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

export const getAllBranches = () => {
  return Api.get();
};

export const getBranchById = (id) => {
  return Api.get(`/get-branch/${id}`);
};

export const deleteBranch = (id) => {
  return Api.delete(`/delete-branch/${id}`);
};

export const updateBranch = async (id, data) => {
  const formData = new FormData();
  formData.append("academic_id", data.academic_id);
  formData.append("branch_address", data.branch_address);
  formData.append("branch_city", data.branch_city);
  formData.append("branch_code", data.branch_code);
  formData.append("branch_dist", data.branch_dist);
  formData.append("branch_email", data.branch_email);
  formData.append("branch_name", data.branch_name);
  formData.append("branch_phone", data.branch_phone);
  formData.append("branch_pin", data.branch_pin);
  formData.append("branch_state", data.branch_state);
  formData.append("report_card_template", data.report_card_template);
  formData.append("receipt_template", data.receipt_template);
  formData.append("id_card_template", data.id_card_template);
  formData.append("print_file", data.print_file);
  formData.append("report_card", data.report_card);
  formData.append("logo_file", data.logo_file);
  formData.append("text_logo", data.text_logo);
  formData.append("personality_traits", data.personality_traits);
  formData.append("period_attendance", data.period_attendance);
  formData.append("subject_select", data.subject_select);

  const response = await Api.post(`update-branch/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

export const uploadBranch = async (data) => {
  return Api.post("/upload-branches", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getUploadProgress = async (batchId) => {
  const response = Api.get(`/upload-progress?batch_id=${batchId}`);
  return response;
};
