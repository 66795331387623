import React, { useEffect, useRef, useState } from "react"
import $ from "jquery"
import "datatables.net-bs4"
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css"
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "datatables.net-responsive-bs4"
import "datatables.net-buttons-bs4"
import "datatables.net-buttons/js/buttons.html5.min.js"
import "datatables.net-buttons/js/buttons.print.min.js"
import "datatables.net-buttons/js/buttons.colVis.min.js"
import "jszip/dist/jszip.min.js"
import { showSweetAlert, showErrorAlert, showLoadingAlert, showSuccessAlert } from "../../Modals/SweetAlertModel"
import Swal from "sweetalert2"
import { useParams } from "react-router-dom"
import AssetModal from "../../Components/CommonComponents/AssetModal"
import { createAssestManagement, getAllAssestManagement, getAssestManagementById,updateAssestManagementById,  deleteAssestManagementById, } from "../../Services/CommonApis/AssetsManagemntApi"

const AssetsManagement = () => {
  const tableRef = useRef(null)
  const tableInstance = useRef(null)
  const [showModal, setShowModal] = useState(false)
  const [modalMode, setModalMode] = useState("create")
  const { branchId } = useParams()
  const [assetDetails, setAssetDetails] = useState({
    branch_id: branchId,
    id: "",
    asset_type: "",
    name: "",
    brand_model: "",
    purchase_price: "",
    serial_number: "",
    stock: "",
    purchase_date: "",
    description: "",
    last_service_date: "",
    notes: "",
  })

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, Delete it!",
      "No, Cancel!",
    )

    if (result.isConfirmed) {
      try {
        showLoadingAlert()
        const response = await deleteAssestManagementById(id)
        Swal.close()
        showSuccessAlert("Deleted!", response.message || "Data Deleted Successfully")
        if (tableInstance.current) {
          tableInstance.current.ajax.reload()
        }
      } catch (error) {
        Swal.close()
        showErrorAlert("Error!", "There was an error deleting the Asset.")
        console.error(error)
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The Asset is safe :)")
    }
  }

  const handleSubmit = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      `You want to ${modalMode === "create" ? "create" : "update"} this Asset.`,
      "info",
      "Yes, Proceed",
      "No, Cancel!",
    )

    if (result.isConfirmed) {
      showLoadingAlert()
      try {
        let response
        if (modalMode === "create") {
          response = await createAssestManagement(values, branchId)
        } else {
          response = await updateAssestManagementById(values.id, values)
        }
        Swal.close()
        showSuccessAlert(
          "Success!",
          response.message || `Asset ${modalMode === "create" ? "created" : "updated"} successfully.`,
        )
        setShowModal(false)
        if (tableInstance.current) {
          tableInstance.current.ajax.reload()
        }
      } catch (error) {
        Swal.close()
        showErrorAlert(
          "Error!",
          error.response?.data?.message ||
          `An error occurred while ${modalMode === "create" ? "creating" : "updating"} the Asset.`,
        )
        console.error("API error:", error)
      }
    }
  }

  const handleEdit = async (id) => {
    try {
      const response = await getAssestManagementById(id)
      setAssetDetails(response)
      setModalMode("edit")
      setShowModal(true)
    } catch (error) {
      console.error("Error fetching Asset details:", error)
      showErrorAlert("Error!", "There was an error fetching the Asset details.")
    }
  }

  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "asset_type.name" },
        { data: "name" },
        { data: "brand_model" },
        { data: "purchase_price" },
        { data: "serial_number" },
        { data: "stock" },
        { data: "purchase_date" },
        { data: "description" },
        { data: "last_service_date" },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "inactive"
                ? ""
                : `<a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>`
            return `
                            <div class="icon-group" style="display: flex; gap: 10px;">
                                <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
                                ${deleteIcon}
                            </div>
                        `
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "csv",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "excel",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "pdf",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "print",
          exportOptions: { columns: ":visible" },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllAssestManagement({
            branch_id:branchId,
            asset_type: "1",
            page: page,
            _limit: length,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          })

          const jsonData = response.data.data || []
          const totalCount = response.data.total || 0

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          showErrorAlert(error.response.data.message);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEdit(id);
    });

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, []);

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Assets Management Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/admin/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Assets Management Details</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Assets Management List
          </h3>
          <button
            className="btn btn-primary float-right"
            onClick={() => {
              setModalMode("create")
              setAssetDetails({
                branch_id: branchId,
                asset_type: "",
                name: "",
                brand_model: "",
                purchase_price: "",
                serial_number: "",
                stock: "",
                purchase_date: "",
                description: "",
                last_service_date: "",
                notes: "",
              })
              setShowModal(true)
            }}
          >
            <i className="fas fa-plus"></i> Create New
          </button>
        </div>
        <div className="card-body">
          <table ref={tableRef} className="table table-striped table-hover table-bordered" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Asset Type</th>
                <th>Name</th>
                <th>Brand Model</th>
                <th>Purchase Price</th>
                <th>Serial Number</th>
                <th>Stock</th>
                <th>Purchase Date</th>
                <th>Description</th>
                <th>Last Service Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>ID</th>
                <th>Asset Type</th>
                <th>Name</th>
                <th>Brand Model</th>
                <th>Purchase Price</th>
                <th>Serial Number</th>
                <th>Stock</th>
                <th>Purchase Date</th>
                <th>Description</th>
                <th>Last Service Date</th>
                <th>Action</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <AssetModal
        show={showModal}
        onHide={() => setShowModal(false)}
        initialValues={assetDetails}
        onSubmit={handleSubmit}
        mode={modalMode}
      />
    </>
  )
}

export default AssetsManagement