import React from "react";
import Inventory from "./Inventory";
import InventoryList from "./InventoryList";
function InventoryManagement() {
  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a className="nav-link active" href="#booksissuedlist" data-toggle="tab">
              Inventory List
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#createbookissued" data-toggle="tab">
              Inventory Create
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div className="active tab-pane" id="booksissuedlist">
            <InventoryList />
          </div>
          <div className="tab-pane" id="createbookissued">
            <Inventory />
          </div>
        </div>
      </div>
    </>
  );
}
export default InventoryManagement;