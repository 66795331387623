import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import { useParams } from "react-router-dom";
import { getAllAdmissions } from "../../Services/CommonApis/AdmissionApi";

const Enroll = () => {
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    studentId: "",
    studentName: "",
    fatherName: "",
    fatherMobile: "",
    motherMobile: "",
    admissionId: "",
  });
  const [showForm, setShowForm] = useState(false);
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const { branchId } = useParams();
  const [selectedAdmissionId, setSelectedAdmissionId] = useState(null);

  useEffect(() => {
    // Initialize DataTable only if the tab is active
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        {
          data: null, // We set data to null since we are combining multiple fields
          render: function (data, type, row) {
            return `${row.first_name} ${row.middle_name} ${row.last_name}`;
          },
          title: "Full Name", // Optional: Set the column header
        },
        { data: "gender" },
        { data: "application_fee" }, // Matches the application_fee from the response
        { data: "admission_date" }, // Admission Date
        { data: "application_type" },

        { data: "class_name" }, // Updated to match the class_id
        { data: "admission_fee" }, // Matches the admission_fee from the response
        { data: "quota" }, // Quota information from the response
        { data: "payment_status" }, // Payment status
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        { extend: "copy", exportOptions: { columns: ":visible" } },
        { extend: "csv", exportOptions: { columns: ":visible" } },
        { extend: "excel", exportOptions: { columns: ":visible" } },
        { extend: "pdf", exportOptions: { columns: ":visible" } },
        { extend: "print", exportOptions: { columns: ":visible" } },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      processing: true,
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllAdmissions({
            branch_id: branchId,

            page: page,
            limit: length,
            sortBy: orderColumn,
            sortOrder: orderDirection,
            search: searchValue,
            payment_status: "paid",
          });

          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          callback({
            draw: data.draw,
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          });
        }
      },
    });

    // Edit icon click handler
    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      // Set the selected admission ID in state
      setSelectedAdmissionId(id);
      // Toggle the form view (show the form with the selected ID)
      setShowForm(true);
    });

    // Delete icon click handler
    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      // Handle delete logic here, like confirming and calling an API to delete the admission
    });

    return () => {
      tableInstance.current.destroy();
    };
  }, [branchId]);

  const handleSearch = () => {
    setRefresh((prev) => !prev); // Trigger table reload
  };

  const handleClear = () => {
    setFilters({
      studentId: "",
      studentName: "",
      fatherName: "",
      fatherMobile: "",
      motherMobile: "",
      admissionId: "",
    });
    setRefresh((prev) => !prev); // Trigger table reload
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const inputStyle = {
    borderColor: "skyblue",
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Enroll Students</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Enroll</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Enroll</h3>
                </div>
                <div className="card-body">
                  <table
                    ref={tableRef}
                    id="announcementTable"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Full Name</th>
                        <th>Gender</th>
                        <th>App Fee</th>
                        <th>Admission Date</th>
                        <th>App Type</th>
                        <th>Class</th>
                        <th>Admission Fee</th>
                        <th>Quota</th>
                        <th>Payment</th>
                      </tr>
                    </thead>

                    <tbody>{/* Static data is rendered by DataTable */}</tbody>
                    <tfoot>
                      <tr>
                        <th>ID</th>
                        <th>Full Name</th>
                        <th>Gender</th>
                        <th>App Fee</th>
                        <th>Admission Date</th>
                        <th>App Type</th>
                        <th>Class</th>
                        <th>Admission Fee</th>
                        <th>Quota</th>
                        <th>Payment</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Enroll;
