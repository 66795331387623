import Api from "../Api";
export const getAllInventory = (params) => {
  return Api.get(`/get-all-asset-inventory`);
};
export const createInventory = (data) => {
  return Api.post(`/isssue-asset-inventory`, data);
};
export const getInventorytById = async (id) => {
  try {
    const response = await Api.get(`/get-asset-inventory/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const returnInventoryById = async (id, data) => {
  return await Api.post(`/return-asset-inventory/${id}`, data);
};