import Api from "../Api";

export const createAnnouncement = (data) => {
  return Api.post(`/create-admission-announcement`, data);
};
export const getAllAnnouncements = (branchId, academicId, params = {}) => {
  return Api.get("/get-admission-announcements", {
    params: { branch_id: branchId, academic_id: academicId, ...params },
  });
};
export const getAnnouncementById = (id) => {
  return Api.get(`/get-admission-announcement-byid/${id}`);
};
export const updateAnnouncementById = (id, data) => {
  return Api.post(`/update-admission-announcement/${id}`, data);
};
export const deleteAnnouncementById = (id) => {
  return Api.delete(`/delete-admission-announcement/${id}`);
};

export const getClassByBranchId = async (branchId) => {
  const id = branchId;
  return await Api.get(`/get-classes-branch/${id}`);
};
export const getClassByAnnouncementId=async(id)=>{
  return await Api.get(`/get-class/announcement/${id}`);
}
