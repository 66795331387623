import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { getAllLanguages } from "../../Services/AdminApis/LanguagesApi";

const PersonalInformation = ({
  steps,
  current,
  studentImage,
  setStudentImage,
}) => {
  const { id } = useParams();
  const { setFieldValue, values } = useFormikContext();
  const [selectedFileName, setSelectedFileName] = useState("");
  const [FileName, setFileName] = useState("");
  const [language, setLanguage] = useState([]);
  const [cast, setCast] = useState([]);
  const [Religion, setReligion] = useState([]);
  const { touched, errors } = useFormikContext();
  const customFileInputStyle = {
    display: "none",
  };
  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    try {
      const response = await getAllLanguages();
      setLanguage(response.data.data);
      setCast(response.data.cast);
      setReligion(response.data.religion);
    } catch (error) {
      console.log(error);
    }
  };
  const customButtonStyle = {
    border: "1px solid lightskyblue",
    padding: "10px",
    backgroundColor: "white",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setStudentImage(URL.createObjectURL(file));
      setFieldValue("stimage", file);
      setSelectedFileName(file.name);
    } else {
      setStudentImage(null);
      setFieldValue("stimage", null);
      setSelectedFileName("");
    }
  };

  const handleachievementFileChange = (event) => {
    const file = event.target.files[1];
    if (file) {
      setFieldValue("achievements", file);
      setFileName(file.name);
    } else {
      setFieldValue("achievements", null);
      setFileName("");
    }
  };
  const handleFocus = (event) => {
    if (event.target.type === "date") {
      event.target.showPicker();
    }
  };

  const handleDateClick = (event) => {
    if (event.target.type === "date") {
      event.target.focus();
    }
  };
  const getBorderColor = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? "red" : "skyblue";
  };
  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === (id ? 3 : 4) && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Personal Information:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 3 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-4">
                <label htmlFor="stdate_of_birth">
                  Date of Birth <span className="mandatory">* </span>
                </label>
                <Field
                  name="stdate_of_birth"
                  type="date"
                  className="form-control"
                  onFocus={handleFocus}
                  onClick={handleDateClick}
                  style={{ borderColor: getBorderColor("stdate_of_birth") }}
                />
                <ErrorMessage
                  name="stdate_of_birth"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="stgender">Gender</label>
                <Field name="stgender" as="select" className="form-control">
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Field>
                <ErrorMessage
                  name="stgender"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="stblood_group">Blood Group</label>
                <Field
                  name="stblood_group"
                  as="select"
                  className="form-control"
                >
                  <option value="">Select Blood Group</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                </Field>
                <ErrorMessage
                  name="stblood_group"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="stcast">
                  Cast <span className="mandatory">* </span>
                </label>
                <Field
                  as="select"
                  name="stcast"
                  className="form-control"
                  style={{ borderColor: getBorderColor("stcast") }}
                >
                  <option value="">Select Cast</option>
                  {cast.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="stcast"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="mother_tongue">
                  Mother tongue<span className="mandatory">* </span>
                </label>
                <Field
                  name="mother_tongue"
                  as="select"
                  className="form-control"
                  style={{ borderColor: getBorderColor("mother_tongue") }}
                >
                  <option value="">Select Language</option>
                  {language.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="mother_tongue"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="streligion">
                  Religion<span className="mandatory">* </span>
                </label>
                <Field
                  as="select"
                  name="streligion"
                  className="form-control"
                  style={{ borderColor: getBorderColor("streligion") }}
                >
                  <option value="">Select Religion</option>
                  {Religion.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="streligion"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="first_lang_id">First Language</label>
                <Field
                  name="first_lang_id"
                  as="select"
                  className="form-control"
                >
                  <option value="">Select Language</option>
                  {language.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="first_lang_id"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="second_lang_id">Second Language</label>
                <Field
                  name="second_lang_id"
                  as="select"
                  className="form-control"
                >
                  <option value="">Select Language</option>
                  {language.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="second_lang_id"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="third_lang_id">Third Language</label>
                <Field
                  name="third_lang_id"
                  as="select"
                  className="form-control"
                >
                  <option value="">Select Language</option>
                  {language.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="third_lang_id"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="additional_skills">Additional Skills</label>
                <Field
                  name="additional_skills"
                  type="text"
                  className="form-control"
                />
                <ErrorMessage
                  name="additional_skills"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="stimage" className="d-block">
                  Upload Image
                </label>
                <div style={customButtonStyle}>
                  <label htmlFor="stimage" style={{ cursor: "pointer" }}>
                    <i
                      className="fas fa-upload"
                      style={{ marginRight: "8px" }}
                    ></i>
                    Browse
                  </label>
                  <input
                    type="file"
                    id="stimage"
                    name="stimage"
                    onChange={handleFileChange}
                    style={customFileInputStyle}
                  />
                </div>
                {studentImage ? (
                  <div>
                    <img
                      src={studentImage}
                      alt="Preview"
                      style={{
                        width: "100px",
                        height: "100px",
                        marginTop: "10px",
                      }}
                    />
                    {selectedFileName && <p>{selectedFileName}</p>}
                  </div>
                ) : values.stimage ? (
                  <img
                    src={`${process.env.REACT_APP_IMAGEURL}/${values.stimage}`}
                    alt="Fallback"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginTop: "10px",
                    }}
                  />
                ) : (
                  <p></p>
                )}
                <ErrorMessage
                  name="stimage"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="achievements" className="d-block">
                  Upload Achievements
                </label>
                <div style={customButtonStyle}>
                  <label htmlFor="achievements" style={{ cursor: "pointer" }}>
                    <i
                      className="fas fa-upload"
                      style={{ marginRight: "8px" }}
                    ></i>
                    Browse
                  </label>
                  <input
                    type="file"
                    id="achievements"
                    name="achievements"
                    onChange={handleachievementFileChange}
                    style={customFileInputStyle}
                  />
                </div>
                {FileName && <div style={{ marginTop: "8px" }}>{FileName}</div>}
                <ErrorMessage
                  name="achievements"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-4 d-flex ml-0 mt-4">
                <label className="mt-3 ml-1" htmlFor="physically_challenge">
                  Physically challenged?
                </label>
                <div className="form-check mt-3 ml-3">
                  <Field
                    type="radio"
                    className="form-check-input mt-2"
                    id="physically_challenge_yes"
                    name="physically_challenge"
                    value="yes"
                    onChange={(e) => {
                      setFieldValue("physically_challenge", e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label ml-1"
                    htmlFor="physically_challenge_yes"
                  >
                    Yes
                  </label>
                </div>

                <div className="form-check mt-3 ml-3">
                  <Field
                    type="radio"
                    className="form-check-input mt-2"
                    id="physically_challenge_no"
                    name="physically_challenge"
                    value="no"
                    checked={values.physically_challenge === "no"}
                    onChange={(e) => {
                      setFieldValue("physically_challenge", e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label ml-1"
                    htmlFor="physically_challenge_no"
                  >
                    No
                  </label>
                </div>
              </div>

              <div className="form-group col-4 d-flex ml-0 mt-4">
                <label className="mt-3 ml-1" htmlFor="neet_applicable">
                  Neet Application?
                </label>
                <div className="form-check mt-3 ml-3">
                  <Field
                    type="radio"
                    className="form-check-input mt-2"
                    id="neet_applicable_yes"
                    name="neet_applicable"
                    value="yes"
                    onChange={(e) => {
                      setFieldValue("neet_applicable", e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label ml-1"
                    htmlFor="neet_applicable_yes"
                  >
                    Yes
                  </label>
                </div>

                <div className="form-check mt-3 ml-3">
                  <Field
                    type="radio"
                    className="form-check-input mt-2"
                    id="neet_applicable_no"
                    name="neet_applicable"
                    value="no"
                    checked={values.neet_applicable === "no"}
                    onChange={(e) => {
                      setFieldValue("neet_applicable", e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label ml-1"
                    htmlFor="neet_applicable_no"
                  >
                    No
                  </label>
                </div>
              </div>
              <div className="form-group col-4 d-flex ml-0 mt-4">
                <label className="mt-3 ml-1" htmlFor="transport_required">
                  Transport Required?
                </label>
                <div className="form-check mt-3 ml-3">
                  <Field
                    type="radio"
                    className="form-check-input mt-2"
                    id="transport_required_yes"
                    name="transport_required"
                    value="yes" // ✅ Corrected Value
                    checked={values.transport_required === "yes"} // ✅ Explicitly set checked
                    onChange={(e) => {
                      setFieldValue("transport_required", e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label ml-1"
                    htmlFor="transport_required_yes"
                  >
                    Yes
                  </label>
                </div>

                <div className="form-check mt-3 ml-3">
                  <Field
                    type="radio"
                    className="form-check-input mt-2"
                    id="transport_required_no"
                    name="transport_required"
                    value="no"
                    checked={values.transport_required === "no"}
                    onChange={(e) => {
                      setFieldValue("transport_required", e.target.value);
                    }}
                  />
                  <label
                    className="form-check-label ml-1"
                    htmlFor="transport_required_no"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default PersonalInformation;
