import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getAllAcademics } from "../Services/AdminApis/AcademicApi";
import { getBranchBySchoolId } from "../Services/CommonApis/branchApi";
import { useNavigate } from "react-router-dom";
import { getSession, setSession } from "../Services/Storage";
import { COOKIE } from "../Schemas/cookieNames";
import { USERROLEID } from "../Schemas/userRoles";
import {
  getSchoolId,
  getUserRoleId,
} from "../Utility/CommonUtility/extractUserDetailsFromToken";

const LoginModal = ({ onSubmit }) => {
  const [branches, setBranches] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const [schoolId, setSchoolId] = useState(null);
  const [academic, setAcademic] = useState([]);
  const [academicyear, setAcademicyear] = useState("");
  const [isAcademicLoading, setIsAcademicLoading] = useState(false);

  const navigate = useNavigate();

  // Fetch all academic years on component mount
  useEffect(() => {
    const fetchAllAcademics = async () => {
      try {
        setIsAcademicLoading(true);
        const response = await getAllAcademics();
        setAcademic(response.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsAcademicLoading(false);
      }
    };

    fetchAllAcademics();

    const adminToken = getSession(COOKIE.ADMIN_TOKEN);
    setRoleId(getUserRoleId(adminToken));
    setSchoolId(getSchoolId(adminToken));
  }, []);

  // Fetch branches based on schoolId and academic year
  const fetchBranches = async (schoolId, academicyear) => {
    try {
      const response = await getBranchBySchoolId(schoolId, {
        academic_id: academicyear,
      });
      setBranches(response.branches);
    } catch (error) {
      console.error(error);
    }
  };



  // Formik initial values
  const initialValues =
    roleId === USERROLEID.SCHOOL_ID
      ? {
          branch: "",
          year: "",
        }
      : {
          year: "",
        };

  // Form validation schema
  const validationSchema =
    roleId === USERROLEID.SCHOOL_ID
      ? Yup.object({
          branch: Yup.string().required("Please select the branch"),
          year: Yup.string().required("Please select the year"),
        })
      : Yup.object({
          year: Yup.string().required("Please select a year"),
        });

  // Handle form submission
  const handleSubmit = (values) => {
    const selectedYear = academic.find(
      (year) => year.id.toString() === values.year
    );

    if (selectedYear) {
      setSession(COOKIE.ACADEMIC_YEAR, selectedYear.id);
      setSession(COOKIE.SECTION_COMPLETE, true);
      navigate("/");
    } else {
      console.log("No matching academic year found.");
    }

    onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <div className="modal-header">
            <h5 className="modal-title">Select Academic Year to Continue</h5>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="year">
                Select Academic Year <span className="mandatory">*</span>
              </label>
              <Field
                as="select"
                name="year"
                className="form-control"
                value={values.year}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setAcademicyear(selectedValue);
                  setFieldValue("year", selectedValue);

                  if (roleId === USERROLEID.SCHOOL_ID && selectedValue) {
                    fetchBranches(schoolId, selectedValue);
                  }
                }}
              >
                <option value="">Select Year</option>
                {isAcademicLoading ? (
                  <option>Loading ...</option>
                ) : (
                  academic.map((year) => {
                    const startDate = new Date(year.start_date);
                    const endDate = new Date(year.end_date);

                    const formattedDateRange = `${startDate.toLocaleString(
                      "default",
                      { month: "short", year: "numeric" }
                    )} - ${endDate.toLocaleString("default", {
                      month: "short",
                      year: "numeric",
                    })}`;

                    return (
                      <option key={year.id} value={year.id}>
                        {formattedDateRange}
                      </option>
                    );
                  })
                )}
              </Field>
              <ErrorMessage
                name="year"
                component="div"
                className="text-error"
              />
            </div>
            {roleId === USERROLEID.SCHOOL_ID && (
              <div className="form-group">
                <label htmlFor="branch">
                  Select Branch <span className="mandatory">*</span>
                </label>
                <Field
                  as="select"
                  name="branch"
                  className="form-control"
                  disabled={!values.year}
                >
                  <option value="">Select Branch</option>
                  {branches.map((branch) => (
                    <option key={branch.id} value={branch.id}>
                      {branch.branch_name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="branch"
                  component="div"
                  className="text-error"
                />
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginModal;
