import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getAllHolidays } from "../../Services/CommonApis/ParentPortalApi";

function SpecialDayList() {
  const { branchId } = useParams(); 
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    if (branchId) {
      fetchHolidays(branchId);
    }
  }, [branchId]);

  const fetchHolidays = async (branchId) => {
    try {
      const response = await getAllHolidays(branchId);
      setHolidays(response.data.data);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  const specialDays = holidays.filter(
    (holiday) => holiday.type === "Special Day"
  );

  const getRandomPastelColor = () => {
    const r = Math.floor(Math.random() * 127 + 128);
    const g = Math.floor(Math.random() * 127 + 128);
    const b = Math.floor(Math.random() * 127 + 128);
    return `rgb(${r}, ${g}, ${b})`;
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("en-IN", options)
      .format(new Date(dateString))
      .toUpperCase()
      .replace(/-/g, "");
  };

  return (
    <div className="special-day-list container">
      <div className="row">
        {specialDays.map((holiday) => (
          <div key={holiday.id} className="col-lg-4 col-md-6 col-sm-12">
            <div
              className="card mb-4 text-center text-white"
              style={{
                backgroundColor: getRandomPastelColor(),
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                overflow: "hidden",
              }}
            >
              <div className="text-center">
                <img
                  src={`${process.env.REACT_APP_IMAGEURL}/${holiday.image}`}
                  className="card-img-top"
                  alt={holiday.name}
                  style={{
                    height: "200px",
                    width: "200px",
                    objectFit: "cover",
                    borderRadius: "10px",
                    margin: "20px auto",
                    display: "block",
                  }}
                />
              </div>
              <div className="card-body">
                <h5 className="card-text">{holiday.name}</h5>
                <p className="card-text">
                  <strong>From:</strong> {formatDate(holiday.from_date)}
                  <br />
                  <strong>To:</strong> {formatDate(holiday.to_date)}
                </p>
                <p className="card-text">{holiday.type}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SpecialDayList;
