import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getCookie, setCookie } from "../../Services/Storage";

function CookiePopUp() {
  const [showCookiePopUp, setShowCookiePopUp] = useState(false);
  const [showMoreOptionPopUp, setShowMoreOptionPopUp] = useState(false);
  const [expanded, seExpanded] = useState(null);
  const [toggles, setToggles] = useState([false, false, false]);
  const [allAllowed, setAllAllowed] = useState(false);

  const checkCookieConsent = () => {
    const cookieAccepted = getCookie("cookies_accepted");
    if (!cookieAccepted) {
      setShowCookiePopUp(true);
    }
  };

  useEffect(() => {
    checkCookieConsent();
  }, []);

  const handleAcceptCookies = () => {
    setCookie("cookies_accepted", "true", 30);
    setShowCookiePopUp(false);
  };

  const handleRejectCookies = () => {
    setShowCookiePopUp(false);
  };

  const handleMoreOptions = () => {
    setShowCookiePopUp(false);
    setShowMoreOptionPopUp(true);
  };

  const handleMoreOptionsSubmit = () => {
    setCookie("cookies_accepted", "true", 30);
    const cookieAccepted = getCookie("cookies_accepted");
    if (!cookieAccepted) {
      setShowCookiePopUp(true);
    }
    setShowMoreOptionPopUp(false);
  };

  const handleMoreOptionsClose = () => {
    const cookieAccepted = getCookie("cookies_accepted");
    if (!cookieAccepted) {
      setShowCookiePopUp(true);
      setShowMoreOptionPopUp(false);
    }
  };

  const handleExpandingButton = (index, event) => {
    if (event.target.closest(".form-check")) return;
    seExpanded(expanded === index ? null : index);
  };

  const handleToggle = (index) => {
    const updatedToggle = [...toggles];
    updatedToggle[index] = !updatedToggle[index];
    setToggles(updatedToggle);
    if (updatedToggle[1] && updatedToggle[2]) {
      setAllAllowed(false);
    }
  };

  const handleAllowAll = () => {
    setToggles([false, true, true]);
    setAllAllowed(true);
    setCookie("cookies_accepted", "true", 30);
    const cookieAccepted = getCookie("cookies_accepted");
    if (!cookieAccepted) {
      setShowCookiePopUp(true);
    }
    setShowMoreOptionPopUp(false);
  };
  return (
    <>
      {/* Cookie Popup */}
      {showCookiePopUp && (
        <div
          className="position-fixed bottom-0 start-0 bg-dark text-white p-3 rounded m-3 shadow"
          style={{
            zIndex: 1050,
            maxWidth: "600px",
            left: "0",
            bottom: "0",
          }}
        >
          <h5>Your privacy is important to us.</h5>
          <p className="mb-3">
            We process your personal information to measure and improve our
            sites and service, to assist our marketing campaigns and to provide
            personalised content and advertising. For more information see our{" "}
            <b>
              <a href="/">Privacy Policy</a>
            </b>
          </p>

          <div className="d-flex justify-content-between">
            <button
              className="btn btn-secondary btn-sm"
              onClick={handleMoreOptions}
            >
              More Options
            </button>
            <button
              className="btn btn-primary btn-sm"
              onClick={handleAcceptCookies}
            >
              Accept All
            </button>
            <button
              className="btn btn-primary btn-sm"
              onClick={handleRejectCookies}
            >
              Reject All
            </button>
          </div>
        </div>
      )}

      {/* More Option Modal */}
      <Modal
        show={showMoreOptionPopUp}
        onHide={handleMoreOptionsClose}
        centered
        backdrop="static"
      >
        <Modal.Header className="bg-light">
          <Modal.Title>Keep Privacy</Modal.Title>
          <button
            type="button"
            className="btn-close"
            style={{ fontSize: "28px" }}
            onClick={handleMoreOptionsClose}
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </Modal.Header>
        <Modal.Body className="overflow-auto" style={{ maxHeight: "400px" }}>
          <p>
            When you visit our website, we store cookies on your browser to
            collect information. The information collected might relate to you,
            your preferences, or your device and is mostly used to make the site
            work as expected. <a href="/moreInfo">More information</a>.
          </p>
          <button
            className={`btn btn-secondary mb-3 ${
              toggles[1] && toggles[2] ? "d-none" : ""
            }`}
            onClick={handleAllowAll}
          >
            Allow All
          </button>
          <h5>Manage Consent Preferences</h5>
          {[
            "Strictly Necessary Cookies",
            "Performance Cookies",
            "Targeted Advertising",
          ].map((item, index) => {
            const isExpanded = expanded === index;
            const hasToggle = index === 1 || index === 2;
            return (
              <div key={index}>
                <button
                  className="list-group-item list-group-item-action d-flex align-items-center"
                  onClick={(event) => handleExpandingButton(index, event)}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      <strong
                        className="me-3"
                        style={{
                          fontSize: "1.3rem",
                        }}
                      >
                        {isExpanded ? "-" : "+"}
                      </strong>
                      <span style={{ fontSize: "1rem" }}>{item}</span>
                    </div>

                    {index === 0 && (
                      <span className="fw-bold text-info ms-3 ms-auto">
                        Always Active
                      </span>
                    )}
                  </div>
                  {hasToggle && (
                    <div
                      className="form-check form-switch ms-auto"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <label
                        className="form-check-label"
                        htmlFor={`toggle-${index}`}
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          checked={toggles[index] || false}
                          onChange={() => handleToggle(index)}
                          id={`toggle-${index}`}
                          style={{
                            width: "50px",
                            height: "25px",
                            backgroundColor: toggles[index]
                              ? "#0d6efd"
                              : "#ccc",
                            borderRadius: "15px",
                            position: "relative",
                            appearance: "none",
                            outline: "none",
                            transition: "background-color 0.3s ease",
                            cursor: "pointer",
                          }}
                        />
                        <span
                          style={{
                            position: "absolute",
                            top: "7px",
                            left: toggles[index] ? "27px" : "3px",
                            width: "20px",
                            height: "20px",
                            backgroundColor: "#fff",
                            borderRadius: "50%",
                            transition: "left 0.3s ease",
                            cursor: "pointer",
                            pointerEvents: "auto",
                          }}
                        />
                      </label>
                    </div>
                  )}
                </button>

                {isExpanded && (
                  <div className="ms-3 mt-2">
                    <p>
                      Details about <strong>{item}</strong>. Learn more by
                      visiting our <a href="/details">policy page</a>.
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer className="bg-light">
          <button
            className="btn btn-secondary"
            onClick={handleMoreOptionsSubmit}
          >
            Confirm My Choices
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CookiePopUp;
