import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getSchools } from "../../Services/AdminApis/schoolApi";
import { getBranchBySchoolId } from "../../Services/CommonApis/branchApi";
import { CreateLibraryBook } from "../../Services/AdminApis/LibraryApi";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";

function AdminBookCreate() {
  const [schoolList, setSchoolsList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [schoolLoading, setSchoolLoading] = useState(true);
  const [branchLoading, setBranchLoading] = useState(false);

  useEffect(() => {
    fetchSchoolList();
  }, []);

  const fetchSchoolList = async () => {
    try {
      setSchoolLoading(true);
      const response = await getSchools();
      setSchoolsList(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setSchoolLoading(false);
    }
  };

  const fetchBranchBySchoolId = async (schoolId) => {
    try {
      setBranchLoading(true);
      const response = await getBranchBySchoolId(schoolId);
      setBranchList(response.branches);
    } catch (error) {
      console.log(error);
    } finally {
      setBranchLoading(false);
    }
  };

  const bookInitialValues = {
    school_id: "",
    branch_id: "",
    name: "",
    title: "",
    description: "",
    author: "",
    price: "",
    publisher: "",
    isbn13: "",
    isbn10: "",
    display_name: "",
    published_date: "",
    quantity:""
  };

  const bookValidationSchema = Yup.object().shape({
    school_id: Yup.string().required("School is required"),
    branch_id: Yup.string().required("Branch is required"),
    name: Yup.string().required("Name is required"),
    title: Yup.string().required("Title is required"),
    author: Yup.string().required("Author is required"),
    price: Yup.number()
      .required("Price is required")
      .positive("Price must be positive"),
    publisher: Yup.string().required("Publisher is required"),
    isbn13: Yup.string()
      .length(13, "ISBN-13 must be 13 characters")
      .required("ISBN-13 is required"),
    isbn10: Yup.string()
      .length(10, "ISBN-10 must be 10 characters")
      .required("ISBN-10 is required"),
    display_name: Yup.string().required("Display name is required"),
    published_date: Yup.date().required("Published date is required"),
    quantity: Yup.number()
      .required("Price is required")
      .positive("Price must be positive"),
  });

  const handleSubmit = async (values) => {
    if (values.published_date) {
      const date = new Date(values.published_date);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
  
      values.published_date = `${day}/${month}/${year}`;
    }
  
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to create the Book",
      "info",
      "Yes, Create",
      "No, Cancel!"
    );
  
    if (result.isConfirmed) {
      showLoadingAlert();
      try {
        const response = await CreateLibraryBook(values);
        if (response.status === 201) {
          showSuccessAlert(
            "Created!",
            "The Book has been created successfully."
          );
          window.location.reload();
        } else {
          const errorMessage =
            response.data?.message || "Book creation failed. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";
  
        if (err.response && err.response.data) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Book creation has been cancelled.");
    }
  };
  

  return (
    <>
      <div className="card card-primary">
        <div className="card-header">
          <h3 className="card-title">
            Book Creation <small>Form</small>
          </h3>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize
            initialValues={bookInitialValues}
            validationSchema={bookValidationSchema}
            onSubmit={(values) => {
              const { school_id, ...otherValues } = values;
              handleSubmit(otherValues);
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form className="form-card">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="school_id" className="form-label">
                        Select School <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        name="school_id"
                        className="form-control"
                        style={{ borderColor: "skyblue" }}
                        onChange={(e) => {
                          const selectedSchoolId = e.target.value;
                          console.log("Selected School ID:", selectedSchoolId);
                          setFieldValue("school_id", selectedSchoolId);
                          fetchBranchBySchoolId(selectedSchoolId);
                          setFieldValue("branch_id", "");
                        }}
                      >
                        <option value="" label="Select School" />
                        {schoolLoading ? (
                          <option>Loading states...</option>
                        ) : (
                          schoolList.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))
                        )}
                      </Field>
                      <ErrorMessage
                        name="school_id"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="branch_id" className="form-label">
                        Select Branch <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        name="branch_id"
                        className="form-control"
                        style={{ borderColor: "skyblue" }}
                      >
                        <option value="" label="Select Branch" />
                        {branchLoading ? (
                          <option>Loading states...</option>
                        ) : (
                          branchList.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.branch_name}
                            </option>
                          ))
                        )}
                      </Field>
                      <ErrorMessage
                        name="branch_id"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="name" className="form-label">
                        Name <span className="mandatory">* </span>
                      </label>
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        style={{ borderColor: "skyblue" }}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="title" className="form-label">
                        Title <span className="mandatory">* </span>
                      </label>
                      <Field
                        type="text"
                        name="title"
                        className="form-control"
                        placeholder="Title"
                        style={{ borderColor: "skyblue" }}
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="display_name" className="form-label">
                        Display Name <span className="mandatory">* </span>
                      </label>
                      <Field
                        type="text"
                        name="display_name"
                        className="form-control"
                        placeholder="Display Name"
                        style={{ borderColor: "skyblue" }}
                      />
                      <ErrorMessage
                        name="display_name"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="description" className="form-label">
                        Description <span className="mandatory"></span>
                      </label>
                      <Field
                        as="textarea"
                        name="description"
                        className="form-control"
                        placeholder="Description"
                        style={{ borderColor: "skyblue" }}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="author" className="form-label">
                        Author <span className="mandatory">* </span>
                      </label>
                      <Field
                        type="text"
                        name="author"
                        className="form-control"
                        placeholder="Author"
                        style={{ borderColor: "skyblue" }}
                      />
                      <ErrorMessage
                        name="author"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="price" className="form-label">
                        Price <span className="mandatory">* </span>
                      </label>
                      <Field
                        type="number"
                        name="price"
                        className="form-control"
                        placeholder="Price"
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(/\D/g, "")
                            .slice(0, 10);
                        }}
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                        }}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <ErrorMessage
                        name="price"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="quantity" className="form-label">
                        Quantity <span className="mandatory">* </span>
                      </label>
                      <Field
                        type="number"
                        name="quantity"
                        className="form-control"
                        placeholder="Quantity"
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(/\D/g, "")
                            .slice(0, 10);
                        }}
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                        }}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <ErrorMessage
                        name="quantity"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="publisher" className="form-label">
                        Publisher <span className="mandatory">* </span>
                      </label>
                      <Field
                        type="text"
                        name="publisher"
                        className="form-control"
                        placeholder="Publisher"
                        style={{ borderColor: "skyblue" }}
                      />
                      <ErrorMessage
                        name="publisher"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="isbn10" className="form-label">
                        ISBN-10 <span className="mandatory">* </span>
                      </label>
                      <Field
                        type="text"
                        name="isbn10"
                        className="form-control"
                        placeholder="ISBN-10"
                        style={{ borderColor: "skyblue" }}
                      />
                      <ErrorMessage
                        name="isbn10"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="isbn13" className="form-label">
                        ISBN-13 <span className="mandatory">* </span>
                      </label>
                      <Field
                        type="text"
                        name="isbn13"
                        className="form-control"
                        placeholder="ISBN-13"
                        style={{ borderColor: "skyblue" }}
                      />
                      <ErrorMessage
                        name="isbn13"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="published_date" className="form-label">
                        Published Date <span className="mandatory">* </span>
                      </label>
                      <Field
                        type="date"
                        name="published_date"
                        className="form-control"
                        style={{ borderColor: "skyblue" }}
                      />
                      <ErrorMessage
                        name="published_date"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group" style={{ textAlign: "right" }}>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default AdminBookCreate;
