import Api from "../Api";
export const ForgotPassword = (data) => {
  return Api.post(`/forgot-password`, data);
};
export const validateLoginOTP = (data) => {
  console.log(data);
  return Api.post(`/verify-otp`, data);
};
export const resetPassword = (data) => {
  return Api.post(`/reset-password`, data);
};
