import Api from "../Api";
export const createStudent = async (data) => {
  const response = await Api.post("/create-student", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
export const getAllStudent = async ({
  branch_id,
  class_id,
  section_id,
  sort_by,
  sort_order,
  per_page,
  page,
  q,
}) => {
  try {
    const response = await Api.get("/get-students", {
      params: {
        branch_id,
        class_id,
        section_id,
        sort_by,
        sort_order,
        per_page,
        page,
        q,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getParentsByNum = (num) => {
  return Api.get(`get-parent-bynumber/${num}`);
};
export const getstudentByid = (id) => {
  return Api.get(`get-student/${id}`);
};
export const updatestudent = async (id, data) => {
  const response = await Api.post(`/update-student/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};
export const deletestudent = (id) => {
  return Api.delete(`/delete-student/${id}`);
};
export const getmediums = () => {
  return Api.get("/get-medium");
};
export const getgroupsbybranch = (id) => {
  return Api.get(`/get-groups-bybranch_id/${id}`);
};

export const getDropDownStudents = async (params) => {
  return await Api.get("/get-student-bybranch", { params });
};

export const getstudentbyclassandsection = async (
  branchId,
  classId,
  section_id
) => {
  const response = await Api.get("/get-student-byclass", {
    params: {
      branch_id: branchId,
      class_id: classId,
      section_id: section_id,
    },
  });
  return response;
};

export const uploadStudent = async (
  branchId,
  classId,
  sectionId,
  confirmation,
  formData
) => {
  formData.append("branch_id", branchId);
  formData.append("class_id", classId);
  formData.append("section_id", sectionId);
  formData.append("confirmation", confirmation);

  return await Api.post("/upload-students", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getstudentbysectionIdBranchId = async (branchId, section_id) => {
  const response = await Api.get("/get-student-bysection", {
    params: {
      branch_id: branchId,
      section_id: section_id,
    },
  });
  return response;
};
