import React, { useEffect, useState } from "react";
import vinayak from "../../Assests/vinayak.jpg";
import christ from "../../Assests/crist.jpg";
import ram from "../../Assests/rama.webp";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  CreateHoliday,
  updateHoliday,
  deleteHoliday,
  getHolidayById,
  getAllHolidays,
} from "../../Services/CommonApis/ParentPortalApi";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { COOKIE } from "../../Schemas/cookieNames";
import { getUserRoleId } from "../../Utility/CommonUtility/extractUserDetailsFromToken";
import { getSession } from "../../Services/Storage";

const holidaysData = [
  {
    name: "New Year's Day",
    day: "Monday",
    holiday_date: "01/01/2024",
    bgColor: "#FFEECC",
  },
  {
    name: "Makar Sankranti",
    day: "Sunday",
    holiday_date: "14/01/2024",
    bgColor: "#FFDAB9",
  },
  {
    name: "Republic Day",
    day: "Friday",
    holiday_date: "26/01/2024",
    bgColor: "#FFCCCB",
  },
  {
    name: "Maha Shivaratri",
    day: "Friday",
    holiday_date: "08/03/2024",
    bgColor: "#E6E6FA",
  },
  { name: "Ugadi", day: "Monday", date: "08/04/2024", bgColor: "#F0E68C" },
  {
    name: "Good Friday",
    day: "Friday",
    holiday_date: "29/03/2024",
    bgColor: "#98FB98",
  },
  { name: "May Day", day: "Wednesday", date: "01/05/2024", bgColor: "#FFDEAD" },
  {
    name: "Independence Day",
    day: "Thursday",
    holiday_date: "15/08/2024",
    bgColor: "#B0E0E6",
  },
  {
    name: "Vinayaka Chavithi",
    day: "Friday",
    holiday_date: "06/09/2024",
    bgColor: "#FFA07A",
    image: vinayak,
  },
  {
    name: "Mahatma Gandhi Jayanti",
    day: "Wednesday",
    holiday_date: "02/10/2024",
    bgColor: "#F5F5DC",
  },
  {
    name: "Dasara",
    day: "Saturday",
    holiday_date: "12/10/2024",
    bgColor: "#FFD700",
    image: ram,
  },
  {
    name: "Deepavali",
    day: "Thursday",
    holiday_date: "31/10/2024",
    bgColor: "#FFFACD",
  },
  {
    name: "Christmas Day",
    day: "Wednesday",
    holiday_date: "25/12/2024",
    bgColor: "#FFE4E1",
    image: christ,
  },
];

function HolidayCarousel() {
  const { branchId } = useParams();
  const token = getSession(COOKIE.ADMIN_TOKEN);
  const role_id = getUserRoleId(token);
  const [holidays, setHolidays] = useState(holidaysData);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editHoliday, setEditHoliday] = useState(null);
  useEffect(() => {
    if (branchId) {
      fetchHolidays(branchId);
    }
  }, []);

  const fetchHolidays = async (branchId) => {
    try {
      const response = await getAllHolidays(branchId);
      const holidays = response.data.data.map((holiday) => {
        const formatDate = (dateString) => {
          const options = { day: "2-digit", month: "short", year: "numeric" };
          return new Intl.DateTimeFormat("en-IN", options)
            .format(new Date(dateString))
            .toUpperCase()
            .replace(/-/g, "");
        };

        return {
          ...holiday,
          from_date: formatDate(holiday.from_date),
          to_date: formatDate(holiday.to_date),
        };
      });

      setHolidays(holidays.length > 0 ? holidays : holidaysData);
    } catch (error) {
      setHolidays(holidaysData);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Holiday name is required")
      .min(3, "Holiday name should be at least 3 characters long"),
    holiday_date: Yup.date()
      .required("Holiday date is required")
      .nullable()
      .min(new Date(), "Holiday date cannot be in the past"),
    image: Yup.mixed().required("Image is required"),
  });

  const handleEditHoliday = async (id) => {
    try {
      const response = await getHolidayById(id);
      setEditHoliday(response.data);

      const parsedAssignTo = response.data.assign_to
        ? response.data.assign_to.split(",").map(Number)
        : [];

      // Set the editHoliday state with parsed assign_to
      setEditHoliday({
        ...response.data,
        assign_to: parsedAssignTo,
      });

      setShowEditModal(true);
    } catch (error) {
      showErrorAlert(
        "Error!",
        "There was an error fetching the holiday details."
      );
      console.error(error);
    }
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const handleUpdateHoliday = async (values) => {
    try {
      showLoadingAlert();
      const formattedFromDate = formatDate(values.from_date);
      const formattedToDate = formatDate(values.to_date);
      const formData = new FormData();
      formData.append("from_date", formattedFromDate);
      formData.append("to_date", formattedToDate);
      formData.append("name", values.name);
      formData.append("is_holiday", values.is_holiday);
      formData.append("type", values.type);
      formData.append("image", values.image);

      values.assign_to.forEach((assign) =>
        formData.append("assign_to[]", assign)
      );

      await updateHoliday(editHoliday.id, formData);

      Swal.close();
      showSuccessAlert("Success!", "The holiday has been updated.");
      setShowEditModal(false);
    } catch (error) {
      Swal.close();
      showErrorAlert("Error!", "There was an error updating the holiday.");
      console.error(error);
    }
  };

  const handleDeleteHoliday = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteHoliday(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The holiday has been deleted.");
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the holiday.");
        console.error(error);
      }
    }
  };

  const generateRandomColor = () => {
    const letters = "CDEF"; 
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };
  const holidayTemplate = (holiday) => (
    <div
      className="border-1 surface-border border-round m-2 text-center p-3"
      style={{
        backgroundColor: generateRandomColor(),
        height: "300px",
        position: "relative",
      }}
    >
      <div
        className="d-flex justify-content-between align-items-start"
        style={{ gap: "2rem", position: "relative", top: "2rem" }}
      >
        {holiday.image && (
          <img
            src={`${process.env.REACT_APP_IMAGEURL}/${holiday.image}`}
            alt={holiday.image}
            style={{
              height: "200px",
              width: "200px",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        )}
        <div style={{ flex: 1, textAlign: "left" }}>
          <h4 style={{ marginBottom: "1rem" }}>{holiday.name}</h4>
          <p>
            <span style={{ fontWeight: "bold" }}>Date:</span>&nbsp;
            {holiday.from_date} - {holiday.to_date}
          </p>

          <p>
            <span style={{ fontWeight: "bold" }}>HolidayType:</span>&nbsp;
            {holiday.type}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Assign To:</span>&nbsp;
            {holiday.assign_name}
          </p>
        </div>
      </div>

      {(role_id === 1 || role_id === 2) && (
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            display: "flex",
          }}
        >
          <button className="btn" onClick={() => handleEditHoliday(holiday.id)}>
            <i className="fa fa-pencil"></i>
          </button>
          <button
            className="btn"
            onClick={() => handleDeleteHoliday(holiday.id)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex justify-content-center align-items-center min-h-screen">
      {(role_id === 1 || role_id === 2) && (
        <div className="d-flex justify-content-end mt-4 mr-3"></div>
      )}

      <div className="d-flex flex-row flex-wrap gap-3 justify-content-center">
        {holidays.map((holiday, index) => (
          <div key={index} className="col-6">
            {holidayTemplate(holiday)}
          </div>
        ))}
      </div>
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Holiday</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            name: editHoliday?.name || "",
            from_date: editHoliday?.from_date || "",
            to_date: editHoliday?.to_date || "",
            is_holiday: editHoliday?.is_holiday || "",
            type: editHoliday?.type || "",
            image: editHoliday?.image || null,
            assign_to: Array.isArray(editHoliday?.assign_to)
              ? editHoliday.assign_to // Use the array as is
              : typeof editHoliday?.assign_to === "string"
              ? [...new Set(editHoliday.assign_to.split(",").map(Number))] // Parse string to array
              : [],
          }}
          onSubmit={handleUpdateHoliday}
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-6">
                      <label>
                        Holiday Name <span className="mandatory">*</span>
                      </label>
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter holiday name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-error"
                      />
                    </div>
                    <div className="form-group col-6">
                      <label>
                        From Date <span className="mandatory">*</span>
                      </label>
                      <Field
                        type="date"
                        name="from_date"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="from_date"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-6">
                      <label>
                        To Date <span className="mandatory">*</span>
                      </label>
                      <Field
                        type="date"
                        name="to_date"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="to_date"
                        component="div"
                        className="text-error"
                      />
                    </div>
                    <div className="form-group col-6">
                      <label>
                        Holiday Day <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        name="is_holiday"
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("is_holiday", e.target.value);
                          if (e.target.value === "0") {
                            setFieldValue("type", "Special Day");
                          } else {
                            setFieldValue("type", "");
                          }
                        }}
                      >
                        <option value="">Select Day</option>
                        <option value="0">Working Day</option>
                        <option value="1">Holiday</option>
                      </Field>
                      <ErrorMessage
                        name="is_holiday"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-6">
                      <label>
                        Holiday Type <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        name="type"
                        className="form-control"
                        disabled={values.is_holiday === "0"}
                      >
                        <option value="">Select Type</option>
                        {values.is_holiday === "1" && (
                          <>
                            <option value="General Holiday">
                              General Holiday
                            </option>
                            <option value="Festival Holiday">
                              Festival Holiday
                            </option>
                          </>
                        )}
                        <option value="Special Day">Special Day</option>
                      </Field>
                      <ErrorMessage
                        name="type"
                        component="div"
                        className="text-error"
                      />
                    </div>
                    <div className="form-group col-6">
                      <label>
                        Holiday Image <span className="mandatory">*</span>
                      </label>
                      <input
                        type="file"
                        name="image"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue("image", event.target.files[0]);
                        }}
                      />
                      <ErrorMessage
                        name="image"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Assign Notifications:</label>
                    <div className="d-flex flex-wrap">
                      {[
                        "Admin",
                        "Management",
                        "Teaching Staff",
                        "Non-Teaching Staff",
                        "Parent",
                        "Students",
                      ].map((label, index) => {
                        const value = index + 2;
                        return (
                          <div
                            key={index}
                            className="d-flex align-items-center mx-3 mb-2"
                            style={{ minWidth: "200px" }}
                          >
                            <input
                              type="checkbox"
                              value={value}
                              className="mr-2"
                              onChange={(e) => {
                                const checked = e.target.checked;
                                const value = parseInt(e.target.value, 10);
                                setFieldValue(
                                  "assign_to",
                                  checked
                                    ? [...values.assign_to, value]
                                    : values.assign_to.filter(
                                        (v) => v !== value
                                      )
                                );
                              }}
                              checked={values.assign_to.includes(value)}
                            />
                            <span>{label}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowEditModal(false)}
                >
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  Update
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

export default HolidayCarousel;
