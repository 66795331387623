import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useParams } from "react-router-dom";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getstudentbyclassandsection } from "../../Services/CommonApis/StudentApi";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import {
  createConcession,
  getAllFees,
} from "../../Services/CommonApis/FeesApi";
import {
  createAcademicPayments,
  getPaymentMethod,
  getStudentAcademicFee,
} from "../../Services/CommonApis/FeesApi";
import {
  showSuccessAlert,
  showLoadingAlert,
  showErrorAlert,
} from "../../Modals/SweetAlertModel";
import { getAllConcession } from "../../Services/CommonApis/FeesApi";
import { useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import { getFeesSubComponentsByFCID } from "../../Services/CommonApis/FeesApi";
import * as Yup from "yup";

const StudentWiseConcession = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [classOptions, setClassOptions] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [sections, setSections] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [students, setStudents] = useState([]);
  const [fees, setFees] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentFees, setStudentFees] = useState([]);
  const [concessions, setConcession] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [feesSubComponents, setFeesSubComponents] = useState([]);
  const [selectedComponet, setSelectedComponent] = useState("");

  const { branchId } = useParams();

  useEffect(() => {
    if (selectedStudent?.fee_component_id) {
      fetchSubComponetsByFCID(selectedStudent.fee_component_id);
    }
  }, [selectedStudent?.fee_component_id]);

  const fetchSubComponetsByFCID = async (id) => {
    try {
      let response = await getFeesSubComponentsByFCID(id);
      setFeesSubComponents(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchClasses(branchId);
    fetchFeeComponents(branchId);
    fetchConcession();
  }, [branchId]);

  useEffect(() => {
    setShowTable(!!selectedClassId && selectedSection !== "");
  }, [selectedClassId, selectedSection]);

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      const classes = response.data.classes.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }));
      setClassOptions(classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchFeeComponents = async (branchId) => {
    try {
      const response = await getAllFees(branchId);
      setFees(response.data.data);
    } catch (error) {
      console.error("Error fetching fees:", error);
    }
  };

  const fetchConcession = async () => {
    try {
      const response = await getAllConcession();
      setConcession(response.data);
    } catch (error) {
      console.error("Error fetching fees:", error);
    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSections(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  const handleSectionChange = (event, setFieldValue) => {
    const selectedSectionId = event.target.value;
    setSelectedSection(selectedSectionId);
    setFieldValue("section", selectedSectionId);
  };

  const handleFeeComponentChange = (event, setFieldValue) => {
    const selectedfcId = event.target.value;
    setSelectedComponent(selectedfcId);
    setFieldValue("fcid", selectedfcId);
  };

  const handleAssignConcession = (student) => {
    setSelectedStudent(student);
    setModalOpen(true);
  };

  const handleModalSubmit = async (values) => {
    const submissionData = {
      ...values,
      class_id: selectedClassId,
      student_id: selectedStudent.student_id,
      branch_id: branchId,
      fcap_id: selectedStudent.fee_component_id,
      fsap_id: values.sub_component,
      ...(selectedSection !== "0" && { section_id: selectedSection }),
    };

    try {
      const response = await createConcession(submissionData);
      showSuccessAlert("Success");
    } catch (errors) {
      showErrorAlert(errors.response.data);
    }
    setModalOpen(false);
  };

  useEffect(() => {
    if (showTable) {
      tableInstance.current = $(tableRef.current).DataTable({
        destroy: true,
        columns: [
          {
            data: null,
            title: "S.No",
            render: (data, type, row, meta) => meta.row + 1,
          },
          {
            data: "academic_period",
            title: "Academic Period",
          },
          {
            data: "full_name",
            title: "Student & Parent Info",
            render: (data, type, row) => {
              return `
                <div>
                  <!-- Student Name -->
                  <div>${row.full_name || "N/A"}</div>
          
                  <!-- Parent Name -->
                  <small style="color: gray; display: block;">
                    Parent: ${row.parent_name || "N/A"}
                  </small>
                  
                  <!-- Admission No -->
                  <small style="color: gray;">
                    ${row.admission_no || "N/A"}
                  </small>
                  
                  <!-- Class & Section -->
                  <small style="font-weight: bold; display: block;">
                    ${
                      row.class_name
                        ? `${row.class_name} - ${row.section_name}`
                        : "N/A"
                    }
                  </small>
                </div>
              `;
            },
          },
          {
            data: "fee_component",
            title: "Fee Component",
          },
          {
            data: "fees_sub_component",
            title: "Fee Details",
            render: (data, type, row) => {
              if (Array.isArray(data) && data.length > 0) {
                return data.map((sub) => sub.name).join(", ");
              }
              return "No Details";
            },
          },
          {
            data: "concession_amount",
            title: "Concession Amount",
          },
          {
            data: "amount",
            title: "Amount",
          },
          {
            data: "balance",
            title: "Balance",
          },

          {
            data: "amount_paid",
            title: "Amount Paid",
          },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data, type, row) => {
              return `
                <a 
                  title="Assign Concession"
                  style="display: flex; align-items: center; gap: 5px;" 
                  class="edit-icon" 
                  data-id="${row.id}" 
                  data-student-id="${row.student_id}" 
                  data-fee-component-id="${row.fee_component_id}" 
                  data-amount="${row.amount}"
                >
                  <i class="fa-solid fa-money-bill-transfer"></i>
                </a>
              `;
            },
            title: "Actions",
          },
        ],

        lengthChange: true,
        order: true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          { extend: "copy", exportOptions: { columns: ":visible" } },
          { extend: "csv", exportOptions: { columns: ":visible" } },
          { extend: "excel", exportOptions: { columns: ":visible" } },
          { extend: "pdf", exportOptions: { columns: ":visible" } },
          { extend: "print", exportOptions: { columns: ":visible" } },
        ],
        serverSide: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 75, 100, -1],
          [10, 25, 50, 75, 100, "All"],
        ],
        language: {
          paginate: {
            previous: "Back",
            next: "Next",
            first: "First",
            last: "Last",
          },
          emptyTable: "No Data Found",
        },
        ajax: async function (data, callback) {
          try {
            const start = data.start || 0;
            const length = data.length || 10;
            const page = Math.floor(start / length) + 1;
            const orderColumnIndex =
              data.order && data.order.length > 0 ? data.order[0].column : 0;
            const orderDirection =
              data.order && data.order.length > 0 ? data.order[0].dir : "desc";
            const orderColumn = data.columns[orderColumnIndex].data;
            const searchValue = data.search.value;

            const payload = {
              branch_id: branchId,
              class_id: selectedClassId,
              component_id: selectedComponet,
            };

            // Conditionally add section_id
            if (selectedSection !== "0") {
              payload.section_id = selectedSection;
            }

            const response = await getStudentAcademicFee(payload);

            const jsonData = response.data.data.academic_fees || [];
            const totalCount = response.data.data.academic_fees.total || 0;
            callback({
              draw: data.draw,
              recordsTotal: totalCount,
              recordsFiltered: totalCount,
              data: jsonData.length > 0 ? jsonData : [],
            });
          } catch (error) {
            setTimeout(() => {
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }, 5000);
          }
        },
      });

      $(tableRef.current).on("click", ".edit-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
        const student_id = $(this).data("student-id");
        const fee_component_id = $(this).data("fee-component-id");
        const amount = $(this).data("amount");

        const studentData = { id, student_id, fee_component_id, amount };
        handleAssignConcession(studentData);
      });
    }
  }, [branchId, selectedClassId, selectedSection, selectedComponet, showTable]);

  const validationSchema = Yup.object().shape({
    concession_id: Yup.string().required("Concession is required"),
    amount: Yup.number()
      .required("Amount is required")
      .test(
        "is-amount-valid",
        "Amount cannot be greater than total fee amount",
        function (value) {
          if (selectedStudent?.amount && value > selectedStudent.amount) {
            return false;
          }
          return true;
        }
      ),
    date: Yup.date().required("Date is required"),
    amount_type: Yup.string().required("Amount type is required"),
    sub_component: Yup.string().nullable(),
  });

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Student Wise Concession
          </h3>
        </div>
        <Formik initialValues={{ class_id: "" }}>
          {({ setFieldValue }) => (
            <div className="card-body">
              <div className="row">
                <div className="form-group col-4">
                  <label htmlFor="class_id">
                    Class Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="class_id"
                    name="class_id"
                    className="form-control"
                    onChange={(e) => {
                      const classId = e.target.value;
                      setFieldValue("class_id", classId);
                      setSelectedClassId(classId);
                      fetchSections(classId);
                    }}
                  >
                    <option value="">Select Class</option>
                    {classOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="class_id"
                    component="div"
                    className="text-error"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="section">
                    Section Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="section"
                    name="section"
                    className="form-control"
                    value={selectedSection}
                    onChange={(e) => handleSectionChange(e, setFieldValue)}
                    disabled={!selectedClassId}
                  >
                    <option value="">Select Section</option>
                    <option value="0">All</option>
                    {sections.map((sec) => (
                      <option key={sec.id} value={sec.id}>
                        {sec.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="section"
                    component="div"
                    className="text-error"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="fcid">Select Fee Component</label>
                  <Field
                    as="select"
                    name="fcid"
                    className="form-control"
                    onChange={(e) => handleFeeComponentChange(e, setFieldValue)}
                  >
                    <option value="">Select</option>
                    {fees.map((fee) => (
                      <option key={fee.id} value={fee.id}>
                        {fee.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fcid"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              {showTable && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table
                          ref={tableRef}
                          className="table table-striped table-bordered"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Student Name</th>
                              <th>Fee Component</th>
                              <th>Fee details</th>
                              <th>Concession Amount</th>
                              <th>Amount</th>
                              <th>Balance</th>
                              <th>Academic Period</th>
                              <th>Amount Paid</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                          <tfoot>
                            <tr>
                              <th>S.No</th>
                              <th>Student Name</th>
                              <th>Fee Component</th>
                              <th>Fee details</th>
                              <th>Concession Amount</th>
                              <th>Amount</th>
                              <th>Balance</th>
                              <th>Academic Period</th>
                              <th>Amount Paid</th>
                              <th>Actions</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </Formik>
      </div>

      {/* Concession Modal */}
      <Dialog
        header="Assign Concession"
        visible={isModalOpen}
        style={{ width: "40vw" }}
        onHide={() => setModalOpen(false)}
      >
        <Formik
          initialValues={{
            concession_id: "",
            amount: "",
            date: new Date().toISOString().split("T")[0], // Sets today's date in YYYY-MM-DD format
            amount_type: "",
            sub_component: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleModalSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="concession_id">
                  Select Concession<span className="mandatory">*</span>
                </label>
                <Field
                  as="select"
                  name="concession_id"
                  className="form-control"
                  onChange={(e) =>
                    setFieldValue("concession_id", e.target.value)
                  }
                >
                  <option value="">Select</option>
                  {concessions.map((concession) => (
                    <option key={concession.id} value={concession.id}>
                      {concession.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="concession_id"
                  component="div"
                  className="text-danger"
                />
              </div>

              {/* Subcomponents Dropdown */}
              {selectedStudent?.fee_component_id && (
                <div className="form-group">
                  <label htmlFor="sub_component">Select Subcomponent</label>
                  <Field
                    as="select"
                    name="sub_component"
                    className="form-control"
                    onChange={(e) =>
                      setFieldValue("sub_component", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    {feesSubComponents.map((subComponent) => (
                      <option key={subComponent.id} value={subComponent.id}>
                        {subComponent.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="sub_component"
                    component="div"
                    className="text-danger"
                  />
                </div>
              )}

              <div className="form-group">
                <label>
                  Type<span className="mandatory">*</span>
                </label>
                <div>
                  <label style={{ marginRight: "10px" }}>
                    <Field
                      type="radio"
                      name="amount_type"
                      value="0"
                      style={{ marginRight: "10px" }}
                    />
                    Amount
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="amount_type"
                      value="1"
                      style={{ marginLeft: "20px", marginRight: "10px" }}
                    />
                    Percentage
                  </label>
                </div>
                <ErrorMessage
                  name="amount_type"
                  component="div"
                  className="text-error"
                />
              </div>

              <div className="form-group">
                <label htmlFor="amount">
                  {values.amount_type === "percentage"
                    ? "Concession Percentage"
                    : "Concession Amount"}{" "}
                  <span className="mandatory">*</span>
                </label>
                <Field type="number" name="amount" className="form-control" />
                <ErrorMessage
                  name="amount"
                  component="div"
                  className="text-error"
                />
              </div>

              <div className="form-group">
                <label htmlFor="date">
                  Date <span className="mandatory">*</span>
                </label>
                <Field type="date" name="date" className="form-control" />
                <ErrorMessage
                  name="date"
                  component="div"
                  className="text-error"
                />
              </div>

              <button
                type="submit"
                className="btn btn-primary mt-3 float-right"
              >
                Assign
              </button>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default StudentWiseConcession;
