import * as XLSX from "xlsx";

const handleDownload = () => {
  const fileName = "branches.xlsx";
  const headers = [
    "Branch Name",
    "Branch Code",
    "Affiliation No",
    "Email",
    "Phone",
    "Address",
    "Pin Code",
    "status",
  ];
  const data = [
    [
      "Venkatesh Branch",
      "CB001",
      "AFF12345",
      "centralbranche@gmail.com",
      "9123453789",
      "123 Main St",
      "100016",
      1,
    ],
    [
      "Sir Branch",
      "WB002",
      "AFF67890",
      "westside@gmail.com",
      "9876543210",
      "456 West Ave",
      "900011",
      1,
    ],
  ];

  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Branches");

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export default handleDownload;
