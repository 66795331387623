import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import {
  createBookIssue,
  getLibraryBooksList,
} from "../../Services/AdminApis/LibraryApi";
import { useParams } from "react-router-dom";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getstudentbyclassandsection } from "../../Services/CommonApis/StudentApi";
import { getSession } from "../../Services/Storage";
import { COOKIE } from "../../Schemas/cookieNames";
import { getUserId } from "../../Utility/CommonUtility/extractUserDetailsFromToken";
import Select from "react-select";

function NewBookIssue() {
  const { branchId } = useParams();
  const [bookList, setBookList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [isClassLoading, setClassLoading] = useState(true);
  const [isSectionLoading, setSectionLoading] = useState(false);
  const [studentLoading, setStudentLoading] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const adminToken = getSession(COOKIE.ADMIN_TOKEN);
    setUserId(getUserId(adminToken));
    fetchBooks(branchId);
    fetchClassByBranchId(branchId);
  }, [branchId]);

  useEffect(() => {
    if (selectedClassId) {
      setSectionLoading(true);
      fetchSectionByClassId(selectedClassId);
      setSelectedSectionId(null);
    }
  }, [selectedClassId]);

  useEffect(() => {
    if (selectedClassId && selectedSectionId) {
      setStudentLoading(true);
      fetchStudentsBySectionId(branchId, selectedClassId, selectedSectionId);
      setSelectedStudentId(null);
    }
  }, [branchId, selectedClassId, selectedSectionId]);

  const fetchBooks = async (branchId) => {
    try {
      const response = await getLibraryBooksList({ branch_id: branchId });
      setBookList(response.data.books.map((book) => ({
        label: book.name, // Display name
        value: book.id, // Value to store
      })));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClassByBranchId = async (branchId) => {
    try {
      setClassLoading(true);
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
    } catch (error) {
      console.log(error);
    } finally {
      setClassLoading(false);
    }
  };

  const fetchSectionByClassId = async (classId) => {
    try {
      setSectionLoading(true);
      const response = await getSectionByClassId(classId);
      setSectionList(response.data.sections);
    } catch (error) {
      console.log(error);
    } finally {
      setSectionLoading(false);
    }
  };

  const fetchStudentsBySectionId = async (
    branchId,
    selectedClassId,
    selectedSectionId
  ) => {
    try {
      const response = await getstudentbyclassandsection(
        branchId,
        selectedClassId,
        selectedSectionId
      );
      setStudentList(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setStudentLoading(false);
    }
  };

  const initialValues = {
    book_id: [],
    student_id: "",
    user_id: userId,
    issue_date: today,
  };

  const validationSchema = Yup.object().shape({
    book_id: Yup.array().min(1, "Please select at least one book"),
    student_id: Yup.string().required("Please select a student"),
    issue_date: Yup.string().required("Issue date is required"),
  });

  const handleSubmit = async (values) => {
    if (values.issue_date) {
      const date = new Date(values.issue_date);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      values.issue_date = `${day}/${month}/${year}`;
    }

    values.branch_id = branchId;
    values.book_id = values.book_id.map((book) => book.value);

    const result = await showSweetAlert(
      "Are you sure?",
      "You want to issue the book",
      "info",
      "Yes, Issue",
      "No, cancel!"
    );

    if (result.isConfirmed) {
      showLoadingAlert();
      try {
        const response = await createBookIssue(values);
        if (response.status === 201) {
          showSuccessAlert("Created!", "The Book has been Issued successfully.");
          window.location.reload();
        } else {
          const errorMessage =
            response.data?.message || "Book Iusse failed. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";

        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Book Issue has been cancelled.");
    }
  };

  return (
    <div className="card card-primary">
      <div className="card-header">
        <h3 className="card-title">
          Book Creation <small>Form</small>
        </h3>
      </div>
      <div className="card-body">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="form-card">
              <div className="row">
                <div className="form-group col-4">
                  <label htmlFor="class_id">
                    Class Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="class_id"
                    name="class_id"
                    className="form-control"
                    onChange={(e) => {
                      const selectedClassId = e.target.value;
                      setFieldValue("class_id", selectedClassId);
                      setSelectedClassId(selectedClassId);
                    }}
                  >
                    {isClassLoading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Class</option>
                        {classList.map((cls) => (
                          <option key={cls.id} value={cls.id}>
                            {cls.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="class_id"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="section_id">
                    Section Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="section_id"
                    name="section_id"
                    className="form-control"
                    value={selectedSectionId || ""}
                    onChange={(e) => {
                      const selectedSectionId = e.target.value;
                      setFieldValue("section_id", selectedSectionId);
                      setSelectedSectionId(selectedSectionId);
                    }}
                    disabled={!selectedClassId}
                  >
                    {isSectionLoading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Section</option>
                        {sectionList.map((section) => (
                          <option key={section.id} value={section.id}>
                            {section.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="section_id"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="student_id">
                    Student Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="student_id"
                    name="student_id"
                    className="form-control"
                    value={selectedStudentId || ""}
                    onChange={(e) => {
                      const selectedStudentId = e.target.value;
                      setFieldValue("student_id", selectedStudentId);
                      setSelectedStudentId(selectedStudentId);
                    }}
                    disabled={!selectedSectionId}
                  >
                    {studentLoading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Student</option>
                        {studentList.map((student) => (
                          <option key={student.id} value={student.id}>
                            {student.first_name} {student.last_name}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="student_id"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="book_id">Book Name</label>
                  <Select
                    id="book_id"
                    name="book_id"
                    options={bookList}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(selectedOptions) => {
                      setFieldValue("book_id", selectedOptions);
                    }}
                    value={values.book_id}
                  />
                  <ErrorMessage
                    name="book_id"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label htmlFor="issue_date" className="form-label">
                      Published Date <span className="mandatory">* </span>
                    </label>
                    <Field
                      type="date"
                      name="issue_date"
                      className="form-control"
                      style={{ borderColor: "skyblue" }}
                    />
                    <ErrorMessage
                      name="issue_date"
                      component="div"
                      className="text-error"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default NewBookIssue;
