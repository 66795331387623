import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { getSession } from "../../Services/Storage";
import { COOKIE } from "../../Schemas/cookieNames";
import { USERROLEID } from "../../Schemas/userRoles";
import {
  getBranchId,
  getSchoolId,
  getUserRoleId,
} from "../../Utility/CommonUtility/extractUserDetailsFromToken";
function StaffSidebar() {
  const [schoolId, setSchoolId] = useState(null);
  const [branchId, setBranchId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const location = useLocation();
  const adminToken = getSession(COOKIE.ADMIN_TOKEN);

  const getActiveLinkStyles = (path) => ({
    backgroundColor: location.pathname === path ? "white" : "",
    color: location.pathname === path ? "#808080" : "",
  });

  const handleToggle = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu);
  };
  useEffect(() => {
    if (adminToken) {
      setRoleId(getUserRoleId(adminToken));
      if (roleId === USERROLEID.TEACHER_ID) {
        setSchoolId(getSchoolId(adminToken));
        setBranchId(getBranchId(adminToken));
      } else {
        setSchoolId(getSession(COOKIE.SCHOOL_ID));
        setBranchId(getSession(COOKIE.BRANCH_ID));
      }
    }
  }, [adminToken, roleId]);
  console.log(schoolId);
  console.log(branchId);

  const links = [
    {
      path: `/teaching-staff/school/${schoolId}/branch/${branchId}/class`,
      label: "class",
      icon: "fas fa-chalkboard-teacher",
    },
    {
      path: `/teaching-staff/school/${schoolId}/branch/${branchId}/section`,
      label: "Section",
      icon: "fas fa-columns",
    },
    {
      path: `/teaching-staff/school/${schoolId}/branch/${branchId}/homework`,
      label: "homework",
      icon: "fas fa-book-open",
    },
    {
      path: `/teaching-staff/school/${schoolId}/branch/${branchId}/attendance`,
      label: "attendance",
      icon: "fas fa-user-check",
    },
  ];
  const ReportCardMenu = [
    {
      path: `/teaching-staff/school/${schoolId}/branch/${branchId}/reportcard-dashboard`,
      label: "ReportCard Dashboard",
      icon: "fas fa-clipboard-list",
    },
    {
      path: `/teaching-staff/school/${schoolId}/branch/${branchId}/exam-setup`,
      label: "Exam Setup",
      icon: "fas fa-clipboard-list",
    },
    {
      path: `/teaching-staff/school/${schoolId}/branch/${branchId}/exam-grade-setup`,
      label: "Exam Grade Setup",
      icon: "fa fa-check-circle",
    },
    {
      path: `/teaching-staff/school/${schoolId}/branch/${branchId}/exam-report-lock`,
      label: "Report Lock",
      icon: "fa fa-lock",
    },
    {
      path: `/teaching-staff/school/${schoolId}/branch/${branchId}/remarks`,
      label: "Remark",
      icon: "fas fa-pencil-alt",
    },
    {
      path: `/teaching-staff/school/${schoolId}/branch/${branchId}/personality-traits`,
      label: "Personality Traits",
      icon: "fas fa-lightbulb",
    },
    {
      path: `/teaching-staff/school/${schoolId}/branch/${branchId}/report-configuration`,

      label: "Report Card Config",

      icon: "fa fa-wallet",
    },
    {
      path: `/teaching-staff/school/${schoolId}/branch/${branchId}/marks-entry`,
      label: "Marks Entry",
      icon: "fa fa-keyboard",
    },

    {
      path: `/teaching-staff/school/${schoolId}/branch/${branchId}/report`,

      label: "Report Cards",

      icon: "fa fa-check-circle",
    },
    {
      path: `/teaching-staff/school/${schoolId}/branch/${branchId}/overall`,

      label: "Overall Reports",

      icon: "fa-solid fa-bars-progress",
    },
  ];

  const feesSubmenu = [
    {
      path: `/parent/academic-fee`,
      label: "Academic Fee",
      icon: "fas fa-book",
    },
    {
      path: `/parent/transport-fee`,
      label: "Transport Fee",
      icon: "fas fa-bus",
    },
    {
      path: `/parent/hostel-fee`,
      label: "Hostel Fee",
      icon: "fas fa-bed",
    },
    {
      path: `/parent/other-fee`,
      label: "Other Fee",
      icon: "fas fa-clipboard-list",
    },
  ];

  const attendanceSubmenu = [
    {
      path: `/teaching-staff/student-attendance`,
      label: " View Attendance",
      icon: "fas fa-user-check",
    },
    {
      path: `/teaching-staff/student-leaves`,
      label: " Leaves",
      icon: "fas fa-calendar-alt",
    },
  ];

  const TransportSubmenu = [
    {
      path: `/parent/student-pickuprequest`,
      label: "Pickup Request",
      icon: "fas fa-truck-pickup",
    },
  ];

  const requestSubmenu = [
    {
      path: `/parent/it-form-request`,
      label: "IT Form Request",
      icon: "fas fa-file-alt",
    },
    {
      path: `/parent/bonafide-request`,
      label: "Bonafide Request",
      icon: "fas fa-certificate",
    },
    {
      path: `/parent/tc-request`,
      label: "TC Request",
      icon: "fas fa-graduation-cap",
    },
  ];

  const helpdeskSubmenu = [
    {
      path: `/parent/feedback-list`,
      label: "Concerns/Feedback List",
      icon: "fas fa-comment-dots",
    },
    {
      path: `/parent/concerns`,
      label: "Concerns From School",
      icon: "fas fa-school",
    },
  ];

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {roleId === USERROLEID.PARENT_ID ? (
        <Link to={`/parent/student-profile`} className="brand-link">
          <img
            src="/plugins/dist/img/Logo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
          />
          <span className="brand-text font-weight-light">SR EDU KYC</span>
        </Link>
      ) : (
        <Link to="/parent/student-profile" className="brand-link">
          <img
            src="/plugins/dist/img/Logo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
          />
          <span className="brand-text font-weight-light">SR EDU KYC</span>
        </Link>
      )}

      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="/plugins/dist/img/user2-160x160.jpg"
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info">
            <Link to={`/parent/student-profile`} className="d-block">
              Alexander Pierce
            </Link>
          </div>
        </div>

        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {links.map(({ path, label, icon }) => (
              <li className="nav-item" key={path}>
                <Link
                  to={path}
                  className={`nav-link ${
                    location.pathname === path ? "active-sub" : ""
                  }`}
                  style={getActiveLinkStyles(path)}
                >
                  <i className={`nav-icon ${icon}`}></i>
                  <p>{label}</p>
                </Link>
              </li>
            ))}

            {/* Report card menu*/}
            <li
              className={`nav-item has-treeview ${
                openMenu === "fees" ? "menu-open" : ""
              }`}
            >
              <a
                className={`nav-link ${openMenu === "fees" ? "active" : ""}`}
                onClick={() => handleToggle("fees")}
              >
                <i className="nav-icon fas fa-file-alt"></i>
                <p>
                  Report Card
                  <i className="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                {ReportCardMenu.map(({ path, label, icon }) => (
                  <li className="nav-item" key={path}>
                    <Link
                      to={path}
                      className={`nav-link ${
                        location.pathname === path ? "active-sub" : ""
                      }`}
                      style={getActiveLinkStyles(path)}
                    >
                      <i className={`nav-icon ${icon}`}></i>
                      <p>{label}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default StaffSidebar;
