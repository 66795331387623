import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import Swal from "sweetalert2";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import {
  createExamGrade,
  getAllExamGrades,
  getExamGradeById,
  updateExamGradeById,
  deleteExamGrade,
} from "../../Services/AdminApis/ExamGradeApi";
import { useParams } from "react-router-dom";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import { getSchools } from "../../Services/AdminApis/schoolApi";
import { getBranchBySchoolId } from "../../Services/CommonApis/branchApi";

const ExamGradeConfiguration = () => {
  const { branchId } = useParams();
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editGroupId, setEditGroupId] = useState(null);
  const [editGroupName, setEditGroupName] = useState("");
  const [editMaxMarks, setEditMaxMarks] = useState("");
  const [editMinMarks, setEditMinMarks] = useState("");
  const [editClassId, setEditClassId] = useState("");
  const [classOptions, setClassOptions] = useState([]);
  const [schools, setSchools] = useState([]);
  const [branches, setBranches] = useState([]);

  const [isStateLoading, setIsStateLoading] = useState(false);
  const [isDistrictLoading, setIsDistrictLoading] = useState(false);

  useEffect(() => {
    if (!branchId) {
      fetchSchools();
    } else {
      fetchClasses(branchId);
    }
  }, [branchId]);

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);

      const classes = response.data.classes.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }));
      console.log(classes);
      setClassOptions(classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchSchools = async () => {
    try {
      setIsStateLoading(true);
      const response = await getSchools();
      console.log(response);
      setSchools(response.data);
    } catch (error) {
      console.error("Error fetching schools:", error);
    } finally {
      setIsStateLoading(false);
    }
  };

  const fetchBranch = async (schoolId) => {
    try {
      setIsDistrictLoading(true);
      const response = await getBranchBySchoolId(schoolId);
      console.log(response.branches);
      setBranches(response.branches);
    } catch (error) {
      console.error("Error fetching branches:", error);
    } finally {
      setIsDistrictLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        const response = await deleteExamGrade(id);
        Swal.close();
        showSuccessAlert("Deleted!", response.data.message || "Data Deleted Successfully");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the grade.");
        console.error(error);
      }
    } else {
      showErrorAlert("Cancelled", "The grade is safe :)");
    }
  };

  const handleCreateExamGrade = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "Do you want to create Grade?",
      "info",
      "Yes, create it!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        const response = await createExamGrade({
          ...values,
          branch_id: branchId || values.branch_id,
        });
        Swal.close();
        showSuccessAlert("Created!", response.data.message || "Data Created Successfully");
        setShowCreateModal(false);
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error creating the Grade.");
        console.error(error);
      }
    } else {
      showErrorAlert("Cancelled", "Grade creation has been cancelled.");
    }
  };

  const handleEdit = async (id) => {
    try {
      const response = await getExamGradeById(id);
      const { name, max_marks, min_marks, class_id } = response.data;
      setEditGroupId(id);
      setEditGroupName(name);
      setEditMaxMarks(max_marks);
      setEditMinMarks(min_marks);
      setEditClassId(class_id);
      setShowEditModal(true);
    } catch (error) {
      console.error("Error fetching group details:", error);
      showErrorAlert("Error!", "There was an error fetching the Grade.");
    }
  };

  const handleUpdateExamGrade = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "Do you want to update Grade?",
      "info",
      "Yes, update it!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        if (
          values.name === editGroupName &&
          values.max_marks === editMaxMarks &&
          values.min_marks === editMinMarks &&
          values.class_id === editClassId
        ) {
          showErrorAlert("No changes detected", "Please modify the Grade details before updating.");
          return;
        }

        showLoadingAlert();
        const response = await updateExamGradeById(editGroupId, {
          ...values,
          branch_id: branchId || values.branch_id,

        });
        Swal.close();
        showSuccessAlert("Updated!", response.message || "Data Updated Successfully"); // Updated message
        setShowEditModal(false);
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error updating the Grade.");
        console.error(error);
      }
    } else {
      showErrorAlert("Cancelled", "Grade update has been cancelled.");
    }
  };


  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "name" },
        {
          data: "class_name",
          render: (data) => (data ? data : "N/A"),
        },
        {
          data: "max_marks",
          render: (data) => (data ? data : "N/A"),
        },
        {
          data: "min_marks",
          render: (data) => (data ? data : "N/A"),
        },
        {
          data: "status",
          render: (data) => (data === "1" ? "Active" : "Inactive"),
        },
        {
          data: null,
          title: "Actions",
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "inactive"
                ? ""
                : `<a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>`;
            return `
        <div class="icon-group" style="display: flex; gap: 10px;">
          <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
          ${deleteIcon}
        </div>
      `;
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "csv",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "excel",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "pdf",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "print",
          exportOptions: { columns: ":visible" },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllExamGrades({
            page: page,
            _limit: length,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });

          const jsonData = response.data.data;
          const totalCount = response.data.total;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEdit(id);
    });

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, []);

  const initialValues = {
    name: "",
    class_id: "",
    max_marks: "0",
    min_marks: "0",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Grade name is required"),
    class_id: Yup.string().required("Class name is required"),
    max_marks: Yup.number()
      .required("Maximum marks are required")
      .positive("Maximum marks must be a positive number")
      .integer("Maximum marks must be an integer"),
    min_marks: Yup.number()
      .required("Minimum marks are required")
      .positive("Minimum marks must be a positive number")
      .integer("Minimum marks must be an integer")
      .max(Yup.ref('max_marks'), 'Minimum marks must be less than or equal to Maximum marks'),
  });


  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Exam Grades Setup</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 style={{ paddingTop: "5px" }} className="card-title">
                    Exam Grade List
                  </h3>
                  <button
                    className="btn btn-primary float-right"
                    onClick={() => setShowCreateModal(true)}
                  >
                    Create New
                  </button>
                </div>
                <div className="card-body">
                  <table
                    ref={tableRef}
                    className="table table-striped table-bordered"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Grade Name</th>
                        <th>Class Name</th>
                        <th>Maximum Marks</th>
                        <th>Minimum Marks</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th>ID</th>
                        <th>Grade Name</th>
                        <th>Class Name</th>
                        <th>Maximum Marks</th>
                        <th>Minimum Marks</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Create Group Modal */}
        <Modal
          show={showCreateModal}
          onHide={() => setShowCreateModal(false)}
          centered
          backdrop="static"
          className="swal2-blur-popup"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Grade</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCreateExamGrade}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="name">
                      Grade Name <span className="mandatory">* </span>
                    </label>
                    <Field
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter grade name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-error"
                    />
                  </div>

                  {!branchId && (
                    <>
                      <div className="form-group">
                        <label htmlFor="state">
                          Select School <span className="mandatory">* </span>
                        </label>
                        <Field
                          as="select"
                          className="form-control"
                          id="state"
                          name="school_id"
                          placeholder="Select School"
                          onChange={(e) => {
                            const schoolId = e.target.value;
                            setFieldValue("school_id", schoolId);
                            fetchBranch(schoolId);
                          }}
                        >
                          <option value="" label="Select School" />
                          {isStateLoading ? (
                            <option>Loading...</option>
                          ) : (
                            schools.map((school) => (
                              <option key={school.id} value={school.id} label={school.name} />
                            ))
                          )}
                        </Field>
                        <ErrorMessage name="school_id" component="div" className="text-error" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="district">
                          Select Branch <span className="mandatory">* </span>
                        </label>
                        <Field
                          as="select"
                          className="form-control"
                          id="district"
                          name="branch_id"
                          onChange={(e) => {
                            const selectedBranchId = e.target.value;
                            setFieldValue("branch_id", selectedBranchId);
                            fetchClasses(selectedBranchId);
                          }}
                        >
                          <option value="" label="Select Branch" />
                          {isDistrictLoading ? (
                            <option>Loading...</option>
                          ) : (
                            branches.map((branch) => (
                              <option
                                key={branch.id}
                                value={branch.id}
                                label={branch.branch_name}
                              />
                            ))
                          )}
                        </Field>
                        <ErrorMessage name="branch_id" component="div" className="text-error" />
                      </div>
                    </>
                  )}

                  <div className="form-group">
                    <label htmlFor="class_id">
                      Select Class <span className="mandatory">* </span>
                    </label>
                    <Field
                      as="select"
                      className="form-control"
                      id="class_id"
                      name="class_id"
                    >
                      <option value="" label="Select Class" />
                      {classOptions.map((cls) => (
                        <option
                          key={cls.value}
                          value={cls.value}
                          label={cls.label}
                        />
                      ))}
                    </Field>
                    <ErrorMessage
                      name="class_id"
                      component="div"
                      className="text-error"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">
                      Minimum Marks <span className="mandatory">* </span>
                    </label>
                    <Field
                      type="number"
                      name="min_marks"
                      className="form-control"
                      placeholder="Enter only numbers"
                      style={{
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <ErrorMessage
                      name="min_marks"
                      component="div"
                      className="text-error"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">
                      Maximum Marks <span className="mandatory">* </span>
                    </label>
                    <Field
                      type="number"
                      name="max_marks"
                      className="form-control"
                      placeholder="Enter only numbers"
                      style={{
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <ErrorMessage
                      name="max_marks"
                      component="div"
                      className="text-error"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowCreateModal(false)}
                  >
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>

        {/* Edit Group Modal */}
        <Modal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Grade</Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={{
              name: editGroupName,
              max_marks: editMaxMarks,
              min_marks: editMinMarks,
              class_id: editClassId,
            }}
            validationSchema={validationSchema}
            onSubmit={handleUpdateExamGrade}
          >
            {({ handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="form-group">
                    <label htmlFor="name">
                      Grade Name <span className="mandatory">* </span>
                    </label>
                    <Field
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter group name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-error"
                    />
                  </div>
                  {!branchId && (
                    <>
                      <div className="form-group">
                        <label htmlFor="state">
                          Select School <span className="mandatory">* </span>
                        </label>
                        <Field
                          as="select"
                          className="form-control"
                          id="state"
                          name="school_id"
                          placeholder="Select School"
                          onChange={(e) => {
                            const schoolId = e.target.value;
                            setFieldValue("school_id", schoolId);
                            fetchBranch(schoolId);
                          }}
                        >
                          <option value="" label="Select School" />
                          {isStateLoading ? (
                            <option>Loading...</option>
                          ) : (
                            schools.map((school) => (
                              <option key={school.id} value={school.id} label={school.name} />
                            ))
                          )}
                        </Field>
                        <ErrorMessage name="school_id" component="div" className="text-error" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="district">
                          Select Branch <span className="mandatory">* </span>
                        </label>
                        <Field
                          as="select"
                          className="form-control"
                          id="district"
                          name="branch_id"
                          onChange={(e) => {
                            const selectedBranchId = e.target.value;
                            setFieldValue("branch_id", selectedBranchId);
                            fetchClasses(selectedBranchId);
                          }}
                        >
                          <option value="" label="Select Branch" />
                          {isDistrictLoading ? (
                            <option>Loading...</option>
                          ) : (
                            branches.map((branch) => (
                              <option
                                key={branch.id}
                                value={branch.id}
                                label={branch.branch_name}
                              />
                            ))
                          )}
                        </Field>
                        <ErrorMessage name="branch_id" component="div" className="text-error" />
                      </div>
                    </>
                  )}


                  <div className="form-group">
                    <label htmlFor="class_id">
                      Select Class <span className="mandatory">* </span>
                    </label>
                    <Field
                      as="select"
                      className="form-control"
                      id="class_id"
                      name="class_id"
                    >
                      <option value="" label="Select Class" />
                      {classOptions.map((cls) => (
                        <option
                          key={cls.value}
                          value={cls.value}
                          label={cls.label}
                        />
                      ))}
                    </Field>
                    <ErrorMessage
                      name="class_id"
                      component="div"
                      className="text-error"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">
                      Minimum Marks <span className="mandatory">* </span>
                    </label>
                    <Field
                      type="number"
                      name="min_marks"
                      className="form-control"
                      placeholder="Enter only numbers"
                      style={{
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <ErrorMessage
                      name="min_marks"
                      component="div"
                      className="text-error"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">
                      Maximum Marks <span className="mandatory">* </span>
                    </label>
                    <Field
                      type="number"
                      name="max_marks"
                      className="form-control"
                      placeholder="Enter only numbers"
                      style={{
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <ErrorMessage
                      name="max_marks"
                      component="div"
                      className="text-error"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowEditModal(false)}
                  >
                    Close
                  </Button>
                  <Button type="submit" variant="primary">
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </section>
    </>
  );
};

export default ExamGradeConfiguration;
