import Api from "../Api";
export const createEvent = async (data) => {
  return await Api.post(`/create-events`, data);
};
export const getAllEvents = () => {
  return Api.get("/get-all-events");
};
export const deleteEventById = (id) => {
  return Api.delete(`/delete-events/${id}`);
};
export const getEventById = (id) => {
  return Api.get(`/get-events/${id}`);
};
export const updateEventById = (id, data) => {
  return Api.post(`/update-events/${id}`, data);
};
export const recievertype = () => {
  return Api.get(`/global/eventType`);
};
export const getAllNotificationTypes = () => {
  return Api.get(`/notification-types`);
};
