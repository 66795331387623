import React from "react";
import { useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { getParentsByNum } from "../../Services/CommonApis/StudentApi";
import InputMask from "react-input-mask";
import Swal from "sweetalert2";

import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";

const StudentParentDetails = ({ steps, current, parentImage, setParentImage }) => {
  const { setFieldValue, values } = useFormikContext();
  const [selectedFileName, setSelectedFileName] = useState("");
  const { touched, errors } = useFormikContext();
  const customFileInputStyle = {
    display: "none",
  };
  const customButtonStyle = {
    border: "1px solid lightskyblue",
    padding: "10px",
    backgroundColor: "white",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
  };
  const handlePhoneChange = async (event) => {
    const inputValue = event.target.value;
    setFieldValue("num", inputValue);

    if (inputValue.length === 10) {
      try {
        setFieldValue("pfirst_name", "");
        setFieldValue("pmiddle_name", "");
        setFieldValue("plast_name", "");
        setFieldValue("phone", "");
        setFieldValue("email", "");
        setFieldValue("date_of_birth", "");
        setFieldValue("gender", "");
        setFieldValue("aadhaar_card_no", "");
        setFieldValue("pan_card_no", "");

        const response = await getParentsByNum(inputValue);
        if (response.data.data && response.data.data.id) {
          const parentData = response.data.data;
          const fullname =
            parentData.first_name +
            " " +
            parentData.last_name +
            " (#" +
            parentData.id +
            ")";

          const result = await showSweetAlert(
            "Parent Already Exists with #" + fullname,
            "Do you want to map with existing parents?",
            "You want to delete the Student!",
            "Yes",
            "No"
          );
          if (result.isConfirmed) {
            setFieldValue("parent_id", parentData.id || "");
            setFieldValue("pfirst_name", parentData.first_name || "");
            setFieldValue("pmiddle_name", parentData.middle_name || "");
            setFieldValue("plast_name", parentData.last_name || "");
            setFieldValue("phone", parentData.phone || "");
            setFieldValue("email", parentData.alt_email || "");
            setFieldValue("date_of_birth", parentData.date_of_birth || "");
            setFieldValue("gender", parentData.gender || "");
            setFieldValue("aadhaar_card_no", parentData.aadhaar_card_no || "");
            setFieldValue("pan_card_no", parentData.pan_card_no || "");
          }
        }
      } catch (error) {
        console.error("Error fetching parent data: ", error);
      }
    }
  };
  const getBorderColor = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? "red" : "skyblue";
  };
  const handleFileUpload = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setParentImage(URL.createObjectURL(file));
      setFieldValue("image", file);
      setSelectedFileName(file.name);
    } else {
      setParentImage(null);
      setFieldValue("stimage", null);
      setSelectedFileName("");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };
  const handleFocus = (event) => {
    if (event.target.type === "date") {
      event.target.showPicker();
    }
  };
  const handleDateClick = (event) => {
    if (event.target.type === "date") {
      event.target.focus();
    }
  };
  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === 3 && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Parent Details:</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 4 - {steps - 1}</h2>
              </div>
            </div>
            <div className="row ml-3 mr-3 mt-3">
              <div className="form-group col-4">
                <label htmlFor="pfirst_name">
                  First Name <span className="mandatory">* </span>
                </label>
                <Field
                  name="pfirst_name"
                  type="text"
                  className="form-control"
                  style={{ borderColor: getBorderColor("pfirst_name") }}
                />
                <ErrorMessage
                  name="pfirst_name"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="pmiddle_name">
                  Middle Name
                </label>
                <Field
                  name="pmiddle_name"
                  type="text"
                  className="form-control"
                />
                <ErrorMessage
                  name="pmiddle_name"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="plast_name">
                  Last Name <span className="mandatory">* </span>
                </label>
                <Field name="plast_name" type="text" className="form-control" />
                <ErrorMessage
                  name="plast_name"
                  component="div"
                  className="field-error text-danger"
                  style={{ borderColor: getBorderColor("plast_name") }}
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="email">
                  Email <span className="mandatory">* </span>
                </label>
                <Field name="email" type="email" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="field-error text-danger"
                  style={{ borderColor: getBorderColor("email") }}
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="phone">
                  Phone No <span className="mandatory">*</span>
                </label>
                <InputMask
                  mask="999-999-9999"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={(e) => setFieldValue("phone", e.target.value)}
                >
                  {(inputProps) => (
                    <Field
                      {...inputProps}
                      type="text"
                      className="form-control"
                      id="phone"
                      name="phone"
                      style={{ borderColor: getBorderColor("phone"), letterSpacing: "5px" }}
                    />
                  )}
                </InputMask>
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="field-error text-danger mr-2"
                />
              </div>
              {false && (
                <div className="form-group col-4">
                  <label htmlFor="parent_id">
                    Parent ID <span className="mandatory">* </span>
                  </label>
                  <Field
                    name="parent_id"
                    type="text"
                    className="form-control"
                    style={{ borderColor: getBorderColor("role_id") }}
                  />
                  <ErrorMessage
                    name="parent_id"
                    component="div"
                    className="field-error text-danger"
                  />
                </div>
              )}
              <div className="form-group col-4">
                <label htmlFor="alt-phone">
                  Alt Phone
                </label>
                <InputMask
                  mask="999-999-9999"
                  value={values['alt-phone']}
                  onChange={handleChange}
                  onBlur={(e) => setFieldValue('alt-phone', e.target.value)}
                >
                  {(inputProps) => (
                    <Field
                      {...inputProps}
                      type="text"
                      className="form-control"
                      id="alt-phone"
                      name="alt-phone"
                      style={{ letterSpacing: "5px" }}
                    />
                  )}
                </InputMask>
                <ErrorMessage
                  name="alt-phone"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="date_of_birth">
                  Date of Birth
                </label>
                <Field
                  name="date_of_birth"
                  type="date"
                  className="form-control"
                  onFocus={handleFocus}
                  onClick={handleDateClick}

                />
                <ErrorMessage
                  name="date_of_birth"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="gender">
                  Gender <span className="mandatory">* </span>
                </label>
                <Field
                  as="select"
                  name="gender"
                  className="form-control"
                  style={{
                    borderColor: getBorderColor("gender"),
                  }}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Field>
                <ErrorMessage
                  name="gender"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="aadhaar_card_no">Aadhaar Card No</label>
                <Field name="aadhaar_card_no">
                  {({ field }) => (
                    <InputMask
                      {...field}
                      mask="9999-9999-9999"
                      className="form-control"
                      style={{ letterSpacing: "5px" }}
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="aadhaar_card_no"
                  component="div"
                  className="field-error text-danger"
                />
              </div>

              {/* PAN Card No */}
              <div className="form-group col-4">
                <label htmlFor="pan_card_no">PAN Card No</label>
                <Field name="pan_card_no">
                  {({ field }) => (
                    <InputMask
                      {...field}
                      mask="*****-9999*"
                      formatChars={{
                        "*": "[A-Za-z]",
                        9: "[0-9]",
                      }}
                      maskChar=""
                      onChange={(e) => {
                        const upperCaseValue = e.target.value.toUpperCase();
                        setFieldValue("pan_card_no", upperCaseValue);
                      }}
                      className="form-control"
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="pan_card_no"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="image">Image</label>
                <div style={customButtonStyle}>
                  <label htmlFor="image" style={{ cursor: "pointer" }}>
                    <i
                      className="fas fa-upload"
                      style={{ marginRight: "8px" }}
                    ></i>
                    Browse
                  </label>
                  <input
                    name="image"
                    type="file"
                    id="image"
                    onChange={handleFileUpload}
                    style={customFileInputStyle}
                  />
                </div>
                {parentImage && (
                  <div>
                    <img
                      src={parentImage}
                      alt="Preview"
                      style={{
                        width: "100px",
                        height: "100px",
                        marginTop: "10px",
                      }}
                    />
                    {selectedFileName && (
                      <p>{selectedFileName}</p>
                    )}
                  </div>)}

                <ErrorMessage
                  name="image"
                  component="div"
                  className="field-error text-danger"
                />
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};
export default StudentParentDetails;
