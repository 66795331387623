import React, { useState } from "react";
import AdminBookCreate from "./AdminBookCreate";
import AdminBooksList from "./AdminBooksList";
import ClassWiseConcession from "./ClasswiseConcession";
import StudentWiseConcession from "./StudentwiseConcession";
import ClassWiseConcessionList from "./ClasswiseConcessionList";

function AdminFeeManagement() {
  const [activeTab, setActiveTab] = useState("classwiseconcession");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

 
 

  return (
    <>
      <div class="card-header">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a
              className={`nav-link ${
                activeTab === "classwiseconcession" ? "active" : ""
              }`}
              onClick={() => handleTabClick("classwiseconcession")}
            >
              Classwise Concession
            </a>
          </li>
          <li class="nav-item">
            <a
              className={`nav-link ${
                activeTab === "studentwiseconcession" ? "active" : ""
              }`}
              onClick={() => handleTabClick("studentwiseconcession")}
            >
              Studentwise Concession
            </a>
          </li>
        </ul>
      </div>

      <div class="card-body">
        <div class="tab-content">
          <div
            className={`tab-pane ${
              activeTab === "classwiseconcession" ? "active" : ""
            }`}
            id="classwiseconcession"
          >
            {activeTab === "classwiseconcession" && <ClassWiseConcessionList />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "studentwiseconcession" ? "active" : ""
            }`}
            id="studentwiseconcession"
          >
            {activeTab === "studentwiseconcession" && <StudentWiseConcession />}
          </div>

         
        </div>
      </div>
    </>
  );
}

export default AdminFeeManagement;
