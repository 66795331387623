import { React, useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getAllAcademics } from "../../Services/AdminApis/AcademicApi";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { createAcademicSetup } from "../../Services/CommonApis/FeesApi";
import { getAllFees } from "../../Services/CommonApis/FeesApi";
import Select from "react-select";
import { getFeesSubComponentsByFCID } from "../../Services/CommonApis/FeesApi";
import { getSession } from "../../Services/Storage";
import { COOKIE } from "../../Schemas/cookieNames";
import { useNavigate } from "react-router-dom";
import { getstudentbyclassandsection } from "../../Services/CommonApis/StudentApi";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function StudentWiseFeeSetup() {
  const { branchId } = useParams();
  const { schoolId } = useParams();
  const navigate = useNavigate();
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [classId, setClassId] = useState(null);
  const [isClassLoading, setClassLoading] = useState(true);
  const [isSectionLoading, setSectionLoading] = useState(null);
  const [feeTypeLoding, setFeetypeLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [fees, setFees] = useState([]);
  const [selectedFeeTypes, setSelectedFeeTypes] = useState([]);
  const [feesSubComponents, setFeesSubComponents] = useState({});
  const academicId = getSession(COOKIE.ACADEMIC_YEAR);
  const [selectedsectionId, setSelectedSectionId] = useState(null);
  const [students, setStudents] = useState();
  const [subComponents, setSubComponents] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);

  useEffect(() => {
    if (classId) {
      setSectionLoading(true);
    }
  }, [classId]);

  useEffect(() => {
    fetchClassByBranchId(branchId);
    fetchFeeComponents();
  }, []);
  const fetchFeeComponents = async (branchId) => {
    try {
      const response = await getAllFees(branchId);
      setFees(response.data.data);
      setFeetypeLoading(false);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };
  const filteredStudents = students
    ? students.filter((student) =>
        student.first_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];
  useEffect(() => {
    if (classId && selectedsectionId) {
      fetchstudentsbyclassandsection(branchId, classId, selectedsectionId);
    }
  }, [classId, selectedsectionId]);
  const fetchstudentsbyclassandsection = async (
    branchId,
    classId,
    selectedsectionId
  ) => {
    try {
      const response = await getstudentbyclassandsection(
        branchId,
        classId,
        selectedsectionId
      );
      setStudents(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onRowSelect = (e) => {
    const selectedData = e.value.map((student) => ({
      id: student.id,
      fee_sub_component: student.fee_sub_component,
      amount: student.amount,
    }));
    setSelectedStudents(selectedData);
  };
  const fetchClassByBranchId = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
      setClassLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (selectedFeeTypes) {
      fetchsubcomponentsbyFCIDd(selectedFeeTypes);
    }
  }, [selectedFeeTypes]);

  const fetchsubcomponentsbyFCIDd = async () => {
    try {
      const response = await getFeesSubComponentsByFCID(selectedFeeTypes);
      setSubComponents(response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSectionByClassId = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSectionList(response.data.sections);
      setSectionLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSubComponetsByFCID = async (id) => {
    try {
      let response = await getFeesSubComponentsByFCID(id);
      setFeesSubComponents((prev) => ({
        ...prev,
        [id]: response, // Store subcomponents by fee type ID
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to create the academic setup",
      "info",
      "Yes, Create",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      showLoadingAlert();
      try {
        // Format the data as required
        const formattedData = values.fee_type.map((feeTypeId) => {
          return {
            comp_id: feeTypeId,
            data:
              values.fee_type_details[feeTypeId]?.map((detail) => ({
                id: detail.id,
                fee_sub_component: detail.select,
                start_date: detail.start_date,
                end_date: detail.end_date,
                reminder_day: detail.reminder_day,
                amount_old: detail.amount_old,
                amount_new: detail.amount_new,
                show_on_receipt: detail.show_on_receipt,
                online_payment: detail.online_payment,
              })) || [],
          };
        });
        const studentData = selectedStudents.map((student) => ({
          id: student.id, // Student ID
          amount: Number(student.amount), // Convert to number
          fee_sub_component: Number(student.fee_sub_component), // Convert to number
        }));

        // Prepare the final payload
        const payload = {
          class_id: [values.class_id],
          section_id: values.section_id,
          academic_id: academicId,
          school_id: schoolId,
          branch_id: branchId,
          fees: formattedData,
          student_id: studentData,
        };

        const response = await createAcademicSetup(payload);
        if (response.status === 201) {
          showSuccessAlert(response.data.data);
          navigate(`/school/${schoolId}/branch/${branchId}/academic-fee-setup`);
        } else {
          const errorMessage =
            response.data?.message ||
            "Academic setup creation failed. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";
        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert(
        "Cancelled",
        "Academic setup creation has been cancelled."
      );
    }
  };
  const initialValues = {
    class_id: "",
    section_id: "",
    fee_type: [],
    fee_type_details: {},
    students: students
      ? students.map((student) => ({
          id: student.id,
          name: student.first_name,
          amount: "",
          fee_sub_component: "",
          isSelected: false,
          selectedId: null,
          isSelectedall: false,
        }))
      : [], // Ensure it's an array even if students is undefined
  };
  const validationSchema = Yup.object().shape({
    class_id: Yup.string().required("Class is required"),
    section_id: Yup.string().required("Section is required"),
    fee_type: Yup.array().min(1, "At least one fee type must be selected"),
    fee_type_details: Yup.object().shape({
      ...Object.fromEntries(
        selectedFeeTypes.map((feeTypeId) => [
          [feeTypeId],
          Yup.array().of(
            Yup.object().shape({
              fee_sub_component: Yup.string().required(
                "Fee sub component is required"
              ),
              start_date: Yup.date()
                .required("Start date is required")
                .nullable(),
              end_date: Yup.date()
                .required("End date is required")
                .nullable()
                .test(
                  "is-greater",
                  "End date must be after start date",
                  function (endDate) {
                    const { start_date } = this.parent;
                    return endDate && start_date
                      ? new Date(endDate) > new Date(start_date)
                      : true;
                  }
                ),
              reminder_day: Yup.number()
                .typeError("Reminder day must be a number")
                .required("Reminder day is required"),

              show_on_receipt: Yup.boolean(),
              online_payment: Yup.boolean(),
            })
          ),
        ])
      ),
    }),
  });

  // Function to log selected details
  const logSelectedDetails = (selectedStudents) => {
    selectedStudents.forEach((student) => {
      console.log({
        student_id: student.id,
        fee_sub_component: student.fee_sub_component,
        amount: student.amount,
      });
    });
  };

  console.log(selectedStudents);
  return (
    <>
      <hr />
      <div className="col-md-12">
        <div className="card card-primary">
          <div className="card-header">
            <h3 className="card-title">
              Academic Fee Creation <small>Form</small>
            </h3>
          </div>
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
            // enableReinitialize
          >
            {({ setFieldValue, values }) => (
              <Form id="form">
                <div className="card p-4">
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label htmlFor="class_id">
                        Class Name <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        id="class_id"
                        name="class_id"
                        className="form-control"
                        onChange={(e) => {
                          const selectedClassId = e.target.value;
                          fetchSectionByClassId(selectedClassId);
                          setFieldValue("class_id", selectedClassId);
                          setClassId(selectedClassId);
                        }}
                      >
                        {isClassLoading ? (
                          <option value="" disabled>
                            Loading...
                          </option>
                        ) : (
                          <>
                            <option value="">Select Class</option>
                            {classList.map((cls) => (
                              <option key={cls.id} value={cls.id}>
                                {cls.name}
                              </option>
                            ))}
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        name="class_id"
                        component="div"
                        className="text-error"
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="section_id">
                        Section Name <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        id="section_id"
                        name="section_id"
                        className="form-control"
                        disabled={!classId}
                        onChange={(e) => {
                          const selctedSectionId = e.target.value;
                          fetchSectionByClassId(selctedSectionId);
                          setFieldValue("section_id", selctedSectionId);
                          setSelectedSectionId(selctedSectionId);
                        }}
                      >
                        {isSectionLoading ? (
                          <option value="" disabled>
                            Loading...
                          </option>
                        ) : (
                          <>
                            <option value="">Select Section</option>
                            {sectionList.map((section) => (
                              <option key={section.id} value={section.id}>
                                {section.name}
                              </option>
                            ))}
                          </>
                        )}
                      </Field>
                      <ErrorMessage
                        name="section_id"
                        component="div"
                        className="text-error"
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="fee_type">
                        Component <span className="mandatory">*</span>
                      </label>
                      <Field name="fee_type">
                        {({ field, form }) => {
                          const options = fees.map((fee) => ({
                            value: fee.id,
                            label: fee.name,
                          }));

                          const handleChange = async (selectedOptions) => {
                            let selectedFeeTypeIds = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];

                            if (selectedFeeTypeIds.includes("selectAll")) {
                              selectedFeeTypeIds = options.map(
                                (option) => option.value
                              );
                            }

                            setFieldValue("fee_type", selectedFeeTypeIds); // Update fee_type field
                            setSelectedFeeTypes(selectedFeeTypeIds);

                            const updatedFeeTypeDetails = {
                              ...form.values.fee_type_details,
                            };

                            for (const id of selectedFeeTypeIds) {
                              if (!updatedFeeTypeDetails[id]) {
                                await fetchSubComponetsByFCID(id); // Fetch data for the fee type if not already fetched
                                updatedFeeTypeDetails[id] = [
                                  {
                                    fee_sub_component: "",
                                    start_date: "",
                                    end_date: "",
                                    reminder_day: "",
                                    amount_old: "",
                                    amount_new: "",
                                    show_on_receipt: false,
                                    online_payment: false,
                                  },
                                ]; // Set default structure for fee type
                              }
                            }

                            Object.keys(updatedFeeTypeDetails).forEach(
                              (key) => {
                                if (!selectedFeeTypeIds.includes(Number(key))) {
                                  delete updatedFeeTypeDetails[key];
                                }
                              }
                            );

                            form.setFieldValue(
                              "fee_type_details",
                              updatedFeeTypeDetails
                            ); // Update fee_type_details
                          };

                          // Add the "Select All" option at the beginning of the options
                          const selectAllOption = {
                            value: "selectAll",
                            label: "Select All",
                          };

                          return (
                            <Select
                              id="fee_type"
                              options={[selectAllOption, ...options]} // Prepend "Select All" option to the list
                              isMulti
                              closeMenuOnSelect={false} // Keeps the menu open after selection
                              hideSelectedOptions={false}
                              onChange={handleChange}
                              value={options.filter((option) =>
                                (form.values.fee_type || []).includes(
                                  option.value
                                )
                              )}
                              placeholder="Select fee Type"
                              // Custom checkbox rendering for options
                              getOptionLabel={(e) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={
                                      e.value === "selectAll"
                                        ? options.length ===
                                          form.values.fee_type.length
                                        : form.values.fee_type.includes(e.value)
                                    }
                                    readOnly
                                    style={{ marginRight: "10px" }}
                                  />
                                  {e.label}
                                </div>
                              )}
                            />
                          );
                        }}
                      </Field>

                      <ErrorMessage
                        name="fee_type"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    {selectedFeeTypes.map((feeTypeId) => (
                      <div key={feeTypeId} className="card mb-3 shadow-sm">
                        <div className="card-header">
                          <h5>
                            {fees.find((fee) => fee.id === feeTypeId)?.name}:
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead className="thead-light">
                                <tr>
                                  <th>Select</th>
                                  <th>Fee Sub Component</th>
                                  <th>Fee Start Date</th>
                                  <th>Fee End Date</th>
                                  <th>Reminder Day</th>
                                  <th>Amount (Old Student)</th>
                                  <th>Amount (New Student)</th>
                                  <th>Show on Receipt</th>
                                  <th>Online Payment</th>
                                </tr>
                              </thead>
                              <tbody>
                                {feesSubComponents[feeTypeId]?.map(
                                  (subComp, index) => (
                                    <tr key={index}>
                                      <td className="text-center">
                                        <Field
                                          type="checkbox"
                                          name={`fee_type_details.${feeTypeId}[${index}].select`}
                                          checked={
                                            values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select === subComp.id
                                          }
                                          onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setFieldValue(
                                              `fee_type_details.${feeTypeId}[${index}].select`,
                                              isChecked ? subComp.id : false
                                            );
                                          }}
                                          className="form-check-input"
                                        />
                                      </td>
                                      <td>
                                        <span className="form-control-plaintext">
                                          {subComp.name || "N/A"}
                                        </span>
                                      </td>
                                      <td>
                                        <Field
                                          type="date"
                                          name={`fee_type_details.${feeTypeId}[${index}].start_date`}
                                          className="form-control"
                                          disabled={
                                            !values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select
                                          }
                                        />
                                        <ErrorMessage
                                          name={`fee_type_details.${feeTypeId}[${index}].start_date`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          type="date"
                                          name={`fee_type_details.${feeTypeId}[${index}].end_date`}
                                          className="form-control"
                                          disabled={
                                            !values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select
                                          }
                                        />
                                        <ErrorMessage
                                          name={`fee_type_details.${feeTypeId}[${index}].end_date`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          type="number"
                                          name={`fee_type_details.${feeTypeId}[${index}].reminder_day`}
                                          className="form-control"
                                          placeholder="Enter reminder days"
                                          disabled={
                                            !values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select
                                          }
                                        />
                                        <ErrorMessage
                                          name={`fee_type_details.${feeTypeId}[${index}].reminder_day`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          type="number"
                                          name={`fee_type_details.${feeTypeId}[${index}].amount_old`}
                                          className="form-control"
                                          placeholder="Amount for old students"
                                          disabled={
                                            !values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select
                                          }
                                        />
                                        <ErrorMessage
                                          name={`fee_type_details.${feeTypeId}[${index}].amount_old`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          type="number"
                                          name={`fee_type_details.${feeTypeId}[${index}].amount_new`}
                                          className="form-control"
                                          placeholder="Amount for new students"
                                          disabled={
                                            !values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select
                                          }
                                        />
                                        <ErrorMessage
                                          name={`fee_type_details.${feeTypeId}[${index}].amount_new`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </td>
                                      <td className="text-center">
                                        <Field
                                          type="checkbox"
                                          name={`fee_type_details.${feeTypeId}[${index}].show_on_receipt`}
                                          disabled={
                                            !values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select
                                          }
                                        />
                                        <ErrorMessage
                                          name={`fee_type_details.${feeTypeId}[${index}].show_on_receipt`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </td>
                                      <td className="text-center">
                                        <Field
                                          type="checkbox"
                                          name={`fee_type_details.${feeTypeId}[${index}].online_payment`}
                                          disabled={
                                            !values.fee_type_details?.[
                                              feeTypeId
                                            ]?.[index]?.select
                                          }
                                        />
                                        <ErrorMessage
                                          name={`fee_type_details.${feeTypeId}[${index}].online_payment`}
                                          component="div"
                                          className="text-error"
                                        />
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {classId &&
                  selectedsectionId &&
                  selectedFeeTypes.length > 0 ? (
                    <div className="card p-3">
                      <div className="row">
                        <div
                          className="col-md-12"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            marginBottom: "10px",
                          }}
                        >
                          <span
                            className="input-group-text"
                            style={{ marginRight: "5px" }}
                          >
                            <i className="fas fa-search"></i>{" "}
                            {/* Font Awesome magnifying glass icon */}
                          </span>
                          <input
                            type="text"
                            placeholder="Search by Student Name"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            style={{
                              width: "20%",
                              padding: "5px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          />
                        </div>
                      </div>
                      <DataTable
                        value={filteredStudents}
                        selection={selectedStudents}
                        onSelectionChange={(e) => {
                          setSelectedStudents(e.value);
                          logSelectedDetails(e.value); // Log selected details
                        }}
                        dataKey="id"
                        responsiveLayout="scroll"
                      >
                        {/* Select All Checkbox in Header */}
                        <Column
                          header={
                            <input
                              type="checkbox"
                              checked={
                                selectedStudents.length ===
                                  filteredStudents.length &&
                                filteredStudents.length > 0
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedStudents(filteredStudents); // Select all
                                } else {
                                  setSelectedStudents([]); // Deselect all
                                }
                              }}
                              style={{
                                height: "20px",
                                width: "3rem",
                              }}
                            />
                          }
                          body={(rowData) => (
                            <input
                              type="checkbox"
                              checked={selectedStudents.some(
                                (s) => s.id === rowData.id
                              )}
                              onClick={(e) => e.stopPropagation()} // Prevent row click
                              onChange={(e) => {
                                const selected = e.target.checked;
                                setSelectedStudents((prev) =>
                                  selected
                                    ? [...prev, rowData]
                                    : prev.filter((s) => s.id !== rowData.id)
                                );
                              }}
                              style={{
                                height: "20px",
                                width: "3rem",
                              }} // Medium-sized checkbox
                            />
                          )}
                          style={{ width: "3rem" }}
                        />

                        <Column
                          field="first_name"
                          header="Student Name"
                        ></Column>

                        <Column
                          header="Fee Sub Component"
                          body={(rowData) => (
                            <Select
                              options={subComponents.map((subComp) => ({
                                value: subComp.id,
                                label: subComp.name,
                              }))}
                              value={rowData.fee_sub_component}
                              onChange={(selectedOption) => {
                                const selectedValue = selectedOption
                                  ? selectedOption.value
                                  : "";
                                setSelectedStudents((prevState) => {
                                  const updatedStudents = prevState.map(
                                    (student) => {
                                      if (student.id === rowData.id) {
                                        return {
                                          ...student,
                                          fee_sub_component: selectedValue,
                                        };
                                      }
                                      return student;
                                    }
                                  );
                                  return updatedStudents;
                                });
                              }}
                            />
                          )}
                        ></Column>

                        <Column
                          header="Fee Amount"
                          body={(rowData) => (
                            <input
                              type="number"
                              name="fee_amount"
                              value={
                                selectedStudents.find(
                                  (student) => student.id === rowData.id
                                )?.amount || ""
                              }
                              onChange={(e) => {
                                const updatedAmount = e.target.value;
                                setSelectedStudents((prevState) => {
                                  const updatedStudents = prevState.map(
                                    (student) => {
                                      if (student.id === rowData.id) {
                                        return {
                                          ...student,
                                          amount: updatedAmount,
                                        };
                                      }
                                      return student;
                                    }
                                  );
                                  return updatedStudents;
                                });
                              }}
                            />
                          )}
                        ></Column>
                      </DataTable>
                    </div>
                  ) : null}

                  <div className="text-right">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
export default StudentWiseFeeSetup;
