import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createSection } from "../../Services/CommonApis/SectionApi";
import { showSuccessAlert } from "../../Modals/SweetAlertModel";
import {
  showErrorAlert,
  showLoadingAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";

const romanNumerals = [
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
];

const validationSchema = Yup.object({
  inputs: Yup.array().of(Yup.string().required("Section Name is required")),
  class_id: Yup.string().required("Class is required"),
});

const SectionCreate = () => {
  const { branchId } = useParams();

  const [classOptions, setClassOptions] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState(null);

  useEffect(() => {
    fetchClasses(branchId);
  }, [branchId]);

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      const classes = response.data.classes.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }));
      setClassOptions(classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const getNextRoman = (roman) => {
    const index = romanNumerals.indexOf(roman);
    return index < romanNumerals.length - 1 ? romanNumerals[index + 1] : roman;
  };

  const getNextNumber = (num) => {
    return (parseInt(num, 10) + 1).toString();
  };

  const getNextInputValue = (lastValue) => {
    const trimmedValue = lastValue.trim();

    if (!trimmedValue) {
      return "";
    }

    const numberMatch = trimmedValue.match(/(\d+)$/);
    const romanMatch = trimmedValue.match(/(I|II|III|IV|V|VI|VII|VIII|IX|X)$/);
    const letterMatch = trimmedValue.match(/([A-Z])$/);

    if (numberMatch) {
      return trimmedValue.replace(
        numberMatch[0],
        getNextNumber(numberMatch[0])
      );
    } else if (romanMatch) {
      return trimmedValue.replace(romanMatch[0], getNextRoman(romanMatch[0]));
    } else if (letterMatch) {
      const currentLetter = letterMatch[0];
      const nextLetter = String.fromCharCode(currentLetter.charCodeAt(0) + 1);
      return trimmedValue.replace(currentLetter, nextLetter);
    }

    return "";
  };

  const handleSubmit = async (values) => {
    const hasDuplicates = new Set(values.inputs).size !== values.inputs.length;
    if (hasDuplicates) {
      showErrorAlert(
        "Duplicate Section Name",
        "Section names cannot be duplicated."
      );
      return;
    }
    const formattedData = {
      section_name: values.inputs,
      class_id: selectedClassId,
    };

    const result = await showSweetAlert(
      "Are you sure?",
      "You want to create the section",
      "info",
      "Yes, Create",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      showLoadingAlert();
      try {
        const response = await createSection(formattedData);
        if (response.status === 201) {
          showSuccessAlert("Created!", response.data.message || "Data Created Successfully.")
            .then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
        } else {
          showErrorAlert(
            "Error!",
            response.data?.message ||
            "Section creation failed. Please try again."
          );
        }
      } catch (err) {
        console.error("API error:", err);
        showErrorAlert(
          "Error",
          err.response?.data?.message ||
          "An unexpected error occurred. Please try again."
        );
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Section creation has been cancelled.");
    }
  };

  return (
    <>
      <div className="col-md-12">
        <div className="card card-primary">
          <div className="card-header">
            <h3 className="card-title">
              Section Creation
            </h3>
          </div>

          <Formik
            initialValues={{
              inputs: [""],
              class_id: selectedClassId || "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                <div style={{ marginTop: "20px" }}>
                  <div className="form-card">
                    <div className="card-body">
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="class_id" className="form-label">
                            Class Name <span className="mandatory">* </span>{" "}
                          </label>

                          <Field
                            as="select"
                            name="class_id"
                            className="form-control"
                            style={{ borderColor: "skyblue" }}
                            onChange={(e) => {
                              setFieldValue("class_id", e.target.value);
                              setSelectedClassId(e.target.value);
                            }}
                          >
                            <option value="" label="Select class" />
                            {classOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="class_id"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="form-group col-12">
                        <label>
                          Section Name <span className="mandatory">*</span>
                        </label>
                      </div>

                      {values.inputs.map((input, index) => (
                        <div
                          className="row"
                          style={{ marginLeft: "1px" }}
                          key={index}
                        >
                          <div className="form-group col-6">
                            <Field
                              type="text"
                              name={`inputs.${index}`}
                              className="form-control"
                              placeholder="Section Name"
                            />
                            <ErrorMessage
                              name={`inputs.${index}`}
                              component="div"
                              className="text-error"
                            />
                          </div>
                          <div className="form-group col-2 d-flex align-items-end">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => {
                                const newInputs = [
                                  ...values.inputs,
                                  getNextInputValue(
                                    values.inputs[values.inputs.length - 1]
                                  ),
                                ];
                                setFieldValue("inputs", newInputs);
                              }}
                            >
                              +
                            </button>
                            {values.inputs.length > 1 && (
                              <button
                                type="button"
                                className="btn btn-danger ml-2"
                                onClick={() => {
                                  const newInputs = values.inputs.filter(
                                    (_, i) => i !== index
                                  );
                                  setFieldValue("inputs", newInputs);
                                }}
                              >
                                -
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default SectionCreate;
