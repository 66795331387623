import Api from "../Api";

export const createGroup = async (data) => {
  try {
    const response = await Api.post("/create-group", data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getGroups = async (params) => {

  const response = await Api.get("/get-groups-bybranch_id", { params });
  return response;

};

export const deleteGroupById = async (id) => {
  try {
    const response = await Api.delete(`/delete-group/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateGroupById = async (id, data) => {
  try {
    const response = await Api.post(`/update-group/${id}`, data, {
      params: {
        branch_id: id,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getGroupById = async (id) => {
  try {
    const response = await Api.get(`/get-group/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
