import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  deleteStaffById,
  getStaffByBranchId,
} from "../../Services/AdminApis/StaffApi";
import StaffCreate from "../CommonPages/StaffCreate";
import parentavatar from "../../Assests/avatar-icon0002_750950-43.avif";
import SchoolGrid from "./SchoolGrid";
import StaffGridView from "../CommonPages/StaffGridView";

const StaffList = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const { branchId, schoolId } = useParams();
  const [staffEdit, setStaffEdit] = useState(false);
  const [isGridView, setIsGridView] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [staff, setStaff] = useState(null);

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        const response = await deleteStaffById(id);
        Swal.close();
        showSuccessAlert(
          "Deleted!",
          response.data.message || "Data Deleted Successfully"
        );
        // setRefresh((prev) => !prev);
        navigate(`/school/${schoolId}/branch/${branchId}/staff`);
        window.location.reload();
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";

        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The staff member is safe :)");
    }
  };

  const [staffId, setStaffId] = useState(null);

  useEffect(() => {
    if (isGridView === false) {
      tableInstance.current = $(tableRef.current).DataTable({
        destroy: true,
        columns: [
          { data: "id", title: "S.No" },
          { data: "first_name", title: "First Name" },
          { data: "last_name", title: "Last Name" },
          {
            data: "phone_no",
            title: "Phone Number",
            render: (data, type, row) => {
              return data && data.trim() ? data : "----";
            }
          },
          { data: "email", title: "Email" },
          { data: "epf_no", title: "EPF No" },
          { data: "uan_no", title: "UAN No" },
          { data: "esi_no", title: "ESI No" },
          {
            data: "status",
            title: "Status",
            render: (data, type, row) => {
              return data === "1" ? "Active" : "Inactive";
            },
          },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data, type, row) => {
              return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="/school/${schoolId}/branch/${branchId}/staff-edit/${row.id}" title="Edit"><i class="fas fa-edit"></i></a>
                  <a href="#" title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
              </div>
          `;
            },
          },
        ],
        lengthChange: true,
        order: true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          {
            extend: "copy",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "csv",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excel",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdf",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            exportOptions: {
              columns: ":visible",
            },
          },
        ],
        serverSide: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 75, 100, -1],
          [10, 25, 50, 75, 100, "All"],
        ],
        language: {
          paginate: {
            previous: "Back",
            next: "Next",
            first: "First",
            last: "Last",
          },
          emptyTable: "No Staff Data Found",
        },
        ajax: async function (data, callback) {
          try {
            const start = data.start || 0;
            const length = data.length || 10;
            const page = Math.floor(start / length) + 1;
            const orderColumnIndex =
              data.order && data.order.length > 0 ? data.order[0].column : 0;
            const orderDirection =
              data.order && data.order.length > 0 ? data.order[0].dir : "desc";
            const orderColumn = data.columns[orderColumnIndex].data;
            const searchValue = data.search.value;

            const response = await getStaffByBranchId({
              branch_id: branchId,
              page: page,
              _limit: length,
              _sort: orderColumn,
              _order: orderDirection,
              q: searchValue,
            });

            let jsonData = response.data.data || [];
            setStaff(response.data.data);
            let totalCount = response.data.total || 0;

            callback({
              draw: data.draw,
              recordsTotal: totalCount,
              recordsFiltered: totalCount,
              data: jsonData.length > 0 ? jsonData : [],
            });
          } catch (error) {
            console.error("Error fetching data:", error);
            showErrorAlert(error.response.data.message);
            setTimeout(() => {
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }, 5000);
          }
        },
      });

      $(tableRef.current).on("click", ".delete-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
        handleDelete(id);
      });

      $(tableRef.current).on("click", ".edit-icon", function (e) {
        e.preventDefault();
        const id = $(this).data("id");
        setStaffId(id);
        setStaffEdit(true);
      });

      return () => {
        if (tableInstance.current) {
          tableInstance.current.destroy(true);
        }
      };
    }
  }, [refresh, isGridView, branchId]);

  const handleToggleView = () => {
    if (isGridView) {
      setIsGridView(false);
    } else {
      setIsGridView(true);
    }
  };

  return (
    <>
      {staffEdit ? (
        <StaffCreate staffId={staffId} />
      ) : (
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Staff List Table</h3>
            <div className="card-tools">
              <button
                onClick={handleToggleView}
                className="btn btn-tool"
                style={{ color: "#007BFF" }}
              >
                <i
                  className={`fas ${isGridView ? "fa-list" : "fa-grip"} fa-2x`}
                ></i>
              </button>
            </div>
          </div>
          {isGridView ? (
            <StaffGridView />
          ) : (
            <div className="card-body">
              <table
                ref={tableRef}
                id="example6"
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>EPF No</th>
                    <th>UAN No</th>
                    <th>ESI No</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody></tbody>
                <tfoot>
                  <tr>
                    <th>S.No</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>EPF No</th>
                    <th>UAN No</th>
                    <th>ESI No</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default StaffList;
