import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  createLanguage,
  getLanguageById,
  updateLanguageById,
} from "../../Services/AdminApis/LanguagesApi";
import {
  showErrorAlert,
  showSuccessAlert,
  showLoadingAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const LanguageForm = () => {
  const { id } = useParams();
  const [apiMessage, setApiMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [language, setLanguage] = useState({
    name: "",
    status: "",
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const result = await showSweetAlert(
      "Are you sure?",
      `You want to ${id ? "update" : "create"} the language.`,
      "info",
      "Yes, Proceed",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      setLoading(true);
      showLoadingAlert();

      try {
        let response;
        if (id) {
          response = await updateLanguageById(id, values);
        } else {
          response = await createLanguage(values);
        }

        if (response.status === 200 || response.status === 201) {
          const successMessage =
            response.data.message || `Data ${id ? "updated" : "created"} Successfully.`;
          showSuccessAlert(
            id ? "Updated!" : "Created!",
            successMessage
          );
          navigate("/admin/language-list");
        } else {
          const errorMessage =
            response.data?.message ||
            "Language creation or update failed. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (error) {
        console.error("API Error:", error);
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred. Please try again.";

        setApiMessage(errorMessage);
        showErrorAlert("Error", errorMessage);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      const errorMessage = id
        ? "Language updation cancelled. Please try again."
        : "Language creation cancelled. Please try again.";
      showErrorAlert("Cancelled", errorMessage);
    }
  };

  useEffect(() => {
    if (id) {
      getLanguage(id);
    }
  }, [id]);

  const getLanguage = async (id) => {
    try {
      const response = await getLanguageById(id);
      const languagedata = response.data.data;
      setLanguage({
        name: languagedata.name,
        status: languagedata.status,
      });
    } catch (error) {
      console.error("Error fetching language:", error);
      showErrorAlert(
        "Error",
        "There was an issue fetching the language details."
      );
    }
  };

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Language Form</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Language Form</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 style={{ color: "skyblue" }}>
                    {id ? "Update Language" : "Add Language"}
                  </h3>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={language}
                    enableReinitialize
                    validationSchema={Yup.object({
                      name: Yup.string().required("Name is required"),
                    })}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form className="form-card ml-4">
                        <div className="col-4">
                          <label htmlFor="name" className="form-label">
                            Name <span className="mandatory">*</span>
                          </label>
                          <Field
                            type="text"
                            name="name"
                            className="form-control"
                            id="name"
                            placeholder="Enter language name"
                            style={{ borderColor: "skyblue" }}
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="row ml-1 mt-2">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting || loading}
                          >
                            {loading ? "Submitting..." : "Submit"}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LanguageForm;
