import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import { useParams } from "react-router-dom";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import {
  getDefaultersReport,
  getAllFees,
  getFeesSubComponentsByFCID,
} from "../../Services/CommonApis/FeesApi";
import Select from "react-select";
import { generateDefaultersReportPdf } from "../../Services/CommonApis/FeesApi";
import { showErrorAlert, showSuccessAlert } from "../../Modals/SweetAlertModel";

const StudentList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [classOptions, setClassOptions] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [sections, setSections] = useState([]);
  const [isClassLoading, setClassLoading] = useState(false);
  const [isSectionLoading, setSectionLoading] = useState(false);
  const { schoolId, branchId } = useParams();
  const [selectedClassId, setSelectedClassId] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [students, setStudents] = useState([]);
  const [selectedComponet, setSelectedComponent] = useState("");
  const [fees, setFees] = useState([]);
  const [feesSubComponents, setFeesSubComponents] = useState([]);
  const [selectedSubComponentIds, setSelectedSubcomponentIds] = useState([]);

  useEffect(() => {
    fetchClasses(branchId);
    fetchFeeComponents(branchId);
  }, [branchId]);

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      const classes = response.data.classes.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }));
      setClassOptions(classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchFeeComponents = async (branchId) => {
    try {
      const response = await getAllFees(branchId);
      setFees(response.data.data);
    } catch (error) {
      console.error("Error fetching fees:", error);
    }
  };

  useEffect(() => {
    const initializeDataTable = async () => {
      const response = await getDefaultersReport({
        branch_id: branchId,
        class_id: selectedClassId,
        section_id: selectedSection,
        component_id: selectedComponet,
        sub_com_id: selectedSubComponentIds,
      });

      const jsonData = response.data.data || [];
      const totalCount = response.data.total || 0;
      const subComponentNames = [
        ...new Set(
          jsonData.flatMap((item) =>
            item.sub_component_name?.length
              ? item.sub_component_name.map((sub) => sub.name)
              : []
          )
        ),
      ];

      const hasSubComponentNames = jsonData.some(
        (item) => item.sub_component_name && item.sub_component_name.length > 0
      );

      const dynamicColumns =
        subComponentNames.length > 0
          ? subComponentNames.map((subName) => ({
              title: subName,
              data: null,
              render: (data) => {
                const sub = data.sub_component_name?.find(
                  (item) => item.name === subName
                );
                return sub ? sub.amount : "N/A";
              },
            }))
          : [];

      const columns = [
        {
          data: null,
          title: "S.No",
          render: (data, type, row, meta) => meta.row + 1,
        },
        {
          data: "student_name",
          title: "Student Info",
          render: (data, type, row) => {
            return `
              <div>
                <div>${row.student_name || "N/A"}</div>
                <small style="color: gray;">${row.admission_no || "N/A"}</small>
                <small style="font-weight: bold; display: block;">
                  ${row.class_section_name || "N/A"}
                </small>
              </div>`;
          },
        },
        {
          data: "component_name",
          title: "Fee Component",
        },
        ...dynamicColumns,
        {
          data: "amount",
          title: "Total Due Amount",
        },
        {
          data: "due_date",
          title: "Due Date",
        },
      ];

      tableInstance.current = $(tableRef.current).DataTable({
        destroy: true,
        columns,
        lengthChange: true,
        order: true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          {
            extend: "copy",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "csv",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excel",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdf",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            exportOptions: {
              columns: ":visible",
            },
          },
        ],

        data: jsonData,
      });
    };

    initializeDataTable();

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, [
    showTable,
    selectedClassId,
    selectedSection,
    selectedComponet,
    selectedSubComponentIds,
  ]);

  useEffect(() => {
    fetchSections(selectedClassId);
  }, [selectedClassId]);

  const fetchSections = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSections(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };
  const handleSectionChange = (event, setFieldValue) => {
    const selectedSectionId = event.target.value;
    setSelectedSection(selectedSectionId);
    setFieldValue("section", selectedSectionId);
    setShowTable(true);
  };

  const handleFeeComponentChange = (event, setFieldValue) => {
    const selectedfcId = event.target.value;
    setSelectedComponent(selectedfcId);
    setFieldValue("fcid", selectedfcId);
    fetchSubComponentsByFCID(selectedfcId);
  };

  const fetchSubComponentsByFCID = async (id) => {
    try {
      const response = await getFeesSubComponentsByFCID(id);
      setFeesSubComponents(response);
    } catch (error) {
      console.error("Error fetching fee subcomponents:", error);
    }
  };

  const handleDownloadPdf = async () => {
    if (!selectedClassId || !selectedSection) {
      showErrorAlert("Please select class and section.");
      return;
    }
    try {
      const response = generateDefaultersReportPdf(
        branchId,
        selectedClassId,
        selectedSection
      );
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Defaulters List
          </h3>
          <button
            className="btn btn-primary float-right"
            onClick={handleDownloadPdf}
          >
            Download PDF
          </button>
        </div>
        <Formik initialValues={{ class_id: "", fsap_id: [] }}>
          {({ setFieldValue, values }) => (
            <div className="card-body">
              <div className="row">
                <div className="form-group col-4">
                  <label htmlFor="class_id">
                    Class Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="class_id"
                    name="class_id"
                    className="form-control"
                    onChange={(e) => {
                      const classId = e.target.value;

                      setFieldValue("class_id", classId);

                      setSelectedClassId(e.target.value);
                    }}
                  >
                    {isClassLoading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Class</option>
                        {classOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="class_id"
                    component="div"
                    className="text-error"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="section">
                    Section Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="section"
                    name="section"
                    className="form-control"
                    value={selectedSection}
                    onChange={(e) => handleSectionChange(e, setFieldValue)}
                    disabled={!selectedClassId}
                  >
                    {isSectionLoading ? (
                      <option value="" disabled>
                        Loading...
                      </option>
                    ) : (
                      <>
                        <option value="">Select Section</option>
                        {sections.map((sec) => (
                          <option key={sec.id} value={sec.id}>
                            {sec.name}
                          </option>
                        ))}
                      </>
                    )}
                  </Field>
                  <ErrorMessage
                    name="section_id"
                    component="div"
                    className="text-error"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="fcid">Select Fee Component</label>
                  <Field
                    as="select"
                    name="fcid"
                    className="form-control"
                    onChange={(e) => handleFeeComponentChange(e, setFieldValue)}
                  >
                    <option value="">Select</option>
                    {fees.map((fee) => (
                      <option key={fee.id} value={fee.id}>
                        {fee.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fcid"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="fsap_id">
                    SubComponent Name <span className="mandatory">*</span>
                  </label>
                  <Field name="fsap_id">
                    {({ field, form }) => {
                      const options = feesSubComponents.map((subComponent) => ({
                        value: subComponent.id,
                        label: subComponent.name,
                      }));

                      const selectAllOption = {
                        value: "selectAll",
                        label: "Select All",
                      };

                      const handleChange = (selectedOptions) => {
                        let selectedIds = selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : [];

                        if (selectedIds.includes("selectAll")) {
                          selectedIds = options.map((option) => option.value);
                        }

                        setSelectedSubcomponentIds(selectedIds);

                        form.setFieldValue("fsap_id", selectedIds);
                      };

                      return (
                        <Select
                          id="fsap_id"
                          options={[selectAllOption, ...options]}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          onChange={handleChange}
                          value={options.filter((option) =>
                            (form.values.fsap_id || []).includes(option.value)
                          )}
                          placeholder="Select Fee Subcomponent"
                          getOptionLabel={(e) => (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  e.value === "selectAll"
                                    ? options.length ===
                                      (form.values.fsap_id || []).length
                                    : (form.values.fsap_id || []).includes(
                                        e.value
                                      )
                                }
                                readOnly
                                style={{ marginRight: "10px" }}
                              />
                              {e.label}
                            </div>
                          )}
                          isDisabled={!form.values.fcid}
                        />
                      );
                    }}
                  </Field>
                  <ErrorMessage
                    name="fsap_id"
                    component="div"
                    className="text-error"
                  />
                </div>
              </div>

              {showTable ? (
                <div className="card p-3 row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table
                        ref={tableRef}
                        id="example1"
                        className="table table-bordered table-striped"
                      >
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Student Info</th>
                            <th>Fee Component</th>
                            <th>Amount</th>
                            <th>Due Date</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                        <tfoot>
                          <tr>
                            <th>S.No</th>
                            <th>Student Info</th>
                            <th>Fee Component</th>
                            <th>Amount</th>
                            <th>Due Date</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="alert alert-info">
                  Please select both class and section to view the defaulters
                  list.
                </div>
              )}
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};
export default StudentList;
