export const extractUserDetailsFromToken = (adminToken) => {
  if (!adminToken) {
    console.error("No admin token provided.");
    return null;
  }
  try {
    const payload = adminToken.split(".")[1];
    const userDetails = JSON.parse(atob(payload));
    return userDetails;
  } catch (error) {
    console.error("Invalid token:", error);
    return null;
  }
};
export const getUserRoleId = (adminToken) => {
  const userDetails = extractUserDetailsFromToken(adminToken);
  return userDetails ? userDetails.user_roleid : null;
};
export const getUserRoleName = (adminToken) => {
  const userDetails = extractUserDetailsFromToken(adminToken);
  console.log(userDetails.role_name + "====");
  return userDetails ? userDetails.role_name : null;
};
export const getSchoolId = (adminToken) => {
  const userDetails = extractUserDetailsFromToken(adminToken);
  return userDetails ? userDetails.school_id : null;
};

export const getBranchId = (adminToken) => {
  const userDetails = extractUserDetailsFromToken(adminToken);
  return userDetails ? userDetails.branch_id : null;
};

export const getParentId = (adminToken) => {
  const userDetails = extractUserDetailsFromToken(adminToken);
  return userDetails ? userDetails.parent_id : null;
}

export const getUserEmail = (adminToken) => {
  const userDetails = extractUserDetailsFromToken(adminToken);
  return userDetails ? userDetails.user_email : null;
}

export const getUserId = (adminToken) => {
  const userDetails = extractUserDetailsFromToken(adminToken);
  return userDetails ? userDetails.user_id : null;
}
