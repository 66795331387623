import React, { useState } from "react";
import BranchSubjectList from "../CommonPages/BranchSubjectList";
import BranchSubjectCreate from "../CommonPages/BranchSubjectCreate";
import SubjectUpload from "./SubjectUpload";

function SubjectManagement() {
  const [activeTab, setActiveTab] = useState("subjectlist");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "subjectlist" ? "active" : ""
              }`}
              onClick={() => handleTabClick("subjectlist")}
            >
              Subject List
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "createsubject" ? "active" : ""
              }`}
              onClick={() => handleTabClick("createsubject")}
            >
              Create Subject
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "uploadsubject" ? "active" : ""
              }`}
              onClick={() => handleTabClick("uploadsubject")}
            >
              Upload Subject
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${
              activeTab === "subjectlist" ? "active" : ""
            }`}
            id="subjectlist"
          >
            {activeTab === "subjectlist" && <BranchSubjectList />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "createsubject" ? "active" : ""
            }`}
            id="createsubject"
          >
            {activeTab === "createsubject" && <BranchSubjectCreate />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "uploadsubject" ? "active" : ""
            }`}
            id="uploadsubject"
          >
            {activeTab === "uploadsubject" && <SubjectUpload />}
          </div>
        </div>
      </div>
    </>
  );
}

export default SubjectManagement;
