import React, { useState, useEffect } from "react";
import { getStudentAcademicFee } from "../../Services/CommonApis/FeesApi";
import { useParams, useNavigate } from "react-router-dom";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import {
  getStudentConcessionHistory,
  getAcademicPayments,
} from "../../Services/CommonApis/FeesApi";
import { createAcademicPayments } from "../../Services/CommonApis/FeesApi";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";

const me = "https://via.placeholder.com/150";

function PayStudentFees({ onBack }) {
  const { schoolId, branchId, id, fcid, clsid } = useParams();
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState(null);
  const [classes, setClasses] = useState([]);
  const [concessionHistory, setConcessionHistory] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [applyingAmount, setApplyingAmount] = useState(0);
  const [feesToBePaid, setFeesToBePaid] = useState({});
  const [errors, setErrors] = useState({});
  const [applyingAmountError, setApplyingAmountError] = useState("");
  const [concessionAmount, setConcessionAmount] = useState("");
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [totalFeesToBePaid, setTotalFeesToBePaid] = useState(0);

  useEffect(() => {
    if (studentData) {
      setConcessionAmount(studentData.concession_amount);
    }
  }, [studentData]);

  useEffect(() => {
    setApplyingAmount(Math.max(totalAmount - concessionAmount, 0));
  }, [totalAmount, concessionAmount]);

  useEffect(() => {
    if (studentData?.fees_sub_component) {
      const initialFees = studentData.fees_sub_component.reduce(
        (acc, subComponent, index) => {
          acc[index] = parseFloat(subComponent.amount) || 0;
          return acc;
        },
        {}
      );
      setFeesToBePaid(initialFees);
    }
  }, [studentData]);

  const handleCheckboxChange = (amount, isChecked, index) => {
    setFeesToBePaid((prev) => {
      const updated = { ...prev, [index]: isChecked ? Number(amount) : 0 };
  
      // Get base concession amount
      const baseConcessionAmount = studentData?.concession_amount || 0;
  
      // Count how many checkboxes are selected
      const selectedCount = Object.keys(updated).filter(
        (key) => document.querySelector(`input[data-index="${key}"]`)?.checked
      ).length;
  
      // Update concession amount based on selected count
      setConcessionAmount(baseConcessionAmount * selectedCount);
  
      // Calculate new applying amount based on selected checkboxes
      const newApplyingAmount = Object.keys(updated).reduce(
        (sum, key) => sum + (parseFloat(updated[key]) || 0),
        0
      );
  
      setApplyingAmount(newApplyingAmount);
      return updated;
    });
  
    // Update totalAmount
    setTotalAmount((prev) =>
      isChecked ? prev + Number(amount) : prev - Number(amount)
    );
  
    // Handle 'Select All' checkbox logic
    const selectAllCheckbox = document.querySelector("thead input[type='checkbox']");
    if (!isChecked && selectAllCheckbox) {
      selectAllCheckbox.checked = false;
    }
  
    const allCheckboxes = document.querySelectorAll("tbody input[type='checkbox']");
    const allChecked = Array.from(allCheckboxes).every((checkbox) => checkbox.checked);
  
    if (selectAllCheckbox) {
      selectAllCheckbox.checked = allChecked;
    }
  };


  const handleSelectAll = (isChecked) => {
    const baseConcessionAmount = studentData?.concession_amount || 0;
    const checkboxes = document.querySelectorAll("tbody input[type='checkbox']");
    
    const updatedFees = {};
    checkboxes.forEach((checkbox, index) => {
      checkbox.checked = isChecked;
      updatedFees[index] = isChecked ? Number(checkbox.dataset.amount) : 0;
    });
  
    setFeesToBePaid(updatedFees);
  
    const selectedCount = isChecked ? checkboxes.length : 0;
  
    // Update Concession Amount
    setConcessionAmount(baseConcessionAmount * selectedCount);
  
    // Update Applying Amount
    const totalApplyingAmount = Object.values(updatedFees).reduce(
      (sum, value) => sum + value, 0
    );
  
    setApplyingAmount(totalApplyingAmount);
  
    // Update Total Amount
    const totalFee = Object.values(updatedFees).reduce(
      (sum, value) => sum + value, 0
    );
  
    setTotalAmount(totalFee);
  };
  
  

  const handleFeesInputChange = (value, index) => {
    const numericValue = parseFloat(value || 0);
    const maxAmount = studentData.fees_sub_component[index]?.amount || 0;

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (numericValue > maxAmount) {
        updatedErrors[index] = `Cannot exceed ${maxAmount}`;
      } else {
        delete updatedErrors[index];
      }
      return updatedErrors;
    });

    setFeesToBePaid((prev) => {
      const updated = { ...prev, [index]: numericValue };

      const newApplyingAmount = Object.keys(updated)
        .filter(
          (key) => document.querySelector(`input[data-index="${key}"]`)?.checked
        )
        .reduce((sum, key) => sum + (parseFloat(updated[key]) || 0), 0);

      setApplyingAmount(newApplyingAmount-concessionAmount);

      return updated;
    });
  };

  useEffect(() => {
    if (branchId) {
      fetchClasses(branchId);
    }
  }, [branchId]);

  useEffect(() => {
    fetchStudentMarks(branchId, id);
    fetchConcessionHistory(id);
    fetchTransactionHistory(branchId, clsid, id);
  }, [branchId, id]);

  const fetchStudentMarks = async (branchId, id) => {
    try {
      const response = await getStudentAcademicFee({
        branch_id: branchId,
        class_id: clsid,
        student_id: id,
        component_id: fcid,
      });
      setStudentData(response.data.data.academic_fees[0]);
    } catch (error) {
      console.error("Error fetching student marks:", error);
    }
  };

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchConcessionHistory = async (student_id) => {
    try {
      const response = await getStudentConcessionHistory(student_id);
      setConcessionHistory(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTransactionHistory = async (branch_id, class_id, student_id) => {
    try {
      const response = await getAcademicPayments({
        branch_id: branch_id,
        class_id: [class_id],
        student_id: student_id,
      });
      setTransactionHistory(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBack = () => {
    navigate("/school/1/branch/1/fee-payments", { state: { branchId } });
  };

  if (!studentData) {
    return <div></div>;
  }

  const cardStyle = {
    borderTop: "6px solid skyblue",
  };

  const headingStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "bold",
    padding: "10px 0",
  };

  const tableStyle = {
    width: "100%",
    textAlign: "center",
  };

  const thStyle = {
    fontWeight: "bold",
    padding: "10px",
    textAlign: "left",
  };

  const tdStyle = {
    padding: "10px",
    textAlign: "left",
  };

  const handlePayNow = async () => {
    showLoadingAlert();
    try {
      const selectedSubCompData = [];
      const checkboxes = document.querySelectorAll(
        "tbody input[type='checkbox']"
      );
      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          const index = parseInt(checkbox.dataset.index, 10);
          const subComponent = studentData.fees_sub_component[index];

          selectedSubCompData.push({
            id: subComponent.id,
            amount_to_pay: subComponent.amount || 0,
            amount_paid: feesToBePaid[index] || 0,
          });
        }
      });

      if (selectedSubCompData.length === 0) {
        alert("Please select at least one fee sub-component to proceed.");
        return;
      }

      const requestData = {
        fees_sub_comp_id: selectedSubCompData,
        student_id: id,
        fees_amount: studentData.amount,
        discount: concessionAmount,
        amount_paid: applyingAmount,
      };

      const response = await createAcademicPayments(requestData);
      if (response.status === 201) {
        showSuccessAlert(
          "Created!",
          response.data.message || "Data Created Successfully."
        );
        navigate(`/school/${schoolId}/branch/${branchId}/fee-payments`);
      } else {
      }
    } catch (error) {
      console.error("Error during payment submission:", error);
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      showErrorAlert("Error", errorMessage);
    }
  };

  const handleApplyingAmountChange = (value) => {
    const numericValue = parseFloat(value || 0);

    setApplyingAmount(numericValue);

    const totalFeesToBePaid = Object.keys(feesToBePaid).reduce(
      (sum, key) => sum + (parseFloat(feesToBePaid[key]) || 0),
      0
    );

    if ((numericValue) !== (totalFeesToBePaid - concessionAmount)) {
      setApplyingAmountError(
        `Applying amount (${numericValue}) must match the total fees to be paid (${totalFeesToBePaid}) minus concession amount (${concessionAmount}).`
      );
    } else {
      setApplyingAmountError("");
    }
    
  };

  return (
    <div className="row p-4" style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "1200px",
          zIndex: 1,
        }}
      >
        <button className="btn btn-primary " onClick={handleBack}>
          Back
        </button>
      </div>
      <div className="col-md-4" style={{ marginTop: "30px" }}>
        <div className="card" style={cardStyle}>
          <div className="card-body box-profile">
            <div className="text-center">
              <img
                className="profile-user-img img-fluid img-circle"
                src={me}
                alt="User profile picture"
              />
            </div>
            <h3 className="profile-username text-center">
              {studentData.student_first_name}
            </h3>
            <p className="text-center text-muted">{studentData.branch_name}</p>
            <ul className="list-group list-group-unbordered mb-3">
              <li className="list-group-item">
                <b>Admission No</b>{" "}
                <span className="float-right">{studentData.admission_no}</span>
              </li>
              <li className="list-group-item">
                <b>Parent Name</b>{" "}
                <span className="float-right">{studentData.parent_name}</span>
              </li>
              <li className="list-group-item">
                <b>Class</b>{" "}
                <span className="float-right">{studentData.class_name}</span>
              </li>
              <li className="list-group-item">
                <b>Section</b>{" "}
                <span className="float-right">{studentData.section_name}</span>
              </li>
              <li className="list-group-item">
                <b>Fee Amount</b>{" "}
                <span className="float-right">{studentData.amount}</span>
              </li>
              <li className="list-group-item">
                <b>Academic Year</b>{" "}
                <span className="float-right">
                  {studentData.academic_period}
                </span>
              </li>
              <li className="list-group-item">
                <b>Balance</b>{" "}
                <span className="float-right">{studentData.balance}</span>
              </li>
              <li className="list-group-item">
                <b>Amount Paid</b>{" "}
                <span className="float-right">{studentData.amount_paid}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-md-8" style={{ marginTop: "30px" }}>
        <div className="card mb-4" style={cardStyle}>
          <h5
            className="p-2 mt-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            Transaction Details
          </h5>
          <div className="card-body">
            <table style={tableStyle} className="table table-bordered">
              <thead>
                <tr>
                  <th style={thStyle}>Sub Component</th>
                  <th style={thStyle}>Receipt No</th>
                  <th style={thStyle}>Paid Amount</th>
                  {/* <th style={thStyle}>Payment Mode</th> */}
                  <th style={thStyle}>Paid Date</th>
                  <th style={thStyle}>Final Amount</th>
                  <th style={thStyle}>Download</th>
                </tr>
              </thead>
              <tbody>
                {transactionHistory &&
                  transactionHistory.map((transaction, index) => (
                    <tr key={index}>
                      <td>{transaction.sub_component_name}</td>
                      <td>{transaction.transaction_id}</td>
                      <td>{transaction.amount_paid}</td>
                      {/* <td>{transaction.paid_status}</td> */}
                      <td>{transaction.payment_date}</td>
                      <td>{transaction.amount_to_pay}</td>
                      <td>
                        <i className="fa fa-receipt"></i>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card" style={{ borderTop: "6px solid skyblue" }}>
          <h5
            className="p-2 mt-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            Concession History
          </h5>
          <div className="card-body">
            <table style={tableStyle} className="table table-bordered">
              <thead>
                <tr>
                  <th style={thStyle}>Concession Name</th>
                  <th style={thStyle}>Fee Component</th>
                  <th style={thStyle}>Fee Sub Component</th>
                  <th style={thStyle}>Class Concession Amount</th>
                  <th style={thStyle}>Student Concession Amount</th>
                  <th style={thStyle}>Paid Amount</th>
                </tr>
              </thead>
              <tbody>
                {concessionHistory.map((item, index) => (
                  <tr key={index}>
                    <td>{item.concession_name}</td>
                    <td>{item.component_name || "N/A"}</td>
                    <td>{item.sub_component_name || "N/A"}</td>
                    <td>{item.class_concession_amount || "0"}</td>
                    <td>{item.student_concession_amount || "0"}</td>
                    <td>{item.total_concession_amount || "0"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card" style={{ borderTop: "6px solid skyblue" }}>
          <h5
            className="p-2 mt-2"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            Fee Payment
          </h5>
          <hr />
          <div className="card-body">
            <table style={tableStyle} className="table table-bordered">
              <thead>
                <tr>
                  <th style={thStyle}>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const newFeesToBePaid = {};
                        let totalCheckedAmount = 0;
                        let applyingAmountTemp = 0;

                        studentData.fees_sub_component.forEach(
                          (subComponent, index) => {
                            const amount = parseFloat(subComponent.amount || 0);

                            if (isChecked) {
                              newFeesToBePaid[index] = amount;
                              totalCheckedAmount += amount;
                              applyingAmountTemp += amount;
                            } else {
                              newFeesToBePaid[index] = 0;
                            }
                          }
                        );

                        setFeesToBePaid(newFeesToBePaid);
                        setTotalAmount(isChecked ? totalCheckedAmount : 0);
                        setApplyingAmount(isChecked ? applyingAmountTemp : 0);

                        document
                          .querySelectorAll("tbody input[type='checkbox']")
                          .forEach((checkbox) => {
                            checkbox.checked = isChecked;
                          });
                      }}
                    />
                  </th>
                  <th style={thStyle}>Fee Component</th>
                  <th style={thStyle}>Fee Sub Component</th>
                  <th style={thStyle}>Amount</th>
                  <th style={thStyle}>Paid Amount</th>
                  <th style={thStyle}>Fees to be Paid</th>
                </tr>
              </thead>
              <tbody>
                {studentData?.fees_sub_component?.map((subComponent, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        data-amount={subComponent.amount || 0}
                        data-index={index}
                        onChange={(e) =>
                          handleCheckboxChange(
                            parseFloat(e.target.dataset.amount),
                            e.target.checked,
                            index
                          )
                        }
                      />
                    </td>
                    <td>{studentData.fee_component || "N/A"}</td>
                    <td>{subComponent.name || "N/A"}</td>
                    <td>{subComponent.amount || "0"}</td>
                    <td>{studentData.amount_paid || "0"}</td>
                    <td>
                      <div className="input-group" style={{ width: "100%" }}>
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fas fa-indian-rupee-sign"></i>
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          value={feesToBePaid[index] || ""}
                          onChange={(e) =>
                            handleFeesInputChange(e.target.value, index)
                          }
                          style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                          }}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                      {errors[index] && (
                        <small style={{ color: "red" }}>{errors[index]}</small>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex flex-column align-items-end mr-3 pb-3 mt-5">
              <div className="col-md-6">
                <div className="form-group d-flex">
                  <label
                    className="mb-0 flex-shrink-0"
                    style={{ width: "60%" }}
                  >
                    Total Actual Amount :
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-indian-rupee-sign"></i>
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control flex-grow-1"
                      value={totalAmount}
                      readOnly
                      style={{
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group d-flex">
                  <label
                    className="mb-0 flex-shrink-0"
                    style={{ width: "60%" }}
                  >
                    Total Concession Amount :
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-indian-rupee-sign"></i>
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control flex-grow-1"
                      style={{
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      value={concessionAmount}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value) || 0;
                        setConcessionAmount(value);
                        setApplyingAmount(Math.max(totalAmount - value));
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group d-flex flex-column">
                  <div className="d-flex">
                    <label
                      className="mb-0 flex-shrink-0"
                      style={{ width: "60%" }}
                    >
                      Paying Amount :
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-indian-rupee-sign"></i>
                        </span>
                      </div>
                      <input
                        type="number"
                        className="form-control flex-grow-1"
                        value={applyingAmount}

                        onChange={(e) =>
                          handleApplyingAmountChange(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {applyingAmountError && (
                    <small style={{ color: "red", marginTop: "5px" }}>
                      {applyingAmountError}
                    </small>
                  )}
                </div>
              </div>

              <button
                className="btn btn-primary"
                disabled={
                  !!applyingAmountError ||
                  Object.values(errors).some(Boolean)
                }
                onClick={handlePayNow}
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayStudentFees;
