import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import { getUserRoleId, getUserRoleName } from "../../Utility/CommonUtility/extractUserDetailsFromToken";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";

import {
  deleteBranch,
  getBranchBySchoolId,
} from "../../Services/CommonApis/branchApi";
import { useNavigate, useParams } from "react-router-dom";
import { COOKIE } from "../../Schemas/cookieNames";
import { getSession, setSession } from "../../Services/Storage";
const BranchList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const navigate = useNavigate();
  const [roleId, setRoleId] = useState(null);
  const adminToken = getSession(COOKIE.ADMIN_TOKEN);

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to delete the branch!",
      "warning",
      "Yes, Delete!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        await deleteBranch(id);
        Swal.close();
        showSuccessAlert("Deleted!", "The branch has been deleted.");
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the branch.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The branch is safe 😊");
    }
  };
  let roleName = getUserRoleName(adminToken).toLowerCase();
  useEffect(() => {
    if (adminToken) {
      setRoleId(getUserRoleId(adminToken));
    }
  }, [roleId, roleName]);
  const { id } = useParams();
  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "branch_name" },
        { data: "branch_code" },
        { data: "address" },
        { data: "city" },
        { data: "pin" },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const branchId = row.id;
            const deleteIcon =
              row.status === "inactive"
                ? ""
                : `
              <a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>
            `;
            return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="#" title="View" class="branch-view" data-id="${row.id}" data-name="${row.branch_name}"><i class="fas fa-eye"></i></a>
                <a href="/admin/school/${id}/branch-edit/${branchId}" title="Edit"><i class="fas fa-edit"></i></a>
                ${deleteIcon}
              </div>
            `;
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "csv",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "excel",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "pdf",
          exportOptions: {
            columns: ":visible",
          },
        },
        {
          extend: "print",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
        emptyTable: "No Branch Data Found",
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getBranchBySchoolId(id,{
            page: page,
            _limit: length,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });
          const jsonData = response.branches || [];
          const totalCount = response.total || 0;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          if ((error.response.data.status = 404)) {
            showErrorAlert(
              error.response.data.message ||
              "No branches found for the specified school."
            );
          }
          callback({
            draw: data.draw,
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          });
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".branch-view", function (e) {
      e.preventDefault();
      const branchId = $(this).data("id");
      setSession(COOKIE.SCHOOL_ID, id);
      setSession(COOKIE.BRANCH_ID, branchId);
      if (roleName == "school-manager") {
        navigate(`/manager-dashboard/school/${id}/branch/${branchId}/dashboard`);
      } else if (roleName == "admin") {
        navigate(`/school/${id}/branch/${branchId}/dashboard`);
      }
      else {
        navigate(`/${roleName}/school/${id}/branch/${branchId}/dashboard`);
      }
    });
    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, [id, navigate]);
  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Branch List</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Branches</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Branch List Table</h3>
                  <div className="card-tools">
                    <ul className="nav nav-pills ml-auto">
                      <li className="nav-item p-1">
                        <a
                          className="btn btn-primary active"
                          href={`/admin/school/${id}/branch-create`}
                        >
                          Create New
                        </a>
                      </li>
                      <li className="nav-item p-1">
                        <a
                          className="btn btn-secondary"
                          href={`/admin/school/${id}/branch-upload`}
                        >
                          Upload Branch
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <table
                    ref={tableRef}
                    id="branchTable"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Branch Name</th>
                        <th>Branch Code</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>Pin</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th>ID</th>
                        <th>Branch Name</th>
                        <th>Branch Code</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>Pin</th>
                        <th>Actions</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default BranchList;