import React, { useState, useEffect, useRef, useCallback } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import "../../Styles/SchoolCreate.css";
import { StaffValidation, StaffEditValidation } from "../../Utility/CommonUtility/Validation";
import {
  createStaff,
  getStaffById,
  updateStaffById,
} from "../../Services/AdminApis/StaffApi";
import {
  showLoadingAlert,
  showSuccessAlert,
  showErrorAlert,
} from "../../Modals/SweetAlertModel";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import StaffInformation from "../../Components/CommonComponents/StaffInformation";
import StaffDetails from "../../Components/CommonComponents/StaffDetails";
import PersonalInfo from "../../Components/CommonComponents/PersonalInformation";
import UserDetails from "../../Components/CommonComponents/UserDetails";

const StaffCreate = () => {
  const navigate = useNavigate();
  const { branchId, id, schoolId } = useParams();
  const [current, setCurrent] = useState(1);
  const progressBarRef = useRef(null);
  const steps = id ? 3 : 4;
  const [loading, setLoading] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [staffData, setStaffData] = useState(null);
  const [values, setValues] = useState({});


  useEffect(() => {
    if (id) {
      getStaff(id);
      console.log("id is ", id)
    }
  }, [id]);

  const getStaff = async (id) => {
    try {
      const response = await getStaffById(id);
      console.log("respomns data", response.data.staff)
      setStaffData(response.data.staff);
    } catch (err) {
      console.error("API error:", err);
      showErrorAlert("Error", "Failed to fetch staff data");
    }
  };
  const formatDate = (dateString) => {
    if (dateString) {
      const [year, month, day] = dateString.split("-");
      return `${day}/${month}/${year}`;
    }
    return dateString; // Return the original string if dateString is invalid or empty
  };

  const prevStep = () => {
    setCurrent((prevCurrent) =>
      prevCurrent > 1 ? prevCurrent - 1 : prevCurrent
    );
  };

  const goToStep = (step) => {
    setCurrent(step);
  };

  const setProgressBar = useCallback(
    (curStep) => {
      const percent = ((100 / steps) * curStep).toFixed();
      if (progressBarRef.current) {
        progressBarRef.current.style.width = `${percent}%`;
      }
    },
    [steps]
  );

  useEffect(() => {
    setProgressBar(current);
  }, [current, setProgressBar]);

  const handleSubmit = async (values) => {
    const formValues = { ...values, branch_id: branchId };
    const formData = new FormData();

    Object.keys(formValues).forEach((key) => {
      if (formValues[key] !== null && formValues[key] !== undefined) {
        if (
          key === "joining_date" ||
          key === "anniversary_date" ||
          key === "date_of_birth"
        ) {
          formData.append(key, formatDate(values[key]));
        } else if (
          key.endsWith("_file") ||
          key.endsWith("_card") ||
          key.endsWith("_logo")
        ) {
          const file = formValues[key];
          if (file instanceof File) {
            formData.append(key, file, file.name);
          } else {
            formData.append(key, file);
          }
        } else {
          formData.append(key, formValues[key]);
        }
      }
    });

    showLoadingAlert();

    try {
      let response;
      if (id) {
        response = await updateStaffById(id, formData);
        showSuccessAlert(
          "Updated!",
          response.data.message || "Data Updated Successfully"
        );
        navigate(`/school/${schoolId}/branch/${branchId}/staff`);
      } else {
        response = await createStaff(formData);
        showSuccessAlert(
          "Created!",
          response.data.message || "Data Created Successfully"
        );
        window.location.reload();
      }
    } catch (err) {
      console.error("API error:", err);
      let errorMessage = "An unexpected error occurred. Please try again.";

      if (err.response) {
        errorMessage = err.response.data.error || err.response.data.message;
      } else if (err.request) {
        errorMessage = "No response from the server. Please try again.";
      }
      showErrorAlert("Error", errorMessage);
    } finally {
      setLoading(false);
    }
  };


  const initialValues = {
    employee_no: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    epf_no: "",
    uan_no: "",
    esi_no: "",
    marital_status: "",
    anniversary_date: "",
    spouse_name: "",
    kid_studying: "no",
    assigned_activity: "",
    joining_date: "",
    specialized: "",
    department: "",
    work_location: "",
    qualification: "",
    extra_qualification: "",
    previous_school: "",
    reason_change: "",
    role_id: "",
    phone_no: "",
    date_of_birth: "",
    gender: "",
    blood_group: "",
    religion: "",
    cast: "",
    nationality: "",
    mother_tongue: "",
    aadhaar_card_no: "",
    pan_card_no: "",
    address: "",
    city: "",
    state: "",
    dist: "",
    pin: "",
    tmp_address: "",
    temp_city: "",
    temp_state: "",
    temp_dist: "",
    temp_pin: "",
    image: "",
    school: " ",
  };

  const getInitialValues = () => {
    if (staffData) {
      console.log('staff', staffData);
      return {
        role_id: staffData.role_id || "",
        employee_no: staffData.employee_no || "",
        first_name: staffData.first_name || "",
        middle_name: staffData.middle_name || "",
        last_name: staffData.last_name || "",
        email: staffData.email || "",
        phone_no: staffData.phone_no || "",
        epf_no: staffData.epf_no || "",
        uan_no: staffData.uan_no || "",
        esi_no: staffData.esi_no || "",
        marital_status: staffData.marital_status || "",
        anniversary_date: staffData.anniversary_date || "",
        spouse_name: staffData.spouse_name || "",
        kid_studying: staffData.kid_studying || "no",
        assigned_activity: staffData.assigned_activity || "",
        joining_date: staffData.joining_date || "",
        specialized: staffData.specialized || "",
        department: staffData.department || "",
        work_location: staffData.work_location || "",
        qualification: staffData.qualification || "",
        extra_qualification: staffData.extra_qualification || "",
        previous_school: staffData.previous_school || "",
        reason_change: staffData.reason_change || "",
        date_of_birth: staffData.date_of_birth || "",
        gender: staffData.gender || "",
        blood_group: staffData.blood_group || "",
        religion: staffData.religion || "",
        cast: staffData.cast || "",
        nationality: staffData.nationality || "",
        mother_tongue: staffData.mother_tongue || "",
        aadhaar_card_no: staffData.aadhaar_card_no || "",
        pan_card_no: staffData.pan_card_no || "",
        address: staffData.address || "",
        city: staffData.city || "",
        state: staffData.state || "",
        dist: staffData.dist || "",
        pin: staffData.pin || "",
        tmp_address: staffData.tmp_address || "",
        temp_city: staffData.temp_city || "",
        temp_state: staffData.temp_state || "",
        temp_dist: staffData.temp_dist || "",
        temp_pin: staffData.temp_pin || "",
        image: staffData.image || "",
        school: staffData.school_id || "",
      };
    } else {
      return initialValues;
    }
  };

  return (
    <div className="card">
      <div className="head-card">
        <div className="row ml-4">
          <div className="col-11 col-sm-10 col-md-10 col-lg-6 col-xl-5">
            <Formik
              initialValues={getInitialValues()}
              enableReinitialize
              // validationSchema={StaffValidation}
              // validationSchema={StaffValidation[`tab${current}`]}
              validationSchema={
                id
                  ? StaffEditValidation[`tab${current}`]
                  : StaffValidation[`tab${current}`]
              }
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, validateForm, setTouched }) => (
                <Form id="msform">
                  <ul id="progressbar">
                    <li
                      className={current >= 1 ? "active" : ""}
                      id="staffInfo"
                      onClick={() => goToStep(1)}
                      style={{ width: "250px" }}
                    >
                      <strong>Staff Information</strong>
                    </li>
                    <li
                      className={current >= 2 ? "active" : ""}
                      id="staffDetails"
                      onClick={() => goToStep(2)}
                      style={{ width: "270px" }}
                    >
                      <strong>Staff Details</strong>
                    </li>

                    <li
                      className={current >= 3 ? "active" : ""}
                      id="personalInfo"
                      onClick={() => goToStep(3)}
                      style={{ width: "270px" }}
                    >
                      <strong>Personal Info</strong>
                    </li>

                    <li
                      className={current >= 4 ? "active" : ""}
                      id="additionalInfo"
                      onClick={() => goToStep(4)}
                      style={{ width: "270px" }}
                    >
                      <strong>Additional Info</strong>
                    </li>
                  </ul>
                  <div className="progress">
                    <div
                      ref={progressBarRef}
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <br />
                  {current === 1 && (
                    <StaffInformation
                      steps={steps}
                      current={1}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  )}
                  {current === 2 && <StaffDetails steps={steps} current={2} />}
                  {current === 3 && (
                    <PersonalInfo steps={steps} current={current} />
                  )}
                  {current === 4 && (
                    <UserDetails steps={steps} current={current} />
                  )}

                  <div className="button-group">
                    {/* {current > 1 && current < steps && (
                      <button
                        type="button"
                        className="previous action-button-previous"
                        onClick={prevStep}
                      >
                        Previous
                      </button> */}


                    {((id && current > 1 && current <= 4) ||
                      (!id && current > 1 && current <= 4)) && (
                        <button
                          type="button"
                          className="previous action-button-previous"
                          onClick={prevStep}
                        >
                          Previous
                        </button>
                      )}

                    {((id && current < 4) || (!id && current < 4)) && (
                      <button
                        type="button"
                        className="btn btn-primary action-button"
                        onClick={() => {
                          const currentTabValidationSchema = id
                            ? StaffEditValidation[`tab${current}`]
                            : StaffValidation[`tab${current}`];

                          console.log("Current Tab:", current);
                          console.log("Validation Schema:", currentTabValidationSchema);
                          console.log("Values:", values);

                          currentTabValidationSchema
                            .validate(values, { abortEarly: false })
                            .then(() => {
                              console.log("Validation Passed for Tab:", current);
                              setCurrent(current + 1);
                              setTouched({});
                            })
                            .catch((validationErrors) => {
                              console.log("Validation Errors:", validationErrors);
                              const errors = validationErrors.inner.reduce((acc, err) => {
                                acc[err.path] = err.message;
                                return acc;
                              }, {});
                              setTouched(errors);
                            });
                        }}
                      >
                        Next
                      </button>

                    )}
                    {current === 4 && (
                      <button
                        type="submit"
                        className="action-button"
                        style={{
                          width: loading ? "150px" : "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            &nbsp;Submitting...
                          </>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffCreate;
