import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { useParams } from "react-router-dom";
import { getAllSubjects } from "../../Services/CommonApis/subjectApi";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import {
  createSubject,
  createBranchSubject,
} from "../../Services/CommonApis/subjectApi";
import { FieldArray } from "formik";
import { getsectionsbyclassids } from "../../Services/CommonApis/SectionApi";
import { components } from "react-select";

import BeatLoader from "react-spinners/BeatLoader";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
  showSweetAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";

function BranchSubjectCreate() {
  const { branchId } = useParams();
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [classId, setClassId] = useState([]);
  const [newSubject, setNewSubject] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const [isClassLoading, setClassLoading] = useState(false);
  const [isSectionLoading, setSectionLoading] = useState(false);

  useEffect(() => {
    fetchClassByBranchId(branchId);
    fetchAllSubjects();
  }, [branchId]);

  useEffect(() => {
    if (classId) {
      fetchSectionByClassId(classId);
    }
  }, [classId]);

  const fetchClassByBranchId = async (branchId) => {
    try {
      setClassLoading(true);
      const response = await getClassByBranchId(branchId);
      setClassList(response.data.classes);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setClassLoading(false);
    }
  };
  const fetchSectionByClassId = async (classId) => {
    try {
      setSectionLoading(true);
      const response = await getsectionsbyclassids({ class_id: classId });
      setSectionList(response.data.sections);
    } catch (error) {
      console.log(error);
    } finally {
      setSectionLoading(false);
    }
  };
  const fetchAllSubjects = async () => {
    try {
      const response = await getAllSubjects();
      const mappedSubjects = response.data.data.map((subject) => ({
        value: subject.id.toString(), // Ensure the value is consistent
        label: subject.name,
      }));
      setSubjects(mappedSubjects);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };
  const handleAddNewSubject = async () => {
    if (newSubject) {
      try {
        const response = await createSubject({ name: newSubject });
        const newSubjectData = response.data;
        setSubjects((prevSubjects) => [
          ...prevSubjects,
          { value: newSubjectData.id, label: newSubjectData.name },
        ]);
        setNewSubject("");
        setShowModal(false);
        Swal.fire({
          title: "Created!",
          text: "Subject created successfully.",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            fetchAllSubjects();
          }
        });

      } catch (error) {
        console.error("API error while creating a new subject:", error);
        const errorMessage = error.response?.data?.message;
        if (errorMessage === "The Subject Already Exists") {
          showErrorAlert("Error!", "The subject is already created.");
        } else {
          showErrorAlert("Error!", "An error occurred while creating the subject.");
        }
      }
    }
  };
  
  const handleSubmit = async (values, { resetForm }) => {
    const valuesWithBranchId = {
      ...values, // Spread the existing values (including section_id, etc.)
      branch_id: branchId, // Add the branch_id variable (make sure it's defined)
    };
    const result = await showSweetAlert(
      "Are you sure?",
      "You want to create branch subjects",
      "info",
      "Yes, Create",
      "No, Cancel!"
    );
  
    if (result.isConfirmed) {
      showLoadingAlert();
      try {
        const response = await createBranchSubject(valuesWithBranchId);
        if (response.status === 201) {
          showSuccessAlert("Created!", "Created successfully.");
          resetForm();
          // window.location.reload();
        } else {
          const errorMessage =
            response.data?.message ||
            "Failed to create branch subjects. Please try again.";
          showErrorAlert("Error!", errorMessage);
        }
      } catch (error) {
        console.error("API error during branch subject creation:", error);
        const errorMessage = error.response?.data?.message;
        if (errorMessage === "The Subject Already Exists") {
          showErrorAlert("Error!", "The subject is already created.");
        } else {
          showErrorAlert(
            "Error!",
            errorMessage || "An error occurred during branch subject creation."
          );
        }
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "Branch subject creation has been cancelled.");
    }
  };
  
  
  const CustomMenuList = (props) => {
    const { options, setFieldValue, values, selectProps } = props;
    const selectedClassIds = values.class_id || [];
    const isAllSelected = selectedClassIds.length === options.length;

    const handleSelectAllChange = (e) => {
      if (e.target.checked) {
        const allClassValues = options.map((option) => option.value);
        setFieldValue("class_id", allClassValues);
        selectProps.onChange(options);
      } else {
        setFieldValue("class_id", []);
        selectProps.onChange([]);
      }
    };
    return (
      <components.MenuList {...props}>
        <div
          style={{
            padding: "6px",
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
          <p className="ml-3 mt-2">Select All</p>
        </div>
        {props.children}
      </components.MenuList>
    );
  };

  const CustomOption = (props) => {
    const { isSelected, label, innerRef, innerProps } = props;

    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px",
          height:"30px",
          cursor: "pointer",
          backgroundColor: isSelected ? "lightgray" : "white",
        }}
      >
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null} // Handled by react-select
          style={{ marginRight: "8px" }}
        />
        <p style={{ marginLeft: "5px", marginBottom: "1px", marginTop: "1px" }}>
          {label}
        </p>
      </div>
    );
  };
  const CustomMenuListSection = (props) => {
    const { options, setFieldValue, values, selectProps } = props;

    const selectedSectionIds = values.section_id || [];
    const isAllSelected = selectedSectionIds.length === options.length;

    const handleSelectAllChange = (e) => {
      if (e.target.checked) {
        const allSectionValues = options.map((option) => option.value);
        setFieldValue("section_id", allSectionValues);
        selectProps.onChange(options);
      } else {
        setFieldValue("section_id", []);
        selectProps.onChange([]);
      }
    };

    return (
      <components.MenuList {...props}>
        <div
          style={{
            padding: "6px",
            borderBottom: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
          <p className="ml-3 mt-2">Select All Sections</p>
        </div>
        {props.children}
      </components.MenuList>
    );
  };

  const validationSchema = Yup.object().shape({
    class_id: Yup.array()
      .min(1, "Please select at least one class.")
      .required("Class is required."),
    section_id: Yup.array()
      .min(1, "Please select at least one section.")
      .required("Section is required."),
    subjects: Yup.array()
      .of(
        Yup.object().shape({
          subject_type: Yup.string().required("Subject type is required."),
          subject_id: Yup.string().required("Subject name is required."),
          subject_label: Yup.string()
            .min(3, "Report card name must be at least 3 characters.")
            .required("Report card name is required."),
          subject_code: Yup.string()
            .matches(/^[A-Z]{3}$/, "Subject code must be 3 uppercase letters.")
            .required("Subject code is required."),
        })
      )
      .required("At least one subject is required."),
  });

  const subjectOptions = [
    { value: "add-new", label: "Add New Subject", isAddNew: true },
    ...subjects.filter((subject) => !selectedSubjects.includes(subject.value)),
  ];
  return (
    <>
      {loading ? (
        <div className="text-center mt-5">
          <BeatLoader size={15} color={"#123abc"} loading={loading} />
        </div>
      ) : (
        <>
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">
                Subject Creation <small> Form</small>
              </h3>
            </div>
            <Formik
              initialValues={{
                class_id: "",
                section_id: "",
                subjects: [
                  {
                    subject_type: "",
                    subject_id: "",
                    subject_label: "",
                    exam_type: "",
                    subject_code: "",
                  },
                ],
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="card-body p-2">
                    <div className="card p-4">
                      <div className="row">
                        <div className="form-group col-4">
                          <label htmlFor="class_id">
                            Class Name <span className="mandatory">*</span>
                          </label>
                          <Select
                            id="class_id"
                            name="class_id"
                            options={classList.map((cls) => ({
                              value: cls.id,
                              label: cls.name,
                            }))}
                            onChange={(selected) => {
                              const selectedClassIds = selected.map(
                                (option) => option.value
                              );
                              setFieldValue("class_id", selectedClassIds); // Assuming class_id is an array
                              setClassId(selectedClassIds);
                            }}
                            isLoading={isClassLoading} // Show loading state
                            isMulti // Allow multiple selections
                            placeholder="Select Class"
                            classNamePrefix="react-select" // For custom styling
                            isClearable // Allow clearing the selection
                            components={{
                              MenuList: (props) => (
                                <CustomMenuList
                                  {...props}
                                  setFieldValue={setFieldValue}
                                  values={values} // Pass Formik values
                                />
                              ),
                              Option: CustomOption, // Custom option component
                            }}
                          />
                          <ErrorMessage
                            name="class_id"
                            component="div"
                            className="text-error"
                          />
                        </div>

                        <div className="form-group col-4">
                          <label htmlFor="section_id">
                            Section Name <span className="mandatory">*</span>
                          </label>
                          <Select
                            id="section_id"
                            name="section_id"
                            options={sectionList.map((section) => ({
                              value: section.id,
                              label: `${section.class_name} - ${section.name}`,
                            }))}
                            onChange={(selected) => {
                              const selectedSectionIds = selected.map(
                                (option) => option.value
                              );
                              setFieldValue("section_id", selectedSectionIds); // Assuming section_id is an array
                            }}
                            isLoading={isSectionLoading} // Set loading state
                            isMulti // Allow multiple selections
                            placeholder="Select Section"
                            classNamePrefix="react-select" // Optional: for custom styles
                            isClearable // Optional: allow clearing the selection
                            components={{
                              MenuList: (props) => (
                                <CustomMenuListSection
                                  {...props}
                                  setFieldValue={setFieldValue}
                                  values={values} // Pass Formik values
                                />
                              ),
                              Option: CustomOption, // Reuse the CustomOption component from the class dropdown
                            }}
                          />
                          <ErrorMessage
                            name="section_id"
                            component="div"
                            className="text-error"
                          />
                        </div>
                      </div>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Subject Type</th>
                            <th>Subject Name</th>
                            <th style={{ width: "100px" }}>Subject Code</th>
                            <th style={{ width: "100px" }}>Report Card Name</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray name="subjects">
                            {({ insert, remove, push }) => (
                              <>
                                {values.subjects && values.subjects.length > 0
                                  ? values.subjects.map((subject, index) => (
                                      <tr key={index}>
                                        <td>
                                          <Field
                                            as="select"
                                            name={`subjects.${index}.subject_type`}
                                            className="form-control"
                                          >
                                            <option value="">
                                              Select Type
                                            </option>
                                            <option value="main">
                                              Main Subject
                                            </option>
                                            <option value="optional">
                                              Optional Subject
                                            </option>
                                          </Field>
                                          <ErrorMessage
                                            name={`subjects.${index}.subject_type`}
                                            component="div"
                                            className="text-error"
                                          />
                                        </td>
                                        <td>
                                          <Select
                                            value={
                                              subjects.find((option) => {
                                                return (
                                                  option.value ===
                                                  subject.subject_id?.toString()
                                                ); // Compare
                                              }) || null
                                            }
                                            options={subjectOptions}
                                            onChange={(selected) => {
                                              if (
                                                selected.value === "add-new"
                                              ) {
                                                setShowModal(true);
                                                return;
                                              }

                                              setSelectedSubject(selected);

                                              const subjectShortName =
                                                selected.label
                                                  .substring(0, 3)
                                                  .toUpperCase();

                                              setFieldValue(
                                                `subjects.${index}.subject_code`,
                                                subjectShortName
                                              );
                                              setSelectedSubjects((prev) => [
                                                ...prev,
                                                selected.value,
                                              ]);
                                              setFieldValue(
                                                `subjects.${index}.subject_id`,
                                                selected.value
                                              );
                                            }}
                                            getOptionLabel={(option) => {
                                              if (option.isAddNew) {
                                                return (
                                                  <div className="custom-dropdown-item">
                                                    <FaPlus
                                                      style={{ marginRight: 8 }}
                                                    />
                                                    {option.label}
                                                  </div>
                                                );
                                              }
                                              return option.label; // Return the label
                                            }}
                                            getOptionValue={(option) =>
                                              option.value
                                            }
                                            placeholder="Select Subject"
                                          />
                                          <ErrorMessage
                                            name={`subjects.${index}.subject_id`}
                                            component="div"
                                            className="text-error"
                                          />
                                        </td>
                                        <td style={{ width: "170px" }}>
                                          <Field
                                            type="text"
                                            name={`subjects.${index}.subject_code`}
                                            className="form-control"
                                            placeholder="Subject code"
                                          />
                                          <ErrorMessage
                                            name={`subjects.${index}.subject_code`}
                                            component="div"
                                            className="text-error"
                                          />
                                        </td>
                                        <td style={{ width: "200px" }}>
                                          <Field
                                            type="text"
                                            name={`subjects.${index}.subject_label`}
                                            className="form-control"
                                            placeholder="Enter report card name"
                                          />
                                          <ErrorMessage
                                            name={`subjects.${index}.subject_label`}
                                            component="div"
                                            className="text-error"
                                          />
                                        </td>
                                        <td
                                          style={{
                                            verticalAlign: "middle",
                                            textAlign: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "5px",
                                            }}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              style={{
                                                height: "38px",
                                                padding: "6px 12px",
                                                backgroundColor: "red", // Override background color
                                                borderColor: "red", // Override border color
                                              }}
                                              onClick={() => remove(index)}
                                            >
                                              -
                                            </button>
                                            {index ===
                                              values.subjects.length - 1 && (
                                              <button
                                                type="button"
                                                className="btn btn-primary ml-2"
                                                style={{
                                                  height: "38px",
                                                  padding: "6px 12px",
                                                }}
                                                onClick={() =>
                                                  push({
                                                    class_id: "",
                                                    section_id: "",
                                                    subject_type: "",
                                                    subject_id: "",
                                                    subject_label: "",
                                                    is_grade: "",
                                                    enter_as_grade: "",
                                                    add_grand: "",
                                                    apply_to_all: "",
                                                  })
                                                }
                                              >
                                                +
                                              </button>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  : null}
                              </>
                            )}
                          </FieldArray>
                        </tbody>
                      </table>

                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn btn-primary ml-5 mt-4"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add New Subject</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <label htmlFor="new-subject">Subject Name</label>
                  <input
                    type="text"
                    id="new-subject"
                    value={newSubject}
                    onChange={(e) => setNewSubject(e.target.value)}
                    placeholder="New Subject Name"
                    className="form-control"
                  />
                </div>
                <div style={{ marginLeft: "8px", marginTop: "30px" }}>
                  <Tooltip title="Need Approval" placement="left">
                    <IconButton style={{ padding: 0 }}>
                      <InfoIcon style={{ color: "blue" }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={handleAddNewSubject}>
                Add
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default BranchSubjectCreate;
