import React, { useState } from "react";
import ClassList from "../CommonPages/ClassList";
import CreateClass from "../CommonPages/CreateClass";
import ClassUpload from "./ClassUpload";
import FeeCollectionReport from "./FeeCollectionReport";
import DefaultersList from "./DefaultersList";
import OverallFeeReport from "./OverallFeeReport";
import ConcessionFeeReport from "./ConcessionFeeReport";

function FeeReportManagement() {
  const [activeTab, setActiveTab] = useState("classlist");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "classlist" ? "active" : ""
              }`}
              onClick={() => handleTabClick("classlist")}
            >
              Fee Collection Report
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "createclass" ? "active" : ""
              }`}
              onClick={() => handleTabClick("createclass")}
            >
              Defaulters Report
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === "overall" ? "active" : ""}`}
              onClick={() => handleTabClick("overall")}
            >
              Overall Report
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "concession" ? "active" : ""
              }`}
              onClick={() => handleTabClick("concession")}
            >
              Concession Report
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === "classlist" ? "active" : ""}`}
            id="classlist"
          >
            {activeTab === "classlist" && <FeeCollectionReport />}
          </div>

          <div
            className={`tab-pane ${activeTab === "overall" ? "active" : ""}`}
            id="overall"
          >
            {activeTab === "overall" && <OverallFeeReport />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "createclass" ? "active" : ""
            }`}
            id="createclass"
          >
            {activeTab === "createclass" && <DefaultersList />}
          </div>
          <div
            className={`tab-pane ${activeTab === "concession" ? "active" : ""}`}
            id="concession"
          >
            {activeTab === "concession" && <ConcessionFeeReport />}
          </div>
        </div>
      </div>
    </>
  );
}

export default FeeReportManagement;
