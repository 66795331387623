import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
function SchoolManagerSidebar() {
  const [openMenu, setOpenMenu] = useState("");
  const location = useLocation();
  const schoolSetupPaths = [
    "/school-manager/school-list",
    "/school-manager/subject-list",
    "/school-manager/school-edit",
    "/school-manager/school-view",
    "/school-manager/school/",
  ];
  const adminSettingsPaths = [
    "/admin/academic-list",
    "/admin/language-list",
    "/admin/qualification-list",
    "/admin/department-list",
    "/admin/medium-list",
    "/admin/group-list",
    "/admin/remarks",
  ];
  const feesPaths = ["/admin/fees-dashboard", "/admin/fees-timelines"];
  const reportPaths = [
    "/admin/reportcard-dashboard",
    "/admin/exam-setup",
    "/admin/exam-grade-setup",
    "/admin/exam-report-lock",
  ];
  const idCardPaths = ["/admin/id-card"];
  const certificatePaths = [
    "/admin/certificate-list",
    "/admin/certificate-field-list",
  ];
  const jobsPaths = ["/admin/job-details", "/admin/job-notification-details"];

  const libraryPaths = [
    {
      path: `/library`,
      label: "Books",
      icon: "fas fa-book",
    },
  ];
  useEffect(() => {
    if (location.pathname === "/schooldashboard") {
      setOpenMenu("schooldashboard");
    } else if (
      location.pathname.startsWith("/school-manager/school-edit") ||
      location.pathname.startsWith("/school-manager/school-view") ||
      location.pathname.includes("/school-manager/school/") ||
      schoolSetupPaths.includes(location.pathname)
    ) {
      setOpenMenu("schoolSetup");
    } else if (adminSettingsPaths.includes(location.pathname)) {
      setOpenMenu("adminSettings");
    } else if (feesPaths.includes(location.pathname)) {
      setOpenMenu("fees");
    }
    else if (reportPaths.includes(location.pathname)) {
      setOpenMenu("report");
    } else if (idCardPaths.includes(location.pathname)) {
      setOpenMenu("template");
    } else if (certificatePaths.includes(location.pathname)) {
      setOpenMenu("certificate");
    } else if (libraryPaths.includes(location.pathname)) {
      setOpenMenu("library");
    } else if (jobsPaths.includes(location.pathname)) {
      setOpenMenu("jobs");
    } else {
      setOpenMenu("");
    }
  }, [location.pathname]);

  const handleToggle = (menu) => {
    if (openMenu === menu) {
      setOpenMenu("");
    } else {
      setOpenMenu(menu);
    }
  };
  const getActiveLinkStyles = (path) => ({
    backgroundColor: location.pathname === path ? "white" : "",
    color: location.pathname === path ? "#808080" : "",
  });

  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to="/school-manager/admin-dashboard" className="brand-link">
          <img
            src="/plugins/dist/img/Logo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
          />
          <span className="brand-text font-weight-light">SR EDU KYC</span>
        </Link>

        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* School Setup */}
              <li
                className={`nav-item has-treeview ${openMenu === "schoolSetup" ? "menu-open" : ""
                  }`}
              >
                <a
                  className={`nav-link ${openMenu === "schoolSetup" ? "active" : ""
                    }`}
                  onClick={() => handleToggle("schoolSetup")}
                  style={{
                    backgroundColor:
                      openMenu === "schoolSetup" ? "#007bff" : "",
                    color: openMenu === "schoolSetup" ? "white" : "",
                  }}
                >
                  <i className="nav-icon fas fa-building"></i>
                  <p>
                    School Setup
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "schoolSetup" && (
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        to="/school-manager/school-list"
                        className={`nav-link ${location.pathname.startsWith("/school-manager/school-list") ||
                          location.pathname.startsWith("/school-manager/school-edit") ||
                          location.pathname.startsWith("/school-manager/school-view") ||
                          location.pathname.includes("/school-manager/school/")
                          ? "active-sub"
                          : ""
                          }`}
                        style={getActiveLinkStyles("/school-manager/school-list")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>School List</p>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
}
export default SchoolManagerSidebar;