import * as XLSX from "xlsx";

const handleDownload = () => {
  const fileName = "schools.xlsx";
  const headers = [
    "Name",
    "School Code",
    "Address",
    "City",
    "District",
    "State",
    "Pin Code",
    "Status",
    "Branch Name",
    "Branch Code",
    "Email",
    "Phone",
    "Branch Address",
    "Branch City",
    "Branch District",
    "Branch Pincode",
    "status",
    "Affialiation No",
  ];
  const data = [
    [
      "School A",
      "SCH001",
      "123 Main St",
      "New York",
      "NY",
      "telamgana",
      "100001",
      "1",
      "Branch A",
      "Br101",
      "branch1@gmail.com",
      "9390404583",
      "124 Main St",
      "New York",
      "NY",
      "100901",
      "1",
      "A01",
    ],
    [
      "School B",
      "SCH002",
      "456 Elm St",
      "Los Angeles",
      "CA",
      "California",
      "900001",
      "1",
      "Branch B",
      "Br102",
      "branch2@gmail.com",
      "9390404584",
      "457 Elm St",
      "Los Angeles",
      "California",
      "900802",
      "1",
      "A02",
    ],
  ];

  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Schools");

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export default handleDownload;
