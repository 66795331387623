import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import {
  createholiday,
  getRoles,
} from "../../Services/CommonApis/ParentPortalApi";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";

const validationSchema = Yup.object({
  from_date: Yup.date().required("From date is required"),
  to_date: Yup.date().required("To date is required"),
  name: Yup.string().required("Holiday name is required"),
  is_holiday: Yup.string().required("Holiday day is required"),
  type: Yup.string().required("Holiday type is required"),
  // assign_to:Yup.string().required("Assignee is Required"),
});

const CreateHoliday = () => {
  const { branchId } = useParams();
  const [roles, setRoles] = useState([]);

  const fetchRoles = async () => {
    try {
      const response = await getRoles();
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
      showErrorAlert("Error", "Unable to fetch roles. Please try again later.");
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const handleSubmit = async (values) => {
    showLoadingAlert();
    try {
      const formattedFromDate = formatDate(values.from_date);
      const formattedToDate = formatDate(values.to_date);

      const formData = new FormData();
      formData.append("from_date", formattedFromDate);
      formData.append("to_date", formattedToDate);
      formData.append("name", values.name);
      formData.append("is_holiday", values.is_holiday);
      formData.append("type", values.type);
      formData.append("image", values.image);

      values.assign_to.forEach((assign) =>
        formData.append("assign_to[]", assign)
      );

      const response = await createholiday(formData);

      showSuccessAlert("Success", response.message);
      window.location.reload();
    } catch (err) {
      console.error("API error:", err);

      let errorMessage = "An unexpected error occurred. Please try again.";
      if (err.response) {
        errorMessage = err.response.data.error || err.response.data.message;
      } else if (err.request) {
        errorMessage = "No response from the server. Please try again.";
      }

      showErrorAlert("Error", errorMessage);
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-primary">
        <div className="card-header">
          <h3 className="card-title">
            Holiday Creation <small>Form</small>
          </h3>
        </div>
        <Formik
          initialValues={{
            from_date: "",
            to_date: "",
            name: "",
            is_holiday: "",
            type: "",
            image: null,
            assign_to: [],
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="form-card">
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-6">
                      <label>
                        Holiday Name <span className="mandatory">*</span>
                      </label>
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter holiday name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-error"
                      />
                    </div>
                    <div className="form-group col-6">
                      <label>
                        From Date <span className="mandatory">*</span>
                      </label>
                      <Field
                        type="date"
                        name="from_date"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="from_date"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-6">
                      <label>
                        To Date <span className="mandatory">*</span>
                      </label>
                      <Field
                        type="date"
                        name="to_date"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="to_date"
                        component="div"
                        className="text-error"
                      />
                    </div>
                    <div className="form-group col-6">
                      <label>
                        Holiday Day <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        name="is_holiday"
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("is_holiday", e.target.value); 
                          if (e.target.value === "0") {
                            setFieldValue("type", "Special Day"); 
                          } else {
                            setFieldValue("type", "");
                          }
                        }}
                      >
                        <option value="">Select Day</option>
                        <option value="0">Working Day</option>
                        <option value="1">Holiday</option>
                      </Field>
                      <ErrorMessage
                        name="is_holiday"
                        component="div"
                        className="text-error"
                      />
                    </div>
                    <div className="form-group col-6">
                      <label>
                        Holiday Type <span className="mandatory">*</span>
                      </label>
                      <Field
                        as="select"
                        name="type"
                        className="form-control"
                        disabled={values.is_holiday === "0"}
                      >
                        <option value="">Select Type</option>
                        {values.is_holiday === "1" && (
                          <>
                            <option value="General Holiday">
                              General Holiday
                            </option>
                            <option value="Festival Holiday">
                              Festival Holiday
                            </option>
                          </>
                        )}
                        <option value="Special Day">Special Day</option>
                      </Field>
                      <ErrorMessage
                        name="type"
                        component="div"
                        className="text-error"
                      />
                    </div>
                    <div className="form-group col-6">
                      <label>Holiday Image</label>
                      <input
                        type="file"
                        name="image"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue("image", event.target.files[0]);
                        }}
                      />
                      <ErrorMessage
                        name="image"
                        component="div"
                        className="text-error"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>
                      Assign Notifications:<span className="mandatory">*</span>
                    </label>
                    <div className="row">
                      {roles.map((role) => (
                        <div className="col-md-3" key={role.id}>
                          <label>
                            <input
                              type="checkbox"
                              value={role.id}
                              className="mr-2"
                              onChange={(e) => {
                                const checked = e.target.checked;
                                const value = parseInt(e.target.value, 10);
                                setFieldValue(
                                  "assign_to",
                                  checked
                                    ? [...values.assign_to, value]
                                    : values.assign_to.filter(
                                        (v) => v !== value
                                      )
                                );
                              }}
                              checked={values.assign_to.includes(role.id)}
                            />
                            {role.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateHoliday;
