import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { getDistByStateId, getStates } from "../../Services/AdminApis/StateandDistrictApi";
import InputMask from "react-input-mask";
const UserDetails = ({ current, steps }) => {
  const { values, setFieldValue, touched, errors } = useFormikContext();
  const [tempstates, setTempstates] = useState([]);

  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [tempStates, setTempStates] = useState([]);
  const [tempDistricts, setTempDistricts] = useState([]);
  const [isDistrictLoading, setIsDistrictLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [isSameAddress, setIsSameAddress] = useState(false);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsSameAddress(checked);
    if (checked) {
      setFieldValue("temp_city", values.city);
      setFieldValue("temp_state", values.state);
      setFieldValue("temp_dist", values.dist);
      setFieldValue("temp_pin", values.pin);
      setFieldValue("tmp_address", values.address);

      if (values.state) {
        fetchDistricts(values.state, false); // For main address
      }
    } else {
      setFieldValue("temp_city", "");
      setFieldValue("temp_state", "");
      setFieldValue("temp_dist", "");
      setFieldValue("temp_pin", "");
      setFieldValue("tmp_address", "");
    }
  };

  useEffect(() => {
    fetchStatesAndDistricts(); // Load both states and districts on mount
  }, []);

  const fetchStatesAndDistricts = async () => {
    try {
      setIsStateLoading(true);
      const response = await getStates();
      setStates(response.data.states);
      setTempStates(response.data.states); // For temporary state
    } catch (error) {
      console.error("Error fetching states:", error);
    } finally {
      setIsStateLoading(false);
    }
  };
  const getBorderColor = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? "red" : "skyblue";
  };

  const fetchDistricts = async (stateId, isTemp = false) => {
    try {
      setIsDistrictLoading(true);
      const response = await getDistByStateId(stateId);
      if (isTemp) {
        setTempDistricts(response.data.data);
      } else {
        setDistricts(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching districts:", error);
    } finally {
      setIsDistrictLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setSelectedFileName(file.name);
      setFieldValue("image", file);
    }
  };

  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      <fieldset>
        <div className="form-card">
          <div className="row">
            <div className="col-7">
              <h2 className="fs-title mt-3 ml-3">User Details:</h2>
            </div>
            <div className="col-5">
              <h2 className="steps mr-3 mt-3">Step 4 - {steps - 1}</h2>
            </div>
          </div>

          <div className="row ml-3 mr-3 mt-3">
            <div className="form-group col-6">
              <label htmlFor="aadhaar_card_no">
                Aadhaar Card No <span className="mandatory">* </span>
              </label>
              <Field name="aadhaar_card_no">
                {({ field }) => (
                  <InputMask
                    {...field}
                    mask="9999-9999-9999"
                    maskChar=" "
                    className="form-control"
                    id="aadhaar_card_no"
                    style={{
                      WebkitAppearance: "none",
                      MozAppearance: "textfield",
                      borderColor: getBorderColor("aadhaar_card_no"),
                      letterSpacing: "5px"
                    }}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="aadhaar_card_no"
                component="div"
                className="text-danger"
              />
            </div>

            {/* PAN Card Field */}
            <div className="form-group col-6">
              <label htmlFor="pan_card_no">
                PAN Card No <span className="mandatory">* </span>
              </label>
              <Field name="pan_card_no">
                {({ field }) => (
                  <InputMask
                    {...field}
                    mask="AAAAA-9999A"
                    formatChars={{
                      A: "[A-Za-z]", // Allows both uppercase and lowercase letters
                      9: "[0-9]", // Allows digits only
                    }}
                    maskChar="" // Prevents placeholder characters
                    onChange={(e) => {
                      const upperCaseValue = e.target.value.toUpperCase();
                      setFieldValue("pan_card_no", upperCaseValue);
                    }}
                    className="form-control"
                    id="pan_card_no"
                    style={{
                      borderColor: getBorderColor("pan_card_no"),
                      letterSpacing: "5px",
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="pan_card_no"
                component="div"
                className="text-danger"
              />
            </div>
          </div>

          <div className="row ml-3 mr-3 mt-3">
            <div className="form-group col-6">
              <label htmlFor="city">
                City <span className="mandatory">* </span>{" "}
              </label>
              <Field
                type="text"
                className="form-control"
                id="city"
                name="city"
                placeholder="Enter city"
                style={{ borderColor: getBorderColor("city") }}
              />
              <ErrorMessage
                name="city"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="state">
                State <span className="mandatory">* </span>{" "}
              </label>
              <Field
                as="select"
                className="form-control"
                id="state"
                name="state"
                style={{
                  borderColor: getBorderColor("state"),
                  borderRadius: "0px",
                }}
                onChange={(e) => {
                  const stateId = e.target.value;
                  setFieldValue("state", stateId);
                  fetchDistricts(stateId); // Fetch districts for main state
                }}
              >
                {isStateLoading ? (
                  <option value="" disabled>
                    Loading...
                  </option>
                ) : (
                  <>
                    <option value="" label="Select State" />
                    {states.map((state) => (
                      <option key={state.id} value={state.id} label={state.name} />
                    ))}
                  </>
                )}
              </Field>
              <ErrorMessage name="state" component="div" className="text-danger" />
            </div>

            <div className="form-group col-6">
              <label htmlFor="dist">
                District <span className="mandatory">* </span>{" "}
              </label>
              <Field
                as="select"
                className="form-control"
                id="dist"
                name="dist"
                style={{
                  borderColor: getBorderColor("dist"),
                  borderRadius: "0px",
                }}
              >
                {isDistrictLoading ? (
                  <option value="" disabled>
                    Loading...
                  </option>
                ) : (
                  <>
                    <option value="" label="Select District" />
                    {districts.map((dist) => (
                      <option key={dist.id} value={dist.id} label={dist.name} />
                    ))}
                  </>
                )}
              </Field>
              <ErrorMessage name="dist" component="div" className="text-danger" />
            </div>
            <div className="form-group col-6">
              <label htmlFor="pin">
                Pin <span className="mandatory">* </span>{" "}
              </label>
              <Field
                type="number"
                className="form-control"
                id="pin"
                name="pin"
                placeholder="Enter pin"
                onInput={(e) => {
                  e.target.value = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 6);
                }}
                style={{
                  borderColor: getBorderColor("pin"),
                  WebkitAppearance: "none",
                  MozAppearance: "textfield",
                }}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                    e.preventDefault();
                  }
                }}
              />
              <ErrorMessage
                name="pin"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
          <div className="row ml-3 mr-3 mt-3">
            <div className="form-group col-12">
              <label htmlFor="address">
                Address <span className="mandatory">* </span>{" "}
              </label>
              <Field
                as="textarea"
                className="form-control"
                id="address"
                name="address"
                placeholder="Enter address"
                style={{ borderColor: getBorderColor("address") }}
              />
              <ErrorMessage
                name="address"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
          <div className="form-group col-12 ml-3">
            <input
              type="checkbox"
              id="sameAsPermanent"
              checked={isSameAddress}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="sameAsPermanent" className="ml-2">
              Same as Permanent Address
            </label>
          </div>
          <h5 className="ml-4 mt-2">Temporary Address</h5>
          <div className="row ml-3 mr-3 mt-3">
            <div className="form-group col-6">
              <label htmlFor="temp_city">
                City <span className="optional">(Optional)</span>
              </label>
              <Field
                type="text"
                className="form-control"
                id="temp_city"
                name="temp_city"
                placeholder="Enter temporary city"
                style={{ borderColor: "skyblue" }}
              />
              <ErrorMessage
                name="temp_city"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="temp_state">
                State<span className="optional">(Optional)</span>
              </label>
              <Field
                as="select"
                className="form-control"
                id="temp_state"
                name="temp_state"
                placeholder="Enter temporary state"
                style={{ borderColor: "skyblue" }}
                onChange={(e) => {
                  const tempstateId = e.target.value;
                  setFieldValue("temp_state", tempstateId);
                  fetchDistricts(tempstateId, true); // Fetch districts for temporary state
                }}
              >
                {isStateLoading ? (
                  <option value="" disabled>
                    Loading...
                  </option>
                ) : (
                  <>
                    <option value="" label="Select State" />
                    {tempStates.map((temp_state) => (
                      <option key={temp_state.id} value={temp_state.id} label={temp_state.name} />
                    ))}
                  </>
                )}
              </Field>
              <ErrorMessage name="temp_state" component="div" className="text-danger" />
            </div>

            <div className="form-group col-6">
              <label htmlFor="temp_dist">
                District<span className="optional">(Optional)</span>
              </label>
              <Field
                as="select"
                className="form-control"
                id="temp_dist"
                name="temp_dist"
                placeholder="Enter temporary district"
                style={{ borderColor: "skyblue" }}
              >
                {isDistrictLoading ? (
                  <option value="" disabled>
                    Loading...
                  </option>
                ) : (
                  <>
                    <option value="" label="Select District" />
                    {tempDistricts.map((temp_dist) => (
                      <option key={temp_dist.id} value={temp_dist.id} label={temp_dist.name} />
                    ))}
                  </>
                )}
              </Field>
              <ErrorMessage name="temp_dist" component="div" className="text-danger" />
            </div>
            <div className="form-group col-6">
              <label htmlFor="temp_pin">
                Pin <span className="optional">(Optional)</span>
              </label>
              <Field
                type="text"
                className="form-control"
                id="temp_pin"
                name="temp_pin"
                placeholder="Enter temporary pin"
                style={{ borderColor: "skyblue" }}
              />
              <ErrorMessage
                name="temp_pin"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
          <div className="row ml-3 mr-3 mt-3">
            <div className="form-group col-12">
              <label htmlFor="tmp_address">
                Address <span className="optional">(Optional)</span>
              </label>
              <Field
                as="textarea"
                className="form-control"
                id="tmp_address"
                name="tmp_address"
                placeholder="Enter temporary address"
                style={{ borderColor: "skyblue" }}
              />
              <ErrorMessage
                name="tmp_address"
                component="div"
                className="text-danger"
              />
            </div>
          </div>
          <div className="row ml-3 mr-3 mt-3">
            <div className="form-group col-6">
              <label htmlFor="image">
                Upload Image<span className="optional">(Optional)</span>
              </label>
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="image"
                    name="image"
                    onChange={handleFileChange}
                    style={{ borderColor: "skyblue" }}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="image"
                    style={{ borderColor: "skyblue" }}
                  >
                    {selectedFileName || "Choose file"}
                  </label>
                </div>
              </div>
              <ErrorMessage
                name="image"
                component="div"
                className="text-error"
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default UserDetails;
