import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import {
  getStates,
  getDistByStateId,
} from "../../Services/AdminApis/StateandDistrictApi";

const UserDetails = ({ steps, current }) => {
  const { setFieldValue, values, errors, touched } = useFormikContext();
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const { id } = useParams();
  const [selectedState, setSelectedState] = useState("");
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [isDistrictLoading, setIsDistrictLoading] = useState(false);
  useEffect(() => {
    fetchStates();
    if (selectedState) {
      fetchDistrict(selectedState);
    }
  }, [selectedState]);


  const fetchStates = async () => {
    try {
      setIsStateLoading(true);
      const response = await getStates();
      setStates(response.data.states);
    } catch (error) {
      console.error("Error fetching states:", error);
    } finally {
      setIsStateLoading(false);
    }
  };

  const fetchDistrict = async (stateId) => {
    if (!stateId) return;
    try {
      setIsDistrictLoading(true);
      const response = await getDistByStateId(stateId);
      setDistricts(response.data.data);
    } catch (error) {
      console.error("Error fetching districts:", error);
    } finally {
      setIsDistrictLoading(false);
    }
  };


  const getBorderColor = (fieldName) => {
    return touched[fieldName] && errors[fieldName] ? "red" : "skyblue";
  };
  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsSameAddress(checked);

    if (checked) {
      setFieldValue("sttemp_city", values.stcity);
      setFieldValue("sttemp_state", values.ststate);
      setFieldValue("sttemp_dist", values.stdist);
      setFieldValue("sttemp_pin", values.stpin);
      setFieldValue("sttemp_country", values.stcountry);
      setFieldValue("sttmp_address", values.staddress);

      if (values.ststate) {
        fetchDistrict(values.ststate);
      }
    } else {
      setFieldValue("sttemp_city", "");
      setFieldValue("sttemp_state", "");
      setFieldValue("sttemp_dist", "");
      setFieldValue("sttemp_pin", "");
      setFieldValue("sttemp_country", "");
      setFieldValue("sttmp_address", "");
    }
  };
  return (
    <div
      className="form-step"
      style={{ border: "2px solid lightskyblue", borderRadius: "8px" }}
    >
      {current === (id ? 4 : 5) && (
        <fieldset>
          <div className="form-card">
            <div className="row">
              <div className="col-7">
                <h2 className="fs-title mt-3 ml-3">Address :</h2>
              </div>
              <div className="col-5">
                <h2 className="steps mr-3 mt-3">Step 4 - {steps - 1}</h2>
              </div>
            </div>
            <h4 className="fs-title mt-2 ml-3">Permanent Address</h4>
            <div className="row ml-3 mr-3 mt-3">
              {/* Address Input */}
              <div className="form-group col-8">
                <label htmlFor="staddress">
                  Address <span className="mandatory">* </span>
                </label>
                <Field
                  type="textarea"
                  className="form-control"
                  id="staddress"
                  name="staddress"
                  placeholder="Enter address"
                  style={{ borderColor: getBorderColor('staddress') }}
                />
                <ErrorMessage
                  name="staddress"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="form-group col-4">
                <label htmlFor="stcity">
                  City <span className="mandatory">* </span>{" "}
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="stcity"
                  name="stcity"
                  placeholder="Enter city"
                  style={{ borderColor: getBorderColor('stcity') }}
                />
                <ErrorMessage
                  name="stcity"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div
                className="form-group col-12"
              >
                <div className="row">
                  <div className="form-group col-4">
                    <label htmlFor="ststate">
                      State <span className="mandatory">* </span>{" "}
                    </label>
                    <Field
                      as="select"
                      className="form-control"
                      id="ststate"
                      name="ststate"
                      placeholder="Enter state"
                      style={{ borderColor: getBorderColor('ststate') }}
                      onChange={(e) => {
                        const ststateId = e.target.value;
                        setFieldValue("ststate", ststateId);
                        fetchDistrict(ststateId);
                      }}
                    >
                      {isStateLoading ? (
                        <option value="" disabled>
                          Loading...
                        </option>
                      ) : (
                        <>
                          <option value="" label="Select State" />
                          {states.map((ststate) => (
                            <option
                              key={ststate.id}
                              value={ststate.id}
                              label={ststate.name}
                            />
                          ))}
                        </>
                      )}
                    </Field>
                    <ErrorMessage
                      name="ststate"
                      component="div"
                      className="text-danger"
                      style={{ borderColor: getBorderColor('ststate') }}
                    />
                  </div>
                  <div className="form-group col-4">
                    <label htmlFor="stdist">District <span className="mandatory">* </span>

                    </label>
                    <Field
                      as="select"
                      className="form-control"
                      id="stdist"
                      name="stdist"
                      style={{
                        borderColor: getBorderColor("stdist"),
                      }}
                    >
                      {isDistrictLoading ? (
                        <option value="" disabled>
                          Loading...
                        </option>
                      ) : (
                        <>
                          <option value="" label="Select District" />
                          {districts.map((stdist) => (
                            <option
                              key={stdist.id}
                              value={stdist.id}
                              label={stdist.name}
                            />
                          ))}
                        </>
                      )}
                    </Field>
                    <ErrorMessage
                      name="stdist"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  {/* Pin Input */}
                  <div className="form-group col-4">
                    <label htmlFor="stpin">
                      Pin <span className="mandatory">* </span>
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="stpin"
                      name="stpin"
                      onChange={(e) => {
                        let value = e.target.value.replace(/[^0-9]/g, "");
                        if (value.length > 6) {
                          value = value.slice(0, 6);
                        }
                        setFieldValue("stpin", value);
                      }}
                      placeholder="Enter pin"
                      style={{
                        borderColor: getBorderColor("stpin"),
                        borderRadius: "0px",
                      }}
                    />
                    <ErrorMessage
                      name="stpin"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group col-12">
                <input
                  type="checkbox"
                  id="sameAsPermanent"
                  checked={isSameAddress}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="sameAsPermanent" className="ml-2">
                  Same as Permanent Address
                </label>
              </div>

              <div className="form-group col-12">
                <h3 className="fs-title mt-2">Temporary Address</h3>
              </div>

              <div className="row col-12">


                {/* Address Input */}
                <div className="form-group col-8">
                  <label htmlFor="sttmp_address">
                    Address
                  </label>
                  <Field
                    type="textarea"
                    className="form-control"
                    id="sttmp_address"
                    name="sttmp_address"
                    placeholder="Enter address"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="sttmp_address"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group col-4">
                  <label htmlFor="temp_city">City</label>
                  <Field
                    type="text"
                    className="form-control"
                    id="sttemp_city"
                    name="sttemp_city"
                    placeholder="Enter city"
                    style={{ borderColor: "skyblue" }}
                  />
                  <ErrorMessage
                    name="sttemp_city"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="form-group col-4">
                <label htmlFor="sttemp_state">
                  State
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="sttemp_state"
                  name="sttemp_state"
                  placeholder="Enter state"
                  style={{
                    borderColor: getBorderColor("sttemp_state"),
                    borderRadius: "0px",
                  }}
                  onChange={(e) => {
                    const sttempstateId = e.target.value;
                    setFieldValue("sttemp_state", sttempstateId);
                    fetchDistrict(sttempstateId);
                  }}
                >
                  {isStateLoading ? (
                    <option value="" disabled>
                      Loading...
                    </option>
                  ) : (
                    <>
                      <option value="" label="Select State" />
                      {states.map((ststate) => (
                        <option
                          key={ststate.id}
                          value={ststate.id}
                          label={ststate.name}
                        />
                      ))}
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name="sttemp_state"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="form-group col-4">
                <label htmlFor="sttemp_dist">
                  District
                </label>
                <Field
                  as="select"
                  className="form-control"
                  id="sttemp_dist"
                  name="sttemp_dist"
                  placeholder="Enter temporary district"
                  style={{ borderColor: "skyblue" }}
                >
                  {isDistrictLoading ? (
                    <option value="" disabled>
                      Loading...
                    </option>
                  ) : (
                    <>
                      <option value="" label="Select District" />
                      {districts.map((sttemp_dist) => (
                        <option
                          key={sttemp_dist.id}
                          value={sttemp_dist.id}
                          label={sttemp_dist.name}
                        />
                      ))}
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name="sttemp_dist"
                  component="div"
                  className="text-danger"
                />
              </div>
              {/* Pin Input */}
              <div className="form-group col-4">
                <label htmlFor="sttemp_pin">
                  Pin
                </label>
                <Field
                  type="text"
                  className="form-control"
                  id="sttemp_pin"
                  name="sttemp_pin"
                  placeholder="Enter pin"
                  style={{ borderColor: "skyblue" }}
                />
                <ErrorMessage
                  name="sttemp_pin"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
          </div>
        </fieldset>
      )}
    </div>
  );
};
export default UserDetails;