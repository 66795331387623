import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
    showSweetAlert,
    showErrorAlert,
    showLoadingAlert,
    showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import { getAllReturntype } from "../../Services/AdminApis/SchoolManagerApi";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import { Modal, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getAllInventory } from "../../Services/CommonApis/InventoryApi";
import { returnInventoryById } from "../../Services/CommonApis/InventoryApi";
import { getInventorytById } from "../../Services/CommonApis/InventoryApi";
const InventoryList = () => {
    const tableRef = useRef(null)
    const tableInstance = useRef(null)
    const [showEditModal, setShowEditModal] = useState(false)
    const [editInventoryId, setEditInventoryId] = useState(null)
    const { branchId } = useParams()
    const [inventoryStatus, setInventoryStatus] = useState("")
    const [inventoryDate, setInventoryDate] = useState("")
    const [inventoryComments, setInventoryComments] = useState("")
    const [returnType, setReturnType] = useState([])
    const handleEdit = async (id) => {
        try {
            const response = await getInventorytById(id)
            const { return_comments, return_date, return_status } = response
            setEditInventoryId(id)
            setInventoryStatus(return_status)
            setInventoryDate(return_date)
            setInventoryComments(return_comments)
            setShowEditModal(true)
        } catch (error) {
            console.error("Error fetching inventory details:", error)
            showErrorAlert("Error", "Unable to fetch inventory details.")
        }
    }
    const handleUpdate = async (values) => {
        const result = await showSweetAlert(
            "Are you sure?",
            "You want to update the Inventory Status.",
            "info",
            "Yes, Proceed",
            "No, cancel!",
        )
        if (result.isConfirmed) {
            showLoadingAlert()
            try {
                const formattedReturnDate = new Date(values.return_date).toLocaleDateString("en-GB")
                const response = await returnInventoryById(editInventoryId, {
                    return_status: values.return_status,
                    return_date: formattedReturnDate,
                    return_comments: values.return_comments,
                })

                if (response.data && response.data.message === "Asset returned successfully") {
                    Swal.close()
                    showSuccessAlert("Success!", "The Inventory status has been updated.")
                    setShowEditModal(false)
                    if (tableInstance.current) {
                        tableInstance.current.ajax.reload(null, false)
                    }
                } else {
                    throw new Error("Unexpected response from server")
                }
            } catch (err) {
                Swal.close()
                showErrorAlert("Error", err.message || "An error occurred while updating.")
            }
        }
    }
    const fetchReturnTypes = async () => {
        try {
            const response = await getAllReturntype()
            setReturnType(response.data)
        } catch (error) {
            console.error("Error fetching menu list:", error)
        }
    }
    const validationSchema = Yup.object().shape({
        return_status: Yup.string().required("Return Status is required"),
        return_comments: Yup.string().required("Comments are required"),
    })
    useEffect(() => {
        fetchReturnTypes()
    }, [])
    useEffect(() => {
        tableInstance.current = $(tableRef.current).DataTable({
            destroy: true,
            columns: [
                { data: "id", title: "S.No" },
                { data: "asset_type.name", title: "Assets type" },
                { data: "asset_name", title: "Name" },
                { data: "user_first_name", title: "User Name" },
                { data: "staff_first_name", title: "Staff Name" },
                { data: "assign_date", title: "Assign Date" },
                { data: "return_status.name", title: "Return Status" },
                {
                    data: null,
                    orderable: false,
                    searchable: false,
                    render: (data, type, row) => {
                        return `
                          <div class="icon-group" style="display: flex; gap: 10px; justify-content: center;">
                              <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
                          </div>
                      `
                    },
                },
            ],
            lengthChange: true,
            order: true,
            responsive: true,
            dom:
                '<"row mb-3"<"col-md-6 text-left"l>>' +
                '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
                '<"row"<"col-sm-12"t>>' +
                '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
            buttons: [
                {
                    extend: "copy",
                    exportOptions: { columns: ":visible" },
                },
                {
                    extend: "csv",
                    exportOptions: { columns: ":visible" },
                },
                {
                    extend: "excel",
                    exportOptions: { columns: ":visible" },
                },
                {
                    extend: "pdf",
                    exportOptions: { columns: ":visible" },
                },
                {
                    extend: "print",
                    exportOptions: { columns: ":visible" },
                },
            ],
            serverSide: true,
            paging: true,
            pageLength: 10,
            lengthMenu: [
                [10, 25, 50, 75, 100, -1],
                [10, 25, 50, 75, 100, "All"],
            ],
            language: {
                paginate: {
                    previous: "Back",
                    next: "Next",
                    first: "First",
                    last: "Last",
                },
            },
            ajax: async function (data, callback) {
                try {
                    const start = data.start || 0;
                    const length = data.length || 10;
                    const page = Math.floor(start / length) + 1;
                    const orderColumnIndex =
                        data.order && data.order.length > 0 ? data.order[0].column : 0;
                    const orderDirection =
                        data.order && data.order.length > 0 ? data.order[0].dir : "desc";
                    const orderColumn = data.columns[orderColumnIndex].data;
                    const searchValue = data.search.value;

                    const response = await getAllInventory({
                        page: page,
                        _limit: length,
                        _sort: orderColumn,
                        _order: orderDirection,
                        q: searchValue,
                    });

                    const jsonData = response.data.data || [];
                    const totalCount = response.data.total || 0;

                    callback({
                        draw: data.draw,
                        recordsTotal: totalCount,
                        recordsFiltered: totalCount,
                        data: jsonData.length > 0 ? jsonData : [],
                    });
                } catch (error) {
                    console.error("Error fetching data:", error);
                    showErrorAlert(error.response.data.message);
                    setTimeout(() => {
                        callback({
                            draw: data.draw,
                            recordsTotal: 0,
                            recordsFiltered: 0,
                            data: [],
                        });
                    }, 5000);
                }
            },
        });



        $(tableRef.current).on("click", ".edit-icon", function (e) {
            e.preventDefault();
            const id = $(this).data("id");
            handleEdit(id);
        });

        return () => {
            if (tableInstance.current) {
                tableInstance.current.destroy();
            }
        };
    }, []);
    const handleFocus = (event) => {
        if (event.target.type === "date") {
          event.target.showPicker();
        }
      };
      const handleDateClick = (event) => {
        if (event.target.type === "date") {
          event.target.focus();
        }
      };

    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h1>Inventory List</h1>
                        </div>
                    </div>
                </div>
            </section>

            <div className="card ml-3 mr-3">
                <div className="card-header">
                    <h3 className="card-title">Inventory List</h3>
                </div>
                <div className="card-body">
                    <table ref={tableRef} className="table table-striped table-hover table-bordered" style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Asset Type</th>
                                <th>Name</th>
                                <th>User Name</th>
                                <th>Staff Name</th>
                                <th>Assign Date</th>
                                <th>Return Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody></tbody>
                        <tfoot>
                            <tr>
                                <th>S.No</th>
                                <th>Asset Type</th>
                                <th>Name</th>
                                <th>User Name</th>
                                <th>Staff Name</th>
                                <th>Assign Date</th>
                                <th>Return Status</th>
                                <th>Action</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <Modal show={showEditModal}
             backdrop="static"
             centered
             onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Inventory Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            return_status: inventoryStatus,
                            return_date: inventoryDate,
                            return_comments: inventoryComments,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleUpdate}
                        enableReinitialize
                    >
                        {({ handleSubmit }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="return_status">Return Status <span className="mandatory">* </span>

                                    </label>
                                    <Field
                                        as="select"
                                        name="return_status"
                                        className="form-control"
                                        style={{ borderColor: "skyblue" }}
                                    >
                                        <option value="">Select Return Status</option>
                                        {returnType.map((returntype) => {
                                            if (returntype.id !== 1) {
                                                return (
                                                    <option key={returntype.id} value={returntype.id}>
                                                        {returntype.name}
                                                    </option>
                                                );
                                            }
                                            return null;
                                        })}
                                    </Field>
                                    <ErrorMessage name="return_status" component="div" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label>Return Date</label>
                                    <Field type="date" name="return_date"
                                     className="form-control"
                                     onFocus={handleFocus}
                                     onClick={handleDateClick}
                                     />
                                    <ErrorMessage name="return_date" component="div" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label>Return Comments <span className="mandatory">* </span>
                                    </label>
                                    <Field type="text" name="return_comments" className="form-control" />
                                    <ErrorMessage name="return_comments" component="div" className="text-danger" />
                                </div>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type="submit">
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default InventoryList;