import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { getAllAssetList } from "../../Services/AdminApis/SchoolManagerApi";

const AssetModal = ({ show, onHide, initialValues, onSubmit, mode }) => {
  const [assetType, setAssetType] = useState([])
  const validationSchema = Yup.object({
    asset_type: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    purchase_price: Yup.number().required("Required"),
    purchase_date: Yup.date().required("Required"),
    stock: Yup.number().required("Required"),
  });

  const handleFocus = (event) => {
    if (event.target.type === "date") {
      event.target.showPicker();
    }
  };
  useEffect(() => {
    const fetchAssetsType = async () => {
      try {
        const response = await getAllAssetList();
        const assetTypes = response.data;
        setAssetType(assetTypes);

        if (assetTypes.length > 0 && !initialValues.asset_type) {
          initialValues.asset_type = assetTypes[0].id;
        }
      } catch (error) {
        console.error("Error fetching asset types:", error);
      }
    };
    fetchAssetsType();
  }, [initialValues]);
  const handleDateClick = (event) => {
    if (event.target.type === "date") {
      event.target.focus();
    }
  };
  return (
    <Modal show={show} 
    backdrop="static"
    centered 
    onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{mode === 'create' ? 'Create Asset' : 'Edit Asset'}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="container">
                <div className="modal-body">
                  {/* Row 1 */}
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label>Assets Type</label>
                      <span className="mandatory">*</span>
                      <Field as="select" name="asset_type" className="form-control" disabled={!assetType.length}>
                        <option value="">Select Asset Type</option>
                        {assetType.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="asset_type" component="div" className="text-danger" />
                    </div>
                    <div className="col-md-6">
                      <label>Name</label>
                      <span className="mandatory">*</span>
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter Name"
                      />
                      <ErrorMessage name="name" component="div" className="text-danger" />
                    </div>
                  </div>

                  {/* Row 2 */}
                  <div className="row g-3 mt-3">
                    <div className="col-md-6">
                      <label>Purchase Price</label>
                      <span className="mandatory">*</span>
                      <Field
                        type="number"
                        name="purchase_price"
                        className="form-control"
                        placeholder="Enter Price"
                      />
                      <ErrorMessage name="purchase_price" component="div" className="text-danger" />
                    </div>
                    <div className="col-md-6">
                      <label>Brand Model</label>
                      <Field
                        type="text"
                        name="brand_model"
                        className="form-control"
                        placeholder="Enter Brand Model"
                      />
                      <ErrorMessage name="brand_model" component="div" className="text-danger" />
                    </div>
                  </div>

                  {/* Row 3 */}
                  <div className="row g-3 mt-3">
                    <div className="col-md-6">
                      <label>Stock</label>
                      <span className="mandatory">*</span>
                      <Field
                        type="number"
                        name="stock"
                        className="form-control"
                        placeholder="Enter Stock Quantity"
                      />
                      <ErrorMessage name="stock" component="div" className="text-danger" />
                    </div>
                    <div className="col-md-6">
                      <label>Purchase Date</label>
                      <span className="mandatory">*</span>
                      <Field
                        type="date"
                        name="purchase_date"
                        className="form-control"
                        onFocus={handleFocus}
                        onClick={handleDateClick}
                      />
                      <ErrorMessage name="purchase_date" component="div" className="text-danger" />
                    </div>
                  </div>

                  {/* Conditional Row 4 */}
                  {values.asset_type !== "2" && values.asset_type !== "3" && (
                    <div className="row g-3 mt-3">
                      <div className="col-md-6">
                        <label>Serial Number</label>
                        <Field
                          type="text"
                          name="serial_number"
                          className="form-control"
                          placeholder="Enter Serial Number"
                        />
                        <ErrorMessage name="serial_number" component="div" className="text-danger" />
                      </div>
                      <div className="col-md-6">
                        <label>Last Service Date</label>
                        <Field
                          type="date"
                          name="last_service_date"
                          className="form-control"
                          onFocus={handleFocus}
                          onClick={handleDateClick}
                        />
                        <ErrorMessage name="last_service_date" component="div" className="text-danger" />
                      </div>
                    </div>
                  )}

                  {/* Row 5 */}
                  <div className="row g-3 mt-3">
                    <div className="col-md-6">
                      <label>Description</label>
                      <Field
                        type="text"
                        name="description"
                        className="form-control"
                        placeholder="Enter Description"
                      />
                      <ErrorMessage name="description" component="div" className="text-danger" />
                    </div>
                    <div className="col-md-6">
                      <label>Notes</label>
                      <Field
                        type="text"
                        name="notes"
                        className="form-control"
                        placeholder="Enter Notes"
                      />
                      <ErrorMessage name="notes" component="div" className="text-danger" />
                    </div>
                  </div>
                </div>
              </div>


            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={onHide}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                {mode === 'create' ? 'Create' : 'Update'}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default AssetModal;