import React, { useEffect, useRef, useState } from "react";
import "../../Styles/AttendanceManagement.css";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import { Modal, Button } from "react-bootstrap";
import { getAllTransportList } from "../../Services/CommonApis/TransportApi";
import { getStudentByParentId } from "../../Services/CommonApis/ParentPortalApi";
import { COOKIE } from "../../Schemas/cookieNames";
import { getSession } from "../../Services/Storage";
import { getParentId } from "../../Utility/CommonUtility/extractUserDetailsFromToken";

const ParentTransportList = () => {
    const tableRef = useRef(null);
    const [studentId, setStudentId] = useState("");
    const [transportDetails, setTransportDetails] = useState([]);
    const [selectedTransport, setSelectedTransport] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const token = getSession(COOKIE.ADMIN_TOKEN);
    const parentId = getParentId(token);

    useEffect(() => {
        if (parentId) {
            fetchStudentByParent(parentId);
        }
    }, [parentId]);

    const fetchStudentByParent = async (parentId) => {
        try {
            const response = await getStudentByParentId({ parent_id: parentId });
            const fetchedStudentId = response.data.data[0]?.id;
            setStudentId(fetchedStudentId);
            console.log("Fetched Student ID:", fetchedStudentId);
        } catch (error) {
            console.error("Error fetching student:", error);
        }
    };

    useEffect(() => {
        if (studentId) {
            fetchTransportDetails();
        }
    }, [studentId]);

    const fetchTransportDetails = async () => {
        try {
            const response = await getAllTransportList(studentId);
            const data = response.data.data ? [response.data.data] : [];
            setTransportDetails(data);
        } catch (error) {
            console.error("Error fetching transport details:", error);
        }
    };
    

    useEffect(() => {
        if (transportDetails.length > 0) {
            const table = $(tableRef.current).DataTable({
                destroy: true,
                data: transportDetails,
                columns: [
                    { data: "id", title: "ID" },
                    { data: "student_name", title: "Student Name" },
                    { data: "route_name", title: "Route Name" },
                    { data: "pilot_name", title: "Pilot Name" },
                    { data: "pilot_phone", title: "Pilot Phone" },
                    { data: "stoppage_name", title: "Stoppage Name" },
                    {
                        data: null,
                        title: "Details",
                        orderable: false,
                        searchable: false,
                        render: (data, type, row) => {
                            return `
                                <a href="#" class="view-icon" data-id="${row.id}" title="View">
                                    <i class="fas fa-eye"></i>
                                </a>`;
                        },
                    },
                ],
            });
    
            // Handle view icon click
            $(tableRef.current).on("click", ".view-icon", function (e) {
                e.preventDefault();
                const rowId = $(this).data("id");
                const transport = transportDetails.find((item) => item.id === rowId);
                if (transport) {
                    setSelectedTransport(transport);
                    setShowModal(true);
                }
            });
    
            // Cleanup on unmount
            return () => {
                if (table) table.destroy();
            };
        }
    }, [transportDetails]);
    
    

    const handleClose = () => setShowModal(false);

    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <h1>Transport List</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 style={{ paddingTop: "5px" }} className="card-title">
                                        Transport List Table
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <table
                                        ref={tableRef}
                                        className="table table-striped table-bordered"
                                        style={{ width: "100%" }}
                                    >
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Student Name</th>
                                                <th>Route Name</th>
                                                <th>Pilot Name</th>
                                                <th>Pilot Phone</th>
                                                <th>Stoppage Name</th>
                                                <th>Details</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>ID</th>
                                                <th>Student Name</th>
                                                <th>Route Name</th>
                                                <th>Pilot Name</th>
                                                <th>Pilot Phone</th>
                                                <th>Stoppage Name</th>
                                                <th>Details</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Modal for viewing transport details */}
            <Modal show={showModal}
             backdrop="static"
             centered
            onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Transport Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedTransport && (
                        <div>
                            <p><strong>Student Name:</strong> {selectedTransport.student_name}</p>
                            <p><strong>Route Name:</strong> {selectedTransport.route_name}</p>
                            <p><strong>Pilot Name:</strong> {selectedTransport.pilot_name}</p>
                            <p><strong>Pilot Phone:</strong> {selectedTransport.pilot_phone}</p>
                            <p><strong>Stoppage Name:</strong> {selectedTransport.stoppage_name}</p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ParentTransportList;
