import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import { deleteFeesPay } from "../../Services/CommonApis/FeesApi";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import { getSchools } from "../../Services/AdminApis/schoolApi";
import { getBranchBySchoolId } from "../../Services/CommonApis/branchApi";
import Swal from "sweetalert2";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import {
  createIdcard,
  deleteIdcardById,
  updateIdcardById,
  getAllids,
  getIdcardById,
} from "../../Services/AdminApis/studentIdCard";

const Idcardlist = () => {
  const tableRef = useRef(null);
  const REACT_APP_IMAGEURL = process.env.REACT_APP_IMAGEURL
  const tableInstance = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editFeesTimeLineNameName, setEditFeesTimeLineNameName] = useState("");
  const [schools, setSchools] = useState([]);
  const [branches, setBranches] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const [idcardData, setIdcardData] = useState({
    name: "",
    file_path: "",
    id_type: "",
  });

  const [isStateLoading, setIsStateLoading] = useState(false);
  const [isDistrictLoading, setIsDistrictLoading] = useState(false);
  const handledeleteIdcard = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, Cancel!"
    );
    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        const response=await deleteIdcardById(id);
        Swal.close();
        showSuccessAlert(response.data.message);
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the Id card.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The Id card is safe :)");
    }
  };

  const handlecreatetemplate = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "Do you want to create Id card?",
      "warning",
      "Yes, create it!",
      "No, Cancel!"
    );
    if (result.isConfirmed) {
      try {
        showLoadingAlert();

        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("file_path", values.file_path);
        formData.append("id_type", values.id_type);

         const response=await createIdcard(formData);

        Swal.close();
        console.error(response.data.message);
        showSuccessAlert(response.data.message);
        setShowCreateModal(false);

        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", error.response.data.message);
        console.error(error);
      }
    }
    else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The ID card is not created.");
    };
  }

  const handleEditidcard = async (id) => {
    try {
      const response = await getIdcardById(id);
      setEditId(id);
      const data = response.data;
      setIdcardData({
        name: data.name,
        file_path: data.file_path,
        id_type: data.id_type,
      });
      setShowEditModal(true);
    } catch (error) {
      console.error("Error fetching fees pay type details:", error);
      showErrorAlert("Error!", "There was an error fetching the Idcards details.");
    }
  };

  const handleUpdate = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "Do you want to update Id card?",
      "warning",
      "Yes, Update It!",
      "No, Cancel!"
    );
    if (result.isConfirmed) {
      try {
        showLoadingAlert();
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("file_path", values.file_path || "");
        formData.append("id_type", values.id_type);

        const response=await updateIdcardById(editId, formData);
        Swal.close();
        showSuccessAlert(response.data.message);
        setShowEditModal(false);
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", error.response.data.message);
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The Id card is not updated.");
    };
  };

  useEffect(() => {
    if (tableInstance.current) {
      tableInstance.current.destroy(true);
      tableInstance.current = null;
    }
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "name" },
        {
          data: "status",
          render: (data) => (data === "1" ? "Active" : "Inactive"),
        },
        {
          data: "file_path",
          render: (filePath) =>
            filePath
              ? `<img src="${REACT_APP_IMAGEURL}/${filePath}" alt="ID Image" style="width: 50px; height: auto;" />`
              : "No Image",
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "Inactive"
                ? ""
                : `<a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>`;
            const viewIcon = row.file_path
              ? `<a href="#" title="View" data-filepath="${row.file_path}" class="view-icon"><i class="fas fa-eye"></i></a>`
              : "";
            return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                 ${viewIcon}

                <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
                ${deleteIcon}
              </div>
            `;
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        { extend: "copy", exportOptions: { columns: ":visible" } },
        { extend: "csv", exportOptions: { columns: ":visible" } },
        { extend: "excel", exportOptions: { columns: ":visible" } },
        { extend: "pdf", exportOptions: { columns: ":visible" } },
        { extend: "print", exportOptions: { columns: ":visible" } },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;
          const response = await getAllids({
            _limit: length,
            page: page,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });

          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;
          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          showErrorAlert(error.response.data.message);
          callback({
            draw: data.draw,
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          });
        }
      },
    });
    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handledeleteIdcard(id);
    });
    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEditidcard(id);
    });
    $(tableRef.current).on("click", ".view-icon", function (e) {
      e.preventDefault();
      const filePath = $(this).data("filepath");
      setImagePath(`${REACT_APP_IMAGEURL}/${filePath}`);
      setShowImageModal(true); // Show the modal
    });
  }, []);

  const initialValues = {
    name: "",
    file_path: "", // ID Card Template File Path
    id_type: "", // Selected ID Card Type (Student/Staff)
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Id card name is required"),
    file_path: Yup.mixed().required("ID Card Template is required"),
    id_type: Yup.string()
      .required("ID Card Type is required")
      .oneOf(
        ["student", "staff", "report", "reciept"],
        "Invalid ID Card Type selected"
      ),
  });
  const EditvalidationSchema = Yup.object({
    name: Yup.string().required("Id card name is required"),

    id_type: Yup.string()
      .required("ID Card Type is required")
      .oneOf(["student", "staff", "report", "reciept"], "Invalid ID Card Type selected"),
  });
  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>ID Card List</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/admin/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Templates</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 className="card-title">Templates List</h3>
          <div className="card-tools">
            <button
              className="btn btn-primary float-right"
              onClick={() => setShowCreateModal(true)}
            >
              Create New
            </button>
          </div>
        </div>
        <div className="card-body">
          <table
            ref={tableRef}
            className="table table-striped table-bordered"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Status</th>
                <th>File</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Status</th>
                <th>File</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <Modal show={showImageModal} 
       backdrop="static"
       centered
      onHide={() => setShowImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imagePath} alt="ID Card" style={{ width: "100%" }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowImageModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Template</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handlecreatetemplate}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                {/* ID Card Name */}
                <div className="form-group">
                  <label htmlFor="name">ID Card Name</label>
                  <span className="mandatory">* </span>
                  <Field
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter ID Card Name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* ID Card Template Image Upload */}
                <div className="form-group">
                  <label htmlFor="file_path">Upload ID Card Template</label>
                  <span className="mandatory">* </span>
                  <input
                    type="file"
                    name="file_path"
                    className="form-control"
                    onChange={(event) => {
                      setFieldValue("file_path", event.currentTarget.files[0]);
                    }}
                  />
                  <ErrorMessage
                    name="file_path"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="id_type">Select ID Card Type</label>
                  <span className="mandatory">* </span>
                  <Field as="select" name="id_type" className="form-control">
                    <option value="">Select ID Card Type</option>
                    <option value="student">Student</option>
                    <option value="staff">Staff</option>
                    <option value="reciept">Reciept</option>
                    <option value="report">Report</option>
                  </Field>
                  <ErrorMessage
                    name="id_type"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowCreateModal(false)}
                >
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Id Card</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={idcardData}
          validationSchema={EditvalidationSchema}
          enableReinitialize={true}
          onSubmit={handleUpdate}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                {/* ID Card Name */}
                <div className="form-group">
                  <label htmlFor="name">ID Card Name</label>
                  <span className="mandatory">* </span>
                  <Field
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter ID Card Name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>

                {/* ID Card Template Image Upload */}
                <div className="form-group">
                  <label htmlFor="file_path">Upload ID Card Template</label>
                  <span className="mandatory">* </span>
                  <input
                    type="file"
                    name="file_path"
                    className="form-control"
                    onChange={(event) => {
                      setFieldValue("file_path", event.currentTarget.files[0]);
                    }}
                  />
                  <ErrorMessage
                    name="file_path"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="id_type">Select ID Card Type</label>
                  <span className="mandatory">* </span>
                  <Field as="select" name="id_type" className="form-control">
                    <option value="">Select ID Card Type</option>
                    <option value="student">Student</option>
                    <option value="staff">Staff</option>
                    <option value="reciept">Reciept</option>
                    <option value="report">Report</option>
                  </Field>
                  <ErrorMessage
                    name="id_type"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowEditModal(false)}
                >
                  Close
                </Button>
                <Button type="submit" variant="primary">
                Update
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
export default Idcardlist;
