import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import AdmissionCreate from "./Admissioncreate";
import { useParams } from "react-router-dom";
import { getAllAdmissions } from "../../Services/CommonApis/AdmissionApi";

const Admission = () => {
  const [showForm, setShowForm] = useState(false);
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const { branchId } = useParams();
  const [selectedAdmissionId, setSelectedAdmissionId] = useState(null);

  // Toggle the form
  const toggleForm = () => {
    setShowForm(!showForm);
  };

  useEffect(() => {
    // Initialize DataTable only if the tab is active
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        {
          data: null, // We set data to null since we are combining multiple fields
          render: function (data, type, row) {
            return `${row.first_name} ${row.middle_name} ${row.last_name}`;
          },
          title: "Full Name", // Optional: Set the column header
        },
        { data: "gender" },
        { data: "application_fee" }, // Matches the application_fee from the response
        { data: "admission_date" }, // Admission Date
        { data: "application_type" },

        { data: "class_name" }, // Updated to match the class_id
        { data: "admission_fee" }, // Matches the admission_fee from the response
        { data: "quota" }, // Quota information from the response
        { data: "payment_status" }, // Payment status
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const editIcon = `
            <a title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
          `;
            return `
          <div class="icon-group d-flex justify-content-center align-items-center" style="height: 100%; display: flex;">
            ${editIcon}
          </div>
        `;
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        { extend: "copy", exportOptions: { columns: ":visible" } },
        { extend: "csv", exportOptions: { columns: ":visible" } },
        { extend: "excel", exportOptions: { columns: ":visible" } },
        { extend: "pdf", exportOptions: { columns: ":visible" } },
        { extend: "print", exportOptions: { columns: ":visible" } },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      processing: true,
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllAdmissions({
            branch_id: branchId,
            page: page,
            limit: length,
            sortBy: orderColumn,
            sortOrder: orderDirection,
            search: searchValue,
          });

          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          callback({
            draw: data.draw,
            recordsTotal: 0,
            recordsFiltered: 0,
            data: [],
          });
        }
      },
    });

    // Edit icon click handler
    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      // Set the selected admission ID in state
      setSelectedAdmissionId(id);
      // Toggle the form view (show the form with the selected ID)
      setShowForm(true);
    });

    // Delete icon click handler
    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      // Handle delete logic here, like confirming and calling an API to delete the admission
    });

    return () => {
      tableInstance.current.destroy();
    };
  }, [branchId]);

  const handleSubmit = (values) => {
    // Handle form submission logic here
    console.log(values);
  };

  // const validationSchema = Yup.object({
  //   applicationNumber: Yup.string().required("Application Number is required"),
  //   academicYear: Yup.string().required("Academic Year is required"),
  //   className: Yup.string().required("Class is required"),
  //   // Add more validation rules here for other fields
  // });

  return (
    <div className="card p-3">
      <div className="d-flex justify-content-between mt-3 mb-3">
        <button
          className="btn btn-primary"
          onClick={() => setShowForm(!showForm)}
        >
          {showForm ? (
            <>
              <i className="pi pi-arrow-left mr-2"></i> Back
            </>
          ) : (
            "+ Add New"
          )}
        </button>
      </div>

      {showForm && (
        <div className="card">
          <div className="mb-3 p-4">
            <AdmissionCreate admissionId={selectedAdmissionId} />
          </div>
        </div>
      )}

      <div className="p-4">
        <h5>Admission Details</h5>
        <div className="card-body">
          <table
            ref={tableRef}
            id="announcementTable"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Full Name</th>
                <th>Gender</th>
                <th>App Fee</th>
                <th>Admission Date</th>
                <th>App Type</th>
                <th>Class</th>
                <th>Admission Fee</th>
                <th>Quota</th>
                <th>Payment</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>{/* Static data is rendered by DataTable */}</tbody>
            <tfoot>
              <tr>
                <th>ID</th>
                <th>Full Name</th>
                <th>Gender</th>
                <th>App Fee</th>
                <th>Admission Date</th>
                <th>App Type</th>
                <th>Class</th>
                <th>Admission Fee</th>
                <th>Quota</th>
                <th>Payment</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Admission;
