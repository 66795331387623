import React, { useState } from "react";
import SchoolGrid from "./SchoolGrid";
import HomeWorkCreateTab from "./HomeCreateTab";
import Homeworkreport from "./Homeworkreport";
import EventNotificationList from "../CommonPages/EventNotificationList";
import ShiftAssignment from "../CommonPages/ShiftNotifications";
function EventNotificationManagement() {
  const [activeTab, setActiveTab] = useState("listview");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <>
      <div class="card-header">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a
              className={`nav-link ${activeTab === "listview" ? "active" : ""}`}
              onClick={() => handleTabClick("listview")}
            >
              Event List
            </a>
          </li>

          <li class="nav-item">
            <a
              className={`nav-link ${
                activeTab === "eventnotification" ? "active" : ""
              }`}
              onClick={() => handleTabClick("eventnotification")}
            >
              Create Event
            </a>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="tab-content">
          <div
            className={`tab-pane ${activeTab === "listview" ? "active" : ""}`}
            id="listview"
          >
            {activeTab === "listview" && <EventNotificationList />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "eventnotification" ? "active" : ""
            }`}
            id="eventnotification"
          >
            {activeTab === "eventnotification" && <ShiftAssignment />}
          </div>
        </div>
      </div>
    </>
  );
}

export default EventNotificationManagement;
