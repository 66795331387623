import Api from "../Api";

export const createAdmission = (data) => {
  return Api.post(`/create-admissionforms`, data);
};
export const getAllAnnouncements = (branchId, params) => {
  return Api.get("/get-admission-announcements", {
    params: { branch_id: branchId, ...params },
  });
};
export const getAdmissionById = (id) => {
  return Api.get(`/get-admissionform/${id}`);
};
export const updateAdmissionById = (id, data) => {
  return Api.post(`/update-admissionform/${id}`, data);
};
export const deleteAnnouncementById = (id) => {
  return Api.delete(`/delete-admission-announcement/${id}`);
};
export const getClassByBranchId = async (branchId) => {
  console.log(branchId);
  const id = branchId;
  return await Api.get(`/get-classes-branch/${id}`);
};
export const getAllAdmissions = async (params) => {
  return await Api.get(`get-admissionforms`, { params });
};
