import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

function AdminSideBar() {
  const [openMenu, setOpenMenu] = useState("");
  const location = useLocation();
  const schoolSetupPaths = [
    "/admin/school-list",
    "/admin/subject-list",
    "/admin/school-edit",
    "/admin/school-view",
    "/admin/school/",
    "/admin/manager-list",
  ];

  const adminSettingsPaths = [
    "/admin/academic-list",
    "/admin/language-list",
    "/admin/qualification-list",
    "/admin/department-list",
    "/admin/medium-list",
    "/admin/group-list",
    "/admin/remarks",
  ];

  const dashboardsPaths = [
    "/",
    "/admin/individual-dashboard",
    "/admin/admin-dashboard",
  ];

  const userVisitsPaths = ["/admin/uservisits"];

  const feesPaths = ["/admin/fees-dashboard", "/admin/fees-timelines"];
  const reportPaths = [
    "/admin/reportcard-dashboard",
    "/admin/exam-setup",
    "/admin/exam-grade-setup",
    "/admin/exam-report-lock",
  ];

  const idCardPaths = ["/admin/id-card"];

  const certificatePaths = [
    "/admin/certificate-list",
    "/admin/certificate-field-list",
  ];
  const jobsPaths = [
    "/admin/job-details",
    "/admin/job-notification-details",
    "/admin/activity-log",
  ];

  const libraryPaths = [
    {
      path: `/library`,
      label: "Books",
      icon: "fas fa-book",
    },
  ];
  useEffect(() => {
    if (location.pathname === "/schooldashboard") {
      setOpenMenu("schooldashboard");
    } else if (
      location.pathname.startsWith("/admin/school-edit") ||
      location.pathname.startsWith("/admin/school-view") ||
      location.pathname.includes("/admin/school/") ||
      schoolSetupPaths.includes(location.pathname)
    ) {
      setOpenMenu("schoolSetup");
    } else if (adminSettingsPaths.includes(location.pathname)) {
      setOpenMenu("adminSettings");
    } else if (feesPaths.includes(location.pathname)) {
      setOpenMenu("fees");
    } else if (dashboardsPaths.includes(location.pathname)) {
      setOpenMenu("dashboards");
    } else if (userVisitsPaths.includes(location.pathname)) {
      setOpenMenu("uservisits");
    } else if (reportPaths.includes(location.pathname)) {
      setOpenMenu("report");
    } else if (idCardPaths.includes(location.pathname)) {
      setOpenMenu("template");
    } else if (certificatePaths.includes(location.pathname)) {
      setOpenMenu("certificate");
    } else if (libraryPaths.includes(location.pathname)) {
      setOpenMenu("library");
    } else if (jobsPaths.includes(location.pathname)) {
      setOpenMenu("jobs");
    } else {
      setOpenMenu("");
    }
  }, [location.pathname]);

  const handleToggle = (menu) => {
    if (openMenu === menu) {
      setOpenMenu("");
    } else {
      setOpenMenu(menu);
    }
  };
  const getActiveLinkStyles = (path) => ({
    backgroundColor: location.pathname === path ? "white" : "",
    color: location.pathname === path ? "#808080" : "",
  });

  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to="/admin/admin-dashboard" className="brand-link">
          <img
            src="/plugins/dist/img/Logo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
          />
          <span className="brand-text font-weight-light">SR EDU KYC</span>
        </Link>

        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Dashboards */}
              <li
                className={`nav-item has-treeview ${
                  openMenu === "dashboards" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    openMenu === "dashboards" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("dashboards")}
                  style={{
                    backgroundColor: openMenu === "dashboards" ? "#007bff" : "",
                    color: openMenu === "dashboards" ? "white" : "",
                  }}
                >
                  <i className="nav-icon fas fa-home"></i>
                  <p>
                    Dashboards
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "dashboards" && (
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        to="/admin/admin-dashboard"
                        className={`nav-link ${
                          location.pathname === "/admin/admin-dashboard"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/admin-dashboard")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Admin Dashboard</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/admin/individual-dashboard"
                        className={`nav-link ${
                          location.pathname === "/admin/individual-dashboard"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles(
                          "/admin/individual-dashboard"
                        )}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Individual Dashboard</p>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li
                className={`nav-item has-treeview ${
                  openMenu === "schoolSetup" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    openMenu === "schoolSetup" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("schoolSetup")}
                  style={{
                    backgroundColor:
                      openMenu === "schoolSetup" ? "#007bff" : "",
                    color: openMenu === "schoolSetup" ? "white" : "",
                  }}
                >
                  <i className="nav-icon fas fa-building"></i>
                  <p>
                    School Setup
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>

                {openMenu === "schoolSetup" && (
                  <ul className="nav nav-treeview">
                    {[
                      {
                        path: "/admin/school-list",
                        label: "School List",
                        extraPaths: [
                          "/admin/school-edit",
                          "/admin/school-view",
                          "/admin/school/",
                        ],
                      },
                      {
                        path: "/admin/subject-list",
                        label: "Subject List",
                      },
                      {
                        path: "/admin/manager-list",
                        label: "School Manager",
                      },
                    ].map(({ path, label, extraPaths = [] }) => {
                      const isActive = [path, ...extraPaths].some((p) =>
                        location.pathname.startsWith(p)
                      );
                      return (
                        <li className="nav-item" key={path}>
                          <Link
                            to={path}
                            className={`nav-link ${
                              isActive ? "active-sub" : ""
                            }`}
                            style={getActiveLinkStyles(path)}
                          >
                            <i className="far fa-circle nav-icon"></i>
                            <p>{label}</p>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>

              <li
                className={`nav-item has-treeview ${
                  openMenu === "adminSettings" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    openMenu === "adminSettings" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("adminSettings")}
                  style={{
                    backgroundColor:
                      openMenu === "adminSettings" ? "#007bff" : "",
                    color: openMenu === "adminSettings" ? "white" : "",
                  }}
                >
                  <i className="nav-icon fas fa-cogs"></i>
                  <p>
                    Admin Settings
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "adminSettings" && (
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        to="/admin/academic-list"
                        className={`nav-link ${
                          location.pathname === "/admin/academic-list"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/academic-list")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Academic List</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/admin/language-list"
                        className={`nav-link ${
                          location.pathname === "/admin/language-list"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/language-list")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Language List</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/admin/qualification-list"
                        className={`nav-link ${
                          location.pathname === "/admin/qualification-list"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/qualification-list")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Qualification List</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/admin/department-list"
                        className={`nav-link ${
                          location.pathname === "/admin/department-list"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/department-list")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Department List</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/admin/group-list"
                        className={`nav-link ${
                          location.pathname === "/admin/group-list"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/group-list")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Group List</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/admin/medium-list"
                        className={`nav-link ${
                          location.pathname === "/admin/medium-list"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/medium-list")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Medium List</p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/admin/remarks"
                        className={`nav-link ${
                          location.pathname === "/admin/remarks"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/remarks")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Remarks</p>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>

              {/* Fees */}
              <li
                className={`nav-item has-treeview ${
                  openMenu === "fees" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${openMenu === "fees" ? "active" : ""}`}
                  onClick={() => handleToggle("fees")}
                  style={{
                    backgroundColor: openMenu === "fees" ? "#007bff" : "",
                    color: openMenu === "fees" ? "white" : "",
                  }}
                  role="link"
                >
                  <i className="nav-icon fa-solid fa-indian-rupee-sign"></i>
                  <p>
                    Fees Setup
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "fees" && (
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        to="/admin/fees-dashboard"
                        className={`nav-link ${
                          location.pathname === "/admin/fees-dashboard"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/fees-dashboard")}
                        role="link"
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Fees Dashboard</p>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              
              <li
                className={`nav-item has-treeview ${
                  openMenu === "report" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    openMenu === "report" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("report")}
                  style={{
                    backgroundColor: openMenu === "report" ? "#007bff" : "",
                    color: openMenu === "report" ? "white" : "",
                  }}
                >
                  <i className="nav-icon fas fa-book-open"></i>
                  <p>
                    Report Card
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "report" && (
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        to="/admin/reportcard-dashboard"
                        className={`nav-link ${
                          location.pathname === "/admin/reportcard-dashboard"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles(
                          "/admin/reportcard-dashboard"
                        )}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>ReportCard Dashboard</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/admin/exam-setup"
                        className={`nav-link ${
                          location.pathname === "/admin/exam-setup"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/exam-setup")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Exam Setup</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/admin/exam-grade-setup"
                        className={`nav-link ${
                          location.pathname === "/admin/exam-grade-setup"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/exam-grade-setup")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Exam Grade Setup</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/admin/exam-report-lock"
                        className={`nav-link ${
                          location.pathname === "/admin/exam-report-lock"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/exam-report-lock")}
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Report Lock</p>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>

              {/* ID Cards */}
              <li
                className={`nav-item has-treeview ${
                  openMenu === "template" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    openMenu === "template" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("template")}
                  style={{
                    backgroundColor: openMenu === "template" ? "#007bff" : "",
                    color: openMenu === "template" ? "white" : "",
                  }}
                  role="link"
                >
                  <i className="nav-icon fas fa-clone"></i>
                  <p>
                    Template Setup
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "template" && (
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        to="/admin/id-card"
                        className={`nav-link ${
                          location.pathname === "template" ? "active-sub" : ""
                        }`}
                        style={getActiveLinkStyles("/admin/id-card")}
                        role="link"
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Templates</p>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li
                className={`nav-item has-treeview ${
                  openMenu === "certificate" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    openMenu === "certificate" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("certificate")}
                  style={{
                    backgroundColor:
                      openMenu === "certificate" ? "#007bff" : "",
                    color: openMenu === "certificate" ? "white" : "",
                  }}
                  role="link"
                >
                  <i className="fas fa-cog"></i>{" "}
                  <p>
                    Certificate Setup
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "certificate" && (
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        to="/admin/certificate-list"
                        className={`nav-link ${
                          location.pathname === "/admin/certificate-list"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/certificate-list")}
                        role="link"
                      >
                        <i className="far fa-circle nav-icon"></i>{" "}
                        <p>Certificate List</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/admin/certificate-field-list"
                        className={`nav-link ${
                          location.pathname === "/admin/certificate-field-list"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles(
                          "/admin/certificate-field-list"
                        )}
                        role="link"
                      >
                        <i className="far fa-circle nav-icon"></i>{" "}
                        <p>Certificate Field List</p>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li
                className={`nav-item has-treeview ${
                  openMenu === "library" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${
                    openMenu === "library" ? "active" : ""
                  }`}
                  onClick={() => handleToggle("library")}
                  style={{
                    backgroundColor: openMenu === "library" ? "#007bff" : "",
                    color: openMenu === "library" ? "white" : "",
                  }}
                  role="link"
                >
                  <i class="nav-icon fa-solid fa-building-columns"></i>{" "}
                  <p>
                    Library
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "library" && (
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        to="/admin/library"
                        className={`nav-link ${
                          location.pathname === "/admin/library"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/library")}
                        role="link"
                      >
                        <i className="far fa-circle nav-icon"></i> <p>Books</p>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li
                className={`nav-item has-treeview ${
                  openMenu === "jobs" ? "menu-open" : ""
                }`}
              >
                <a
                  className={`nav-link ${openMenu === "jobs" ? "active" : ""}`}
                  onClick={() => handleToggle("jobs")}
                  style={{
                    backgroundColor: openMenu === "jobs" ? "#007bff" : "",
                    color: openMenu === "jobs" ? "white" : "",
                  }}
                  role="link"
                >
                  <i className="nav-icon fas fa-tasks"></i>{" "}
                  <p>
                    Jobs Setup
                    <i className="fas fa-angle-left right"></i>
                  </p>
                </a>
                {openMenu === "jobs" && (
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        to="/admin/job-details"
                        className={`nav-link ${
                          location.pathname === "/admin/job-details"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/job-details")}
                        role="link"
                      >
                        <i className="far fa-circle nav-icon"></i>{" "}
                        <p>Job Details</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/admin/job-notification-details"
                        className={`nav-link ${
                          location.pathname ===
                          "/admin/job-notification-details"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles(
                          "/admin/job-notification-details"
                        )}
                        role="link"
                      >
                        <i className="far fa-circle nav-icon"></i>{" "}
                        <p>Job Notification Details</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/admin/activity-log"
                        className={`nav-link ${
                          location.pathname === "/admin/activity-log"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/activity-log")}
                        role="link"
                      >
                        <i className="far fa-circle nav-icon"></i>{" "}
                        <p>Activity Log</p>
                      </Link>
                    </li>
                    {/* blocked IP */}
                    <li className="nav-item">
                      <Link
                        to="/admin/blocked-ip"
                        className={`nav-link ${
                          location.pathname === "/admin/blocked-ip"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/blocked-ip")}
                        role="link"
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Blocked IP</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/admin/uservisits"
                        className={`nav-link ${
                          location.pathname === "/admin/uservisits"
                            ? "active-sub"
                            : ""
                        }`}
                        style={getActiveLinkStyles("/admin/uservisits")}
                        role="link"
                      >
                       <i className="fa fa-user-clock nav-icon"></i>
                        <p>User Visit Log</p>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
}

export default AdminSideBar;
