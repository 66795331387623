import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useParams } from "react-router-dom";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import { getstudentbyclassandsection } from "../../Services/CommonApis/StudentApi";
import { getClassByBranchId } from "../../Services/CommonApis/AnnouncementApi";
import {
  createConcession,
  getAllFees,
} from "../../Services/CommonApis/FeesApi";
import {
  createAcademicPayments,
  getPaymentMethod,
  getStudentAcademicFee,
} from "../../Services/CommonApis/FeesApi";
import {
  showSuccessAlert,
  showLoadingAlert,
  showErrorAlert,
} from "../../Modals/SweetAlertModel";
import { getAllConcession } from "../../Services/CommonApis/FeesApi";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import { generatePdf } from "../../Services/CommonApis/ReportcardApi";
import { useRef } from "react";

const AcademicFeePayments = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [classOptions, setClassOptions] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [sections, setSections] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [students, setStudents] = useState([]);
  const [fees, setFees] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentFees, setStudentFees] = useState([]);
  const [concessions, setConcession] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedComponet, setSelectedComponent] = useState("");

  const { schoolId, branchId } = useParams();

  useEffect(() => {
    fetchClasses(branchId);
    fetchFeeComponents(branchId);
    fetchConcession();
  }, [branchId]);

  useEffect(() => {
    setShowTable(selectedClassId && selectedSection);
  }, [selectedClassId, selectedSection]);

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      const classes = response.data.classes.map((cls) => ({
        value: cls.id,
        label: cls.name,
      }));
      setClassOptions(classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const fetchAcademicSetup = async (branchId, classId, sectionId) => {
    try {
      const params = {
        branch_id: branchId,
        class_id: classId,
        section_id: sectionId,
      };

      const response = await getStudentAcademicFee(params);
      setStudentFees(response.data.data.academic_fees);
    } catch (error) {
      console.error("Failed to fetch academic setup:", error);
    }
  };

  const fetchFeeComponents = async (branchId) => {
    try {
      const response = await getAllFees(branchId);
      setFees(response.data.data);
    } catch (error) {
      console.error("Error fetching fees:", error);
    }
  };

  const fetchConcession = async () => {
    try {
      const response = await getAllConcession();
      setConcession(response.data);
    } catch (error) {
      console.error("Error fetching fees:", error);
    }
  };

  const fetchStudents = async (branchId, classId, sectionId) => {
    try {
      const response = await getstudentbyclassandsection(
        branchId,
        classId,
        sectionId
      );
      setStudents(
        response.data.data.map((student, index) => ({
          ...student,
          concessionAmount: 0,
          index: index + 1,
        }))
      );
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  const fetchSections = async (classId) => {
    try {
      const response = await getSectionByClassId(classId);
      setSections(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  const handleSectionChange = (event, setFieldValue) => {
    const selectedSectionId = event.target.value;
    setSelectedSection(selectedSectionId);
    setFieldValue("section", selectedSectionId);
    fetchStudents(branchId, selectedClassId, selectedSectionId);
    fetchAcademicSetup(branchId, selectedClassId, selectedSectionId);
  };

  const handleFeeComponentChange = (event, setFieldValue) => {
    const selectedfcId = event.target.value;
    setSelectedComponent(selectedfcId);
    setFieldValue("fcid", selectedfcId);
  };

  useEffect(() => {
    if (showTable) {
      tableInstance.current = $(tableRef.current).DataTable({
        destroy: true,
        columns: [
          {
            data: null,
            title: "S.No",
            render: (data, type, row, meta) => meta.row + 1,
          },
          {
            data: "academic_period",
            title: "Academic Period",
          },
          {
            data: "full_name",
            title: "Student & Parent Info",
            render: (data, type, row) => {
              return `
                <div>
                  <!-- Student Name -->
                  <div>${row.full_name || "N/A"}</div>
          
                  <!-- Parent Name -->
                  <small style="color: gray; display: block;">
                    Parent: ${row.parent_name || "N/A"}
                  </small>
                  
                  <!-- Admission No -->
                  <small style="color: gray;">
                    ${row.admission_no || "N/A"}
                  </small>
                  
                  <!-- Class & Section -->
                  <small style="font-weight: bold; display: block;">
                    ${
                      row.class_name
                        ? `${row.class_name} - ${row.section_name}`
                        : "N/A"
                    }
                  </small>
                </div>
              `;
            },
          },
          {
            data: "fee_component",
            title: "Fee Component",
          },
          {
            data: "fees_sub_component",
            title: "Fee Details",
            render: (data, type, row) => {
              if (Array.isArray(data) && data.length > 0) {
                return data.map((sub) => sub.name).join(", ");
              }
              return "No Details";
            },
          },

          {
            data: "concession_amount",
            title: "Concession Amount",
          },
          {
            data: "amount",
            title: "Amount",
          },
          {
            data: "balance",
            title: "Balance",
          },
          {
            data: "amount_paid",
            title: "Amount Paid",
          },
          {
            data: null,
            orderable: false,
            searchable: false,
            render: (data, type, row) => {
              return `
      <a 
        href="/school/${schoolId}/branch/${branchId}/pay-fees/${row.class_id}/${row.student_id}/${row.fee_component_id}" 
        title="Pay Fee"
        style="display: flex; align-items: center; gap: 5px;"
      >
         <i class="fas fa-credit-card"></i>
        <span>Pay Fees</span>
      </a>
    `;
            },
            title: "Actions",
          },
        ],

        lengthChange: true,
        order: true,
        responsive: true,
        dom:
          '<"row mb-3"<"col-md-6 text-left"l>>' +
          '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
          '<"row"<"col-sm-12"t>>' +
          '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        buttons: [
          { extend: "copy", exportOptions: { columns: ":visible" } },
          { extend: "csv", exportOptions: { columns: ":visible" } },
          { extend: "excel", exportOptions: { columns: ":visible" } },
          { extend: "pdf", exportOptions: { columns: ":visible" } },
          { extend: "print", exportOptions: { columns: ":visible" } },
        ],
        serverSide: true,
        paging: true,
        pageLength: 10,
        lengthMenu: [
          [10, 25, 50, 75, 100, -1],
          [10, 25, 50, 75, 100, "All"],
        ],
        language: {
          paginate: {
            previous: "Back",
            next: "Next",
            first: "First",
            last: "Last",
          },
          emptyTable: "No Data Found",
        },
        ajax: async function (data, callback) {
          try {
            const start = data.start || 0;
            const length = data.length || 10;
            const page = Math.floor(start / length) + 1;
            const orderColumnIndex =
              data.order && data.order.length > 0 ? data.order[0].column : 0;
            const orderDirection =
              data.order && data.order.length > 0 ? data.order[0].dir : "desc";
            const orderColumn = data.columns[orderColumnIndex].data;
            const searchValue = data.search.value;

            const response = await getStudentAcademicFee({
              branch_id: branchId,
              class_id: selectedClassId,
              component_id: selectedComponet,
              section_id: selectedSection,
            });
            const jsonData = response.data.data.academic_fees || [];
            const totalCount = response.data.data.academic_fees.total || 0;
            callback({
              draw: data.draw,
              recordsTotal: totalCount,
              recordsFiltered: totalCount,
              data: jsonData.length > 0 ? jsonData : [],
            });
          } catch (error) {
            setTimeout(() => {
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }, 5000);
          }
        },
      });
    }
  }, [branchId, selectedClassId, selectedSection, showTable, selectedComponet]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Fee Details View
          </h3>
        </div>
        <Formik initialValues={{ class_id: "" }}>
          {({ setFieldValue }) => (
            <div className="card-body">
              {/* Class, Section, and Fee Dropdowns */}
              <div className="row">
                <div className="form-group col-4">
                  <label htmlFor="class_id">
                    Class Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="class_id"
                    name="class_id"
                    className="form-control"
                    onChange={(e) => {
                      const classId = e.target.value;
                      setFieldValue("class_id", classId);
                      setSelectedClassId(classId);
                      fetchSections(classId);
                    }}
                  >
                    <option value="">Select Class</option>
                    {classOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="class_id"
                    component="div"
                    className="text-error"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="section">
                    Section Name <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="section"
                    name="section"
                    className="form-control"
                    value={selectedSection}
                    onChange={(e) => handleSectionChange(e, setFieldValue)}
                    disabled={!selectedClassId}
                  >
                    <option value="">Select Section</option>
                    {sections.map((sec) => (
                      <option key={sec.id} value={sec.id}>
                        {sec.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="section"
                    component="div"
                    className="text-error"
                  />
                </div>

                <div className="form-group col-4">
                  <label htmlFor="fcid">Select Fee Component</label>
                  <Field
                    as="select"
                    name="fcid"
                    className="form-control"
                    onChange={(e) => handleFeeComponentChange(e, setFieldValue)}
                  >
                    <option value="">Select</option>
                    {fees.map((fee) => (
                      <option key={fee.id} value={fee.id}>
                        {fee.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fcid"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              {showTable && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table
                          ref={tableRef}
                          className="table table-striped table-bordered"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Student Details</th>
                              <th>Fee Component</th>
                              <th>Fee details</th>
                              <th>Concession Amount</th>
                              <th>Amount</th>
                              <th>Balance</th>
                              <th>Academic Period</th>
                              <th>Amount Paid</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                          <tfoot>
                            <tr>
                              <th>S.No</th>
                              <th>Student Details</th>
                              <th>Fee Component</th>
                              <th>Fee details</th>
                              <th>Concession Amount</th>
                              <th>Amount</th>
                              <th>Balance</th>
                              <th>Academic Period</th>
                              <th>Amount Paid</th>
                              <th>Actions</th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AcademicFeePayments;
