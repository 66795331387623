import React, { useState } from "react";
import StoppagesList from "./StoppagesList";
import Transport from "../CommonPages/CreateStoppages";
import PickUpRequest from "./PIckUpRequest";
import PickUpRequestList from "./PickUpRequestList";

function ParentTransportManagement() {
  const [activeTab, setActiveTab] = useState("pickuprequestl");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "pickuprequestl" ? "active" : ""
              }`}
              onClick={() => handleTabClick("pickuprequestl")}
            >
              Pick Up RequestList
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "addpickuprequest" ? "active" : ""
              }`}
              onClick={() => handleTabClick("addpickuprequest")}
            >
              Add PickUp Request
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${
              activeTab === "pickuprequestl" ? "active" : ""
            }`}
            id="pickuprequestl"
          >
            {activeTab === "pickuprequestl" && <PickUpRequestList />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "addpickuprequest" ? "active" : ""
            }`}
            id="addpickuprequest"
          >
            {activeTab === "addpickuprequest" && <PickUpRequest />}
          </div>
        </div>
      </div>
    </>
  );
}

export default ParentTransportManagement;
