import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import {
  showSweetAlert,
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../Modals/SweetAlertModel";
import Swal from "sweetalert2";
import {
  deleteRemarkById,
  getAllRemarks,
  createRemark,
  getRemarkById,
  updateRemarkById,
} from "../../Services/CommonApis/RemarkApi";
import { Modal, Button, Form } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";

const RemarkList = () => {
  const tableRef = useRef(null);
  const tableInstance = useRef(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editRemarkId, setEditRemarkId] = useState(null);
  const [editRemarkName, setEditRemarkName] = useState("");
  const [editRemarkBy, setEditRemarkBy] = useState("");

  const handleDelete = async (id) => {
    const result = await showSweetAlert(
      "Are you sure?",
      "You won't be able to revert this!",
      "warning",
      "Yes, delete it!",
      "No, Cancel!"
    );

    if (result.isConfirmed) {
      try {
        showLoadingAlert();
       const response= await deleteRemarkById(id);
        Swal.close();
        showSuccessAlert("Deleted!", response.message || "Data Deleted Successfully");
        if (tableInstance.current) {
           tableInstance.current.ajax.reload();
        }
      } catch (error) {
        Swal.close();
        showErrorAlert("Error!", "There was an error deleting the Remark.");
        console.error(error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The Remark is safe :)");
    }
  };


  const handleCreateRemark = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      `You want to create the Remark.`,
      "info",
      "Yes, Proceed",
      "No, Cancel!"
    );
    if (result.isConfirmed) {
      showLoadingAlert();
      const remarkData = {
        name: values.name,
        remarks_by: values.remarks_by,
      };

      try {
        const response=await createRemark(remarkData);
        Swal.close();
        showSuccessAlert("Created!",response.data.message || "Data Created Successfullly.");
        setShowCreateModal(false);
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";
        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", `The Remark has not been created.`);
    };
  }

  const handleEdit = async (id) => {
    try {
      const response = await getRemarkById(id);
      const { name, remarks_by } = response;
      setEditRemarkId(id);
      setEditRemarkName(name);
      setEditRemarkBy(remarks_by);
      setShowEditModal(true);
    } catch (error) {
      console.error("Error fetching Remark details:", error);
      showErrorAlert(
        "Error!",
        "There was an error fetching the Remark details."
      );
    }
  };

  const handleUpdateRemark = async (values) => {
    const result = await showSweetAlert(
      "Are you sure?",
      `You want to update the Remark.`,
      "info",
      "Yes, Proceed",
      "No, Cancel!"
    );
    if (result.isConfirmed) {
      showLoadingAlert();
      try {

       const response= await updateRemarkById(editRemarkId, {
          name: values.name,
          remarks_by: values.remarks_by,
        });
        Swal.close();
        showSuccessAlert("Updated!", response.data.data || "Data Updated Successfully");
        setShowEditModal(false);
        if (tableInstance.current) {
          tableInstance.current.ajax.reload();
        }
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";
        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", `The Remark has not been updated.`);
    }
  };

  useEffect(() => {
    tableInstance.current = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: "id" },
        { data: "name" },
        { data: "remarks_by" },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const deleteIcon =
              row.status === "inactive"
                ? ""
                : `<a title="Delete" data-id="${row.id}" class="delete-icon"><i class="fas fa-trash"></i></a>`;
            return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                <a href="#" title="Edit" data-id="${row.id}" class="edit-icon"><i class="fas fa-edit"></i></a>
                ${deleteIcon}
              </div>
            `;
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        {
          extend: "copy",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "csv",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "excel",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "pdf",
          exportOptions: { columns: ":visible" },
        },
        {
          extend: "print",
          exportOptions: { columns: ":visible" },
        },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: {
          previous: "Back",
          next: "Next",
          first: "First",
          last: "Last",
        },
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;

          const response = await getAllRemarks({
            page: page,
            _limit: length,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });

          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          showErrorAlert(error.response.data.message);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });

    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleDelete(id);
    });

    $(tableRef.current).on("click", ".edit-icon", function (e) {
      e.preventDefault();
      const id = $(this).data("id");
      handleEdit(id);
    });

    return () => {
      if (tableInstance.current) {
        tableInstance.current.destroy();
      }
    };
  }, []);

  const initialValues = {
    name: "",
    remarks_by: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Remark name is required"),
    remarks_by: Yup.string().required("Remark By is required"),
  });

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>Remark Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/admin/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Remark Details</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 style={{ paddingTop: "5px" }} className="card-title">
            Remark List
          </h3>
          <button
            className="btn btn-primary float-right"
            onClick={() => setShowCreateModal(true)}
          >
            <i className="fas fa-plus"></i> Create New
          </button>
        </div>
        <div className="card-body">
          <table
            ref={tableRef}
            className="table table-striped table-hover table-bordered"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Remark</th>
                <th>Remark By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>ID</th>
                <th>Remark</th>
                <th>Remark By</th>
                <th>Actions</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <Modal show={showCreateModal} 
       backdrop="static"
       centered
      onHide={() => setShowCreateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Remark</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleCreateRemark}
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label>Remark Name</label>
                  <span className="mandatory">* </span>
                  <Field
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter Remark Name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label>Remark By</label>
                  <span className="mandatory">* </span>
                  <Field as="select" name="remarks_by" className="form-control">
                    <option value="">Select Remark By</option>
                    <option value="Class Teacher">Class Teacher</option>
                    <option value="Principle">Principal</option>
                  </Field>
                  <ErrorMessage
                    name="remarks_by"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowCreateModal(false)}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={showEditModal} 
       backdrop="static"
       centered
      onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Remark</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ name: editRemarkName, remarks_by: editRemarkBy }}
            validationSchema={validationSchema}
            onSubmit={handleUpdateRemark}
            enableReinitialize
          >
            {({ handleSubmit, values, handleChange }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Remark Name</label>
                  <span className="mandatory">* </span>
                  <Field
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter Remark Name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label>Remark By</label>
                  <span className="mandatory">* </span>
                  <Field as="select" name="remarks_by" className="form-control">
                    <option value="">Select Remark By</option>
                    <option value="Class Teacher">Class Teacher</option>
                    <option value="Principle">Principal</option>
                  </Field>
                  <ErrorMessage
                    name="remarks_by"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowEditModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RemarkList;
