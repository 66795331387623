import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import "../../Styles/StudentProfile.css";
import {
  getStudentByParentId,
  getStudentById,
} from "../../Services/CommonApis/ParentPortalApi";
import { COOKIE } from "../../Schemas/cookieNames";
import { getSession } from "../../Services/Storage";
import { getParentId } from "../../Utility/CommonUtility/extractUserDetailsFromToken";
import {
  createPickUpRequest,
  getPickRequestById,
  updatePickUpRequestById,
} from "../../Services/AdminApis/PickupRequestApi";
import { showSuccessAlert, showErrorAlert } from "../../Modals/SweetAlertModel";

export default function PickUpRequest({ requestId }) {
  useEffect(() => {
    if (requestId) {
      PickUpRequestById(requestId);
    }
  }, [requestId]);
  const [requestDetails, setRequestDetails] = useState([]);
  const PickUpRequestById = async (requestId) => {
    try {
      const response = await getPickRequestById(requestId);
      setRequestDetails(response);
      console.log("ddddddddddd", response);
    } catch (error) {
      console.log(error);
    }
  };
  const token = getSession(COOKIE.ADMIN_TOKEN);
  const parentId = getParentId(token);
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    if (parentId) {
      fetchStudentByParent(parentId);
    }
  }, [parentId]);

  const fetchStudentByParent = async (parentId) => {
    try {
      const response = await getStudentByParentId({ parent_id: parentId });
      setStudentList(response.data.data);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };
  console.log(studentList);
  const handlePickUpRequest = async (values) => {
    try {
      let response;
      if (requestId) {
        response = await updatePickUpRequestById(requestId, values);
      } else {
        response = await createPickUpRequest(values);
      }
      if (response.status === 200) {
        showSuccessAlert("Success", response.data.message);
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = Yup.object().shape({
    student_id: Yup.string().required("Student is required"),
    pickup_date: Yup.date().required("Pickup date is required"),
    pickup_time: Yup.string().required("Pickup time is required"),
    reason: Yup.string().required("Reason is required"),
    pickup_by: Yup.string().required("Pickup by is required"),
    comments: Yup.string(),
  });

  return (
    <div className="card card-primary p-3">
      <div className="card-header">
        <h3 className="card-title">PickUp Request</h3>
      </div>
      <div className="card-body">
        <Formik
          initialValues={{
            student_id: requestDetails?.student_id?.id || "",
            pickup_date: requestDetails?.pickup_date || "",
            pickup_time: requestDetails?.pickup_time || "",
            reason: requestDetails?.reason || "",
            pickup_by: requestDetails?.pickup_by?.id || "",
            comments: requestDetails?.comments || "",
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handlePickUpRequest}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                {/* Student Selection */}
                <div className="form-group col-md-4">
                  <label htmlFor="student_id">
                    Student <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="student_id"
                    name="student_id"
                    className="form-control"
                  >
                    <option value="">Select Student</option>
                    {studentList.map((student) => (
                      <option key={student.id} value={student.id}>
                        {student.first_name + " " + student.last_name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="student_id"
                    component="div"
                    className="text-error"
                  />
                </div>

                {/* Pickup Date */}
                <div className="form-group col-md-4">
                  <label htmlFor="pickup_date">
                    Pickup Date <span className="mandatory">*</span>
                  </label>
                  <Field
                    type="date"
                    id="pickup_date"
                    name="pickup_date"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="pickup_date"
                    component="div"
                    className="text-error"
                  />
                </div>

                {/* Pickup Time */}
                <div className="form-group col-md-4">
                  <label htmlFor="pickup_time">
                    Pickup Time <span className="mandatory">*</span>
                  </label>
                  <Field
                    type="time"
                    id="pickup_time"
                    name="pickup_time"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="pickup_time"
                    component="div"
                    className="text-error"
                  />
                </div>
                {/* Pickup By */}
                <div className="form-group col-md-4">
                  <label htmlFor="pickup_by">
                    Pickup By <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="pickup_by"
                    name="pickup_by"
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="1">Parent</option>
                    <option value="2">Guardian</option>
                    <option value="3">Others</option>
                  </Field>
                  <ErrorMessage
                    name="pickup_by"
                    component="div"
                    className="text-error"
                  />
                </div>
                {/* <div className="form-group col-md-4">
                  <label htmlFor="drop_status">
                    Drop status <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="select"
                    id="drop_status"
                    name="drop_status"
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="1">waiting</option>
                    <option value="2">sent</option>
                    <option value="3">Others</option>
                  </Field>
                  <ErrorMessage
                    name="drop_status"
                    component="div"
                    className="text-error"
                  />
                </div> */}

                {/* Reason */}
                <div className="form-group col-md-4">
                  <label htmlFor="reason">
                    Reason <span className="mandatory">*</span>
                  </label>
                  <Field
                    as="textarea"
                    id="reason"
                    name="reason"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="reason"
                    component="div"
                    className="text-error"
                  />
                </div>

                {/* Comments */}
                <div className="form-group col-md-4">
                  <label htmlFor="comments">Comments</label>
                  <Field
                    as="textarea"
                    id="comments"
                    name="comments"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="comments"
                    component="div"
                    className="text-error"
                  />
                </div>
              </div>

              {/* Submit Button */}
              <div className="d-flex justify-content-end mt-3">
                <Button type="submit" variant="primary">
                  Submit Request
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
