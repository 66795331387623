import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  getDaywiseAttendanceClassSection,
  markCorrection,
} from "../../Services/AdminApis/AttendanceApi";
import { useParams } from "react-router-dom";
import { getClassByBranchId } from "../../Services/CommonApis/classApi";
import { getSectionByClassId } from "../../Services/CommonApis/SectionApi";
import {
  showSuccessAlert,
  showErrorAlert,
  showLoadingAlert,
} from "../../Modals/SweetAlertModel";

const MarkCorrection = () => {
  const { branchId } = useParams();
  const [loading, setLoading] = useState(false);
  const [absenteesData, setAbsenteesData] = useState([]); // Initialize as an empty array
  const [sections, setSections] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [isStateloading, setIsStateloading] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState();
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  useEffect(() => {
    fetchClasses(branchId);
  }, [branchId]);

  const fetchSections = async (classId, branchId) => {
    try {
      const response = await getSectionByClassId(classId, branchId);
      setSections(response.data.sections);
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  const fetchClasses = async (branchId) => {
    try {
      const response = await getClassByBranchId(branchId);
      setClasses(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  const handleAbsentSubmit = async (values) => {
    const { datewise } = values;
    setSelectedDate(datewise); // Update selectedDate here
    await fetchAbsentees(
      branchId,
      formatDate(datewise),
      selectedClassId,
      selectedSection
    );
  };

  const fetchAbsentees = async (
    branchId,
    datewise,
    selectedClassId,
    selectedSection
  ) => {
    try {
      setLoading(true);
      const response = await getDaywiseAttendanceClassSection(
        branchId,
        datewise,
        selectedClassId,
        selectedSection
      );
      const data = response.data;
      const presentData = data.present_data || [];
      const absentData = data.absent_data || [];

      const combinedData = [...presentData, ...absentData].map((item) => {
        const isPresent = presentData.some(
          (p) => p.student_id === item.student_id
        );
        return {
          ...item,
          studentName: item.student_name,
          className: `${item.class_name}- ${item.section_name}`,
          studentRoll: item.student_roll,
          date: item.attendance_date,
          status: isPresent ? "Present" : "Absent",
          markCorrection: isPresent ? "yes" : "no", // Pre-fill markCorrection based on status
        };
      });

      setAbsenteesData(combinedData);
    } catch (error) {
      console.error("Error fetching absentees:", error);
    } finally {
      setLoading(false);
    }
  };

  const validationDaywise = Yup.object({
    datewise: Yup.date().required("Date is required").nullable(),
  });

  const handleMarkCorrectionChange = (studentRoll, status) => {
    setAbsenteesData((prevData) =>
      prevData.map((student) =>
        student.student_roll === studentRoll
          ? { ...student, markCorrection: status }
          : student
      )
    );
  };

  const handleSubmitCorrection = async () => {
    const presentStudentIds = [];
    const absentStudentIds = [];

    absenteesData.forEach((item) => {
      if (item.markCorrection === "yes") {
        presentStudentIds.push(item.student_id);
      } else if (item.markCorrection === "no") {
        absentStudentIds.push(item.student_id);
      }
    });

    const correctionData = {
      date: selectedDate,
      branch_id: parseInt(branchId),
      class_id: parseInt(selectedClassId),
      section_id: parseInt(selectedSection),
      present_student_ids: presentStudentIds,
      absent_student_ids: absentStudentIds,
    };

    try {
      showLoadingAlert();
      const response = await markCorrection(correctionData);
      showSuccessAlert(response.message);
    } catch (error) {
      console.error("Error submitting correction:", error);
      showErrorAlert(error.message);
    }
  };

  const filteredClasses = classes.map(({ id, name, branch_id }) => ({
    id,
    name,
    branch_id,
  }));

  return (
    <>
      <Formik
        initialValues={{
          datewise: new Date().toISOString().split("T")[0],
          class_id: "",
          sectionSelection: "",
        }}
        validationSchema={validationDaywise}
        onSubmit={handleAbsentSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form
            className="d-flex flex-column justify-content-center mt-3"
            style={{
              border: "1px solid skyblue",
              marginTop: "10px",
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                backgroundColor: "rgb(0, 123, 255)",
                padding: "10px",
              }}
            >
              <h5 style={{ marginTop: "0px", color: "white" }}>
                <i className="fas fa-list"></i> Mark Correction for Absentees
              </h5>
            </div>
            <div className="row mt-3 ml-1 mr-1 mb-2">
              <div className="col-md-4">
                <label htmlFor="datewise">
                  Select Date<span className="mandatory">* </span>
                </label>
                <Field
                  type="date"
                  className={`form-control ${
                    errors.datewise && touched.datewise ? "is-invalid" : ""
                  }`}
                  id="datewise"
                  name="datewise"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setFieldValue("datewise", e.target.value);
                  }}
                />
                <ErrorMessage
                  name="datewise"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="col-md-4">
                <label>
                  Select Class<span className="mandatory">* </span>
                </label>
                <Field
                  as="select"
                  name="class_id"
                  className="form-control"
                  style={{ borderColor: "skyblue" }}
                  onChange={(event) => {
                    const selectedClassId = event.target.value;
                    setFieldValue("class_id", selectedClassId);
                    setSelectedClassId(selectedClassId);

                    const selectedClass = filteredClasses.find(
                      (classItem) => classItem.id == selectedClassId
                    );
                    if (selectedClass) {
                      const classId = selectedClass.id;
                      const branchId = selectedClass.branch_id;
                      fetchSections(classId, branchId);
                      setSections([]);
                      setFieldValue("sectionSelection", []);
                    }
                  }}
                >
                  {isStateloading ? (
                    <option value="" disabled>
                      Loading...
                    </option>
                  ) : (
                    <>
                      <option value="">Select class</option>
                      {filteredClasses.map((classItem) => (
                        <option key={classItem.id} value={classItem.id}>
                          {classItem.name}
                        </option>
                      ))}
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name="class_id"
                  component="div"
                  className="text-error"
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="sectionSelection">
                  Select Section<span className="mandatory">* </span>
                </label>
                <Field
                  as="select"
                  name="sectionSelection"
                  className="form-control"
                  style={{ borderColor: "skyblue" }}
                  onChange={(event) => {
                    const selectedSectionId = event.target.value;
                    setFieldValue("sectionSelection", selectedSectionId);
                    setSelectedSection(selectedSectionId);
                  }}
                >
                  {isStateloading ? (
                    <option value="" disabled>
                      Loading...
                    </option>
                  ) : (
                    <>
                      <option value="">Select Section</option>
                      {sections.map((sectionItem) => (
                        <option key={sectionItem.id} value={sectionItem.id}>
                          {sectionItem.name}
                        </option>
                      ))}
                    </>
                  )}
                </Field>
                <ErrorMessage
                  name="sectionSelection"
                  component="div"
                  className="text-error"
                />
              </div>
            </div>
            <div className="text-right mr-2 mb-1">
              <button type="submit" className="btn btn-primary">
                Search
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {loading ? (
        <div className="text-center mt-3">Loading...</div>
      ) : (
        <>
          <div className="mt-4">
            <DataTable
              value={absenteesData}
              rows={10}
              className="p-datatable-sm"
              showGridlines
              rowGroupMode="row"
              dataKey="student_id"
            >
              <Column
                header="Class & Section"
                body={(rowData) => `${rowData.className}`}
                sortable
              />
              <Column
                header="Roll & Name"
                body={(rowData) =>
                  `${rowData.studentName} (${rowData.studentRoll})`
                }
                sortable
              />
              <Column
                header="Mark Correction"
                body={(rowData) => {
                  return (
                    <div className="d-flex gap-3">
                      <label>
                        <input
                          type="radio"
                          className="mr-1"
                          name={`markCorrection-${rowData.student_roll}`}
                          value="yes"
                          checked={rowData.markCorrection === "yes"}
                          onChange={() =>
                            handleMarkCorrectionChange(
                              rowData.student_roll,
                              "yes"
                            )
                          }
                        />
                        Present
                      </label>
                      <label>
                        <input
                          className="ml-3 mr-2"
                          type="radio"
                          name={`markCorrection-${rowData.student_roll}`}
                          value="no"
                          checked={rowData.markCorrection === "no"}
                          onChange={() =>
                            handleMarkCorrectionChange(
                              rowData.student_roll,
                              "no"
                            )
                          }
                        />
                        Absent
                      </label>
                    </div>
                  );
                }}
              />
            </DataTable>

            <div className="text-right mt-2 mb-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmitCorrection}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MarkCorrection;
