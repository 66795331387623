import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "datatables.net-responsive-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons/js/buttons.colVis.min.js";
import "jszip/dist/jszip.min.js";
import Swal from "sweetalert2";
import { showSweetAlert, showErrorAlert, showLoadingAlert, showSuccessAlert } from "../../Modals/SweetAlertModel";
import { getSchoolManagers, deleteSchoolManagerById, getSchoolManagerById } from "../../Services/AdminApis/SchoolManagerApi";
const SchoolManagerList = () => {
  const tableRef = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");

  // Handle delete functionality
  const handleDelete = async (user_id, status) => {
    let value = status === 1 ? 0 : 1; // Determine the new value dynamically
    let valueText = value ? 'Activate' : 'Deactivate'
    const result = await showSweetAlert(
      "Are you sure?",
      `This will change the status to ${valueText}. Do you want to continue?`,
      "warning",
      `Yes, ${valueText} It!`,
      "No, Cancel!"
    );

    console.log("result", user_id, status);

    if (result.isConfirmed) {
      try {
        showLoadingAlert();

        // API call to update/delete the school manager status
        const response = await deleteSchoolManagerById(user_id, value);
        Swal.close();

        // Show success alert with the updated status
        showSuccessAlert(
          `${valueText}`,
          response.message || `Status changed to ${valueText} successfully.`
        );

        // Trigger a refresh to update the data in the UI
        setRefresh((prev) => !prev);
      } catch (err) {
        console.error("API error:", err);
        let errorMessage = "An unexpected error occurred. Please try again.";
        if (err.response) {
          errorMessage = err.response.data.error || err.response.data.message;
        } else if (err.request) {
          errorMessage = "No response from the server. Please try again.";
        }
        showErrorAlert("Error", errorMessage);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showErrorAlert("Cancelled", "The School manager is safe :)");
    }
  };


  // Fetch and handle the table data
  useEffect(() => {
    let setActive = 'color:red;'
    const table = $(tableRef.current).DataTable({
      destroy: true,
      columns: [
        { data: null, render: (data, type, row, meta) => meta.row + 1 },
        { data: "id" },
        { data: "full_name" },
        { data: "phone_no" },
        { data: "email" },
        {
          data: "school_id",
          render: (data) => {
            if (data && Array.isArray(data)) {
              return `
                <ul>
                  ${data
                  .map((school) => {
                    const menuNames = school.menu.map((menu) => menu.name).join(", ");
                    return `<li>${school.name} [${menuNames}]</li>`;
                  })
                  .join("")}
                </ul>
              `;
            }
            return "N/A";
          },
        },
        {
          data: "status",
          render: function (data, type, row) {
            // Check if status is 1 or 0 and return the corresponding text with styles            
            if (data === "1") {
              setActive = 'color:green;'
              return '<span style="color: green;">Active</span>';
            } else {
              return '<span style="color: red;">Inactive</span>';
            }
          }
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            return `
              <div class="icon-group" style="display: flex; gap: 10px;">
                 <a href="/admin/manager-edit/${row.id}" title="Edit"><i class="fas fa-edit"></i></a>
                 <a href="#" title="Delete" data-user_id="${row.id}" data-status="${row.status}" class="delete-icon"><i class="fas fa-power-off delete-icon" style="${setActive}"></i></a>
              </div>
            `;
          },
        },
      ],
      lengthChange: true,
      order: true,
      responsive: true,
      dom:
        '<"row mb-3"<"col-md-6 text-left"l>>' +
        '<"row mb-3"<"col-md-6 text-left"B><"col-md-6 text-right"f>>' +
        '<"row"<"col-sm-12"t>>' +
        '<"row"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
      buttons: [
        { extend: "copy", exportOptions: { columns: ":visible" } },
        { extend: "csv", exportOptions: { columns: ":visible" } },
        { extend: "excel", exportOptions: { columns: ":visible" } },
        { extend: "pdf", exportOptions: { columns: ":visible" } },
        { extend: "print", exportOptions: { columns: ":visible" } },
      ],
      serverSide: true,
      paging: true,
      pageLength: 10,
      lengthMenu: [
        [10, 25, 50, 75, 100, -1],
        [10, 25, 50, 75, 100, "All"],
      ],
      language: {
        paginate: { previous: "Back", next: "Next", first: "First", last: "Last" },
        emptyTable: "No Data Found",
      },
      ajax: async function (data, callback) {
        try {
          const start = data.start || 0;
          const length = data.length || 10;
          const page = Math.floor(start / length) + 1;
          const orderColumnIndex =
            data.order && data.order.length > 0 ? data.order[0].column : 0;
          const orderDirection =
            data.order && data.order.length > 0 ? data.order[0].dir : "desc";
          const orderColumn = data.columns[orderColumnIndex].data;
          const searchValue = data.search.value;
          const response = await getSchoolManagers({
            page: page,
            _limit: length,
            _sort: orderColumn,
            _order: orderDirection,
            q: searchValue,
          });
          const jsonData = response.data.data || [];
          const totalCount = response.data.total || 0;

          callback({
            draw: data.draw,
            recordsTotal: totalCount,
            recordsFiltered: totalCount,
            data: jsonData.length > 0 ? jsonData : [],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          showErrorAlert(error.response.data.message);
          setTimeout(() => {
            callback({
              draw: data.draw,
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }, 5000);
        }
      },
    });
    $(tableRef.current).on("click", ".delete-icon", function (e) {
      e.preventDefault();
      const userId = $(this).data("user_id");
      const status = $(this).data("status");
      console.log("userId Status", userId, status);
      handleDelete(userId, status);
    });
    return () => {
      table.destroy();
    };
  }, [refresh]);

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <h1>School Manager Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/admin/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">School Manager</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <div className="card ml-3 mr-3">
        <div className="card-header">
          <h3 className="card-title">School Manager Table</h3>
          <div className="card-tools">
            <ul className="nav nav-pills ml-auto">
              <li className="nav-item p-1">
                <a className="btn btn-primary active" href="/admin/manager-create">
                  Create New
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="card-body">
          <table ref={tableRef} id="example1" className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>S.No</th>
                <th>User Id</th>
                <th>Full name </th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>School Name [Menus]</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr>
                <th>S.No</th>
                <th>User Id</th>
                <th>Full name </th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>School Name [Menus]</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </tfoot>
          </table>
          {showError && (
            <div className={`error-alert error-alert-danger ${showError ? "fade-in" : "fade-out"}`} onAnimationEnd={() => { if (!showError) setError(""); }}>
              {error}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SchoolManagerList;