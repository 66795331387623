import React, { useState } from "react";
import AcademicFeeCreate from "../CommonPages/AcademicFeeCreate";
import StudentWiseFeeSetup from "../CommonPages/StudentWiseFeeSetup";

function FeeSetupManagement() {
  const [activeTab, setActiveTab] = useState("classlist");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "classlist" ? "active" : ""
              }`}
              onClick={() => handleTabClick("classlist")}
            >
              Studentwise Fee Setup
            </a>
          </li>

          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "createclass" ? "active" : ""
              }`}
              onClick={() => handleTabClick("createclass")}
            >
              Classwise Fee Setup
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === "classlist" ? "active" : ""}`}
            id="classlist"
          >
            {activeTab === "classlist" && <StudentWiseFeeSetup />}
          </div>

          <div
            className={`tab-pane ${
              activeTab === "createclass" ? "active" : ""
            }`}
            id="createclass"
          >
            {activeTab === "createclass" && <AcademicFeeCreate />}
          </div>
        </div>
      </div>
    </>
  );
}

export default FeeSetupManagement;
