import React, { useState, useEffect } from "react";
import SectionList from "../CommonPages/SectionList";
import SectionCreate from "../CommonPages/SectionCreate";
import SectionUpload from "./SectionUpload";
import { getSession } from "../../Services/Storage";
import { USERROLEID } from "../../Schemas/userRoles";
import { COOKIE } from "../../Schemas/cookieNames";
import { getUserRoleId } from "../../Utility/CommonUtility/extractUserDetailsFromToken";

function SectionManagement() {
  const [activeTab, setActiveTab] = useState("sectionlist");
  const [roleId, setRoleId] = useState(null);
  const adminToken = getSession(COOKIE.ADMIN_TOKEN);
  useEffect(() => {
    if (adminToken) {
      setRoleId(getUserRoleId(adminToken));
    }
  }, [adminToken, roleId]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="card-header">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTab === "sectionlist" ? "active" : ""
              }`}
              onClick={() => handleTabClick("sectionlist")}
            >
              Section List
            </a>
          </li>
          {roleId !== USERROLEID.TEACHER_ID && (
            <>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "createsection" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("createsection")}
                >
                  Create Section
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "uploadsection" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("uploadsection")}
                >
                  Upload Section
                </a>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className="card-body">
        <div className="tab-content">
          <div
            className={`tab-pane ${
              activeTab === "sectionlist" ? "active" : ""
            }`}
            id="sectionlist"
          >
            {activeTab === "sectionlist" && <SectionList />}
          </div>

          {roleId !== USERROLEID.TEACHER_ID && (
            <>
              <div
                className={`tab-pane ${
                  activeTab === "createsection" ? "active" : ""
                }`}
                id="createsection"
              >
                {activeTab === "createsection" && <SectionCreate />}
              </div>

              <div
                className={`tab-pane ${
                  activeTab === "uploadsection" ? "active" : ""
                }`}
                id="uploadsection"
              >
                {activeTab === "uploadsection" && <SectionUpload />}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SectionManagement;
